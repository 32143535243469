enum TIME_GRANULARITY {
  Undefined = 0,
  hourly = 1,
  daily = 2,
  weekly = 3,
  monthly = 4,
  yearly = 5,
  fivemin = 10,
}

export default TIME_GRANULARITY;
