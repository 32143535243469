import { ApiClients } from 'ApiClients';
import { PowerCol } from 'power/components/base/grid';
import { IBindingConstraintItem } from 'power/types';
import { FC, useEffect, useState } from 'react';
import ConstraintsTable from './ConstraintsTable';
// import SubstationCardTabs from './SubstationCardTabs';
import ISubstationInfoModel from 'power/types/ISubstationInfoModel';

type Props = {
  substationInfo: ISubstationInfoModel;
};

const ConstraintsBySubstation: FC<Props> = ({ substationInfo }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<IBindingConstraintItem[]>();

   useEffect(() => {
      const fetchData = async () => {
         setLoading(true);
         ApiClients.getInstance()
            .headroomConstraintsBySubstation(substationInfo.id)
            .then((data) => {
               setData(data);
            })
            .finally(() => {
               setLoading(false);
            });
      };
      fetchData();
   }, [substationInfo.id]);

   return (
      <>
         {/* <PowerCol span={24} style={{paddingBottom: 16}}></PowerCol>
         <PowerCol span={6}>
            <SubstationCardTabs
               title="Select Model Identifier" substationInfos={substationInfos} selectedSubstationId={selectedSubstationId}
               setSelectedSubstationId={setSelectedSubstationId} />
         </PowerCol> */}
         <PowerCol span={24} style={{ padding: '0px 40px 16px' }}>
            <h3>Substation Binding Constraints</h3>
            <ConstraintsTable loading={loading} data={data} />
         </PowerCol>
         {/* <PowerCol span={24} style={{paddingBottom: 16}}></PowerCol> */}
      </>
   );
};

export default ConstraintsBySubstation;
