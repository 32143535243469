import { Expression } from 'mapbox-gl';
import { MAP_LEGEND_COLORS } from 'power/constants/map/MAP_LEGEND_COLORS';
import { PRICE_STYLE } from 'power/constants/map/styles';
import type { LayerProps } from 'react-map-gl';

const normalizeNumberExpression = (getValueExpression: mapboxgl.Expression, locale = 'en', maxFractionDigits = 1): mapboxgl.Expression => {
   if (maxFractionDigits === 0) {
      getValueExpression = ['round', ['number', getValueExpression]];
   }

   return [
      'case',

      ['all', ['>=', ['abs', getValueExpression], 1.0e9]],

      ['concat', ['to-string', ['round', ['/', ['number', getValueExpression], ['number', 1.0e9]]]], 'B'],

      ['all', ['>=', ['abs', getValueExpression], 1.0e6]],
      ['concat', ['to-string', ['round', ['/', ['number', getValueExpression], ['number', 1000000]]]], 'M'],

      ['all', ['>=', ['abs', getValueExpression], 1.0e3]],
      ['concat', ['to-string', ['round', ['/', ['number', getValueExpression], ['number', 1000]]]], 'K'],

      [
         'number-format',
         getValueExpression,
         {
            locale,
            'max-fraction-digits': maxFractionDigits,
         },
      ],
   ];
};

const AVG_EXPRESSION: mapboxgl.Expression = ['/', ['to-number', ['get', 'price_total']], ['to-number', ['get', 'point_count']]];

const AVG_EXPRESSION_NORMALIZE: mapboxgl.Expression = normalizeNumberExpression(AVG_EXPRESSION);

const VALUE_EXPRESSION_NORMALIZE: mapboxgl.Expression = normalizeNumberExpression(['get', 'value'], undefined, 1);

const getLegendColorExpression = (min: number, max: number, linearProperty = AVG_EXPRESSION): Expression => {
   const tickValue = (max - min) / (MAP_LEGEND_COLORS.length - 1);

   const exp: Expression = ['interpolate', ['linear'], linearProperty];

   MAP_LEGEND_COLORS.forEach((color, i) => {
      exp.push(min + tickValue * i);
      exp.push(color);
   });
   return exp;
};

export const priceLayerProps = (min: number, max: number, unit: '$' | '%') => {
   const clusterColor = getLegendColorExpression(min, max);
   const singleColor = getLegendColorExpression(min, max, ['get', 'value']);

   const clusterLayer: LayerProps = {
      id: 'price-clusters',
      type: 'circle',
      source: 'price-layer-source',
      filter: ['has', 'point_count'],
      paint: {
         'circle-color': clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
         'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
   };

   const clusterCountLayer: LayerProps = {
      id: 'price-cluster-count',
      type: 'symbol',
      source: 'price-layer-source',
      filter: ['has', 'point_count'],
      paint: {
         'text-color': '#FFFFFF',
      },
      layout: {
         'text-field': unit === '$' ? ['concat', unit, AVG_EXPRESSION_NORMALIZE] : ['concat', AVG_EXPRESSION_NORMALIZE, unit],
         'text-size': 12,
         'text-font': ['Inter Medium'],
         'text-allow-overlap': true,
      },
   };

   const unclusteredPointLayer: LayerProps = {
      // id: 'price-unclustered-point',
      type: 'symbol',
      source: 'price-layer-source',
      filter: ['!', ['has', 'point_count']],
      paint: {
         ...PRICE_STYLE.PAINT,
         'text-color': singleColor,
         'icon-color': singleColor,
      },
      layout: {
         ...PRICE_STYLE.LAYOUT,
         // 'icon-offset': ['get', 'offsetIcon'],
         'text-font': ['Inter Medium'],
      },
   };

   const unclusteredPointHighlightLayer: LayerProps = {
      // id: 'price-unclustered-point',
      type: 'circle',
      source: 'price-layer-source',
      filter: ['!', ['has', 'point_count']],
      paint: {
         ...PRICE_STYLE.PAINT,
         'circle-color': '#7c00bf',
         'circle-radius': 7,
         /* 'circle-translate': ['get', 'offsetIcon'], */
      },
   };

   const unclusteredTextLayer: LayerProps = {
      // id: 'price-unclustered-text',
      type: 'symbol',
      source: 'price-layer-source',
      filter: ['!', ['has', 'point_count']],
      paint: {
         ...PRICE_STYLE.PAINT,
         'text-color': singleColor,
         // 'text-halo-color': '#fff',
         // 'text-halo-width': 16,
      },
      layout: {
         'text-field': unit === '$' ? ['concat', unit, VALUE_EXPRESSION_NORMALIZE] : ['concat', VALUE_EXPRESSION_NORMALIZE, unit],
         'text-font': ['Inter Medium'],
         'text-size': 13,
         'text-offset': [1.85, 0],
         // 'text-offset': ['get', 'offsetText'],
         'text-line-height': 1.5,
         'text-allow-overlap': false,
      },
   };
   return { clusterLayer, clusterCountLayer, unclusteredPointLayer, unclusteredPointHighlightLayer, unclusteredTextLayer };
};
