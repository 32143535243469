/* eslint-disable max-len */
import { ApiClients } from 'ApiClients';
import { ICapacitySubstations, ISubstationInfoRequest } from 'power/types';
import { HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON } from 'power/types/enum';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from './Container';
import QueryBar from './QueryBar/QueryBar';

const INITIAL_QUERY: ISubstationInfoRequest = {
   substationId: 148995,
};

export const TransmissionCapacity = () => {
   const [searchParams, setSearchParams] = useSearchParams();

   const [loading, setLoading] = useState<boolean>(true);
   const [query, setQuery] = useState<ISubstationInfoRequest>({ ...INITIAL_QUERY, substationId: Number(searchParams.get('substationId') ?? INITIAL_QUERY.substationId) });
   const [data, setData] = useState<ICapacitySubstations | undefined>();

   const onSubmit = useCallback(
      (newQuery: ISubstationInfoRequest) => {
         setQuery(newQuery);
         setSearchParams((prev) => new URLSearchParams({ ...prev, substationId: newQuery.substationId.toString() }));
      },
      [setSearchParams],
   );

   useEffect(() => {
      setLoading(true);
      ApiClients.getInstance()
         .headroomOverviewSubstations({ substationId: query.substationId, direction: HEADROOM_CAPACITY_DIRECTION.CHARGING, season: HEADROOM_CAPACITY_SEASON.SUMMER })
         .then((response: ICapacitySubstations) => {
            setData(response);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [query]);

   return (
      <>
         <QueryBar loading={loading} query={query} onSubmit={onSubmit} />
         {query !== undefined && data !== undefined && <Container query={query} data={data} />}
      </>
   );
};
