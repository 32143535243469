import { BasisRiskMapContent } from './type';

const en: BasisRiskMapContent = {
   mapPageTitle: 'Basis Risk Map',
   basisRiskDetails: 'Details',
   basisRiskOverview: 'Basis Risk Overview',
   nodeOverview: 'Node Overview',
   yearlyTrend: 'Yearly Trend',
};

export default en;
