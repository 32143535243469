import classes from './index.module.scss';


const FutureInfoTab = () => (
   <div className={classes.fullContainer}>
      <div className={classes.filterBar}>
            
      </div>
      <div className={classes.container}>
            Future Data Info
      </div>
   </div>
);

export default FutureInfoTab;
