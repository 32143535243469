import { ApiClients } from 'ApiClients';
import { PowerCol } from 'power/components/base/grid';
import { BranchInfo, IBindingConstraintItem } from 'power/types';
import { FC, useEffect, useState } from 'react';
import BranchCardTabs from './BranchCardTabs';
import ConstraintsTable from './ConstraintsTable';

type Props = {
  substationId: number;
};
const ConstraintsByBranch: FC<Props> = ({ substationId }) => {
   const [branches, setBranches] = useState<BranchInfo[]>();
   const [selectedBranchId, setSelectedBranchId] = useState<string>();

   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<IBindingConstraintItem[]>();

   useEffect(() => {
      const fetchData = async () => {
         ApiClients.getInstance().headroomBranches(substationId).then((data) => {
            setBranches(data);
         });
      };
      fetchData();
   }, [substationId]);

   useEffect(() => {
      if (branches === undefined || branches.length === 0) return;
      setSelectedBranchId(branches[0].id);
   }, [branches]);

   useEffect(() => {
      if (branches === undefined) return;

      if(branches.length === 0){
         setData([]);
         setLoading(false);
      }

      const fetchData = async () => {
         if (selectedBranchId === undefined) return;
         setLoading(true);
         ApiClients.getInstance().headroomConstraintsByBranch(selectedBranchId)
            .then((data) => {
               setData(data);
            })
            .finally(() => {
               setLoading(false);
            });
      };
      fetchData();
   }, [branches, selectedBranchId]);

   return (
      <>
         {/* <PowerCol span={24} style={{paddingBottom: 16}}></PowerCol> */}
         <PowerCol span={24}>
            <BranchCardTabs
               title="Select Releated Branch" branchInfos={branches} selectedBranchId={selectedBranchId}
               setSelectedBranchId={setSelectedBranchId} />
         </PowerCol>
         <PowerCol span={24} style={{ padding: '0px 40px 16px' }}>
            <h3>Branch Binding Constraints</h3>
            <ConstraintsTable loading={loading} data={data} />
         </PowerCol>
         <PowerCol span={24} style={{paddingBottom: 16}}></PowerCol>
      </>
   );
};

export default ConstraintsByBranch;