import { Layout } from 'antd';
import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import ReactGA from 'react-ga4';

export const MobilePage = () => {
   ReactGA.send({ hitType: 'pageview', page: 'Mobile', title: 'Mobile' });

   return (
      <PrivatePageLayout>
         <Layout style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginBottom: '1rem' }}>
               <span style={{ fontSize: '4rem', fontWeight: 700 }}> Mobile</span>
            </div>
            <div>
               <span style={{ fontSize: '1.5rem', fontWeight: 400 }}>Please use desktop.</span>
            </div>
         </Layout>
      </PrivatePageLayout>
   );
};
