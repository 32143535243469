import PowerMap from 'power/map';
import { usePowerMap } from 'power/map/usePowerMap';
import { useCallback } from 'react';
import SiderControl from './SiderControl';
import classes from './index.module.scss';

const MAP_ID = 'home-map-v2';

const HomeMap = () => {
   const {capacityLayer, nodeLayers, selection, setSelection } = usePowerMap();

   const setSelectedSubstation = useCallback(
      (substationId: number) => {
         setSelection({ substationIds: [substationId] });
      },
      [setSelection],
   );

   const setSelectedNode = useCallback(
      (nodeId: number) => {
         setSelection((prev) => ({ nodes: { ...prev.nodes, ids: [nodeId] } }));
      },
      [setSelection],
   );

   const clearSelections = useCallback(() => {
      setSelection({});
   }, [setSelection]);

   return (
      <div className={classes.fullContainer}>
         <div className={classes.container}>
            <PowerMap id={MAP_ID} controlPositions={{ /* fullScreen: 'top-right', */ baseLayerSwitch: 'bottom-right', /* navigation: 'top-right', */ search: 'top-left' }}>
               <SiderControl
                  capacityQuery={capacityLayer?.query} nodeQuery={nodeLayers?.query ?? {}} selection={selection}
                  setSelectedSubstation={setSelectedSubstation} setSelectedNode={setSelectedNode} onClose={clearSelections} />
            </PowerMap>
         </div>
      </div>
   );
};

export default HomeMap;
