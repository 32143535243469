import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import IQTable from 'modules/InterconnectionQueue/table';
import PATHS from 'power/PATHS';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const IQListPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.IQ_List, title: 'Interconnection Queue' });
   
   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Interconnection Queue" />}>
         <IQTable />
      </PrivatePageLayout>
   );
}