import { FeatureCollection, Point } from 'geojson';
import { IHeadroomStaticPointFeature } from 'power/types';
import { IHeadroomStaticLayers } from 'power/types/IHeadroomStaticPointFeature';

export const headroomCapacityToSubstationsGeojson = (data: IHeadroomStaticPointFeature[]): FeatureCollection<Point> => ({
   type: 'FeatureCollection',
   features: data.map((s) => {
      const properties = s.labels.reduce(
         (acc, label) => {
            acc[label.key] = label.value;
            return acc;
         },
      { icon: s.icon || '' } as any,
      );

      const offset = 0; // 0.02;
      return {
         type: 'Feature',
         geometry: { type: 'Point', coordinates: [s.lonLat[0] + offset, s.lonLat[1] + offset] },
         properties,
      };
   }),
});

export const headroomStaticPointFeaturesToGeojson = (
   data: IHeadroomStaticLayers,
): {
  generator: FeatureCollection<Point>;
  iq: FeatureCollection<Point>;
  load: FeatureCollection<Point>;
  retirement: FeatureCollection<Point>;
  transformer: FeatureCollection<Point>;
} => {
   const generator = headroomCapacityToSubstationsGeojson(data.generator);
   const iq = headroomCapacityToSubstationsGeojson(data.iq);
   const load = headroomCapacityToSubstationsGeojson(data.load);
   const retirement = headroomCapacityToSubstationsGeojson(data.retirement);
   const transformer = headroomCapacityToSubstationsGeojson(data.transformer);

   return { generator, iq, load, retirement, transformer };
};

export const bboxCenterCoordinate = (bbox: number[]): [number, number] => {
   console.log('bbox', bbox);
   const [minX, minY, maxX, maxY] = bbox;
   return [(minX + maxX) / 2, (minY + maxY) / 2];
};
