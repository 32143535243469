import { ApiClients } from 'ApiClients';
import { PowerSearchFields } from 'power/types';
import { fromQueryString, toQueryString } from 'power/utils/queryString';
import { ComponentType, Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props<D, Q = Partial<PowerSearchFields>> {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  apiRoute: string;
  setPageData: (data: D) => void;
  query?: Q;
  setQuery: Dispatch<SetStateAction<Q | undefined>>;
  QueryBar: ComponentType<{ loading: boolean; query: Q; onSubmit: (newQuery: Q) => void }>;
}

function PowerPage<D, Q>({ loading, setLoading, apiRoute, setPageData, query, setQuery, QueryBar }: Props<D, Q>) {
   const [searchParams, setSearchParams] = useSearchParams();
   // const { hash } = useLocation();

   const onQuery = useCallback((newQuery: Q) => setQuery(newQuery), [setQuery]);

   const fetcher = useCallback(async (request: Partial<Q>) => ApiClients.getInstance().ApiRequestPage<Q, D>(apiRoute, request), [apiRoute]);

   const onSubmitFilter = useCallback(
      (newQuery: Q) => {
      // const newQueryParams = toQueryString(newQuery as any);
         const hash = window.location.hash;
         setSearchParams((prev) => {
            const prevQ = fromQueryString<Q>(prev);
            return toQueryString({ ...prevQ, ...newQuery });
         });
         window.location.hash = hash;
      },
      [setSearchParams],
   );

   useEffect(() => {
      setLoading(true);
      const queryRequest = fromQueryString<Q>(searchParams);
      fetcher(queryRequest)
         .then((pageDetail) => {
            onQuery(pageDetail.requestModel);
            setPageData(pageDetail.result);
            setLoading(false);
         })
         .catch(() => {
            console.error('Error while fetching data');
         })
         .finally(() => {
            setLoading(false);
         });
   }, [fetcher, onQuery, query, searchParams, setLoading, setPageData]);

   if (query === undefined) return null;

   return <QueryBar loading={loading} query={query} onSubmit={onSubmitFilter} />;
}

export default PowerPage;
