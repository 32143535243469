import { Spin } from 'antd';
import { FC } from 'react';

type Props = {
  size?: 'small' | 'large' | 'default';
};
export const FullSpin: FC<Props> = ({ size }) => (
   <div style={{ minHeight: 400, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin size={size} />;
   </div>
);
