import { CircleLayout, CirclePaint, Expression, LineLayout, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

// BORDER
const LINE_LAYOUT: LineLayout = {};
const LINE_PAINT: LinePaint = {
   'line-color': '#FFFFFF',
   'line-width': 2,
};

// LABEL
const TITLE_LAYOUT: SymbolLayout = {
   // 'Zone' değeri ile ['get', 'price'] birleştir
   'text-field': ['concat', ['get', 'zone'], '\n', ['to-string', ['get', 'price']]],
   'text-size': 14, // Yazı boyutu
   // font weight
   'text-font': ['Arial Unicode MS Bold'],
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, -.25],
};
const TITLE_PAINT = (circleColor?: Expression | string): SymbolPaint => ({
   // 'text-color': '#fff',
   // 'text-color': '#597EF7',
   'text-color': circleColor ?? '#FFFFFF',
});

// CIRCLE
const CIRCLE_LAYOUT: CircleLayout = {
};
const CIRCLE_PAINT = (circleColor?: Expression | string): CirclePaint => ({
   // 'circle-color': '#00ff00', // clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
   // 'circle-color': circleColor,
   'circle-color': '#FFFFFF',
   'circle-radius': 22,
   // border color
   // 'circle-stroke-color': '#FFFFFF',
   'circle-stroke-color': circleColor,
   'circle-stroke-width': 1,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default { LINE_LAYOUT, LINE_PAINT, TITLE_LAYOUT, TITLE_PAINT, CIRCLE_LAYOUT, CIRCLE_PAINT };
