import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { HeaderSecondary } from '../HeaderSecondary/HeaderSecondary';
import { SidebarActions } from '../SidebarActions/SidebarActions';
import classes from './PageContent.module.scss';

export const PageContent = ({ headerCount = 0, actionBar, headerSecondary, children }: { headerCount: number; actionBar: React.ReactNode; headerSecondary?: React.ReactNode; children: React.ReactNode }) => (
   <>
      <Layout style={{ marginRight: actionBar === undefined ? 0 : 80 }}>
         {/* {actionBar && <SidebarActions>{actionBar}</SidebarActions>} */}
         <Content className={classes.pageContent}>
            {headerSecondary && <HeaderSecondary>{headerSecondary}</HeaderSecondary>}
            {children}
         </Content>
      </Layout>

      {actionBar && <SidebarActions headerCount={headerCount}>{actionBar}</SidebarActions>}
   </>
);
