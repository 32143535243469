import { PPMapContent } from './type';

const en: PPMapContent = {
   title: 'Power Plant Map',
   retired_t: 'Retired',
   canceled_t: 'Canceled',
   operating_t: 'Operating',
   planned_t: 'Planned',
   outOfService_t: 'Out of Service',
   capacityByFuelTypeAndOperatingDate_t: 'Capacity (MW) by Fuel Type & Operating Date',
   entityQYear_t: 'Entity / Q Year',
   averageBasisRisk_t: 'Average Basis Risk',
};

export default en;
