import { Switch } from 'antd';
import mapboxgl from 'mapbox-gl';
import { MAPBOX } from 'power/constants';
import { usePowerMap } from 'power/map/usePowerMap';
import React, { useCallback } from 'react';
import styles from './BaseLayerControlContainer.module.scss';

type Props = {
  map?: mapboxgl.Map;
};
const BaseLayerControlContainer: React.FC<Props> = ({ map }) => {
   const { mapStyle, setMapStyle } = usePowerMap();

   const toggleStyle = useCallback(() => {
      if (map) {
         const nextStyleOption = mapStyle === 'Default' ? 'Satellite' : 'Default';
         map.setStyle(MAPBOX.Styles[nextStyleOption]);
         setMapStyle(nextStyleOption);
      }
   }, [map, mapStyle, setMapStyle]);

   if (map === undefined) return null;

   return (
      <div className={styles.baseLayerControlContainer}>
         <Switch
            checkedChildren="Default" unCheckedChildren="Satellite" defaultChecked={mapStyle === 'Satellite'}
            onChange={toggleStyle} style={{ backgroundColor: mapStyle === 'Default' ? '#666666' : '#42b389' }} />
      </div>
   );
};

export default BaseLayerControlContainer;
