import { CircleLayout, CirclePaint } from 'mapbox-gl';

const LAYOUT: CircleLayout = {
   visibility: 'visible',
};

const PAINT: CirclePaint = {
   /* 'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#9dc5e8', '#1D39C4'],
   'fill-outline-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#1D39C4', '#597EF7'],
   'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.3], */
   'circle-radius': 6,
   'circle-color': 'rgba(55,148,179,1)',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
