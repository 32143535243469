enum VECTOR_LAYER_ID {
  POWER_PLANTS = 'powerdevd.power_plants_tileset',
  // TRANSMISSON_LINES = 'powerdevd.transmission_lines_tileset',
  TRANSMISSON_LINES = 'powerdevd.tl_ny_iso_8', // 'powerdevd.ny_iso_tl',
  ZONE = 'powerdevd.ny_iso_zones',
  // ICAP_ZONE = 'powerdevd.ny_iso_icap_zones',
  ICAP_ZONE = 'powerdevd.nyiso_icap1',

  COUNTIES = 'powerdevd.county_boundaries_tileset',
  STATES = 'powerdevd.state_boundaries_tileset',
  ISO = 'powerdevd.iso_tileset',

  OVERPASS_RELATIONS = 'powerdevd.clql8i13r6cv51tr0sav7dslf-2lqii',
  OVERPASS_WAYS = 'powerdevd.8e3ntel7',
  OVERPASS_NODES = 'powerdevd.df6067li',

  PRICE_LAYER = 'powerdevd.price',
  PRICE_LABEL_LAYER = 'powerdevd.price-label',
}

export default VECTOR_LAYER_ID;
