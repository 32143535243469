import PageContainer from 'components/ui/PageContainer';
import { IIQTableRequest } from 'power/types';
import { useEffect, useState } from 'react';
import Detail from './Detail';
import FilterBar from './FilterBar';
import IIQList from './IIQList';
import useIQTable, { initialFilter } from './useIQTable';

const IQTable = () => {
   const [detailId, setDetailId] = useState<number>(undefined as any);
   const [filter, setFilter] = useState<IIQTableRequest>(initialFilter);
   const { data, loading, fetch } = useIQTable();

   useEffect(() => {
      fetch(filter);
   }, [fetch, filter]);

   return (
      <PageContainer status="success" statusCode={200} filterBarRowCount={0}>
         <FilterBar filter={filter} setFilter={setFilter} />

         <IIQList
            loading={loading}
            data={data as any}
            currentPageNumber={filter.pageNumber}
            onPaginationChange={(pageNumber, pageSize, pfilter, order) => {
               setFilter({ ...filter, pageNumber, pageSize, ...pfilter, order });
            }}
            onDetail={setDetailId}
         />

         {detailId && <Detail id={detailId} onClose={() => setDetailId(undefined as any)} />}
      </PageContainer>
   );
};

export default IQTable;
