// import { CommentOutlined, ShareAltOutlined, StarOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { ENV } from 'power/constants';
import { DATA_PROVIDER } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { FC } from 'react';
import classes from './HeaderTitle.module.scss';

interface IHeaderTitle {
  title: string;
}

const HeaderTitle: FC<IHeaderTitle> = ({ title }) => {
   const { iso, isos, setIso } = useAuth();
   

   document.title = title;

   return (
      <div className={classes.header}>
         <div className={classes.headerLogo}>
            <img src="/img/powerdev_96_48.png" alt="PowerDev" />
         </div>
         <div className={classes.headerTitle}>{title}</div>
         <div className="header-action-buttons">
            {/* <div role="button" title="Share">
               <ShareAltOutlined />
            </div>
            <div role="button" title="Add a comment">
               <CommentOutlined />
            </div>
            <div role="button" title="Add to favorites">
               <StarOutlined />
            </div> */}
            <div role="button" title="ISO" style={{width: 'auto'}}>
               <Select
                  variant="borderless" value={iso} /* options={isos.map((x) => ({ label: DATA_PROVIDER[x], value: x }))} */
                  onChange={(value) => setIso(value)}>
                  {isos.map((x) => (
                     <Select.Option key={x} value={x} disabled={x !== DATA_PROVIDER.NYISO && !ENV.DEVELOPMENT}>
                        {DATA_PROVIDER[x]}
                     </Select.Option>
                  ))}
               </Select>
            </div>
         </div>
      </div>
   );
};

export default HeaderTitle;
