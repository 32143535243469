// import { PowerMap } from 'power/components/maps';
import { IICAPCapacityParameters } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC } from 'react';
import Table from '../Table';

type Props = {
  data?: IICAPCapacityParameters[];
};

const TslLcrTable: FC<Props> = ({ data }) => {
   const keys = ['TSL (%)', 'LCR (%)'];

   /* const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] = [
      { zone: ICAP_ZONE_NYISO.LI, keys, allValues: [[482.0, 3.91]] },
      { zone: ICAP_ZONE_NYISO.NYC, keys, allValues: [[482.0, 3.91]] },
      { zone: ICAP_ZONE_NYISO.GJ, keys, allValues: [[482.0, 3.91]] },
   ]; */

   const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] =
    data
       ?.filter((x) => x.transmission_security_limit || x.capacity_requirement)
       .map((x) => ({
          zone: x.zone,
          keys,
          allValues: [[x.transmission_security_limit, x.capacity_requirement]],
       })) ?? [];

   if(data === undefined || data.length === 0) return null;

   return <Table headers={['Capacity Parameters', '', data && data.length > 0 ? data[0].period_year.toString() : '']} rows={rows} />;
};

export default TslLcrTable;
