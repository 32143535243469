import { FUEL_TYPE } from 'power/types/enum';
import TL_VOLTAGE from 'power/types/enum/TL_VOLTAGE';

const IMAGE_URL = 'https://image.power.dev';
// const IMAGE_URL = 'https://s3.us-east-2.amazonaws.com/image.power.dev';

const IMAGE_PATHS = {
   /**
   * website
   */
   LOGO_LIGHT: `${IMAGE_URL}/website/logos/power_dev_logo_white_60.png?v=1`,
   LOGO_DARK: `${IMAGE_URL}/website/logos/power_dev_logo_black_60.png?v=1`,
   USER_NO_IMAGE: `${IMAGE_URL}/website/img/user-no-image.png`,
   MARKER: `${IMAGE_URL}/website/svg/marker.svg`,
   LEGEND: `${IMAGE_URL}/website/svg/legend_icon.svg`,

   /**
   * map-assets
   */
   MAP_ASSETS: {
      HUB: `${IMAGE_URL}/portal/map-assets/lmp/hub.png`,
      NODE: `${IMAGE_URL}/portal/map-assets/lmp/nodev2.png`,
      BUS: `${IMAGE_URL}/portal/map-assets/lmp/bus.png`,

      HUB_SDF: `${IMAGE_URL}/portal/map-assets/price/hub.png`,
      NODE_SDF: `${IMAGE_URL}/portal/map-assets/price/node.png`,
      BUS_SDF: `${IMAGE_URL}/portal/map-assets/price/bus.png`,

      TEST: `${IMAGE_URL}/portal/map-assets/price/hub.png`,
      TEST2: `${IMAGE_URL}/portal/map-assets/price/sdf.png`,
      FUEL_TYPE: (fuelType: FUEL_TYPE, x = '') => `${IMAGE_URL}/portal/map-assets/pp/${FUEL_TYPES_IMAGE_NAMES[fuelType]}${x}.png`,
      // FUEL_TYPE_IQ: (fuelType: FUEL_TYPE, x = '') => `${IMAGE_URL}/portal/map-assets/iq/${FUEL_TYPES_IMAGE_NAMES[fuelType]}${x}.png`,

      // remove '+' ''
      VOLTAGE: (tlVoltage: TL_VOLTAGE, x = '') => encodeURI(`${IMAGE_URL}/portal/map-assets/transmission-lines/voltage/${tlVoltage.replace('+', '')}${x}.png`),

      GRID: (l: 'generator' | 'iq' | 'load' | 'retirement' | 'transformer') => `${IMAGE_URL}/portal/map-assets/grid/${l}.png`,

      FILE: (name: string) => `${IMAGE_URL}/portal/map-assets/${name}`,
   },
};

const FUEL_TYPES_IMAGE_NAMES: Record<FUEL_TYPE, string> = {
   [FUEL_TYPE.Undefined]: 'undefined',
   [FUEL_TYPE.Wind]: 'wind',
   [FUEL_TYPE.Solar]: 'solar',
   [FUEL_TYPE.Coal]: 'coal',
   [FUEL_TYPE.Gas]: 'gas',
   [FUEL_TYPE.Hydro]: 'hydro',
   [FUEL_TYPE.Nuclear]: 'nucleer',
   [FUEL_TYPE.Hybrid]: 'hybrid',
   [FUEL_TYPE.Oil]: 'oil',
   [FUEL_TYPE.Battery]: 'battery',
   [FUEL_TYPE.OtherStorage]: 'storage-other2',
   [FUEL_TYPE.BioMass]: 'biomass',
   [FUEL_TYPE.Thermal]: 'thermal',

   [FUEL_TYPE.ACTransmission]: 'ac-transmission',
   [FUEL_TYPE.DCTransmission]: 'dc-transmission',
   [FUEL_TYPE.Other]: 'other',

   // [FUEL_TYPE.Geothermal]: 'geothermal',
   // [FUEL_TYPE.NaturalGas]: 'gas',
   // [FUEL_TYPE.PetroleumCoke]: 'petroleum',
   // [FUEL_TYPE.PumpedStorage]: 'pumped',
};

export default IMAGE_PATHS;
