enum LOCATION_TYPE {
  Undefined = 0,
  Region = 3,
  StateProvince = 5,
  City = 7,
  District = 8,
  Area = 10,
  Zone = 30,
  AncillaryRegion = 40,
  EiaRegion = 41,
  BalancingAuth = 45,
  DraftNode = 110,
  OutageZone = 200,
  FuelMixZone = 201,
  FuelMixRegion = 202,
  Postcode = 300,
  Place = 301,
  Locality = 302,
  Neighborhood = 304,
  MapBox = -1,
}

export default LOCATION_TYPE;
