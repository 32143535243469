// import { CommentOutlined, ShareAltOutlined, StarOutlined } from '@ant-design/icons';
import { FC } from 'react';
import classes from './InfoBox.module.scss';

interface IInfoBox {
  title: string;
  data: {key: string; value: string | number;}[];
}

const InfoBox: FC<IInfoBox> = ({ title, data }) => (
   <div className="info-box">
      <div className={classes.content}>
         <div className={classes.title}>
            {title}
         </div>
         <div className={classes.datas}>
            {
               data.map(({ key, value }) => (
                  <div key={key} className={classes.data}>
                     <div className={classes.label}>
                        {key}
                     </div>
                     <div className={classes.value}>
                        {value}
                     </div>
                  </div>
               ))
            }
         </div>
      </div>
   </div>
);

export default InfoBox;
