import { IMAGE_PATHS } from 'power/constants';

const images: { name: string; url: string }[] = [
   { name: 'Hub-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.HUB_SDF },
   { name: 'Node-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.NODE_SDF },
   { name: 'Bus-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.BUS_SDF },
   /* { name: 'Test-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.TEST }, */
   /* { name: 'Test2-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.TEST2 }, */
];

export default images;
