/* eslint-disable max-len */
import { PPOverviewContent } from './type';

const en: PPOverviewContent = {
   title: 'Power Plant Overview',
   capacityByFuelType: 'Capacity (MW) by Fuel Type',
   capacityByFuelTypeInfo:
    'The Capacity (MW) by Fuel Type view shows the capacity distribution in MW for the US in a bar chart and the proportional capacity distribution by fuel types for each ISO and the non-ISO region in individual pie charts. The data can be explored in depth by utilizing the ISO, fuel type, status, and operating year filters located at the top of the page.',
   projectMWByISOAndOperatingDate: 'Project (MW) by ISO & Operating Date',
   projectMWByISOAndOperatingDateInfo:
    'The Project (MW) by ISO & Operating Date charts shows the historical capacity additions of power plants categorized by fuel type and operating year for each ISO. The data can be explored in depth by utilizing the ISO, fuel type, status, and operating year filters located at the top of the page.',
   capacityFactorByFuelType: 'Capacity Factor by Fuel Type',
   capacityFactorByFuelTypeInfo: 'The Capacity Factor by Fuel Type chart shows the average capacity factor of generators by fuel type.',
   capacityFactor: 'Capacity Factor',
   averagePlantAgeByFuelType: 'Average Plant Age by Fuel Type',
   averagePlantAgeByFuelTypeInfo: 'The Average Plant Age by Fuel Type chart shows the average age of generators by fuel type.',
   ageYear: 'Age (Year)',
   retired: 'Retired',
   canceled: 'Canceled',
   operating: 'Operating',
   planned: 'Planned',
   outOfService: 'Out of Service',
};

export default en;
