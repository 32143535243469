import { Input, type InputProps } from 'antd';
import { useOnChangeDebounce } from 'power/hooks';
import { ChangeEvent, FC, PropsWithChildren, useCallback } from 'react';

interface IInputDebounce extends InputProps {
  value: string | number | readonly string[];
  onChangeDebounce: (newValue: string | number | readonly string[]) => Promise<boolean>;
  onChangeCallback?: (newValue: string | number | readonly string[]) => void;
  delay?: number;
}

const InputDebounce: FC<PropsWithChildren<IInputDebounce>> = ({
   value,
   // custom props
   onChangeDebounce,
   onChangeCallback,
   delay = 500,
   ...rest
}) => {
   const { inputValue, onChangeHandler } = useOnChangeDebounce(value, onChangeDebounce, onChangeCallback, delay);

   const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
         onChangeHandler(e.target.value);
      },
      [onChangeHandler],
   );

   return <Input value={inputValue} {...rest} onChange={onChange} />;
};

export default InputDebounce;
