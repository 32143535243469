const ENV = {
   DEVELOPMENT: process.env.NODE_ENV === 'development',
   DOMAIN: (process.env.REACT_APP_DOMAIN ?? 'prod') as ('dev' | 'test' | 'trial' | 'prod'),
   BASE_API_URL: process.env.REACT_APP_API_URL ?? 'https://dev-portalapi.power.dev/',
   BASE_API_V2_URL: process.env.REACT_APP_API_V2_URL ?? 'https://power-hub-dev-api.connectpowerdev.com/',
   MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? 'pk.eyJ1IjoicG93ZXJkZXZkIiwiYSI6ImNsM3N1aHU4YTAyeG4zY254dmZzeHFxOXkifQ.VH5mvbyZ4RHi2pF4nJvw0Q',
   GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID ?? '',
};

console.debug(ENV);
export default ENV;
