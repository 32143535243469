import LazyComponent from 'power/components/base/LazyComponent';
import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { MARKET_TYPE, MOVING_AVERAGE_TYPE, TBX_VALUE_TYPE } from 'power/types/enum';
import { FC, memo } from 'react';
import InfoBoxes from './components/InfoBoxes';
import PerformanceTimeSeriesChart from './components/PerformanceTimeSeriesChart';
import TBxTimeSeriesChart from './components/TimeSeriesChart';
import YearlyTxBx from './components/YearlyTBxChart';

type Props = {
   query: INodeInfoRequest;
   data: INodeInfoResponse;
};

const TbxTab: FC<Props> = ({ query, data }) => (
   <PowerRow gutter={[16, 16]}>
      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <InfoBoxes nodeId={data.node.id} />
      </PowerCol>
      <PowerCol
         xs={24} sm={24} md={12}
         lg={12}>
         <TBxTimeSeriesChart nodeId={data.node.id} tbxValueType={TBX_VALUE_TYPE.Performance} />
      </PowerCol>
      <PowerCol
         xs={24} sm={24} md={12}
         lg={12}>
         <TBxTimeSeriesChart nodeId={data.node.id} tbxValueType={TBX_VALUE_TYPE.Average} />
      </PowerCol>
      <PowerCol
         xs={24} sm={24} md={16}
         lg={16}>
         <LazyComponent>
            <PerformanceTimeSeriesChart
               filter={{
                  id: data.node.id,
                  hubId: data.node.hubId,
                  // component: TXBX_COMPONENT.Tb4,
                  // periodType: TIME_GRANULARITY.yearly,
                  // marketType: MARKET_TYPE.Realtime,
                  // sumType: DATA_SUM_TYPE.Daily,
                  // startDate: '2018-01-01T00:00:00.000',
                  // endDate: '2024-05-02T00:00:00.000',
               }}
               marketType={MARKET_TYPE.DayAhead}
            />
         </LazyComponent>
      </PowerCol>

      <PowerCol
         xs={24} sm={24} md={8}
         lg={8}>
         <LazyComponent>
            <YearlyTxBx
               filter={{
                  movingAverage: MOVING_AVERAGE_TYPE.MovingAvg100Day,
                  id: data.node.id,
                  // hubId: data.node.hubId,
                  // component: TXBX_COMPONENT;
                  // periodType: TIME_GRANULARITY;
                  // marketType: MARKET_TYPE;
                  // sumType: DATA_SUM_TYPE;
                  // startDate: string;
                  // endDate: string;
               }}
               marketType={MARKET_TYPE.DayAhead}
            />
         </LazyComponent>
      </PowerCol>

      <PowerCol
         xs={24} sm={24} md={16}
         lg={16}>
         <LazyComponent>
            <PerformanceTimeSeriesChart
               filter={{
                  id: data.node.id,
                  hubId: data.node.hubId,
                  // component: TXBX_COMPONENT.Tb4,
                  // periodType: TIME_GRANULARITY.yearly,
                  // marketType: MARKET_TYPE.Realtime,
                  // sumType: DATA_SUM_TYPE.Daily,
                  // startDate: '2018-01-01T00:00:00.000',
                  // endDate: '2024-05-02T00:00:00.000',
               }}
               marketType={MARKET_TYPE.Realtime}
            />
         </LazyComponent>
      </PowerCol>

      <PowerCol
         xs={24} sm={24} md={8}
         lg={8}>
         <LazyComponent>
            <YearlyTxBx
               filter={{
                  movingAverage: MOVING_AVERAGE_TYPE.MovingAvg100Day,
                  id: data.node.id,
                  // hubId: data.node.hubId,
                  // component: TXBX_COMPONENT;
                  // periodType: TIME_GRANULARITY;
                  // marketType: MARKET_TYPE;
                  // sumType: DATA_SUM_TYPE;
                  // startDate: string;
                  // endDate: string;
               }}
               marketType={MARKET_TYPE.Realtime}
            />
         </LazyComponent>
      </PowerCol>
   </PowerRow>
);

export default memo(TbxTab);
