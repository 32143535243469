/* eslint-disable no-restricted-globals */
const removeDoubleSpaces = (s: string) => s.replace(/ +(?= )/g, '').trim();

const validLat = (n: number) => n <= 90 && n >= -90;
const validLng = (n: number) => n <= 180 && n >= -180;

const toLnglat = (query: string): { lng: number; lat: number } | undefined => {
   query = removeDoubleSpaces(query);
   let parts = query.split(',').length === 2 ? query.split(',') : query.split(' ').length === 2 ? query.split(' ') : undefined;
   if (parts === undefined) return undefined;

   parts = parts.map((part) => part.trim()).filter((x) => x !== '');
   let numbers = parts.reduce<number[]>((prev, cur) => (isNaN(Number(cur)) ? prev : [...prev, Number(cur)]), []);
   numbers = numbers.filter((x) => x !== undefined);
   if (numbers.length !== 2) return undefined;

   const lng = numbers[1];
   const lat = numbers[0];
   if (validLat(lat) && validLng(lng)) {
      return { lng, lat };
   }
   if (validLat(lng) && validLng(lat)) {
      return { lng: lat, lat: lng };
   }

   return undefined;
};

export default toLnglat;
