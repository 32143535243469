import ValueColorInterpolate from './ValueColorInterpolate';
import ValueColorLineer from './ValueColorLineer';
import deepCopy from './deepCopy';
import enums from './enum';
import parseJwtToken from './jwt';
import math from './math';
import recKeyVal from './recKeyVal';
import times from './times';
// map
import br from './br';
import legendTicks from './legendTicks';
import lmp from './lmp';

import capitalizeFirstLetter from './capitalizeFirstLetter';
import interpolateColor from './interpolateColor';
import isExist from './isExist';
import { arrayToGeoJsonLineString, arrayToGeoJsonMultiLineString, arrayToGeoJsonPoint, arrayToGeoJsonPolygon } from './map/geoJson';
import getLayerInfo from './map/getLayerInfo';
import layerFilter from './map/layerFilter';
import lineToArc from './map/lineToArc';
import staticMapUrl from './map/staticMapUrl';
import toLnglat from './map/toLnglat';
import nodeMap from './nodeMap';
import onlyUnique from './onlyUnique';
import sleep from './sleep';
import tbx from './tbx';

const map = {
   getLayerInfo,
   layerFilter,
   arrayToGeoJsonPoint,
   arrayToGeoJsonLineString,
   arrayToGeoJsonMultiLineString,
   arrayToGeoJsonPolygon,
   toLnglat,
   lineToArc,
   staticMapUrl,
};

export const utils = {
   times,
   enums,
   parseJwtToken,
   ValueColorInterpolate,
   ValueColorLineer,
   map,
   math,
   lmp,
   nodeMap,
   tbx,
   br,
   sleep,
   recKeyVal,
   deepCopy,
   legendTicks,
   interpolateColor,
   isExist,
   onlyUnique,
   capitalizeFirstLetter,
};
