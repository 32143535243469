import { FeatureCollection, Point } from 'geojson';
import { IPriceLayerProperties } from 'power/types/map';

// [lat, lng, value, nodetype, id]
const datasToPriceGeojsons = (datas: any[]): FeatureCollection<Point, IPriceLayerProperties> => {
   const featureCollection: FeatureCollection<Point, IPriceLayerProperties> = {
      type: 'FeatureCollection',
      features: datas
         .filter((d) => Number(d[0]) > 1)
         .map((d) => ({
            type: 'Feature',
            geometry: {
               type: 'Point',
               coordinates: [Number(d[1]), Number(d[0])],
            },
            properties: {
               price: Number(d[2]),
               locationType: d[5] === 'IsoHub' ? 'hub' : d[5] === 'DraftNode' ? 'node' : 'bus',
               id: Number(d[6]),
               payload: [d[3], d[4] /* d[7], d[8] */],
            },
         })),
   };

   return featureCollection;
};

const lmp = { datasToPriceGeojsons };

export default lmp;
