import { IQ_STATUS } from 'power/types/enum';

const IQ_STATUSES = [
   { label: 'Active', value: IQ_STATUS.Active },
   { label: 'Operational', value: IQ_STATUS.Operational },
   { label: 'Withdrawn', value: IQ_STATUS.Withdrawn },
   { label: 'Suspended', value: IQ_STATUS.Suspended },
];

export default IQ_STATUSES;
