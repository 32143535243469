import { LMPHeatmapsContent } from './type';

const en: LMPHeatmapsContent = {
   heatmapsPageTitle: 'LMP Heatmaps',
   lmpOverview: 'LMP Overview',
   detailedAnalysis: 'Detailed Analysis',
   futureData: 'Future Data',
   dataDownload: 'Data Download',
   substituteNode: 'Substitute Node',
   substituteNodeDescription: 'The substitute node is the closest node that provides data if the selected node doesn\'t have data for a required date range. The substitute node selection is based on low distance and high price profile similarity.',
   solarLmp: 'Solar LMP',
   windLmp: 'Wind LMP',
   DayAheadHeatmapInfo1: 'LMP Component Day Ahead',
   RealTimeHeatmapInfo1: 'LMP Component Real Time',
   DayAheadHeatmapInfo2: 'MCC Component Day Ahead',
   RealTimeHeatmapInfo2: 'MCC Component Real Time',
   DayAheadHeatmapInfo10: 'STD Component Day Ahead',
   RealTimeHeatmapInfo10: 'STD Component Real Time',
   DayAheadHeatmapInfo5: 'SOLAR LMP Component Day Ahead',
   RealTimeHeatmapInfo5: 'SOLAR LMP Component Real Time',
   DayAheadHeatmapInfo6: 'WIND LMP Component Day Ahead',
   RealTimeHeatmapInfo6: 'WIND LMP Component Real Time',
   dateFromDateTo: 'Date from/to Date',
   countyState: 'County/State',
   latLng: 'Lat/Lng',
};

export default en;
