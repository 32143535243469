import { Line } from '@ant-design/plots';
import { Flex, Radio, Spin } from 'antd';
import { mainColorSet } from 'constants/NewColors';
import { FUTURE_YEARS } from 'modules/Future/FUTURE_YEARS';
import { FuturePriceRequest } from 'power/types';
import { MARKET_TYPE, TXBX_COMPONENT } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import classes from './index.module.scss';
import useFutureTbx from './useFutureTbx';

type Props = {
  loadingSize?: [number, number];
  query: FuturePriceRequest;
};

const FutureTbxChart: FC<Props> = ({ query, loadingSize = [352, 470] }) => {
   const { loading, data, fetch } = useFutureTbx();
   const [tbx, setTbx] = useState(TXBX_COMPONENT.Tb4);

   useEffect(() => {
      fetch({ scenario: query.scenario, market_type: query.market_type, tbx, start_year: FUTURE_YEARS[0], end_year: FUTURE_YEARS[FUTURE_YEARS.length - 1] });
   }, [fetch, query.market_type, query.scenario, query.year, tbx]);

   return (
      <div className={classes.chart} style={{ height: loadingSize[1] }}>
         <Flex justify="space-between">
            <h3 style={{ margin: '4px 0 8px 4px' }}>
          Future TB{tbx} {query.market_type === MARKET_TYPE.DayAhead ? 'Day-Ahead' : 'Real Time'}
            </h3>
            <Radio.Group value={tbx} onChange={(e) => setTbx(e.target.value)} style={{ marginTop: 6 }}>
               <Radio value={TXBX_COMPONENT.Tb1}>TB1</Radio>
               <Radio value={TXBX_COMPONENT.Tb2}>TB2</Radio>
               <Radio value={TXBX_COMPONENT.Tb3}>TB3</Radio>
               <Radio value={TXBX_COMPONENT.Tb4}>TB4</Radio>
            </Radio.Group>
         </Flex>
         {loading ? (
            <div className={classes.loading}>
               <Spin size="large" />
            </div>
         ) : data.length ? (
            <Line
               data={data}
               xField="year"
               yField="price"
               color={mainColorSet}
               seriesField="zone"
               style={{ height: loadingSize[1] - 60 }}
               yAxis={{ title: { text: 'Price ($/MWh)' }, min: data.reduce((acc, cur) => Math.min(acc, cur.price), Infinity), max: data.reduce((acc, cur) => Math.max(acc, cur.price), -Infinity) }}
               xAxis={{ title: { text: 'Year' } }}
               legend={{ position: 'top' }}
            />
         ) : (
            <div className={classes.loading}>No data available</div>
         )}
      </div>
   );
};

export default FutureTbxChart;
