import { IMAGE_PATHS } from 'power/constants';
import { FUEL_TYPE } from 'power/types/enum';

export const images: { name: string; url: string }[] = [
   { name: 'Wind-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Wind) },
   { name: 'Solar-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Solar) },
   { name: 'Coal-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Coal) },
   { name: 'Gas-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Gas) },
   { name: 'Hydroelectric-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Hydro) },
   { name: 'Nuclear-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Nuclear) },
   // { name: 'Hybrid-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Hybrid) },
   { name: 'Oil-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Oil) },
   { name: 'Battery-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Battery) },
   { name: 'OtherStorage-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.OtherStorage) },
   { name: 'Biomass-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.BioMass) },
   { name: 'Geothermal-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Thermal) },
   //
   //
   { name: 'Other-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Other) },
   
   
   // 
   // { name: 'PumpedStorage-image-powerdev', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.PumpedStorage) },
   
   
   { name: 'default-icon', url: IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(FUEL_TYPE.Other) },
];