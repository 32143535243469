import { Modal } from 'antd';
import IqPopup from 'power/map/layers/StaticLayers/IqLayer/popup/IqPopup';
import { FC } from 'react';

type Props = {
  id: number;
  onClose: () => void;
};

const Detail: FC<Props> = ({ id, onClose = () => {} }) => (
   <Modal
      open onOk={onClose} onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      style={{maxWidth: 360, maxHeight: '65vh'}}
   >
      <IqPopup id={id} />

   </Modal>
); 

export default Detail;
