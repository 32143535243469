// import { PowerMap } from 'power/components/maps';
import { Flex } from 'antd';
import { IICAPCapacityParameters, IICAPStripRequest } from 'power/types';
import { FC } from 'react';
import classes from './index.module.scss';
import MonthlyTable from './MonthlyTable';
import SpotTable from './SpotTable';
import StripTable from './StripTable';
import TslLcrTable from './TslLcrTable';

type Props = {
   auctions: ('strip' | 'monthly' | 'spot')[];
   queryStrip: IICAPStripRequest;
   month?: number;
   capacityParameters?: IICAPCapacityParameters[];
};

const TableView: FC<Props> = ({ auctions, queryStrip, month, capacityParameters }) => (
   <div className={classes.container}>
      <Flex justify="space-between">
         {auctions.includes('monthly') && month && <MonthlyTable queryStrip={queryStrip} month={month} />}
         {capacityParameters && (
            <div style={{  margin: '0px 80px 0px 0px' }}>
               <TslLcrTable data={capacityParameters} />
               {/* <h3>Capacity Parameters</h3>
               <ul>
                  {capacityParameters.map(({ zone, transmission_security_limit, capacity_requirement }) => (
                     <li key={zone}>{`${zone}: ${transmission_security_limit} / ${capacity_requirement}`}</li>
                  ))}
               </ul> */}
            </div>
         )}
      </Flex>
      
      <Flex>
         {auctions.includes('spot') && month && <SpotTable queryStrip={queryStrip} month={month} />}
         {auctions.includes('strip') && <StripTable queryStrip={queryStrip} />}
      </Flex>

      
   </div>
);

export default TableView;
