import dayjs from 'dayjs';

const nowSeconds = () => {
   const d = new Date();
   // let utcSeconds = Math.floor(d.getTime() / 1000) + d.getTimezoneOffset() * 60;
   const ueSeconds = Math.floor(d.getTime() / 1000);
   return ueSeconds;
};

const formatDatetime = (datetime: string) => {
   const d = dayjs(datetime);
   return d.format('YYYY, MMM DD');
};

const times = { nowSeconds, formatDatetime };
export default times;
