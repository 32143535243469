enum SUBSCRIPTION_PACKAGE {
  HEADROOM_CAPACITY = 'Headroom Capacity',
  NODAL_ANALYSIS = 'Nodal Analysis',
  INTERCONNECTION_QUEUE = 'Interconnection Queue',
  POWER_PLANT = 'Power Plant',
  TRANSMISSION_LINE = 'Transmission Lines',
  ICAP = 'ICAP',
  ICAP_FORECAST = 'ICAP Forecast',
  FUTURE_PRICE = 'Future Price',
  GENERATION_POCKET = 'Generation Pocket',
  NEWS = 'News',
}

export default SUBSCRIPTION_PACKAGE;
