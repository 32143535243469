import { Header } from 'antd/es/layout/layout';
// import { ENV } from 'power/constants';
import { USER_TYPE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import classes from './HeaderPrimary.module.scss';

export const HeaderPrimary = ({ children }: { children: React.ReactNode }) => {
   const { isUserType } = useAuth();

   const label = useMemo(() => {
      if (isUserType(USER_TYPE.ZENOBE)) {
         return 'ZENOBE';
      }

      if (isUserType(USER_TYPE.CVE_NA)) {
         return 'CVE North America';
      }

      if (isUserType(USER_TYPE.INTERNAL)) {
         return 'PowerDev Internal';
      }

      return undefined;
   }, [isUserType]);

   return (
      <Header className={classes.headerPrimary}>
         <>
            {children}
            {label && (
               <div
                  style={{
                     position: 'absolute',
                     right: '90px',
                     paddingRight: '7px',
                     fontSize: '13px',
                     letterSpacing: '2px',
                     lineHeight: '16px',
                     top: '15px',
                     fontWeight: '400',
                     color: '#f86904',
                  }}
               >
                  {label === 'ZENOBE' ? <img src="/img/zenobe-logo-small.png" alt="ZENOBE" style={{ height: '14px' }} /> : <b>{label}</b>} <span style={{ fontSize: 10 }}>SPECIFIC VERSION</span>
                  {/* <img src="/img/zenobe-logo-small.png" alt="ZENOBE" style={{ height: '14px' }} />  */}
               </div>
            )}
         </>
      </Header>
   );
};
