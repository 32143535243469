import { ApiClients } from 'ApiClients';
import { IBaseChartData, IBaseDataResponse, IChartData, ITxBxMovingAverageTimeSeriesRequest } from 'power/types';
import { useCallback, useState } from 'react';

const useMovingAverageTimeSeries = () => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<IBaseDataResponse<IBaseChartData<IChartData>> | undefined>(undefined);

   const fetch = useCallback(async (filter: Partial<ITxBxMovingAverageTimeSeriesRequest>, reload: boolean = false) => {
      setLoading(true);
      setData(undefined);
      return ApiClients.getInstance().tbxMovingAverageTimeSeries(filter, reload)
         .then((data) => {
            setData(data);
         })
         .catch((error) => {
            console.error(error);
            setData(undefined);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   return {
      loading,
      data,
      fetch,
   };
};

export default useMovingAverageTimeSeries;
