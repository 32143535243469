import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import { TransmissionCapacity } from 'modules/TransmissionCapacity';
import PATHS from 'power/PATHS';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const TransmissionCapacityPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.TransmissionCapacity, title: 'Transmission Capacity' });
   
   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Transmission Capacity" />}>
         <TransmissionCapacity />
      </PrivatePageLayout>
   );

}