import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import HistoricalHeatmap from 'modules/Historical/HistoricalHeatmap';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const HistoricalHeatmapPage = () => {
   ReactGA.send({ hitType: 'pageview', page: '/historical/heatmap', title: 'Historical Heatmap' });

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Historical Heatmap" />}>
         <HistoricalHeatmap />
      </PrivatePageLayout>
   );
};
