import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import mapboxgl from 'mapbox-gl';
import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';

type Props = {
  map?: mapboxgl.Map;
  onClose?: () => void;
  width?: string | number;
};
const SiderControlContent: React.FC<PropsWithChildren<Props>> = ({ map, onClose, children, width = '26rem' }) => {
   if (map === undefined) return null;

   // console.log(map.getContainer().parentElement, map.getContainer().parentElement?.style.height);
   const parentHeight = map.getContainer().parentElement?.style.height;
   const height = parentHeight ? `calc(${parentHeight} - 20px)` : 'calc(100svh -122px)';

   return (
      <>
         
         <div className={children ? `${styles.siderControlContainer} ${styles.isOpen}` : `${styles.siderControlContainer} ${styles.isClose}`} style={{ height, width: children ? width : 0 }}>
            <div className={styles.header}>
               <div className={styles.closeBtn}>
                  <Button
                     size="small"
                     shape="default"
                     icon={<CloseOutlined />}
                     style={{ border: 'none' }}
                     onClick={() => {
                        onClose && onClose();
                     }}
                  />
               </div>
            </div>

            <div className={styles.content}>{children}</div>
         </div>
      </>
   );
};

export default SiderControlContent;
