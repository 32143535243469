import { FillLayout, FillPaint } from 'mapbox-gl';

const LAYOUT: FillLayout = {};

const PAINT: FillPaint = {
   'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#9dc5e8', '#1D39C4'],
   // 'fill-outline-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#1D39C4', '#597EF7'],
   'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.2],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
