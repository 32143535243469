import Basisrisk from './basisrisk';
import Battery from './battery';
import Future from './future';
import Iq from './iq';
import Layers from './layers';
import LmpDataDownload from './lmpdatadownload';

export const Svg = {
   Basisrisk,
   Battery,
   Iq,
   Future,
   LmpDataDownload,
   Layers,
};
