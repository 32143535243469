/* eslint-disable max-len */
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
   <svg
      viewBox="0 0 1024 1024" width="1em" height="1em"
      fill="currentColor">
      <path d="M945.52 164.2C953.48 164.2 960 157.68 960 149.72V106.28C960 98.32 953.48 91.8 945.52 91.8H862V78.48C862 70.52 855.48 64 847.52 64H670C611.88 64 562.82 102.74 547.2 155.8H78.48C70.52 155.8 64 162.31 64 170.28V213.72C64 221.68 70.52 228.2 78.48 228.2H547.2C562.82 281.26 611.88 320 670 320H847.52C855.48 320 862 313.48 862 305.52V292.2H945.52C953.48 292.2 960 285.68 960 277.72V234.28C960 226.32 953.48 219.8 945.52 219.8H862V164.21H945.52V164.2ZM790 248H670C639.12 248 614 222.88 614 192C614 161.12 639.12 136 670 136H790V248Z" fill="#040000" />
      <path d="M945.52 411.8H862V398.48C862 390.52 855.48 384 847.52 384H670C611.88 384 562.82 422.74 547.2 475.8H78.48C70.52 475.8 64 482.31 64 490.28V533.72C64 541.68 70.52 548.2 78.48 548.2H547.2C562.82 601.26 611.88 640 670 640H847.52C855.48 640 862 633.48 862 625.52V612.2H945.52C953.48 612.2 960 605.68 960 597.72V554.28C960 546.32 953.48 539.8 945.52 539.8H862V484.21H945.52C953.48 484.21 960 477.69 960 469.73V426.29C960 418.33 953.48 411.81 945.52 411.81V411.8ZM790 568H670C639.12 568 614 542.88 614 512C614 481.12 639.12 456 670 456H790V568Z" fill="#040000" />
      <path d="M945.52 731.8H862V718.48C862 710.52 855.48 704 847.52 704H670C611.88 704 562.82 742.74 547.2 795.8H78.48C70.52 795.8 64 802.32 64 810.28V853.72C64 861.68 70.52 868.2 78.48 868.2H547.2C562.82 921.26 611.88 960 670 960H847.52C855.48 960 862 953.48 862 945.52V932.2H945.52C953.48 932.2 960 925.68 960 917.72V874.28C960 866.32 953.48 859.8 945.52 859.8H862V804.21H945.52C953.48 804.21 960 797.69 960 789.73V746.29C960 738.33 953.48 731.81 945.52 731.81V731.8ZM790 888H670C639.12 888 614 862.88 614 832C614 801.12 639.12 776 670 776H790V888Z" fill="#040000" />
   </svg>
);

const IconSvg = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;
export default IconSvg;
