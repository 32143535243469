import { ApiClients } from 'ApiClients';
import { FutureTbx, FutureTbxRequest } from 'power/types';
import { NYISO_ZONE } from 'power/types/enum';
import { useCallback, useState } from 'react';

const NYISO_ZONES: NYISO_ZONE[] = [NYISO_ZONE.A, NYISO_ZONE.B, NYISO_ZONE.C, NYISO_ZONE.D, NYISO_ZONE.E, NYISO_ZONE.F, NYISO_ZONE.G, NYISO_ZONE.H, NYISO_ZONE.I, NYISO_ZONE.J, NYISO_ZONE.K];

const useFutureTbx = () => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<FutureTbx[]>([]);

   const fetch = useCallback((query: Omit<FutureTbxRequest, 'zone'>) => {
      setLoading(true);
      setData([]);

      const promises: Promise<FutureTbx[]>[] = NYISO_ZONES.map((zone) => ApiClients.getInstance().futureTbx({ ...query, zone }));
      Promise.all(promises)
         .then((res) => {
            setData(res.flat());
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   return { loading, data, fetch };
};

export default useFutureTbx;
