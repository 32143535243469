import { ICAP_ZONE_BORDER_STYLE } from 'power/constants/map/styles';
import { VECTOR_LAYER_ID } from 'power/types/enum';
import { utils } from 'power/utils';
import React from 'react';
import { Layer, Source, useMap } from 'react-map-gl';

const { /* layerType, */ layerId, sourceUrl, sourceLayer, /* layerLayout, layerPaint, */ layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.ICAP_ZONE);

const ICAPZoneLayer: React.FC<{ mapId: string }> = ({ mapId }) => {
   const { [mapId]: map } = useMap();

   // const [selectedZone, setSelectedZone] = useState<{ location: mapboxgl.LngLat; properties: any } | undefined>(undefined);

   /* // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         const location = ev.lngLat;
         const properties = features.filter((f) => f.layer.id === layerId)?.map((x) => x.properties as any);
         setSelectedZone({ location, properties });
      };

      if (map) {
         map.on('click', layerId, onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', layerId, onClickListener as any);
         }
      };
   }, [map]); */

   if (!map) return null;

   return (
      <>
         <Source id={layerId} type="vector" url={sourceUrl}>
            {/* <Layer
               id={layerId} type="fill" source={layerId}
               source-layer={sourceLayer} layout={layerLayout as FillLayout} paint={layerPaint as FillPaint}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} interactive /> */}
            <Layer
               id={`${layerId}-border`} type="line" source={layerId}
               source-layer={sourceLayer} layout={ICAP_ZONE_BORDER_STYLE.LAYOUT} paint={ICAP_ZONE_BORDER_STYLE.PAINT}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} />
            {/* <Layer
               id={`${layerId}-label`} type="symbol" source={layerId}
               source-layer={sourceLayer} layout={ICAP_ZONE_LABEL_STYLE.LAYOUT} paint={ICAP_ZONE_LABEL_STYLE.PAINT}
               minzoom={layerMinzoom} maxzoom={layerMaxzoom} filter={['==', '$type', 'Point']} /> */}
         </Source>

         {/* {selectedZone && selectedZone.properties.length && (
            <Popup
               longitude={selectedZone.location.lng} latitude={selectedZone.location.lat} onClose={() => setSelectedZone(undefined)}
               closeOnClick={false}>
               <layerProps.popup key={selectedZone.properties[0].id} features={selectedZone.properties} />
            </Popup>
         )} */}
      </>
   );
};

export default ICAPZoneLayer;
