import { Column } from '@ant-design/plots';
import CardPanel from 'components/ui/CardPanel';
import { ITxBxMovingAverageTimeSeriesRequest } from 'power/types';
import { DATA_SUM_TYPE, MARKET_TYPE, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { utils } from 'power/utils';
import { useEffect } from 'react';
import usePerformanceTimeSeries from '../usePerformanceTimeSeries';

const YearlyTBxChart = ({ filter, marketType }: { filter: Partial<ITxBxMovingAverageTimeSeriesRequest>; marketType: MARKET_TYPE }) => {
   const { loading, data, fetch } = usePerformanceTimeSeries();

   useEffect(() => {
      fetch({ ...filter, component: TXBX_COMPONENT.Undefined, sumType: DATA_SUM_TYPE.Yearly, marketType });
   }, [fetch, filter, marketType]);

   const infoContent = marketType === MARKET_TYPE.DayAhead ?
      'Yearly Day-Ahead TBx performance is calculated by subtracting the Hub LMP from the Nodal value and dividing the result by the Hub LMP. A positive value indicates that the Nodal LMP is higher than the Hub LMP.' :
      'Yearly Real-Time TBx performance is calculated by subtracting the Hub LMP from the Nodal value and dividing the result by the Hub LMP. A positive value indicates that the Nodal LMP is higher than the Hub LMP.';

   return (
      <CardPanel
         title={`Yearly ${marketType === MARKET_TYPE.DayAhead ? 'DA' : 'RT'}  Node vs Hub`} 
         defaultViewMode="chart" 
         status={loading ? 'loading' : 'success'}
         fullScreen
         downloadURL={data?.downloadUrl}
         refreshClick={() => fetch({ ...filter, component: TXBX_COMPONENT.Undefined, sumType: DATA_SUM_TYPE.Yearly, marketType }, true)}
         info={infoContent}
      >
         {() => (
            <>
               <div style={{ margin: '-10px 8px', color: '#808080' }}>%</div>
               <Column
                  // loading={loading}
                  className="antd-chart"
                  data={data?.result?.data ?? []}
                  xField="x"
                  yField="y"
                  seriesField="group"
                  isGroup
                  /* legend={false} */
                  /* yAxis={{
                  label: {
                     formatter: (v) => normalizeNumber(+v, { maximumFractionDigits: 2 }),
                  },
               }} */
                  /* tooltip={{
                  shared: true,
                  formatter: (d) => ({
                     name: d.group,
                     value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                  }),
               }} */
                  xAxis={{
                     label: {
                        formatter: (x) => utils.math.formatDate(x, TIME_GRANULARITY.yearly),
                        /* autoRotate: true, */
                     },
                  }}
                  tooltip={{
                     /* shared: true, */
                     title: (d) => utils.math.formatDate(d, TIME_GRANULARITY.yearly),
                     formatter: (d) => ({ name: d.group, value: d.y }),
                  }}
                  legend={{
                     position: 'top',
                     padding: [0, 0, 10, 64],
                     marker: (g: string) => (g.startsWith('Tb') ? {} : { symbol: 'hyphen', style: { lineDash: [4, 4] } }),
                  }}
                  /* yAxis={{
                     min: -100,
                     max: 100,
                  }} */
               />
            </>
         )}
      </CardPanel>
   );
};

export default YearlyTBxChart;
