import { DollarCircleOutlined } from '@ant-design/icons';
import { Tabs, type TabsProps } from 'antd';
import { Svg } from 'power/components/base/svg';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import IcapFuture from './IcapFuture';
import IcapHistorical from './IcapHistorical';
import classes from './index.module.scss';

export const ICAP = () => {
   const { hash } = useLocation();
   const { hasPackage } = useAuth();

   const setActiveKey = (key: string) => {
      window.location.hash = key;
   };

   const items: TabsProps['items'] = useMemo(() => {
      const tabProps: TabsProps['items'] = [
         {
            key: '#map',
            label: 'ICAP Historical',
            children: <IcapHistorical />,
            icon: <DollarCircleOutlined />,
            disabled: !hasPackage(SUBSCRIPTION_PACKAGE.ICAP),
         },
         {
            key: '#future',
            label: 'ICAP Future',
            children: <IcapFuture />,
            icon: <Svg.Future />,
            disabled: !hasPackage(SUBSCRIPTION_PACKAGE.ICAP_FORECAST),
         },
      ];

      return tabProps;
   }, [hasPackage]);

   return (
      <div className={classes.tabContainer}>
         <Tabs
            activeKey={!!hash ? hash : items.find((x) => !x.disabled)?.key} type="card" items={items}
            onChange={setActiveKey} />
      </div>
   );
};
