import { VECTOR_LAYER_ID } from 'power/types/enum';
import { IVectorLayer } from 'power/types/map';
import { PP_STYLE, PRICE_STYLE, STATE_STYLE, TRANSMISSON_LINES_STYLE, ZONE_STYLE } from './styles';
import COUNTY_STYLE from './styles/COUNTY_STYLE';
import ISO_STYLE from './styles/ISO_STYLE';
import OVERPASS_NODE_STYLE from './styles/OVERPASS_NODE_STYLE';
import OVERPASS_RELATION_STYLE from './styles/OVERPASS_RELATION_STYLE';
import OVERPASS_WAY_STYLE from './styles/OVERPASS_WAY_STYLE';

const VECTOR_LAYERS: Record<VECTOR_LAYER_ID, IVectorLayer> = {
   [VECTOR_LAYER_ID.POWER_PLANTS]: {
      type: 'symbol',
      id: VECTOR_LAYER_ID.POWER_PLANTS,
      source: VECTOR_LAYER_ID.POWER_PLANTS,
      source_name: VECTOR_LAYER_ID.POWER_PLANTS,
      sourceLayer: VECTOR_LAYER_ID.POWER_PLANTS,
      layout: PP_STYLE.LAYOUT,
      paint: PP_STYLE.PAINT,
      minzoom: 3,
      maxzoom: 22,
      fields: {
         b_f_type: 'number',
         b_status: 'number',
         c_class: 'string',
         f_1: 'number',
         f_10: 'number',
         f_11: 'number',
         f_12: 'number',
         f_13: 'number',
         f_14: 'number',
         f_15: 'number',
         f_2: 'number',
         f_3: 'number',
         f_4: 'number',
         f_5: 'number',
         f_6: 'number',
         f_7: 'number',
         f_8: 'number',
         f_9: 'number',
         o_year: 'number',
         p_id: 'number',
         s_1: 'number',
         s_2: 'number',
         s_3: 'number',
         s_4: 'number',
         s_5: 'number',
      },
   },
   [VECTOR_LAYER_ID.TRANSMISSON_LINES]: {
      type: 'line',
      id: VECTOR_LAYER_ID.TRANSMISSON_LINES,
      source: VECTOR_LAYER_ID.TRANSMISSON_LINES,
      source_name: VECTOR_LAYER_ID.TRANSMISSON_LINES,
      sourceLayer: VECTOR_LAYER_ID.TRANSMISSON_LINES,
      layout: TRANSMISSON_LINES_STYLE.LAYOUT,
      paint: TRANSMISSON_LINES_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {
         id: 'string',
         voltage: 'number',
         voltage_class: 'string',
      },
   },
   [VECTOR_LAYER_ID.ZONE]: {
      type: 'fill',
      id: VECTOR_LAYER_ID.ZONE,
      source: VECTOR_LAYER_ID.ZONE,
      source_name: VECTOR_LAYER_ID.ZONE,
      sourceLayer: VECTOR_LAYER_ID.ZONE,
      layout: ZONE_STYLE.LAYOUT,
      paint: ZONE_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {
         id: 'string',
         name: 'string',
         type: 'string',
      },
   },
   [VECTOR_LAYER_ID.ISO]: {
      type: 'fill',
      id: VECTOR_LAYER_ID.ISO,
      source: VECTOR_LAYER_ID.ISO,
      source_name: VECTOR_LAYER_ID.ISO,
      sourceLayer: VECTOR_LAYER_ID.ISO,
      layout: ISO_STYLE.LAYOUT,
      paint: ISO_STYLE.PAINT,
      minzoom: 3,
      maxzoom: 8,
      fields: {
         ALAND: 'number',
         AWATER: 'number',
         DIVISION: 'string',
         FUNCSTAT: 'string',
         GEOID: 'string',
         GlobalID: 'string',
         INTPTLAT: 'string',
         INTPTLON: 'string',
         LSAD: 'string',
         MTFCC: 'string',
         NAME: 'string',
         OBJECTID: 'number',
         REGION: 'string',
         SHAPE_Area: 'number',
         SHAPE_Length: 'number',
         STATEFP: 'string',
         STATENS: 'string',
         STUSPS: 'string',
      },
   },
   [VECTOR_LAYER_ID.COUNTIES]: {
      type: 'fill',
      id: VECTOR_LAYER_ID.COUNTIES,
      source: VECTOR_LAYER_ID.COUNTIES,
      source_name: VECTOR_LAYER_ID.COUNTIES,
      sourceLayer: VECTOR_LAYER_ID.COUNTIES,
      layout: COUNTY_STYLE.LAYOUT,
      paint: COUNTY_STYLE.PAINT,
      minzoom: 4,
      maxzoom: 7,
      fields: {
         ALAND: 'number',
         AWATER: 'number',
         DIVISION: 'string',
         FUNCSTAT: 'string',
         GEOID: 'string',
         GlobalID: 'string',
         INTPTLAT: 'string',
         INTPTLON: 'string',
         LSAD: 'string',
         MTFCC: 'string',
         NAME: 'string',
         OBJECTID: 'number',
         REGION: 'string',
         SHAPE_Area: 'number',
         SHAPE_Length: 'number',
         STATEFP: 'string',
         STATENS: 'string',
         STUSPS: 'string',
      },
   },
   [VECTOR_LAYER_ID.STATES]: {
      type: 'fill',
      id: VECTOR_LAYER_ID.STATES,
      source: VECTOR_LAYER_ID.STATES,
      source_name: VECTOR_LAYER_ID.STATES,
      sourceLayer: VECTOR_LAYER_ID.STATES,
      layout: STATE_STYLE.LAYOUT,
      paint: STATE_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 4,
      fields: {
         ALAND: 'number',
         AWATER: 'number',
         DIVISION: 'string',
         FUNCSTAT: 'string',
         GEOID: 'string',
         GlobalID: 'string',
         INTPTLAT: 'string',
         INTPTLON: 'string',
         LSAD: 'string',
         MTFCC: 'string',
         NAME: 'string',
         OBJECTID: 'number',
         REGION: 'string',
         SHAPE_Area: 'number',
         SHAPE_Length: 'number',
         STATEFP: 'string',
         STATENS: 'string',
         STUSPS: 'string',
      },
   },

   [VECTOR_LAYER_ID.OVERPASS_RELATIONS]: {
      type: 'circle',
      id: VECTOR_LAYER_ID.OVERPASS_RELATIONS,
      source: VECTOR_LAYER_ID.OVERPASS_RELATIONS,
      source_name: VECTOR_LAYER_ID.OVERPASS_RELATIONS,
      sourceLayer: 'overpass-usa-substations-relatio',
      layout: OVERPASS_RELATION_STYLE.LAYOUT,
      paint: OVERPASS_RELATION_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {},
   },

   [VECTOR_LAYER_ID.OVERPASS_WAYS]: {
      type: 'circle',
      id: VECTOR_LAYER_ID.OVERPASS_WAYS,
      source: VECTOR_LAYER_ID.OVERPASS_WAYS,
      source_name: VECTOR_LAYER_ID.OVERPASS_WAYS,
      sourceLayer: 'overpass-usa-substations-way-0epnw8',
      layout: OVERPASS_WAY_STYLE.LAYOUT,
      paint: OVERPASS_WAY_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {},
   },

   [VECTOR_LAYER_ID.OVERPASS_NODES]: {
      type: 'circle',
      id: VECTOR_LAYER_ID.OVERPASS_NODES,
      source: VECTOR_LAYER_ID.OVERPASS_NODES,
      source_name: VECTOR_LAYER_ID.OVERPASS_NODES,
      sourceLayer: 'overpass-usa-substations-node-0a5ukp',
      layout: OVERPASS_NODE_STYLE.LAYOUT,
      paint: OVERPASS_NODE_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {},
   },

   [VECTOR_LAYER_ID.PRICE_LAYER]: {
      type: 'symbol',
      id: VECTOR_LAYER_ID.PRICE_LAYER,
      source: VECTOR_LAYER_ID.PRICE_LAYER,
      source_name: VECTOR_LAYER_ID.PRICE_LAYER,
      sourceLayer: VECTOR_LAYER_ID.PRICE_LAYER,
      layout: PRICE_STYLE.LAYOUT,
      paint: PRICE_STYLE.PAINT,
      minzoom: 1,
      maxzoom: 22,
      fields: {
         id: 'number',
         price: 'number',
         type: 'string',
      },
   },
   [VECTOR_LAYER_ID.PRICE_LABEL_LAYER]: {
      type: 'symbol',
      id: VECTOR_LAYER_ID.PRICE_LABEL_LAYER,
      source: VECTOR_LAYER_ID.PRICE_LABEL_LAYER,
      source_name: VECTOR_LAYER_ID.PRICE_LABEL_LAYER,
      sourceLayer: VECTOR_LAYER_ID.PRICE_LABEL_LAYER,
      layout: PRICE_STYLE.LAYOUT,
      paint: PRICE_STYLE.PAINT,
      minzoom: 1,
      maxzoom: 22,
      fields: {
         id: 'number',
         price: 'number',
         type: 'string',
      },
   },
   [VECTOR_LAYER_ID.ICAP_ZONE]: {
      type: 'fill',
      id: VECTOR_LAYER_ID.ICAP_ZONE,
      source: VECTOR_LAYER_ID.ICAP_ZONE,
      source_name: VECTOR_LAYER_ID.ICAP_ZONE,
      sourceLayer: VECTOR_LAYER_ID.ICAP_ZONE,
      layout: ZONE_STYLE.LAYOUT,
      paint: ZONE_STYLE.PAINT,
      minzoom: 0,
      maxzoom: 22,
      fields: {
         id: 'string',
         name: 'string',
         type: 'string',
      },
   },
};

export default VECTOR_LAYERS;
