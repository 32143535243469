/* eslint-disable max-len */
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
   <svg
      viewBox="0 0 1024 1024" width="1em" height="1em"
      fill="currentColor">
      <polygon points="886.91 623.43 895.89 295.34 794.06 295.34 803.04 623.43 886.91 623.43" />
      <path d="m883.14,676.6c-10.05-10.24-23.81-16-38.16-15.97-14.61-.23-28.67,5.55-38.9,15.97-10.51,9.97-16.34,23.91-16.07,38.39-.33,14.65,5.49,28.76,16.07,38.9,10.22,10.45,24.28,16.24,38.9,16.02,9.47.03,18.77-2.53,26.88-7.41,8.2-4.89,15.08-11.72,20.02-19.89,5.11-8.31,7.82-17.87,7.83-27.62.08-14.55-5.93-28.47-16.57-38.39Z" />
      <path d="m554.4,742.38l-47.7-47.83c-3.81-3.83-10.01-3.85-13.84-.03-.01.01-.02.02-.03.03l-110.34,110.34V226.3l103.15,103.15s.02.02.03.03c3.83,3.81,10.03,3.8,13.84-.03l47.7-47.83s.02-.02.03-.03c3.81-3.83,3.8-10.03-.03-13.84l-197.07-196.71-4.15-4.15c-1.2-1.21-2.65-2.04-4.18-2.49-.03,0-.06-.01-.09-.02-.27-.08-.54-.15-.82-.2-.08-.02-.16-.02-.25-.04-.23-.04-.45-.07-.68-.1-.13-.01-.26-.01-.39-.02-.18-.01-.36-.03-.54-.02-.18,0-.36.01-.54.02-.13,0-.26,0-.39.02-.23.02-.45.06-.68.1-.08.01-.16.02-.25.04-.28.05-.55.12-.82.2-.03,0-.06.01-.09.02-1.53.45-2.97,1.28-4.18,2.49l-4.12,4.12-197.1,196.74c-3.83,3.81-3.85,10.01-.03,13.84.01.01.02.02.03.03l47.7,47.83c3.81,3.83,10.01,3.85,13.84.03.01-.01.02-.02.03-.03l109.37-109.37v576.67l-102.19-102.19s-.02-.02-.03-.03c-3.83-3.81-10.03-3.8-13.84.03l-47.7,47.83s-.02.02-.03.03c-3.81,3.83-3.8,10.03.03,13.84l197.1,196.74,4.12,4.12c1.2,1.21,2.65,2.04,4.18,2.49.03,0,.06.01.09.02.27.08.54.15.82.2.08.02.16.02.25.04.23.04.45.07.68.1.13.01.26.01.39.02.18.01.36.03.54.02.18,0,.36-.01.54-.02.13,0,.26,0,.39-.02.23-.02.45-.06.68-.1.08-.01.16-.02.25-.04.28-.05.55-.12.82-.2.03,0,.06-.01.09-.02,1.53-.45,2.97-1.28,4.18-2.49l4.15-4.15,197.07-196.71c3.83-3.81,3.85-10.01.03-13.84-.01-.01-.02-.02-.03-.03ZM337.26,184.66l1.79-1.79,1.79,1.79h-3.59Zm8.98,656.48l-3.38-3.38h6.77l-3.38,3.38Z" />
   </svg>
);

const IconSvg = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;
export default IconSvg;
