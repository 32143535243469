// #region base colors

import { MAP_LEGEND_COLORS } from 'power/constants/map/MAP_LEGEND_COLORS';

export const greenBase = {
   light2: '#DBFFEC',
   light1: '#B3F2D5',
   light: '#85E6BC',
   main: '#5AD8A6',
   second: '#42B389',
   dark: '#2D8C6C',
   dark2: '#1C6650',
   dark3: '#114033',
};

export const orangeBase = {
   light: '#FF9C6E',
   main: '#FF7A45',
   second: '#FA541C',
   dark: '#D4380D',
   dark2: '#AD2102',
   dark3: '#871400',
};

export const blueBase = {
   light: '#85A5FF',
   main: '#597EF7',
   second: '#2F54EB',
   dark: '#1D39C4',
   dark2: '#10239E',
   dark3: '#061178',
};

export const claretBase = {
   main: '#731732',
   light: '#BB2552',
};

export const pestoBase = {
   main: '#6F7835',
   light: '#A8B455',
};

export const luxorGoldBase = {
   main: '#9B7431',
   light: '#C69849',
};

export const yellowishGreen = {
   light: '#BAE637',
   main: '#A0D911',
   second: '#7CB305',
   dark: '#5B8C00',
   dark2: '#3F6600',
   dark3: '#254000',
};

export const redBase = {
   light2: '#F5BCB5',
   light1: '#E88D87',
   light: '#DB605C',
   main: '#CF3636',
   second: '#A82328',
   dark: '#82151C',
   dark2: '#5C0A12',
   dark3: '#36050C',
};

export const barleyCornBase = {
   main: '#93864D',
   light: '#B5A973',
};

export const finBase = {
   main: '#603356',
   light: '#A05490',
};

export const blueBayouxBase = {
   main: '#4B5E78',
   light: '#758AA9',
};

export const rawUmberBase = {
   main: '#795E16',
   light: '#B99122',
};

export const mediumGreen = {
   light: '#58BF66',
   main: '#36B34B',
   second: '#238C38',
   dark: '#146627',
   dark2: '#0A4018',
   dark3: '#041A0A',
};

export const congoBrownBase = {
   main: '#673C3C',
   light: '#9A5B5B',
};

export const fucshiaBlueBase = {
   main: '#934EBC',
   light: '#B585D1',
};

export const carrotOrange = {
   light: '#FFA940',
   main: '#FA8C16',
   second: '#D46B08',
   dark: '#AD4E00',
   dark2: '#873800',
   dark3: '#612500',
};

export const pictonBlue = {
   light: '#5CC8DB',
   main: '#36B4CF',
   second: '#238BA8',
   dark: '#156782',
   dark2: '#0A455C',
   dark3: '#052636',
};

// /

export const grayBase = {
   light: '#7E8B9E',
   main: '#5D7092',
   second: '#3F4E6B',
   dark: '#252F45',
   dark2: '#191F33',
   dark3: '#000000',
};

export const yellowBase = {
   light: '#FFD666',
   main: '#FFC53D',
   second: '#FAAD14',
   dark: '#D48806',
   dark2: '#AD6800',
   dark3: '#874D00',
};

export const whiteBase = {
   main: '#F4F2F2',
};

export const purpleBase = {
   light: '#B37FEB',
   main: '#9254DE',
   second: '#722ED1',
   dark: '#531DAB',
   dark2: '#391085',
   dark3: '#22075E',
};

export const pinkBase = {
   light: '#FFADD2',
   main: '#FF85C0',
   second: '#F759AB',
   dark: '#EB2F96',
   dark2: '#C41D7F',
   dark3: '#9E1068',
};

export const bananaYellow = {
   light: '#FFF566',
   main: '#FFEC3D',
   second: '#FADB14',
   dark: '#D4B106',
   dark2: '#AD8B00',
   dark3: '#876800',
};

export const pinkishPurple = {
   light: '#E461ED',
   main: '#D039E1',
   second: '#A625BA',
   dark: '#7F1694',
   dark2: '#5A0B6E',
   dark3: '#380647',
};

export const mustardGreen = {
   light: '#B7BF1F',
   main: '#A5B300',
   second: '#7C8C00',
   dark: '#576600',
   dark2: '#344000',
   dark3: '#141A00',
};

export const blackBase = {
   main: '#040000',
};

// #endregion

// #region color sets

export const lightColorSet = [
   greenBase.light,
   blueBase.light,
   orangeBase.light,
   grayBase.light,
   yellowBase.light,
   purpleBase.light,
   yellowishGreen.light,
   pinkBase.light,
   bananaYellow.light,
   pictonBlue.light,
   carrotOrange.light,
   pinkishPurple.light,
   mediumGreen.light,
   redBase.light,
   mustardGreen.light,
   greenBase.second,
   blueBase.second,
   orangeBase.second,
   grayBase.second,
   yellowBase.second,
];

export const mainColorSet = [
   greenBase.main,
   blueBase.main,
   orangeBase.main,
   grayBase.main,
   yellowBase.main,
   purpleBase.main,
   yellowishGreen.main,
   pinkBase.main,
   bananaYellow.main,
   pictonBlue.main,
   carrotOrange.main,
   pinkishPurple.main,
   mediumGreen.main,
   redBase.main,
   mustardGreen.main,
   greenBase.second,
   blueBase.second,
   orangeBase.second,
   grayBase.second,
   yellowBase.second,
];

export const darkColorSet = [
   greenBase.dark,
   blueBase.dark,
   orangeBase.dark,
   grayBase.dark,
   yellowBase.dark,
   purpleBase.dark,
   yellowishGreen.dark,
   pinkBase.dark,
   bananaYellow.dark,
   pictonBlue.dark,
   carrotOrange.dark,
   pinkishPurple.dark,
   mediumGreen.dark,
   redBase.dark,
   mustardGreen.dark,
   greenBase.second,
   blueBase.second,
   orangeBase.second,
   grayBase.second,
   yellowBase.second,
];

export const heatMapColorSet = [greenBase.dark, '#FFFFFF', redBase.main];

export const heatMapColorSetWhity = ['#FFFFFF', greenBase.dark, greenBase.second, greenBase.main, greenBase.light, greenBase.light1, greenBase.light2, redBase.light2, redBase.light1, redBase.light, redBase.main, redBase.second, redBase.dark];

export const heatMapColorSetNew = [
   // '#FFFFFF',
   '#98DDC5',
   '#2D8C6C',
   '#D48805',
   '#D4390C',
];

export const mapColorSet = ['#449C8C', '#C69849', '#BF2A22'];

export type IColorSet = 'main' | 'mainDark' | 'heatMap' | 'heatMapWhity' | 'heatMapColorSetNew' | 'map' | 'mapColorGradient' | 'negativeHeatMap';

export const ColorSets: Record<IColorSet, string[]> = {
   main: mainColorSet,
   mainDark: darkColorSet,
   heatMap: heatMapColorSet,
   heatMapColorSetNew,
   heatMapWhity: heatMapColorSetWhity,
   map: mapColorSet,
   mapColorGradient: MAP_LEGEND_COLORS,
   negativeHeatMap: [...heatMapColorSet].reverse(),
};

// #endregion
