import { ApiClients } from 'ApiClients';
import { Button } from 'antd';
import PATHS from 'power/PATHS';
import { FullSpin } from 'power/components/base/FullSpin';
import { PowerMapSelection } from 'power/map/usePowerMap';
import { ICapacityBranch, ICapacityNodes, ICapacitySubstations, IHeadroomCapacityFilter, INodeMapPageQuery } from 'power/types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CapacityInfo from './capacityInfo';
import classes from './index.module.scss';
import NodeInfo from './nodeInfo';

// const getSubstationsByIds = async (substationIds: number[]): Promise<ISubstationCapacity[]> => Promise.resolve(substationIds.map((id) => ({ id, name: `Substation ${id}` })));
// const getSubstationByBranchId = async (branchId: string): Promise<ISubstationCapacity> => Promise.resolve({ id: 101, name: `Branch ${branchId}` });
// const getSubstationByNodeId = async (nodeId: number): Promise<ISubstationCapacity> => Promise.resolve({ id: nodeId, name: `Node ${nodeId}` });
// const getNodesBySubstationId = async (substationId: number): Promise<INode[]> => Promise.resolve([{ id: 1, name: `Node 1 ${substationId}` }, { id: 2, name: `Node 2 ${substationId}` }]);
// const getNodesByIds = async (nodeIds: number[]): Promise<INode[]> => Promise.resolve(nodeIds.map((id) => ({ id, name: `Node ${id}` })));

type Props = {
  capacityQuery?: IHeadroomCapacityFilter;
  nodeQuery: Partial<INodeMapPageQuery>;

  selection: PowerMapSelection;

  setSelectedSubstation: (substationId: number) => void;
  setSelectedNode: (nodeId: number) => void;

  onClose: () => void;
};

const Sider: React.FC<Props> = ({ capacityQuery, nodeQuery, selection, setSelectedSubstation, setSelectedNode, onClose }) => {
   const [loading, setLoading] = useState<boolean>(true);

   const [capacityInfoSubstation, setCapacityInfoSubstation] = useState<ICapacitySubstations>();
   const [capacityInfoBranch, setCapacityInfoBranch] = useState<ICapacityBranch>();
   const [nodesData, setNodesData] = useState<ICapacityNodes>();

   const selectedSubstationId = useMemo(() => (selection?.substationIds ? (selection.substationIds.length > 0 ? selection.substationIds[0] : 0) : 0), [selection?.substationIds]);
   const selectedBranchId = useMemo(() => (selection?.branchIds ? (selection.branchIds.length > 0 ? selection.branchIds[0] : '') : ''), [selection?.branchIds]);
   const selectedNodeId = useMemo(() => (selection?.nodes?.ids ? (selection.nodes?.ids.length > 0 ? selection.nodes.ids[0] : 0) : 0), [selection?.nodes?.ids]);

   // set capacity info
   useEffect(() => {
      setLoading(true);

      if (capacityQuery && selectedSubstationId !== 0) {
         ApiClients.getInstance()
            .headroomOverviewSubstations({
               direction: capacityQuery.direction,
               season: capacityQuery.season,
               substationId: selectedSubstationId,
            })
            .then((data) => {
               data.substations = data.substations.filter((substation) => capacityQuery.voltage.min <= substation.voltage && substation.voltage <= capacityQuery.voltage.max);
               setCapacityInfoSubstation(data);
               setCapacityInfoBranch(undefined);
               setNodesData(undefined);
               setLoading(false);
            })
            .catch((error) => {
               console.error(error.message);
               onClose();
            });
      } else if (capacityQuery && selectedBranchId !== '') {
         ApiClients.getInstance()
            .headroomOverviewBranch({
               direction: capacityQuery.direction,
               season: capacityQuery.season,
               branchId: selectedBranchId,
            })
            .then((data) => {
               setCapacityInfoBranch(data);
               setCapacityInfoSubstation(undefined);
               setNodesData(undefined);
               setLoading(false);
            })
            .catch((error) => {
               console.error(error.message);
               onClose();
            });
      } else if (selectedNodeId !== 0) {
         ApiClients.getInstance()
            .nodesOverview(selectedNodeId)
            .then((data) => {
               setNodesData(data);
               setCapacityInfoSubstation(undefined);
               setCapacityInfoBranch(undefined);
               setLoading(false);
            })
            .catch((error) => {
               console.error(error.message);
               onClose();
            });
      } else {
         setCapacityInfoSubstation(undefined);
         setCapacityInfoBranch(undefined);
         setNodesData(undefined);
      }
   }, [capacityQuery, selectedSubstationId, selectedBranchId, onClose, selectedNodeId]);

   return (
      <>
         <div>
            {loading ? (
               <FullSpin size="large" />
            ) : (
               <>
                  {(capacityInfoBranch || capacityInfoSubstation) && (
                     <>
                        <CapacityInfo
                           data={{ substations: capacityInfoSubstation, branch: capacityInfoBranch }} selectedSubstationId={selectedSubstationId} selectedBranchId={selectedBranchId}
                           setSelectedSubstation={setSelectedSubstation} />
                        {(selectedSubstationId || capacityInfoBranch?.branch.fromStationId) && (
                           <div className={classes.bottomButtons}>
                              <Link to={`${PATHS.TransmissionCapacity}?substationId=${selectedSubstationId || capacityInfoBranch?.branch.fromStationId}`} target="_blank" rel="noopener noreferrer">
                                 <Button
                                    type="primary" size="large" style={{ width: '100%' }}
                                    shape="round">
                        Capacity Detail
                                 </Button>
                              </Link>
                    
                           </div>
                        )}
                     </>
                  )}

                  {nodesData && (
                     <>
                        <NodeInfo
                           key={selectedNodeId} nodeQuery={nodeQuery} nodes={nodesData.nodes ?? []}
                           location={nodesData.location}
                           selectedNodeId={selectedNodeId} selectedSubstationId={selectedSubstationId} setSelectedNode={setSelectedNode} />
                        <div className={classes.bottomButtons}>
                           <Link to={`${PATHS.NodeAnalysis}?nodeId=${selectedNodeId}`} target="_blank" rel="noopener noreferrer">
                              <Button
                                 type="primary" size="large" style={{ width: '100%' }}
                                 shape="round">
                      Node Analysis
                              </Button>
                           </Link>
                        </div>
                     </>
                  )}
               </>
            )}
         </div>
      </>
   );
};

export default Sider;
