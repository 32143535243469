import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin /* type SelectProps */ } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useDebounce } from 'power/hooks';
import { ILocationLookupMapBox } from 'power/types';
import { DATA_PROVIDER, NODE_TYPE } from 'power/types/enum';
import { utils } from 'power/utils';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import styles from './SelectLocation.module.scss';

const TagNames: Partial<Record<NODE_TYPE, string>> = {
   [NODE_TYPE.IsoBus]: 'B',
   [NODE_TYPE.DraftNode]: 'N',
   [NODE_TYPE.IsoHub]: 'H',
   [NODE_TYPE.Undefined]: 'S',
};

interface ISelectLocation /* extends SelectProps */ {
  // value?: number;
  fetcher: (query: string) => Promise<ILocationLookupMapBox[]>;
  onSelectCoordinate?: (selectedLng: number, selectedLat: number) => void;
  onSelectLocation?: (selectedLocation: ILocationLookupMapBox) => void;

  onClear?: () => void;

  minWidth?: number;
  placeholder?: React.ReactNode;
}

const SelectLocation: FC<PropsWithChildren<ISelectLocation>> = ({
   // value,
   fetcher,
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   onSelectCoordinate,
   onSelectLocation,
   onClear,
   minWidth = 400,
   placeholder = 'Search a node name, address or coordinates',
   /* ...rest */
}) => {
   const [fetching, setFetching] = useState(false);
   const [optionLngLat, setOptionLngLat] = useState<{ lng: number; lat: number } | undefined>(undefined);
   const [options, setOptions] = useState<ILocationLookupMapBox[]>([]);

   const [inputValue, setInputValue] = useState<string>('');
   const newQuery = useDebounce<string>(inputValue, 400);

   useEffect(() => {
      if (newQuery) {
         setOptionLngLat(undefined);
         setOptions([]);
         const lngLat = utils.map.toLnglat(newQuery);
         if (lngLat) {
            setOptionLngLat({ lng: lngLat.lng, lat: lngLat.lat });
         } else {
            setFetching(true);
            fetcher(newQuery)
               .then((r) => {
                  setOptions(r);
               })
               .finally(() => setFetching(false));
         }
      }
   }, [fetcher, newQuery, setOptions]);

   return (
      <Select
      /* value={options.some((o) => o.id === value) ? value : undefined} */
      // value={value}
      // size="small"
      /* variant="borderless" */
         placeholder={placeholder}
         style={{ backgroundColor: '#ffffff', minWidth, /* borderRadius: 5 */ }}
         listHeight={325}
         popupMatchSelectWidth={false}
         showSearch
         allowClear
         suffixIcon={<SearchOutlined />}
         filterOption={false}
         notFoundContent={fetching ? <Spin size="small" /> : null}
         onSearch={(newInputValue: string) => setInputValue(newInputValue)}
         onChange={(_, option: DefaultOptionType | DefaultOptionType[]) => {
            if (option && onSelectLocation) {
               onSelectLocation((option as any).payload as ILocationLookupMapBox);
            }
            setInputValue('');
         }}
         onClear={onClear}
      >
         {optionLngLat && (
            <Select.Option key={`${optionLngLat.lng}-${optionLngLat.lat}`} value={`${optionLngLat.lng}-${optionLngLat.lat}`} payload={optionLngLat}>
               <span>{`Coordinate(${optionLngLat.lat}, ${optionLngLat.lng})`}</span>
            </Select.Option>
         )}
         {options.map((location: ILocationLookupMapBox) => (
            <Select.Option key={location.id} value={location.id} payload={location}>
               <span>{location.nodeType === NODE_TYPE.MapBox ? location.description : location.name}</span>

               {/* location.nodeType &&  */location.nodeType !== NODE_TYPE.MapBox && (
                  <span className={styles[`icon-${TagNames[location.nodeType] ?? 'N'}`]}>
                     <span className={styles.tag}>{TagNames[location.nodeType] ?? 'N'}</span>
                  </span>
               )}

               {Number(location.provider) > 0 && location.nodeType !== NODE_TYPE.MapBox && <span className={styles.provider}>{DATA_PROVIDER[location.provider]}</span>}
            </Select.Option>
         ))}
      </Select>
   );
};

export default SelectLocation;
