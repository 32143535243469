import { FeatureCollection, Point } from 'geojson';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { loadImageAsync } from 'utils/mapbox';

type Props = {
  mapId: string;
  layerId: string;
  geojson: FeatureCollection<Point>;
  iconUrl: string;
  iconOffset?: number[];
};

const SymbolLayer: FC<Props> = ({ mapId, layerId, geojson, iconUrl, iconOffset = [0, 0] }) => {
   const { [mapId]: map } = useMap();
   const [loadingImages, setLoadingImages] = useState(false);

   const loadImgaes = useCallback(() => {
      if (map === undefined) return;
      setLoadingImages(true);
      loadImageAsync(map, iconUrl, layerId).then(() => setLoadingImages(false));
   }, [iconUrl, layerId, map]);

   useEffect(() => {
      loadImgaes();
   }, [loadImgaes]);

   useEffect(() => {
      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [loadImgaes, map]);

   if (loadingImages) return null;

   return (
      <Source id={layerId} type="geojson" data={geojson}>
         <Layer id={layerId} type="symbol" layout={{ 'icon-image': layerId, 'icon-size': 0.15, 'icon-allow-overlap': true, 'icon-offset': iconOffset }} />
      </Source>
   );
};

export default memo(SymbolLayer);
