import { ApiClients } from 'ApiClients';
import { FeatureCollection, MultiLineString, Point } from 'geojson';
import { CapacityProperties } from 'power/map/layers/CapacityLayers/type';
import { IHeadroomCapacity, IHeadroomCapacityFilter } from 'power/types';
import { useCallback, useEffect, useState } from 'react';
import { headroomCapacityToSubstationsGeojson, headroomCapacityToTransmissionLinesGeojson } from './utils';

const INITIAL_HEADROOM_CAPACITY: IHeadroomCapacity = {
   substations: [],
   transmissionLines: [],
};

const INITIAL_CAPACITY_SUBSTATIONS: FeatureCollection<Point, CapacityProperties> = {
   type: 'FeatureCollection',
   features: [],
};

const INITIAL_CAPACITY_BRANCHES: FeatureCollection<MultiLineString, CapacityProperties> = {
   type: 'FeatureCollection',
   features: [],
};

const useHeadroomCapacity = () => {
   const [loading, setLoading] = useState(true);
   const [headroomCapacity, setHeadroomCapacity] = useState<IHeadroomCapacity>(INITIAL_HEADROOM_CAPACITY);
   const [capacitySubstations, setCapacitySubstations] = useState<FeatureCollection<Point, CapacityProperties>>(INITIAL_CAPACITY_SUBSTATIONS);
   const [capacityBranches, setCapacityBranches] = useState<FeatureCollection<MultiLineString, CapacityProperties>>(INITIAL_CAPACITY_BRANCHES);

   useEffect(() => {
      const substations = headroomCapacityToSubstationsGeojson(headroomCapacity);
      setCapacitySubstations(substations);

      const branches = headroomCapacityToTransmissionLinesGeojson(headroomCapacity);
      setCapacityBranches(branches);
   }, [headroomCapacity]);

   const fetch = useCallback(async (filter: IHeadroomCapacityFilter) => {
      setLoading(true);
      setCapacitySubstations(INITIAL_CAPACITY_SUBSTATIONS);
      setCapacityBranches(INITIAL_CAPACITY_BRANCHES);
      return ApiClients.getInstance()
         .headroomMapData(filter)
         .then((data) => {
            // console.log('headroomCapacity', data)
            setHeadroomCapacity(data);
         })
         .catch((error) => {
            console.error(error);
            setHeadroomCapacity(INITIAL_HEADROOM_CAPACITY);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   return {
      loading,
      fetch,
      // headroomCapacity,
      capacitySubstations,
      capacityBranches,
   };
};

export default useHeadroomCapacity;
