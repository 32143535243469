import { AuthProvider } from 'providers/AuthProvider';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './app/App';
import './index.css';
import { AntdConfigProvider } from './providers/AntdConfigProvider';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
   <>
      {/* <React.StrictMode> */}
      <AntdConfigProvider>
         <AuthProvider>
            <App />
         </AuthProvider>
      </AntdConfigProvider>
      <ToastContainer />
      {/* </React.StrictMode> */}
   </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();