import { BaseContent } from './type';

const en: BaseContent = {
   loading: 'Loading',
   linear: 'Linear',
   logarithmic: 'Logarithmic',
   distance: 'Distance',
   date: 'Date',
   ancillary: 'Ancillary',
   active: 'Active',
   disabled: 'Disabled',
   constant: 'Constant',
   schedule: 'Schedule',
   specificProduction: 'Specific Production',
   arbitrage: 'Arbitrage',
   import: 'Import',
   download: 'Download',
   capacity: 'Capacity',
   custom: 'Custom',
   utility: 'Utility',
   reportDate: 'Report Date',
   marketType: 'Market Type',
   marketType2: 'Market',
   standalone: 'Standalone',
   acCoupled: 'AC Coupled',
   dcCoupled: 'DC Coupled',
   energy: 'Energy',
   merchant: 'Merchant',
   future: 'Future',
   start: 'Start',
   da: 'DA',
   rt: 'RT',
   services: 'Services',
   dayAhead: 'Day-Ahead',
   realTime: 'Real-Time',
   quickSight: 'QuickSight',
   dayAheadDash: 'Day-ahead',
   realTimeDash: 'Real-time',
   timeGranularity: 'Time Granularity',
   timeMonthly: 'Time (Monthly)',
   timeHourly: 'Time (Hourly)',
   search: 'Search',
   notifications: 'Notifications',
   powerdev: 'PowerDev ',
   legend: 'Legend',
   year: 'Year',
   startYear: 'Start Year',
   endYear: 'End Year',
   maxPrice: 'max price',
   minPrice: 'min price',
   maxVolatility: 'max volatility',
   numberOfNegative: 'Number of Negative',
   prices: 'prices',
   node: 'Node',
   hub: 'Hub',
   bus: 'Bus',
   nodes: 'nodes',
   live: 'Live',
   lastWeek: 'Last Week',
   week: 'Week',
   lastMonth: 'Last Month',
   lastYear: 'Last Year',
   thisYear: 'This Year',
   mw: 'Megawatt',
   iso: 'ISO',
   lmp: 'LMP',
   mce: 'MCE',
   mcc: 'MCC',
   mcl: 'MCL',
   tb1: 'TB1',
   tb2: 'TB2',
   tb3: 'TB3',
   tb4: 'TB4',
   std: 'STD',
   spinning: 'Spinning',
   spinningReserve: 'Spinning Reserve',
   nonSpinning: 'Non spin.',
   nonSpinningReserve: 'Non spinning Reserve',
   tenMinSpinning: '10-min Spinning',
   regulationUp: 'Regulation up',
   regulationDown: 'Regulation down',
   clear: 'Clear',
   delete: 'Delete',
   ok: 'Ok',
   save: 'Save',
   noData: 'No data',
   noResults: 'No results match the selected criteria. Please try again.',
   naturalGas: 'Natural gas',
   nuclear: 'Nuclear',
   hydro: 'Hydro',
   wind: 'Wind',
   hybrid: 'Hybrid',
   coal: 'Coal',
   solar: 'Solar',
   petroleum: 'Petroleum',
   other: 'Other',
   no: 'No',
   name: 'Name',
   totalDemand: 'Total Demand',
   averageHourlyDemand: 'Average Hourly Demand',
   totalGeneration: 'Total Generation',
   averageHourlyGeneration: 'Average Hourly Generation',
   fuelType: 'Fuel Type',
   max: 'Max',
   min: 'Min',
   thisPage: 'this page',
   years: 'Years',
   hubs: 'HUBS',
   hubLabel: 'Hub',
   average: 'Average',
   percentage: 'Percentage',
   count: 'Count',
   negativeAverage: 'Negative Average',
   negativeHours: 'Negative Hours',
   product: 'Product',
   blog: 'Blog',
   pricing: 'Pricing',
   contact: 'Contact',
   startNow: 'Start Now',
   startFree: 'Start Free',
   negativeCount: 'Negative Count',
   provider: 'Provider',
   price: 'Price',
   valueAvg: 'Value Avg',
   topValueAvg: 'Top Value Avg',
   bottomValueAvg: 'Bottom Value Avg',
   demand: 'Demand',
   filters: 'Filters',
   dateRange: 'Date Range',
   dartSpread: 'DART Spread',
   negativeDart: 'Negative Dart',
   startDate: 'Start Date',
   endDate: 'End Date',
   type: 'Type',
   hours: 'hours',
   hour: 'Hour',
   perYear: 'per year',
   rate: 'Rate',
   days: 'days',
   season: 'Season',
   cancel: 'Cancel',
   next: 'Next',
   ancillaryZone: 'Ancillary Zone',
   add: 'Add',
   back: 'Back',
   submit: 'Submit',
   value: 'Value',
   detail: 'Detail',
   pointDetail: 'Point Detail',
   all: 'All',
   cursorArea: 'Move your cursor here for scroll the page',
   ascending: 'Ascending',
   descending: 'Descending',
   dashboard: 'Dashboard',
   month: 'Month',
   standardDeviation: 'Standard Deviation',
   aToZ: 'A to Z',
   zToA: 'Z to A',
   lowToHigh: 'Low to High',
   highToLow: 'High to Low',
   latitude: 'Latitude',
   longitude: 'Longitude',
   reload: 'Reload',
   city: 'City',
   county: 'County',
   state: 'State',
   maxPeak: 'Max Peak',
   peak: 'Peak',
   range: 'Range',
   address: 'Address',
   component: 'Component',
   yearly: 'Yearly',
   select: 'Select',
   serviceError: 'An unexpected error occurred. Please reload the page and try again.',
   networkError: 'An unexpected error occurred. Please reload the page and try again.',
};

export default en;
