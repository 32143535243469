import { LMPIndexContent } from './type';

const en: LMPIndexContent = {
   lmpModuleTitle: 'LMP',
   overviewPageTitle: 'Overview',
   detailPageTitle: 'Detailed Analysis',
   heatmapsPageTitle: 'Heatmaps',
   futureDataPageTitle: 'Future Data',
};

export default en;
