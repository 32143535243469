import { useCallback, useEffect, useState } from 'react';
import useDebounce from './useDebounce';

const useOnChangeDebounce = <T>(defaultValue: T, onChangeDebounce: (newType: T) => Promise<boolean>, onChangeCallback?: (newType: T) => void, delay = 500) => {
   const [inputValue, setInputValue] = useState<T>(defaultValue);
   const debouncedValue = useDebounce<T>(inputValue, delay);

   useEffect(() => {
      if (debouncedValue === defaultValue) return;

      onChangeDebounce(debouncedValue).then((updated: boolean) => {
      // console.log('onChangeDebounce.updated', updated);
         if (updated === false) setInputValue(defaultValue);
      });
   }, [debouncedValue, defaultValue, onChangeDebounce]);

   const onChangeHandler = useCallback(
      (newValue: T) => {
         setInputValue(newValue);

         if (onChangeCallback) onChangeCallback(newValue);
      },
      [onChangeCallback],
   );

   return { inputValue, onChangeHandler };
};

export default useOnChangeDebounce;
