import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import InfoBox from 'power/components/base/InfoBox/InfoBox';
import { IBasisRiskSumValue } from 'power/types';
import { MARKET_TYPE, PERIOD_TYPE } from 'power/types/enum';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  nodeId: number;
};

const InfoBoxes: FC<Props> = ({ nodeId }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<IBasisRiskSumValue[]>();

   useEffect(() => {
      ApiClients.getInstance().basisRiskSumValues(nodeId, PERIOD_TYPE.last_5_year).then((res) => {
         setData(res);
         setLoading(false);
      });
   }, [nodeId]);

   const lmpBrAvgDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.lmpAvg ?? '';
   const lmpBrAvgRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.lmpAvg ?? '';
   const lmpBrStdDevDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.lmpStdDev ?? '';
   const lmpBrStdDevRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.lmpStdDev ?? '';
   const nodeLmpAvgDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.nodeLmpAvg ?? '';
   const nodeLmpAvgRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.nodeLmpAvg ?? '';
   const hubLmpAvgDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.hubLmpAvg ?? '';
   const hubLmpAvgRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.hubLmpAvg ?? '';

   if (loading || !data || data.length < 2) return null;

   return (
      <PowerRow gutter={[16, 16]} style={{ padding: '4px' }}>
         <PowerCol span={0} xxl={2}></PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP Basis Risk Avg"
               data={[
                  { key: 'DA', value: lmpBrAvgDA },
                  { key: 'RT', value: lmpBrAvgRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP Basis Risk STDV"
               data={[
                  { key: 'DA', value: lmpBrStdDevDA },
                  { key: 'RT', value: lmpBrStdDevRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="Node LMP Avg"
               data={[
                  { key: 'DA', value: nodeLmpAvgDA },
                  { key: 'RT', value: nodeLmpAvgRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="Hub LMP Avg"
               data={[
                  { key: 'DA', value: hubLmpAvgDA },
                  { key: 'RT', value: hubLmpAvgRT },
               ]}
            />
         </PowerCol>

         <PowerCol span={0} xxl={2}></PowerCol>
      </PowerRow>
   );
};

export default memo(InfoBoxes);
