import { ApiClients } from 'ApiClients';
import { IUser } from 'power/types';
import { useAuth } from 'providers/AuthProvider';
import { useEffect, useState } from 'react';

const useAppLoading = () => {
   const [loading, setLoading] = useState<boolean>(true);
   const { loggedIn, loggedOut } = useAuth();

   useEffect(() => {
      
      ApiClients.getInstance()
         .me()
         .then((user: IUser | undefined) => {
            if (user) {
               loggedIn(user);
            } else {
               loggedOut();
            }
         })
         .catch(() => {
            loggedOut();
         })
         .finally(() => {
            setLoading(false);
         });
   }, [loggedIn, loggedOut]);

   return { loading };
};

export default useAppLoading;
