import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { FORMAT } from 'power/constants';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
// import styles from './SelectDateRange.module.scss';

interface ISelectDateRange {
  startDate: string;
  endDate: string;
  onChange: (startDate: string, endDate: string) => void;
  pickerMode?: 'date' | 'year' | 'month';
  dateFormat?: string;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
}

const SelectDateRange: FC<PropsWithChildren<ISelectDateRange>> = ({ startDate, endDate, onChange, pickerMode = 'date', dateFormat = FORMAT.DATE, minDate, maxDate }) => {
   const startDayjs = useMemo(() => dayjs(startDate), [startDate]);
   const endDayjs = useMemo(() => dayjs(endDate), [endDate]);

   const onChangeHandle = useCallback(
      (values: [dayjs.Dayjs, dayjs.Dayjs]) => {
      // console.log('values', values);
         if (!values || !values[0]) return;

         onChange(values[0].format(dateFormat), values[1].format(dateFormat));
      },
      [dateFormat, onChange],
   );

   return (
      <DatePicker.RangePicker
      /* size="small" */
         format={dateFormat}
         picker={pickerMode}
         suffixIcon={null}
         value={[startDayjs, endDayjs]}
         onChange={(range) => onChangeHandle(range as [dayjs.Dayjs, dayjs.Dayjs])}
         /* disabledDate={(current) => {
            if (current > maxDate || current < minDate) return true;

            const tooLate = localStates.startDate && current.diff(localStates.startDate, 'days') >= maxDayRange;
            return !!tooLate;
         }} */
         minDate={minDate}
         maxDate={maxDate}
         allowClear={false}
         style={{ border: 0 }}
      />
   );
};

export default SelectDateRange;
