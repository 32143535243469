import { Input } from 'antd';
import { FC, useCallback } from 'react';
import classes from './LegendControl.module.scss';

type Props = {
  min: number;
  max: number;
  setMin: (value: number) => void;
  setMax: (value: number) => void;
  tickCount: number;
}

export const LegendControl: FC<Props> = ({min,  max, setMin, setMax, tickCount}) => {
   const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // check is input value is a number (not a string or empty string or NaN etc.)
      if (isNaN(Number(e.target.value))) return;
    
      const value = Number(e.target.value);
      if (e.target.name === 'min') setMin(value);
      if (e.target.name === 'max') setMax(value);
   };

   const tickTop = useCallback((index: number) => {
      const tickHeight = 100 / (tickCount - 1);
      return `${index * tickHeight}%`;
   }, [tickCount]);

   const tickValue = useCallback((index: number) => {
      const tickRange = (max - min) / (tickCount - 1);
      return (max - ((index + 1) * tickRange)).toFixed(0);
   }, [max, min, tickCount]);
  
   return (
      <div className={classes.control}>
         <div className={classes.input}>
            <Input
               type="number" name="max" value={max}
               size="small" style={{fontSize: 12}}
               onChange={onChange} />
         </div>
         
         <div className={classes.container}>
            {
               Array.from({length: tickCount - 2}, (_, i) => (
                  <div key={i} className={classes.tick} style={{top: tickTop(i + 1)}}>{tickValue(i)}</div>
               ))
            }
            
            
         </div>
         <div className={classes.input}><Input
            type="number" name="min" value={min}
            size="small" style={{fontSize: 12}}
            onChange={onChange} />
         </div>
      </div>
   );
}