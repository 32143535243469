// import { PowerMap } from 'power/components/maps';
import { Select } from 'antd';
import { ApiClients } from 'ApiClients';
import { IICAPFuture, IICAPFutureRequest } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import Chart from '../Chart';

const ICAP_ZONES: ICAP_ZONE_NYISO[] = [ICAP_ZONE_NYISO.NYCA, ICAP_ZONE_NYISO.LI, ICAP_ZONE_NYISO.NYC, ICAP_ZONE_NYISO.GJ];
const OPTIONS = [{ label: 'Price ($/kW - Month)', value: 'price' }];

type Props = {
  query: IICAPFutureRequest;
};

const FutureChart: FC<Props> = ({ query }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<(IICAPFuture & { x: string })[]>([]);

   useEffect(() => {
      setLoading(true);
      setData([]);

      const promises = ICAP_ZONES.map((zone) => ApiClients.getInstance().icapFutureTimeseries({ zone }));

      Promise.all(promises)
         .then((res) => {
            const d = res.flat();
            const d2 = d.map((item) => ({ ...item, x: `${item.period_year}-${item.period_month.toString().padStart(2, '0')}-01T00:00:00.000` }));
            setData(d2);
         })
         .finally(() => setLoading(false));
   }, []);

   return (
      <Chart
         select={(
            <Select
               value="price" options={OPTIONS} onChange={(value) => {}}
               style={{ minWidth: 180, textAlign: 'right' }} variant="borderless" size="small" />
         )}
         title="ICAP Future"
         loadingSize={loading ? [352, 380] : undefined}
         data={data}
         yField="price"
         yTitle={OPTIONS.find((o) => o.value === 'price')?.label}
         xTitle="Month"
      />
   );
};

export default FutureChart;
