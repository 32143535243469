/* eslint-disable react/no-array-index-key */
import React, { PropsWithChildren, useCallback } from 'react';
import Map, { ControlPosition, FullscreenControl, GeolocateControl, MapProps, NavigationControl, ScaleControl, ViewStateChangeEvent } from 'react-map-gl';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ENV, MAPBOX } from 'power/constants';
import BaseLayerControl from './controls/BaseLayerControl';
import SearchControl from './controls/SearchControl';
import { usePowerMap } from './usePowerMap';

// https://github.com/visgl/react-map-gl/issues/1266#issuecomment-753686953
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export type PowerMapViewProps = {
  id: string;
  controlPositions?: {
    fullScreen?: ControlPosition;
    navigation?: ControlPosition;
    geolocate?: ControlPosition;
    scale?: ControlPosition;
    baseLayerSwitch?: ControlPosition;
    search?: ControlPosition;
  };
  customMapProps?: Partial<MapProps>;
};

const PowerMapView: React.FC<PropsWithChildren<PowerMapViewProps>> = ({ id, controlPositions, customMapProps, children }) => {
   const { mapStyle, bounds, setBounds } = usePowerMap();

   const onMoveEnd = useCallback(
      (e: ViewStateChangeEvent) => {
         const newBounds = e.target.getBounds();
         setBounds([newBounds._sw.lng, newBounds._sw.lat, newBounds._ne.lng, newBounds._ne.lat]);
      },
      [setBounds],
   );

   return (
      <Map
         id={id}
         initialViewState={{ bounds }}
         mapStyle={MAPBOX.Styles[mapStyle]}
         // interactiveLayerIds={interactiveLayerIds}
         // onClick={onClick as any}
         mapboxAccessToken={ENV.MAPBOX_ACCESS_TOKEN}
         onMoveEnd={onMoveEnd}
         cursor="crosshair"
         maxBounds={MAPBOX.Bounds.USA}
         {...customMapProps}
      >
         {controlPositions?.baseLayerSwitch && <BaseLayerControl position={controlPositions?.baseLayerSwitch} />}
         {controlPositions?.scale && <ScaleControl position={controlPositions?.scale} />}
         {controlPositions?.geolocate && <GeolocateControl position={controlPositions?.geolocate} />}
         {controlPositions?.navigation && <NavigationControl position={controlPositions?.navigation} />}
         {controlPositions?.fullScreen && <FullscreenControl position={controlPositions?.fullScreen} />}
         <div id="noluyo" style={{position: 'absolute', top: 0, right: 0}}>
            {controlPositions?.search && <SearchControl position={controlPositions?.search} />}
         </div>

         {children}
      </Map>
   );
};

export default PowerMapView;
