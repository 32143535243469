import { DATA_PROVIDER } from 'power/types/enum';

export const DATA_PROVIDERS_LIST: { key: keyof typeof DATA_PROVIDER; value: DATA_PROVIDER; label: string; center: [number, number] }[] = [
   {
      key: 'Undefined',
      value: DATA_PROVIDER.Undefined,
      label: 'All ISOs',
      center: [-100.64366114141396, 41.98774097750529],
   },
   {
      key: 'SPP',
      value: DATA_PROVIDER.SPP,
      label: 'SPP',
      center: [-96.7610700041987, 38.248025634536894],
   },
   {
      key: 'ERCOT',
      value: DATA_PROVIDER.ERCOT,
      label: 'ERCOT',
      center: [-97.32164998050962, 29.46040083406259],
   },
   {
      key: 'MISO',
      value: DATA_PROVIDER.MISO,
      label: 'MISO',
      center: [-89.76698027132497, 36.84111070502373],
   },
   {
      key: 'CAISO',
      value: DATA_PROVIDER.CAISO,
      label: 'CAISO',
      center: [-121.3599081704049, 36.9333958798413],
   },
   {
      key: 'PJM',
      value: DATA_PROVIDER.PJM,
      label: 'PJM',
      center: [-78.40566077462529, 37.67454523142092],
   },
   {
      key: 'ISONE',
      value: DATA_PROVIDER.ISONE,
      label: 'ISONE',
      center: [-71.08886222547842, 42.43916631513018],
   },
   {
      key: 'NYISO',
      value: DATA_PROVIDER.NYISO,
      label: 'NYISO',
      center: [-73.88061933861962, 41.16506452383391],
   },
];

export default DATA_PROVIDERS_LIST;
