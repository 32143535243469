import { ZONE_BORDER_STYLE, ZONE_LABEL_STYLE } from 'power/constants/map/styles';
import { VECTOR_LAYER_ID } from 'power/types/enum';
import { CustomLayerProps, IBaseFilter, /* IZoneFilter, */ IZoneLayerProperties } from 'power/types/map';
import { utils } from 'power/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Layer, MapRef, Popup, Source } from 'react-map-gl';

const { /* layerType, */ layerId, sourceUrl, sourceLayer, /* layerLayout, layerPaint, */ layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.ZONE);

export const ZONE_DEFAULT_FILTER: IBaseFilter = {
   active: false,
};

type Props = {
  map: MapRef;
  layerProps: CustomLayerProps<IZoneLayerProperties, IBaseFilter>;
  beforeId?: string;
};

const ZoneLayer: React.FC<Props> = ({ map, layerProps, beforeId }) => {
   const [selectedZone, setSelectedZone] = useState<{ location: mapboxgl.LngLat; properties: IZoneLayerProperties[] } | undefined>(undefined);

   const filter = useMemo(() => layerProps?.filter || ZONE_DEFAULT_FILTER, [layerProps]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         const location = ev.lngLat;
         const properties = features.filter((f) => f.layer.id === layerId)?.map((x) => x.properties as IZoneLayerProperties);
         setSelectedZone({ location, properties });
      };

      if (map) {
         map.on('click', layerId, onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', layerId, onClickListener as any);
         }
      };
   }, [map]);

   return (
      <>
         {filter.active && (
            <Source id={layerId} type="vector" url={sourceUrl}>
               {/* <Layer
                  id={layerId} type="fill" source={layerId}
                  source-layer={sourceLayer} layout={layerLayout as FillLayout} paint={layerPaint as FillPaint}
                  minzoom={layerMinzoom} maxzoom={layerMaxzoom} beforeId={beforeId}
                  interactive /> */}
               <Layer
                  id={`${layerId}-border`} type="line" source={layerId}
                  source-layer={sourceLayer} layout={ZONE_BORDER_STYLE.LAYOUT} paint={ZONE_BORDER_STYLE.PAINT}
                  minzoom={layerMinzoom} maxzoom={layerMaxzoom} beforeId={beforeId} />
               <Layer
                  id={`${layerId}-label`} type="symbol" source={layerId}
                  source-layer={sourceLayer} layout={ZONE_LABEL_STYLE.LAYOUT} paint={ZONE_LABEL_STYLE.PAINT}
                  minzoom={layerMinzoom} maxzoom={layerMaxzoom} beforeId={beforeId} />
            </Source>
         )}
         {selectedZone && selectedZone.properties.length && layerProps.popup && (
            <Popup
               longitude={selectedZone.location.lng} latitude={selectedZone.location.lat} onClose={() => setSelectedZone(undefined)}
               closeOnClick={false}>
               <layerProps.popup key={selectedZone.properties[0].id} features={selectedZone.properties} />
            </Popup>
         )}
      </>
   );
};

export default ZoneLayer;
