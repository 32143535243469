import { LineLayout, LinePaint } from 'mapbox-gl';

const LAYOUT: LineLayout = {};

const PAINT: LinePaint = {
   // 'line-color': '#FFFFFF',
   'line-color': '#597EF7',
   'line-width': 2
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
