import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { HeaderPrimary } from './HeaderPrimary/HeaderPrimary';
import { PageContent } from './PageContent/PageContent';
// import classes from './index.module.scss'

type Props = {
  headerPrimary?: React.ReactNode;
  headerSecondary?: React.ReactNode;
  actionBar?: React.ReactNode;
  children?: React.ReactNode;
};
export const PrivatePageLayout: React.FC<Props> = ({ headerPrimary, headerSecondary, actionBar, children }) => (
   <>
      {headerPrimary && <HeaderPrimary>{headerPrimary}</HeaderPrimary>}

      <PageContent headerCount={headerPrimary ? 1 : 0} actionBar={actionBar} headerSecondary={headerSecondary}>
         <Suspense fallback={<Spin />}>{children}</Suspense>
      </PageContent>
   </>
);
