import { PowerCol, PowerRow, StaticMap } from 'components/ui';
import { INodeInfoResponse } from 'power/types';
import { utils } from 'power/utils';
import { FC } from 'react';
import NodeInfoBox from './NodeInfoBox';

type Props = {
  data: INodeInfoResponse;
};

const InfoBar: FC<Props> = ({ data }) => (
   <div className="info-bar">
      <PowerRow gutter={[16, 16]}>
         <PowerCol
            sm={0} md={0} lg={0}
            xl={3} xxl={5}></PowerCol>
         <PowerCol
            xs={24} md={12} lg={12}
            xl={9} xxl={7}>
            <div className="info-box">
               <NodeInfoBox node={data.node} />
            </div>
         </PowerCol>
         <PowerCol
            xs={0}
            md={12}
            lg={12}
            xl={9}
            xxl={7}
            style={{
               position: 'relative',
               minHeight: '80px',
               width: '100%',
               overflow: 'hidden',
            }}
         >
            {utils.isExist(data?.node?.latitude) && (
               <div className="info-box" style={{ height: 176 }}>
                  <StaticMap latitude={data?.node?.latitude as any} longitude={data?.node?.longitude as any} />
               </div>
            )}
         </PowerCol>
         <PowerCol
            sm={0} md={0} lg={0}
            xl={3} xxl={5}></PowerCol>
      </PowerRow>
   </div>
);

export default InfoBar;
