enum PERIOD_TYPE {
  custom = 0,
  yesterday = 1,
  last_3_days = 3,
  last_7_days = 7,
  last_15_days = 15,
  this_month = 30,
  last_month = 31,
  this_quarter = 90,
  last_quarter = 91,
  this_year = 360,
  last_year = 361,
  last_2_year = 362,
  last_3_year = 363,
  last_4_year = 364,
  last_5_year = 365,
  live = -1,
}

export default PERIOD_TYPE;
