import { TIME_GRANULARITY } from 'power/types/enum';

/* export const getTimeGranularityTitle = (timeGranularity: TIME_GRANULARITY | TIME_GRANULARITY): string => {
   const enumName = TIME_GRANULARITY[timeGranularity];
   return enumName.charAt(0).toUpperCase() + enumName.slice(1);
}; */

export const parseDateByTimeGranularity = (d: string, selectedTimeGranularity: TIME_GRANULARITY | TIME_GRANULARITY) => {
   const date = new Date(d);
   const language = 'en';

   switch (selectedTimeGranularity) {
   case TIME_GRANULARITY.hourly: {
      const year = d.split('-')[0]; // YYYY
      const monthDay = date.toLocaleString(language, { month: 'short', day: '2-digit' }).split(' ').join('-'); // Mm-DD
      const hour = new Date(d).toLocaleString(language, { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' }); // HH:mm

      // HH:mm
      // YYYY-Mm-DD
      return `${hour}
      ${year}-${monthDay}`;
   }

   case TIME_GRANULARITY.daily: {
      const year = d.split('-')[0]; // YYYY
      const monthDay = date.toLocaleString(language, { month: 'short', day: '2-digit' }).split(' ').join('-'); // Mm-DD
      return year === '0001' ? 'N/A' : `${year}-${monthDay}`; // YYYY-Mm-DD
   }
   case TIME_GRANULARITY.monthly: {
      const year = d.split('-')[0]; // YYYY
      const month = date.toLocaleString(language, { month: 'short' }); // Mm
      return `${year}-${month}`; // YYYY-Mm
   }

   case TIME_GRANULARITY.yearly: {
      return d.split('-')[0]; // YYYY
   }

   default: {
      return date.toLocaleString(language, {
         year: '2-digit',
         month: 'short',
         day: '2-digit',
      });
   }
   }
};

export const setChartRange = (min: number, max: number) => [Math.floor(Math.min(min * 1.1, 0)), Math.round(max * 1.1)] as [number, number];
