/* eslint-disable max-len */
import { BasisRiskDetailContent } from './type';

const en: BasisRiskDetailContent = {
   detailPageTitle: 'Basis Risk Detail',
   solarBasisRisk: 'Solar Basis Risk',
   windBasisRisk: 'Wind Basis Risk',
   averageBasisRisk: 'Average Basis Risk',
   nodeHubTimeSeries: 'Basis Risk Time Series',
   overview: 'Overview',
   dateFromDateTo: 'Date from/to Date',
   latLng: 'Lat/Lng',
   countyState: 'County/State',
   detailedAnalysis: 'Detailed Analysis',
   basisRiskTimeSeriesChartInfo:
    'The Basis Risk Time Series chart displays day-ahead and real-time average, solar, or wind basis risk in time-series format for the selected date range. To view this in more detail, the time granularity can be adjusted using the filter bar at the top of the page. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   averageBasisRiskChartInfo:
    'The Average Basis Risk chart displays the nodal and hub price as time series in the real-time or day-ahead market. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   windBasisRiskChartInfo:
    'The Wind Basis Risk chart displays the nodal and hub wind-weighted price in the real-time or day-ahead market as time series. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   solarBasisRiskChartInfo:
    'The Solar Basis Risk chart displays the nodal and hub solar-weighted price in the real-time or day-ahead market as time series. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   heatmaps: 'Heatmaps',
};

export default en;
