import { useEffect, useState } from 'react';

const useDebounce = <T>(value: T, delay = 500): T => {
   const [debouncedValue, setDebouncedValue] = useState<T>(value);

   useEffect(() => {
      if (value === debouncedValue) return;

      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
         clearTimeout(timer);
      };
   }, [value, delay, debouncedValue]);

   return debouncedValue;
};

export default useDebounce;
