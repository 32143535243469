import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { FC } from 'react';
import InfoBoxes from './components/InfoBoxes';
import TopFiveCongestionConstraints from './components/TopFiveCongestionConstraints';

type Props = {
   query: INodeInfoRequest;
   data: INodeInfoResponse;
};

const MccTab: FC<Props> = ({ query, data }) => (
   <PowerRow gutter={[16, 16]}>
      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <InfoBoxes nodeId={data.node.id} />
      </PowerCol>
      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <TopFiveCongestionConstraints nodeId={data.node.id} />
      </PowerCol>
   </PowerRow>
);

export default MccTab;
