import { DualAxes, DualAxesConfig } from '@ant-design/plots';
import { Radio } from 'antd';
import CardPanel from 'components/ui/CardPanel';
// import columnChartConfig from 'configs/antd/charts';
import { usePowerTimeSeries } from 'modules/HomeMap/SiderControl/container/content/nodeInfo/charts/PowerTimeSeries/usePowerTimeSeries';
import { API_ROUTES } from 'power/constants';
import IArbitrageTimeseriesRequest from 'power/types/IArbitrageTimeseriesRequest';
import { DATA_SUM_TYPE, MARKET_TYPE, PERIOD_TYPE, PRICE_COMPONENT, TIME_GRANULARITY, TXBX_COMPONENT } from 'power/types/enum';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';

// const chartConfig = columnChartConfig('', '');

type Props = {
  nodeId: number;
  marketType: MARKET_TYPE;
  tbxComponent?: TXBX_COMPONENT;
};
const MonthlyArbitrageTimeSeriesChart: FC<Props> = ({ nodeId, marketType, tbxComponent = TXBX_COMPONENT.Tb4 }) => {
   const { loading, fetch, data } = usePowerTimeSeries<IArbitrageTimeseriesRequest>(API_ROUTES.Arbitrage.TimeSeries);

   const [hour, setHour] = useState<TXBX_COMPONENT>(tbxComponent);

   const fetchData = useCallback(
      (reload?: boolean) => {
         fetch(
            {
               nodeId,
               hours: [hour],
               periodType: PERIOD_TYPE.last_5_year,
               sumType: DATA_SUM_TYPE.Monthly,
               components: [PRICE_COMPONENT.BATTERY_REVENUE_KW_YEAR, PRICE_COMPONENT.BATTERY_CHARGING_MWH, PRICE_COMPONENT.BATTERY_DISCHARGING_MWH],
               dayaheadMarket: marketType === MARKET_TYPE.DayAhead ? 1 : 0,
               realtimeMarket: marketType === MARKET_TYPE.Realtime ? 1 : 0,
            },
            reload,
         );
      },
      [fetch, hour, marketType, nodeId],
   );

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   const datas = useMemo(() => {
      if (data?.result?.data) {
         const idProps = (data.result as any).idProps as { [key: number]: { component: PRICE_COMPONENT } };

         const getSeries = (seriesVal: number) => {
            if (seriesVal === PRICE_COMPONENT.BATTERY_REVENUE_KW_YEAR) {
               return '$/kW-month';
            } else if (seriesVal === PRICE_COMPONENT.BATTERY_CHARGING_MWH) {
               return 'Charging MWh/MW-month';
            } else if (seriesVal === PRICE_COMPONENT.BATTERY_DISCHARGING_MWH) {
               return 'Discharging MWh/MW-month';
            }
            return '';
         };

         // eslint-disable-next-line eqeqeq
         const revenueId = Number(Object.keys(idProps).find((key) => idProps[Number(key)].component == PRICE_COMPONENT.BATTERY_REVENUE_KW_YEAR) ?? 0);
         const data1 = data.result.data.filter((d) => d.id === revenueId).map((d) => ({ ...d, series: getSeries(d.id % 1000) }));

         const data2 = data.result.data.filter((d) => d.id !== revenueId).map((d) => ({ ...d, series: getSeries(d.id % 1000) }));

         return [data2, data1];
      }
      return [[], []];
   }, [data]);

   const dualConfig: DualAxesConfig = useMemo(
      () => ({
         className: 'antd-chart',
         data: datas,
         xField: 'x',
         yField: ['y', 'y'],
         geometryOptions: [
            {
               geometry: 'column',
               isGroup: true,
               seriesField: 'series',
               marginRatio: 0,
               color: ['#4ddc8b', '#ff6f30'],
            },
            {
               geometry: 'line',
               seriesField: 'series',
               marginRatio: 0,
               color: '#0f45a8',
               lineStyle: {
                  lineWidth: 2,
               },
            },
         ],
         xAxis: {
            label: {
               formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
               autoRotate: true,
            },
         },
         legend: {
            position: 'bottom',
            padding: [24, 0, 0, 0],
            flipPage: false,
         },
         tooltip: {
            title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.monthly),
            offset: 50,
            showCrosshairs: true,
         },
      }),
      [datas],
   );

   return (
      <CardPanel
         title={`${marketType === MARKET_TYPE.DayAhead ? 'Day-Ahead' : 'Realtime'} Monthly Arbitrage Profile`} defaultViewMode="chart" status={loading || !data?.result?.data ? 'loading' : 'success'}
         fullScreen downloadURL={data?.downloadUrl} refreshClick={() => fetchData(true)}>
         {() => (
            <>
               <div className="card-panel-filter-bar">
                  <Radio.Group value={hour} onChange={(e) => setHour(e.target.value)}>
                     <Radio value={TXBX_COMPONENT.Tb4}>4 Hours</Radio>
                     <Radio value={TXBX_COMPONENT.Tb3}>3 Hours</Radio>
                     <Radio value={TXBX_COMPONENT.Tb2}>2 Hours</Radio>
                     <Radio value={TXBX_COMPONENT.Tb1}>1 Hours</Radio>
                  </Radio.Group>
               </div>
               <div style={{ margin: '4px 4px', color: '#808080', display: 'flex', justifyContent: 'space-between' }}>
                  <span>MWh/MW-month</span>
                  <span>$/kW-month</span>
               </div>
               <DualAxes {...dualConfig} />
            </>
         )}
      </CardPanel>
   );
};

export default MonthlyArbitrageTimeSeriesChart;
