// import { PowerMap } from 'power/components/maps';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { ApiClients } from 'ApiClients';
import { IICAPSpot, IICAPStripRequest } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import infobox from '../infobox.module.scss';

type Props = {
  queryStrip: IICAPStripRequest;
  month: number;
  selectedZone: ICAP_ZONE_NYISO;
  onClose: () => void;
};

const SpotInfo: FC<Props> = ({ queryStrip, month, selectedZone, onClose }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPSpot>();

   useEffect(() => {
      setData(undefined);
      setLoading(true);
      ApiClients.getInstance().icapSpotDetail({ ...queryStrip, period_month: month, zone: selectedZone })
         .then((res) => {
            if (res.length) {
               setData(res[0]);
            }
         })
         .finally(() => setLoading(false));
   }, [month, queryStrip, selectedZone]);

   return (
      <div className={infobox.info} style={{minWidth: 320}}>
         <div className={infobox.header}>
            <div className={infobox.title}>Spot</div>
            <div className={infobox.subtitle}>{selectedZone}</div>
            <div className={infobox.close}>
               <Button
                  icon={<CloseOutlined />} onClick={onClose} style={{ border: 'none' }}
                  size="small" shape="circle" />
            </div>
         </div>
         <div className={infobox.content}>
            {loading && <Spin style={{ margin: '0 auto', padding: 28 }} />}
            {!loading && data && (
               <>
                  <div className={infobox.rowTitles}>
                     <span>Awarded Deficiency (MW)</span>
                     <span>Awarded Excess (MW)</span>
                     <span>% Excess Above Requirement</span>
                     <span>Price ($/kW-M)</span>
                  </div>
                  <div className={infobox.rowValuesPast}>
                     <span>{data.awarded_deficiency}</span>
                     <span>{data.awarded_excess}</span>
                     <span>{data.excess_above_requirement}</span>
                     <span>{data.price}</span>
                  </div>
               </>
            )}
         </div>
      </div>
   );
}

export default SpotInfo;
