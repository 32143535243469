import { ReloadOutlined, WarningFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useContent } from 'content';
import ErrorSVG from 'power/assets/svg/ErrorSVG';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface IProps {
  status: 'loading' | 'noData' | 'success' | 'error';
  statusCode?: number;
  message?: string;
  filterBarRowCount?: number;
  pageHeader?: ReactNode;
}

const filterBarHeight = 59;

// TODO: breadcrumb route listesi ile yapılacak, json route listesi tutulacak
const PageContainer: FC<PropsWithChildren<IProps>> = ({ children, status, statusCode, message, filterBarRowCount = 1, pageHeader }) => {
   const { t } = useContent();

   // const [firstLoadComplated, setFirstLoadComplated] = useState(status === 'success');

   // const prevStatus = usePrevious(status);

   // useEffect(() => {

   //   if (status === 'success' && prevStatus === 'loading' && !firstLoadComplated) setFirstLoadComplated(true);

   // }, [firstLoadComplated, prevStatus, status]);

   return (
      <div className={`page-container page-status-${status}`}>
         {pageHeader}
         <div className="page-content">
            {status === 'loading' && (
            // && !firstLoadComplated
               <div
                  className="page-container-blocker"
                  style={{
                     top: filterBarRowCount * filterBarHeight + 46,
                  }}
               >
                  <div className="panel-loader" />

                  <div className="page-container-blocker-message">{t('base.loading')}</div>
               </div>
            )}

            {status === 'error' && (
               <div className="panel-loader-error">
                  <div className="pl-error-icon">
                     <WarningFilled />

                     <span className="pl-status-code">{statusCode}</span>
                  </div>

                  <div className="panel-loader-blocker-message">
                     {message || t('base.serviceError')}

                     <div className="pl-refresh-button">
                        <Button type="primary" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
                  Refresh Page
                        </Button>
                     </div>
                  </div>
               </div>
            )}

            {status === 'noData' && (
               <div className="panel-loader-no-data">
                  <div className="pl-no-data-icon">
                     <ErrorSVG />
                  </div>

                  <div className="panel-loader-blocker-message">
                     {message || t('base.noResults')}

                     <div className="pl-refresh-button">
                        <Button type="primary" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
                  Refresh Page
                        </Button>
                     </div>
                  </div>
               </div>
            )}

            {status === 'success' &&
          // || status === 'loading'
          children}
         </div>
      </div>
   );
};

export default PageContainer;
