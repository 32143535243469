import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import { ICAP } from 'modules/ICAP';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import { PowerMapProvider } from 'power/map/usePowerMap';
import ReactGA from 'react-ga4';

const INITIAL_BOUNDS: mapboxgl.LngLatBoundsLike = [-70.96, 45.8, -80.0, 39.98];
const INITIAL_MAP_STYLE: 'Default' | 'Satellite' = 'Default';

export const ICAPPage = () => {
   ReactGA.send({ hitType: 'pageview', page: '/icap', title: 'ICAP' });

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="ICAP" />}>
         <PowerMapProvider
            initialState={{
               bounds: INITIAL_BOUNDS,
               mapStyle: INITIAL_MAP_STYLE,
            }}
         >
            <ICAP />
         </PowerMapProvider>
      </PrivatePageLayout>
   );
};
