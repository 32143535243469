import { ILmpOverviewTimeSeriesRequest } from 'modules/NodeAnalysis/Container/Tabs/lmp/types';
import { API_ROUTES } from 'power/constants';
import useChartData from 'power/hooks/useChartData';
import { useCallback, useEffect, useState } from 'react';

export const useLmpTimeseries = (initialQuery?: ILmpOverviewTimeSeriesRequest) => {
   const { loading, error, data, fetch } = useChartData<ILmpOverviewTimeSeriesRequest>(API_ROUTES.LMP.TimeSeries);

   // TODO - add chartProps type
   const [chartData, setChartData] = useState<any | undefined>(undefined);

   useEffect(() => {
      if (!loading && !error && data.result) {
      // TODO - parse data to chartProps
         setChartData(data.result);
      }
   }, [loading, error, data]);

   const fetchLmpTimeseries = useCallback(
      (query: ILmpOverviewTimeSeriesRequest, reload: boolean = false) => {
         setChartData(undefined);
         fetch(query, reload);
      },
      [fetch],
   );

   useEffect(() => {
      if (initialQuery) fetch(initialQuery);
   }, [fetch, initialQuery]);

   return {
      loadingLmpTimeseries: loading,
      errorLmpTimeseries: error,
      chartData,
      fetchLmpTimeseries,
   };
};
