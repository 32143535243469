/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Table, TableColumnsType } from 'antd';
// import { DATA_PROVIDERS_LIST } from 'power/constants';
import { INewsListItem } from 'power/types';
import { FC } from 'react';

type Props = {
  loading: boolean;
  data: INewsListItem[];
};

const NewsTable: FC<Props> = ({ loading, data }) => {
   const columns: TableColumnsType<INewsListItem> = [
      { width: 300, sorter: true, title: 'Title', dataIndex: '', key: 'title' /* , filtered: true, filterSearch: true */, render: (row: INewsListItem) => <div style={{ textAlign: 'center', fontWeight: 500 }}>{row.title}</div> },
      { /* width: 110, */ title: 'Summary', dataIndex: 'summary', key: 'summary' /* , filtered: true, filterSearch: true */ },
      // { width: 170, sorter: true, title: 'Link', dataIndex: 'link', key: 'link', render: (date) => utils.math.formatDate(date, TIME_GRANULARITY.daily) },
      {
         width: 85,
         title: 'Detail',
         dataIndex: '',
         key: 'x',
         render: (row: INewsListItem) => (
            <Button type="link" size="small">
               <a href={row.link} target="_blank" rel="noreferrer">
            Detail
               </a>
            </Button>
         ),
         fixed: 'right',
      },
   ];

   return (
      <Table
         style={{ marginTop: 20 }}
         rowKey={(record) => record.title.toString()}
         loading={loading}
         columns={columns}
         dataSource={data}
         scroll={{ y: 'calc(100svh - 200px)', x: 'calc(100% - 64px)' }}
         /* pagination={{ pageSize: 11, showSizeChanger: false, showQuickJumper: true }} */
         bordered
      />
   );
};

export default NewsTable;
