import type { UseQueryOptions } from 'react-query';
import { QueryClient } from 'react-query';

const queries: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'> = {
   refetchOnWindowFocus: (d) => d.cacheTime > 3600000,
   cacheTime: 3600000,
   staleTime: 3600000,
   retry: false,
   keepPreviousData: true,
};

const queryClientProvider = new QueryClient({
   defaultOptions: {
      queries,
   },
});

export default queryClientProvider;
