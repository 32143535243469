import { FeatureCollection, Point } from 'geojson';
import React, { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { INodeLayerProperties } from '../type';
import { priceLayerProps } from './priceLayerProps';

type Props = {
  layerId?: string;
  mapId: string;
  minmax: number[];
  data: FeatureCollection<Point, INodeLayerProperties>;
  selectedId?: number;
  unit?: '$' | '%';
  beforeId?: string;
};

const PriceLayer: React.FC<Props> = ({ mapId, minmax, data, layerId = 'power-price-layer', selectedId, unit = '$', beforeId }) => {
   // const { [mapId]: map } = useMap();
   // const [loadingLayer, setLoadingLayer] = useState<boolean>(true);

   const { clusterLayer, clusterCountLayer, unclusteredPointLayer, unclusteredPointHighlightLayer, unclusteredTextLayer } = useMemo(() => priceLayerProps(minmax[0], minmax[1], unit), [minmax, unit]);

   /* useEffect(() => {
      const loadImgaes = () => {
         if(map === undefined) return;

         setLoadingLayer(true);
         addMapImages(map, images, true).then(() => setLoadingLayer(false)).finally(() => console.log('PowerPriceLayer.loadImgaes finished'));
      };

      const onClickClustered = (event: MapLayerMouseEvent) => {
         if(map === undefined) return;
         if (event.features === undefined || event.features.length === 0) return;

         const { cluster_id } = event.features[0].properties as any;
         const { coordinates } = event.features[0].geometry as any;
         if (!cluster_id || !coordinates) return;

         const mapboxSource = map.getSource('price-layer-source') as GeoJSONSource;

         mapboxSource.getClusterExpansionZoom(cluster_id, (err, zoom) => {
            if (err) {
               return;
            }

            map.easeTo({
               center: coordinates,
               zoom,
               duration: 500,
            });
         });
      };

      if(map){
         map.on('style.load', loadImgaes);
         map.on('click', 'price-clusters', onClickClustered);
      }
      
      return () => {
         if(map){
            map.off('style.load', loadImgaes);
         }
      };
   }, [map]); */

   // if (loadingLayer) return null;
   // console.log('PowerPriceLayer', 'selectedId', selectedId);

   return (
      <Source
         id="price-layer-source" type="geojson" data={data}
         cluster={true} clusterMaxZoom={9} clusterRadius={50}
         clusterProperties={{ price_total: ['+', ['get', 'value']] }}>
         <Layer {...clusterLayer} beforeId={beforeId} />
         <Layer {...clusterCountLayer} beforeId={beforeId} />
         <Layer {...unclusteredPointLayer} id={layerId} beforeId={beforeId} />
         {selectedId && (
            <Layer
               {...unclusteredPointHighlightLayer} id={`${layerId}-highlight`} beforeId={beforeId}
               filter={['==', ['get', 'id'], selectedId]} />
         )}
         <Layer {...unclusteredTextLayer} id={`${layerId}-label`} beforeId={beforeId} />
      </Source>
   );
};

export default PriceLayer;
