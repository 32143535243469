/* eslint-disable max-len */
import { IQOverviewContent } from './type';

const en: IQOverviewContent = {
   title: 'Interconnection Queue: Overview',
   mwByFuelType: 'Project (MW) by Fuel Type',
   mwByFuelTypeInfo: 'The Project (MW) by Fuel Type chart shows the capacity distribution of interconnection projects by fuel type and request date for each ISO.',
   projectCapacityByIRDate: 'Project (MW) by IR Date',
   projectCapacityByIRDateInfo: 'The Project MW by IR Date chart displays the capacity distribution of interconnection projects by fuel type and interconnection request date.',
   projectCountByIRDate: 'Project Count by IR Date',
   projectCountByIRDateInfo: 'The Project Count by IR Date displays the number of interconnection projects by fuel type and interconnection request date.',
   solarCapacityInQueue: 'Solar Capacity in Queue By ISO',
   solarCapacityInQueueInfo: 'The Solar Capacity in Queue By ISO chart shows the capacity of standalone and hybrid solar projects for each ISO.',
   windCapacityInQueue: 'Wind Capacity in Queue By ISO',
   windCapacityInQueueInfo: 'The Wind Capacity in Queue By ISO chart shows the capacity of standalone and hybrid wind projects for each ISO.',
   batteryCapacityInQueue: 'Battery Capacity in Queue By ISO',
   batteryCapacityInQueueInfo: 'The Battery Capacity in Queue By ISO chart shows the capacity of standalone and hybrid battery projects for each ISO.',
   otherCapacityInQueue: 'Other Capacity in Queue By ISO',
   otherCapacityInQueueInfo: 'The Other Capacity in Queue By ISO chart shows the capacity of standalone and hybrid projects for each ISO. ',
   queueStatusByIRDate: 'Queue Status (%) by IR Date',
   queueStatusByIRDateInfo:
    'The Queue Status (%) by IR Date chart displays the proportional breakdown of interconnection requests\' status for each IR year across different ISO regions. This visualization unveils the distribution of active, operational, withdrawn, and suspended projects over time, shedding light on the interconnection queue dynamics and progress within each specific ISO area.',
   queueStatusCountByIRDate: 'Queue Status Count by IR Date',
   queueStatusCountByIRDateInfo: 'The Queue Status Count by IR Date chart shows the number of projects categorized by their status and interconnection request date.',
   queueStatusCapacityByIRDate: 'Queue Status Capacity by IR Date',
   queueStatusCapacityByIRDateInfo: 'The Queue Status Capacity by IR Date chart shows the capacity of projects categorized by their status and interconnection request date.',
   totalCountOfQueueStatus: 'Total Count of Queue Status',
   totalCapacityOfQueueStatus: 'Total Capacity of Queue Status',
   queueStatusPercentByIRDate: 'Queue Status Count (%) by IR Date',
   queueStatusPercentByIRDateInfo:
    'The Queue Status (%) by IR Date displays the proportional breakdown of interconnection requests\' status for each IR year to visualize the distribution of active, operational, withdrawn, and suspended projects over time to reveal interconnection queue dynamics and progress.',
   queueStatusCapacityPercentByIRDate: 'Queue Status Capacity (%) by IR Date',
   queueStatusCapacityPercentByIRDateInfo:
    'The Queue Status (%) by IR Date displays the proportional breakdown of interconnection requests\' status for each IR year to visualize the distribution of active, operational, withdrawn, and suspended projects over time to reveal interconnection queue dynamics and progress.',
   percentageOfTotalCountOfQueueStatus: '% of Total Count of Queue Status',
   percentageOfTotalCapacityOfQueueStatus: '% of Total Capacity of Queue Status',
   capacity: 'Capacity',
   countOfType: 'Count of Type',
   qYear: 'Q Year',
};

export default en;
