import { CloseOutlined, LineChartOutlined, TableOutlined } from '@ant-design/icons';
import PowerMap from 'power/map';
import { IICAPStripRequest } from 'power/types';
import { useCallback, useState } from 'react';
import ChartView from './ChartView';
import ICAPMapFilter from './FilterBar';
import classes from './index.module.scss';
import MonthlyInfo from './infos/MonthlyInfo';
import SpotInfo from './infos/SpotInfo';
import StripInfo from './infos/StripInfo';
import ICAPCapacityParametersLayer from './layers/ICAPCapacityParametersLayer';
import ICAPMonthlyLayer from './layers/ICAPMonthlyLayer';
import ICAPSpotLayer from './layers/ICAPSpotLayer';
import ICAPStripLayer from './layers/ICAPStripLayer';
import ICAPZoneLayer from './layers/ICAPZoneLayer';
import { useICAPData } from './layers/useICAPData';
import { useSelectedFeature } from './layers/useSelectedFeature';
import TableView from './TableView';

const MAP_ID = 'icap-map-v2';

const ICAPHistoricalMap = () => {
   const { auctions, queryStrip, setAuctions, setQueryStrip, month, setMonth, loadingStrip, strip, loadingSpot, spot, loadingMonthly, monthly, capacityParameters } = useICAPData();
   const { selectedStripZone, selectedSpotZone, selectedMonthlyZone, handleStripClick, handleSpotClick, handleMonthlyClick, clearSelectedZones } = useSelectedFeature();

   const [tableViewOpen, setTableViewOpen] = useState(false);
   const [chartViewOpen, setChartViewOpen] = useState(false);

   const onSubmit = useCallback(
      (auctions: ('strip' | 'monthly' | 'spot')[], query: IICAPStripRequest, month?: number) => {
         clearSelectedZones();
         setAuctions(auctions);
         setQueryStrip(query);
         setMonth(month);
      },
      [clearSelectedZones, setAuctions, setQueryStrip, setMonth],
   );

   return (
      <div className={classes.fullContainer}>
         <div className={classes.filterBar}>
            <ICAPMapFilter
               loading={loadingStrip || loadingSpot || loadingMonthly} query={queryStrip} auctions={auctions}
               month={month} onSubmit={onSubmit} />
         </div>
         <div className={classes.container}>
            <PowerMap
               id={MAP_ID}
               /* customMapProps={{ maxBounds: [-82.02867082714488, 40.3754382777056, -69.65441258121662, 45.12977354909813] as [number, number, number, number], minZoom: 6.7, maxZoom: 6.7 }} */
               customMapProps={{ maxBounds: [-81.64, 40.22, -68.61, 45.225] as [number, number, number, number], minZoom: 5.99, maxZoom: 5.99, maxPitch: 0, bearing: 0 }}
            >
               <ICAPZoneLayer mapId={MAP_ID} />
               <ICAPStripLayer
                  mapId={MAP_ID} query={queryStrip} strip={strip}
                  selectedFeatureZone={handleStripClick} />
               {month && (
                  <>
                     <ICAPSpotLayer
                        mapId={MAP_ID} query={queryStrip} month={month}
                        spot={spot} selectedFeatureZone={handleSpotClick} />
                     <ICAPMonthlyLayer
                        mapId={MAP_ID} query={queryStrip} month={month}
                        monthly={monthly} selectedFeatureZone={handleMonthlyClick} />
                  </>
               )}
               <ICAPCapacityParametersLayer mapId={MAP_ID} data={capacityParameters} />
            </PowerMap>
            <div className={classes.infos}>
               {selectedMonthlyZone && month && (
                  <MonthlyInfo
                     queryStrip={queryStrip} month={month} selectedZone={selectedMonthlyZone}
                     onClose={() => handleMonthlyClick(undefined)} />
               )}
               {selectedSpotZone && month && (
                  <SpotInfo
                     queryStrip={queryStrip} month={month} selectedZone={selectedSpotZone}
                     onClose={() => handleSpotClick(undefined)} />
               )}
               {selectedStripZone && <StripInfo queryStrip={queryStrip} selectedZone={selectedStripZone} onClose={() => handleStripClick(undefined)} />}
            </div>

            {tableViewOpen && (
               <TableView
                  auctions={auctions} queryStrip={queryStrip} month={month}
                  capacityParameters={capacityParameters} />
            )}
            {chartViewOpen && <ChartView auctions={auctions} queryStrip={queryStrip} month={month} />}
            {tableViewOpen || chartViewOpen ? (
               <div
                  style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }}
                  onClick={() => {
                     setTableViewOpen(false);
                     setChartViewOpen(false);
                  }}
               >
                  <CloseOutlined style={{ fontSize: 32 }} />
               </div>
            ) : (
               <>
                  <div style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }} onClick={() => setTableViewOpen(true)}>
                     <TableOutlined style={{ fontSize: 32 }} />
                  </div>
                  <div style={{ position: 'absolute', right: 12, top: 72, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }} onClick={() => setChartViewOpen(true)}>
                     <LineChartOutlined style={{ fontSize: 32 }} />
                  </div>
               </>
            )}
         </div>
      </div>
   );
};

export default ICAPHistoricalMap;
