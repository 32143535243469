import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Select } from 'antd';
import { PowerCol } from 'components/ui';
import { PowerRow } from 'power/components/base/grid';
import { FutureHeatmapRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE, NYISO_ZONE } from 'power/types/enum';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  query: Omit<FutureHeatmapRequest, 'year'>;
  onSubmit: (newQuery: Omit<FutureHeatmapRequest, 'year'>) => void;
};

const FutureHeatmapFilter: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formQuery, setFormQuery] = useState<Omit<FutureHeatmapRequest, 'year'>>(query);
  
   const onChangeScenario = useCallback((value: FUTURE_SCENARIO) => {
      setFormQuery((prev) => ({ ...prev, scenario: value }));
   }, []);

   const onChangeMarketType = useCallback((value: MARKET_TYPE) => {
      setFormQuery((prev) => ({ ...prev, market_type: value }));
   }, []);

   const onChangeZone = useCallback((value: NYISO_ZONE) => {
      setFormQuery((prev) => ({ ...prev, zone: value }));
   }, []);


   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={4} xl={6} lg={7}
            xs={6} style={{ borderLeft: '2px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Scenerio:</span>
               <Select
                  variant="borderless" placeholder="Select scenerio" value={formQuery.scenario}
                  disabled={loading}
                  onChange={onChangeScenario}>
                  <Select.Option value={FUTURE_SCENARIO.LOW}>Low</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.REF}>Ref</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.HIGH}>High</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Market Type:</span>
               <Select
                  variant="borderless" placeholder="Select market type" value={formQuery.market_type}
                  disabled={loading} onChange={onChangeMarketType}>
                  <Select.Option value={MARKET_TYPE.DayAhead}>Day-Ahead</Select.Option>
                  <Select.Option value={MARKET_TYPE.Realtime}>Real Time</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Zone:</span>
               <Select
                  variant="borderless"
                  placeholder="Select zone"
                  value={formQuery.zone}
                  disabled={loading}
                  onChange={onChangeZone}
                  options={[
                     { label: 'A', value: NYISO_ZONE.A },
                     { label: 'B', value: NYISO_ZONE.B },
                     { label: 'C', value: NYISO_ZONE.C },
                     { label: 'D', value: NYISO_ZONE.D },
                     { label: 'E', value: NYISO_ZONE.E },
                     { label: 'F', value: NYISO_ZONE.F },
                     { label: 'G', value: NYISO_ZONE.G },
                     { label: 'H', value: NYISO_ZONE.H },
                     { label: 'I', value: NYISO_ZONE.I },
                     { label: 'J', value: NYISO_ZONE.J },
                     { label: 'K', value: NYISO_ZONE.K },
                  ]}
               ></Select>
            </Flex>
         </PowerCol>

         
         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '2px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default FutureHeatmapFilter;

