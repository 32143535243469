import { PowerRow } from 'power/components/base/grid';
import { ISubstationInfoRequest, ISubstationInfoResponse } from 'power/types';
import { FC } from 'react';
import ConstraintsByBranch from './components/ConstraintsByBranch';
import ConstraintsBySubstation from './components/ConstraintsBySubstation';
// import InfoBoxes from './components/InfoBoxes';

type Props = {
  query: ISubstationInfoRequest;
  data: ISubstationInfoResponse;
};

const CapacityTab: FC<Props> = ({ query, data }) => (
   <PowerRow>
      {/* <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <InfoBoxes substationId={data.substation.id} />
      </PowerCol> */}

      <ConstraintsBySubstation substationInfo={data.substation} />

      <ConstraintsByBranch substationId={data.substation.id} />
   </PowerRow>
);

export default CapacityTab;
