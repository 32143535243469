import { Layout } from 'antd';
import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import PATHS from 'power/PATHS';
import supportImg from 'power/assets/images/support-icon.png';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const SupportPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.Support, title: 'Support' });

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Support" />}>
         <Layout style={{ flexDirection: 'row', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginLeft: '1rem', marginRight: '2rem' }}>
               <img src={supportImg} alt="Support" style={{ width: '10rem' }} />
            </div>
            <div>
               <div style={{ marginBottom: '1rem' }}>
                  <span style={{ fontSize: '1.5rem', fontWeight: 600 }}>Let us help you</span>
               </div>
               <div style={{ marginBottom: '1rem' }}>
                  <span style={{ fontSize: '1rem' }}>If you have any questions or need more information, please contact our customer services.</span>
               </div>
               <div style={{ marginBottom: '1rem' }}>
                  <a href="mailto:helpdesk@power.dev" target="_blank" rel="noreferrer">
              helpdesk@power.dev
                  </a>
               </div>
            </div>
         </Layout>
      </PrivatePageLayout>
   );
};
