/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Table, TableColumnsType } from 'antd';
// import { DATA_PROVIDERS_LIST } from 'power/constants';
import { IIQListItem, IIQTableRequest, IPaginationData } from 'power/types';
import { /* DATA_PROVIDER,  */ FUEL_TYPE, IQ_PAGE_ORDER, IQ_STATUS, TIME_GRANULARITY } from 'power/types/enum';
import { utils } from 'power/utils';
import { FC } from 'react';

type Props = {
  loading: boolean;
  data: IPaginationData<IIQListItem>;
  currentPageNumber: number;
  onPaginationChange: (pageNumber: number, pageSize: number, pfilter: Partial<IIQTableRequest>, order: IQ_PAGE_ORDER) => void;
  onDetail: (id: number) => void;
  // onSelectionChange?: (selectedRow: IIQListItem) => void;
};

const IIQList: FC<Props> = ({ loading, data, currentPageNumber, onPaginationChange, onDetail /* , onSelectionChange */ }) => {
   const columns: TableColumnsType<IIQListItem> = [
      { width: 125, sorter: true, title: 'Request Date', dataIndex: 'requestDate', key: 'requestDate', render: (date) => utils.math.formatDate(date, TIME_GRANULARITY.daily) },
      { width: 110, title: 'Queue Position', dataIndex: 'queuePosition', key: 'queuePosition' },
      { width: 170, sorter: true, title: 'Project Name', dataIndex: 'projectName', key: 'projectName', filtered: true, filterSearch: true },
      /* {
         width: 80,
         title: 'ISO',
         dataIndex: 'provider',
         key: 'providers',
         render: (provider) => DATA_PROVIDER[provider],
         filters: DATA_PROVIDERS_LIST.filter((x) => x.value !== DATA_PROVIDER.Undefined).map((provider) => ({ text: provider.label, value: provider.value })),
      }, */
      /* { width: 250, sorter: true, title: 'Transmission Owner', dataIndex: 'owner', key: 'owner' }, */
      { width: 250, sorter: true, title: 'Interconnection Point', dataIndex: 'interconnectionPoint', key: 'interconnectionPoint' },
      { width: 120, title: 'Status', dataIndex: 'status', key: 'status', render: (status) => IQ_STATUS[status] },
      { width: 110, title: 'Fuel Type', dataIndex: 'fuelType', key: 'fuelType', render: (fuelType) => FUEL_TYPE[fuelType] },
      { width: 100, sorter: true, title: 'Capacity (MW)', dataIndex: 'queueMw', key: 'queueMw' },
      { width: 120, title: 'County', dataIndex: 'county', key: 'county' },
      { width: 120, title: 'State', dataIndex: 'state', key: 'state' },
      {
         width: 100,
         title: 'Detail',
         dataIndex: '',
         key: 'x',
         render: (row: IIQListItem) => (
            <Button type="link" size="small" onClick={() => onDetail(row.id)}>
          Detail
            </Button>
         ),
         fixed: 'right',
      },
   ];

   return (
      <Table
         rowKey={(record) => record.id.toString()}
         loading={loading}
         columns={columns}
         pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            total: data?.totalItemCount,
            current: currentPageNumber,
            style: { marginTop: 16 },
         }}
         onChange={({ current, pageSize }, pfilter: Partial<IIQTableRequest>, { field, order }: any) => {
            // console.log('pagination: current, pageSize', current, pageSize, field, order, pfilter);
            onPaginationChange(current ?? 1, pageSize ?? 10, pfilter ?? {}, getOrderFieldValue(field ?? '', order ?? 'ascend'));
         }}
         /* expandable={{
         expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
         rowExpandable: (record) => record.name !== 'Not Expandable',
      }} */
         dataSource={data?.response}
         scroll={{ y: 'calc(100svh - 260px)', x: 'calc(100% - 64px)' }}
         /* style={{ paddingTop: 4 }} */
         bordered
      />
   );
};

const getOrderFieldValue = (orderField: keyof IIQListItem, order: 'ascend' | 'descend'): IQ_PAGE_ORDER => {
   switch (orderField) {
   case 'requestDate':
      return order === 'ascend' ? IQ_PAGE_ORDER.Date : IQ_PAGE_ORDER.DateDesc;
      /* case 'queuePosition':
      return order === 'ascend' ? IQ_PAGE_ORDER.QueuePosition : IQ_PAGE_ORDER.QueuePositionDesc; */
   case 'projectName':
      return order === 'ascend' ? IQ_PAGE_ORDER.ProjectName : IQ_PAGE_ORDER.ProjectNameDesc;
      /* case 'provider':
      return order === 'ascend' ? IQ_PAGE_ORDER.Provider : IQ_PAGE_ORDER.ProviderDesc; */
      /* case 'state':
      return order === 'ascend' ? IQ_PAGE_ORDER.State : IQ_PAGE_ORDER.StateDesc;
   case 'county':
      return order === 'ascend' ? IQ_PAGE_ORDER.County : IQ_PAGE_ORDER.CountyDesc; */
   case 'owner':
      return order === 'ascend' ? IQ_PAGE_ORDER.Owner : IQ_PAGE_ORDER.OwnerDesc;
   case 'interconnectionPoint':
      return order === 'ascend' ? IQ_PAGE_ORDER.InterconnectionPoint : IQ_PAGE_ORDER.InterconnectionPointDesc;
      /* case 'statusText':
      return order === 'ascend' ? IQ_PAGE_ORDER.StatusText : IQ_PAGE_ORDER.StatusTextDesc;
   case 'fuelType':
      return order === 'ascend' ? IQ_PAGE_ORDER.FuelType : IQ_PAGE_ORDER.FuelTypeDesc; */
   case 'queueMw':
      return order === 'ascend' ? IQ_PAGE_ORDER.Capacity : IQ_PAGE_ORDER.CapacityDesc;
   default:
      return IQ_PAGE_ORDER.Undefined;
   }
};

export default IIQList;
