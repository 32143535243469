import { BarChartOutlined, EnvironmentOutlined, GroupOutlined, InfoCircleOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { ENV } from 'power/constants';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FutureHeatmapTab from './FutureHeatmap';
import FutureInfoTab from './FutureInfo';
import FutureMapTab from './FutureMap';
import FutureTimeseriesTab from './FutureTimeseries';
import classes from './index.module.scss';

export const Future = () => {
   const { hash } = useLocation();

   const setActiveKey = (key: string) => {
      window.location.hash = key;
   };

   const items: TabsProps['items'] = useMemo(() => {
      const tabProps: TabsProps['items'] = [
         {
            key: '#map',
            label: 'Map',
            children: <FutureMapTab  />,
            icon: <EnvironmentOutlined />,
         },
         {
            key: '#timeseries',
            label: 'Daily & Hourly Analysis',
            children: <FutureTimeseriesTab />,
            icon: <BarChartOutlined />,
         },
         {
            key: '#heatmap',
            label: 'Heatmap',
            children: <FutureHeatmapTab />,
            icon: <GroupOutlined />,
            disabled: ENV.DOMAIN === 'trial',
         },
         {
            key: '#info',
            label: 'Info',
            children: <FutureInfoTab />,
            icon: <InfoCircleOutlined />,
            disabled: true,
         },
      ];
      
      return tabProps;
   }, []);

   return (
      <div className={classes.tabContainer}>
         <Tabs
            style={{ /* paddingBottom: 32 */ }} activeKey={!!hash ? hash : items[0].key}
            type="card" items={items} onChange={setActiveKey} />
      </div>
   );
};
