const recKeyVal = (obj: any, prefix?: string): { key: string; value: any } | undefined => {
   const keys = Object.keys(obj);
   if (keys.length === 0) return undefined;

   const values = Object.values(obj);
   const key = prefix ? `${prefix}.${keys[0]}` : keys[0];
   const value = values[0];

   // BESS edit için array lerde böyle bir partial update olacak mı?
   // Yoksa array datasını komple mi update göndermemiz gerekiyor?
   // energySetting.incrementSchedule.0.value : 60
   // Emre: Selam abi arrayleri bir butun olarak gondermek gerekiyor.
   if (Array.isArray(value)) return { key, value };

   if (typeof value === 'object') return recKeyVal(value, key);

   return { key, value };
};

export default recKeyVal;
