import { ApiClients } from 'ApiClients';
import { Button } from 'antd';
import { IBasisRiskOverviewTimeSeriesRequest } from 'modules/NodeAnalysis/Container/Tabs/basis-risk/types';
import { API_ROUTES } from 'power/constants';
import { IBaseChartData, IBaseDataResponse, IChartData, ILocationSimple } from 'power/types';
import INodeInfoModel from 'power/types/INodeIfoModel';
import { MARKET_TYPE, PRICE_COMPONENT } from 'power/types/enum';
import { FC, useState } from 'react';

const testTimeSeries = async (nodes: ILocationSimple[], marketType: MARKET_TYPE) => {
   const result: (IBaseDataResponse<IBaseChartData<IChartData<any, number>>, IBasisRiskOverviewTimeSeriesRequest> | undefined)[] = [];
   const promises: Promise<IBaseDataResponse<IBaseChartData<IChartData<any, number>>, IBasisRiskOverviewTimeSeriesRequest> | undefined>[] = [];

   const fetcher = (nodeId: number, hubId: number) => ApiClients.getInstance().ApiRequestChart<IBasisRiskOverviewTimeSeriesRequest>(API_ROUTES.BASIS_RISK.TimeSeries, {
      nodes: [nodeId],
      dayAheadMarket: marketType === MARKET_TYPE.DayAhead ? 1 : 0,
      realtimeMarket: marketType === MARKET_TYPE.Realtime ? 1 : 0,
      hubId,
      component: PRICE_COMPONENT.NO,
   });

   const nodeData = (nodeId: number): Promise<INodeInfoModel> => ApiClients.getInstance().nodeInfo({ nodeId }).then((res) => res.result.node);

   nodes.forEach((node) => {
      promises.push(nodeData(node.id).then((res) => fetcher(node.id, res.hubId)));
   });

   await Promise.all(promises).then((values) => {
      values.forEach((value, i) => {
         console.log('Value:', nodes[i], value?.result?.data);
         result.push(value);
      });
   });

   return result;
};

type Props = {
  nodes: ILocationSimple[];
  marketType: MARKET_TYPE;
};

export const TimeSeriesTest: FC<Props> = ({ nodes, marketType }) => {
   const [result, setResult] = useState<string>();

   const runTestTimeSeries = async () => {
      if (!nodes) return;
      const testResult = await testTimeSeries(nodes, marketType);
      const emptyResults = testResult.filter((response) => response === undefined || response.result === undefined || response.result.data === undefined || response.result.data.length === 0);
      setResult(emptyResults.length === 0 ? '' : 'Failed');
      console.log('Empty Results:', emptyResults);
   };
   return (
      <>
         <div>
            <Button onClick={runTestTimeSeries}>
          Test Time Series API - {MARKET_TYPE[marketType]}
            </Button>
         </div>
         {result !== undefined && <div>{result === '' ? 'Success' : <div>{result}</div>}</div>}
      </>
   );
};
