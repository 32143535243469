import { Expression, FillLayout, FillPaint, LineLayout, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

// BORDER
const LINE_LAYOUT: LineLayout = {};
const LINE_PAINT: LinePaint = {
   'line-color': '#FFFFFF',
   'line-width': 2,
};

// LABEL
const TITLE_LAYOUT: SymbolLayout = {
   // 'Zone' değeri ile ['feature-state', 'price'] birleştir
   'text-field': ['concat', ['get', 'Zone'], '\n', ['to-string', ['get', 'price']]],
   'text-size': 18, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0],
};
const TITLE_PAINT: SymbolPaint = {
   // 'text-color': '#fff',
   // 'text-color': '#597EF7',
   'text-color': '#FFFFFF',
};

// FILL
const FILL_LAYOUT: FillLayout = {};
const FILL_PAINT = (fillColor?: Expression | string): FillPaint => ({
   'fill-color': fillColor,
   // 'fill-opacity': 0.5,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default { LINE_LAYOUT, LINE_PAINT, TITLE_LAYOUT, TITLE_PAINT, FILL_LAYOUT, FILL_PAINT };
