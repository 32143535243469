import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Flex, Select, Slider } from 'antd';
import { InputNumberDebounce } from 'power/components/base';
import Legend from 'power/map/Legend';
import { usePowerMap } from 'power/map/usePowerMap';
import { IHeadroomCapacityFilter } from 'power/types';
import { HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON } from 'power/types/enum';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseSection from '../base/BaseSection';
import classes from './CapacityLayersSection.module.scss';

type Props = {
  mapId: string;
};

const CapacityLayersSection: React.FC<PropsWithChildren<Props>> = ({ mapId }) => {
   const { capacityLayer, setCapacityLayer } = usePowerMap();

   const changeActive = useCallback(() => {
      setCapacityLayer(
         (prev) => prev && {
            ...prev,
            active: !prev.active,
         },
      );
   }, [setCapacityLayer]);
   const changeSubstationsVisible = useCallback(() => {
      setCapacityLayer((prev) => prev && { ...prev, visible: { ...prev.visible, substations: !prev.visible.substations } });
   }, [setCapacityLayer]);

   const changeBranchVisible = useCallback(() => {
      setCapacityLayer((prev) => prev && { ...prev, visible: { ...prev.visible, branches: !prev.visible.branches } });
   }, [setCapacityLayer]);

   const onChangeVoltage = useCallback(
      (newValues: number[]) => {
         setCapacityLayer((prev) => prev && { ...prev, query: { ...prev.query, voltage: { min: newValues[0], max: newValues[1] } } });
      },
      [setCapacityLayer],
   );

   const onSubmit = useCallback(
      (newQuery: IHeadroomCapacityFilter) => {
         setCapacityLayer((prev) => (prev ? { ...prev, query: newQuery } : undefined));
      },
      [setCapacityLayer],
   );

   useEffect(() => {
      if (capacityLayer?.query) {
         onSubmit(capacityLayer.query);
      }
   }, [capacityLayer?.query, onSubmit]);

   const [minMaxSubstations, minMaxBranches, countSubstations, countBranches] = useMemo(() => {
      const valuesSubstations = capacityLayer?.data?.substations?.features.map((s) => s.properties.capacity) ?? [0];
      const minMaxSubstations = [Math.min(...valuesSubstations), Math.max(...valuesSubstations)];
      const countSubstations = capacityLayer?.data?.substations?.features.length ?? 0;

      const valuesBranches = capacityLayer?.data?.branches?.features.map((t) => t.properties.capacity) ?? [0];
      const minMaxBranches = [Math.min(...valuesBranches), Math.max(...valuesBranches)];
      const countBranches = capacityLayer?.data?.branches?.features.length ?? 0;

      return [minMaxSubstations, minMaxBranches, countSubstations, countBranches];
   }, [capacityLayer?.data?.substations?.features, capacityLayer?.data?.branches?.features]);

   if (!capacityLayer) return null;

   return (
      <BaseSection
         mapId={mapId} active={capacityLayer?.active ?? false} setActive={changeActive}
         title="Headroom Capacity" defaultCollapsed={capacityLayer?.defaultCollapsed} loading={capacityLayer.loading}>
         <div className={classes.capacityControl}>
            <Flex justify="space-around" align="center" style={{ paddingTop: 8 }}>
               <Select
                  variant="borderless"
                  placeholder="Select capacity"
                  defaultValue={HEADROOM_CAPACITY_DIRECTION.CHARGING}
                  value={capacityLayer.query.direction}
                  disabled={capacityLayer.loading}
                  onChange={(value) => setCapacityLayer((prev) => prev && { ...prev, query: { ...prev.query, direction: value as HEADROOM_CAPACITY_DIRECTION } })}
                  size="small"
                  style={{ width: 110, textAlign: 'right' }}
               >
                  <Select.Option value={HEADROOM_CAPACITY_DIRECTION.CHARGING}>Injection</Select.Option>
                  <Select.Option value={HEADROOM_CAPACITY_DIRECTION.DISCHARGING}>Withdrawal</Select.Option>
               </Select>

               <Select
                  variant="borderless"
                  placeholder="Select season"
                  defaultValue={HEADROOM_CAPACITY_SEASON.SUMMER}
                  value={capacityLayer.query.season}
                  disabled={capacityLayer.loading}
                  onChange={(value) => setCapacityLayer((prev) => prev && { ...prev, query: { ...prev.query, season: value as HEADROOM_CAPACITY_SEASON } })}
                  size="small"
                  style={{ width: 130, textAlign: 'right' }}
               >
                  <Select.Option value={HEADROOM_CAPACITY_SEASON.SUMMER}>Summer Peak</Select.Option>
                  <Select.Option value={HEADROOM_CAPACITY_SEASON.LIGHTLOAD}>Light Load</Select.Option>
                  <Select.Option value={HEADROOM_CAPACITY_SEASON.WINTER}>Winter Peak</Select.Option>
               </Select>
            </Flex>

            {/* <Slider
               marks={{ 0: '0', 1: '69', 2: '115', 3: '275', 4: '345' }}
               min={0}
               max={4}
               range
               step={1}
               value={[[0, 69, 115, 275, 345].findIndex((x) => x === capacityLayer.query.voltage.min), [0, 69, 115, 275, 345].findIndex((x) => x === capacityLayer.query.voltage.max)]}
               disabled={capacityLayer.loading}
               tooltip={{ open: false }}
               onChangeComplete={(values) => {
                  console.log(values);
                  const min = [0, 69, 115, 275, 345][values[0]];
                  const max = [0, 69, 115, 275, 345][values[1]];
                  setCapacityLayer((prev) => prev && { ...prev, query: { ...prev.query, voltage: { min, max } } });
               }}
               style={{ margin: '10px 32px 24px' }}
            /> */}
            <SliderDebounce initialValue={[capacityLayer.query.voltage.min, capacityLayer.query.voltage.max]} onChange={onChangeVoltage} disabled={capacityLayer.loading} />
            <Flex justify="center" align="center" style={{ paddingLeft: 20 }}>
               <span style={{ fontSize: 13 }}>Max Refurbishment Cost ($ Million):</span>
               <InputNumberDebounce
                  type="int"
                  variant="borderless"
                  placeholder="All"
                  controls={false}
                  disabled={capacityLayer.loading}
                  value={capacityLayer.query.maxRefurbishmentCost}
                  onChangeDebounce={(value) => {
                     setCapacityLayer((prev) => prev && { ...prev, query: { ...prev.query, maxRefurbishmentCost: Number(value) } });
                     return Promise.resolve(true);
                  }}
                  style={{ width: 50, textAlign: 'right', marginLeft: 10 }}
               />
            </Flex>

            <Flex justify="center" align="center" style={{ paddingBottom: 10 }}>
               <Button
                  className={classes.eye} size="small" type="text"
                  onClick={changeSubstationsVisible}>
                  {capacityLayer.visible.substations ? <EyeOutlined /> : <EyeInvisibleOutlined />} Substations
               </Button>

               <div style={{ width: 32 }} />

               <Button
                  className={classes.eye} size="small" type="text"
                  onClick={changeBranchVisible}>
                  {capacityLayer.visible.branches ? <EyeOutlined /> : <EyeInvisibleOutlined />} Branches
               </Button>
            </Flex>

            {capacityLayer.loading === false && (capacityLayer.visible.substations || capacityLayer.visible.branches) && (
               <div className={classes.legendControl}>
                  <Legend
                     scaleValues={
                        capacityLayer.visible.substations && capacityLayer.visible.branches
                           ? [
                              { position: 'top', label: `(${countSubstations}) Substations (MW)`, minmax: minMaxSubstations },
                              { position: 'bottom', label: `(${countBranches}) Branches (MW)`, minmax: minMaxBranches },
                           ]
                           : capacityLayer.visible.substations && !capacityLayer.visible.branches
                              ? [{ position: 'top', label: `(${countSubstations}) Substations (MW)`, minmax: minMaxSubstations }]
                              : [{ position: 'top', label: `(${countBranches}) Branches (MW)`, minmax: minMaxBranches }]
                     }
                  />
               </div>
            )}
         </div>
      </BaseSection>
   );
};

const VOLTAGES = [0, 69, 115, 275, 345];

const SliderDebounce: React.FC<{ initialValue: number[]; onChange: (newValues: number[]) => void; disabled: boolean }> = ({ initialValue, onChange, disabled }) => {
   const [value, setValue] = useState<number[]>([VOLTAGES.findIndex((x) => x === initialValue[0]), VOLTAGES.findIndex((x) => x === initialValue[0])]);
   const [finalValue, setFinalValue] = useState<number[]>(value);
   const isFirstRender = useRef(true);

   useEffect(() => {
      if (isFirstRender.current) {
         isFirstRender.current = false;
         return;
      }

      const delayDebounceFn = setTimeout(() => {
         onChange(finalValue);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
   }, [finalValue, onChange]);

   const handleAfterChange = (newValue: number[]) => {
      const min = VOLTAGES[newValue[0]];
      const max = VOLTAGES[newValue[1]];
      setFinalValue([min, max]);
   };

   return (
      <Slider
         // {{ 0: '0', 1: '69', 2: '115', 3: '275', 4: '345' }}
         marks={VOLTAGES.reduce((acc, cur, idx) => ({ ...acc, [idx]: cur.toString() }), {})}
         min={0}
         max={4}
         range
         step={1}
         value={value}
         onChange={(newValues: number[]) => setValue(newValues)}
         onChangeComplete={handleAfterChange}
         disabled={disabled}
         tooltip={{ open: false }}
         style={{ margin: '10px 32px 24px' }}
      />
   );
};

export default CapacityLayersSection;
