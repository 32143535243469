enum MOVING_AVERAGE_TYPE {
  Undefined = 0,
  MovingAvg9Day = 9,
  MovingAvg20Day = 20,
  MovingAvg50Day = 50,
  MovingAvg100Day = 100,
  MovingAvg200Day = 200,
}

export default MOVING_AVERAGE_TYPE;
