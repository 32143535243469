const API_ROUTES = {
   AUTH: {
      // Register: 'basic-register',
      Login: 'auth',
      Me: 'currentuser',
      ActivateEmail: 'activateemail',
      ForgotPassword: 'forgot-password',
      ResetPassword: 'reset-password',
      ChangePassword: 'change-password',
      Logout: 'auth/logout',
   },

   ICAP: {
      Strip: 'icap/strip',
   },

   MAP: {
      Node: 'node-map/data',
      PP: 'layers',
   },
   
   NODE: {
      List: '/v2/lookups/node-search-by-term',
      TimeSeries: '/node-map/trend/chart',
      PageDetail: '/node/page-detail',
   },

   IQ: {
      Table: 'layers/iq/pagination',
      Map: 'layers',
      Detail: 'layers/iq',
   },
   
   PP: {
      Detail: 'layers/pp',
   },

   TL: {
      Detail: '/layers/tl',
   },

   LOCATION: {
      SearchByTerm: 'location-search-by-term',
      SearchCountyByTerm: 'county-search-by-term',
      // ByIDs: 'location-by-ids',
      // ByIDsDetailed: 'location-by-ids-detailed',
      Zones: 'location-zones',
      Hubs: 'location-hubs',
      MapboxSearch: 'location-mapbox-search-map',
      ByCoordinates: 'location-by-coordinates',
      Substations: '/location/substations',
   },
   

   LMP: {
      SumValues: 'lmp/sum-values',
      TimeSeries: 'lmp/overview/time-series',
      YearlySum: 'lmp/yearly-sum',
      WeightedSeries: 'v2/lmp/weighted-time-series',
      Heatmap: 'lmp/heat-map',
   },

   TBx: {
      SumValues: 'txbx/sum-values',
      PerformanceTimeSeries: '/txbx/performance/time-series',
      MovingAverageTimeSeries: '/txbx/moving-average/time-series',
   },

   Arbitrage: {
      InfoBox: '/v2/arbitrage/info-box',
      TimeSeries: '/v2/arbitrage/time-series',
   },

   BR: {
      MapPageDetail: 'basis-risk/map/page-detail',
      MapPageInfo: 'basis-risk/map/page-info',
      MapPage: 'basis-risk/map/data-page',
   },

   BASIS_RISK: {
      SumValues: '/basis-risk/sum-values',
      TimeSeries: 'basis-risk/overview/time-series',
      YearlySum: 'basis-risk/yearly-sum',
   },
   

   HEADROOM_CAPACITY: {
      Map: 'headroom-capacity/map/page',
      PageDetail: '/node/page-detail',

      Substations: 'headroom-capacity/sidebar',
      Branch: 'headroom-capacity/sidebar',
      Nodes: 'headroom-capacity/sidebar', // not capacity, just node list..

      SumValues: 'headroom-capacity/substations/sum-values',
      Branches: 'headroom-capacity/branches/by-substation',
      ConstraintsBySubstation: 'headroom-capacity/constraints/by-substation',
      ConstraintsByBranch: 'headroom-capacity/constraints/by-branch',
   },

   CONGESTION: {
      InfoBox: '/congestion/info-box',
      ShadowPrice: '/shadow-price/node',
      TimeSeries: '/congestion/overview/time-series',
   },
};

export default API_ROUTES;
