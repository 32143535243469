import { ApiClients } from 'ApiClients';
import { Alert } from 'antd';
import PATHS from 'power/PATHS';
import EnvelopeSVG from 'power/components/base/svg/Envelope';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import styles from './ForgotPasswordPage.module.scss';

const ForgotPasswordPage = () => {
   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);
   const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
   const navigate = useNavigate();

   const onForgot = useCallback(async (email: string) => {
      setLoading(true);
      setErrorMessage(undefined);
      setSuccess(false);
      try {
         const res = await ApiClients.getInstance().forgotPassword(email);
         if (res === false) throw new Error('Invalid email');
         else setSuccess(true);
      } catch (error: any) {
         console.error('error', error);
         setErrorMessage('Invalid email');
         setLoading(false);
      }
   }, []);

   return (
      <div className={styles.content}>
         <div className={styles.form}>
            <div className={styles.logo} />
            {success ? (
               <Alert
                  message={(
                     <>
                        <h3 className={styles.title}>Check your inbox!</h3>
                        <div className={styles.subtitle}>
                           <br />
                           <EnvelopeSVG />
                           <br />
                           <br />
                           <div>Password reset link has been sent to your email address. Please check your inbox.</div>
                        </div>
                     </>
                  )}
                  type="success"
                  style={{ marginBottom: '1rem', padding: '1rem' }}
               />
            ) : (
               <>
                  <h3 className={styles.title}>Forgot Your Password?</h3>
                  <div className={styles.subtitle}>Please enter your email address to get a password reset link.</div>
               </>
            )}
            <ForgotPasswordForm
               loading={loading} onForgot={onForgot} errorMessage={errorMessage}
               onSignIn={() => navigate(PATHS.Auth_Login)} success={success} />
         </div>
      </div>
   );
};

export default ForgotPasswordPage;
