import { Expression } from 'mapbox-gl';
// import { MAP_LEGEND_COLORS as COLORS } from 'power/constants/map/MAP_LEGEND_COLORS';

// const COLORS = ['#f9ecdd', '#f7d0b4', '#f59971', '#f05c42', '#d5234a', '#9f195b', '#661f54', '#31183b', '#0c0e22']; //  10, 15, 20, 25, 30, 35, 40
// const COLORS = ['#0c0e22', '#31183b', '#661f54', '#9f195b', '#d5234a', '#f05c42', '#f59971', '#f7d0b4', '#f9ecdd'];
// const COLORS = ['#d5234a', '#f05c42', '#f59971', '#f7d0b4'].reverse();
// const COLORS = ['#DCDCDC','#D3D3D3','#C0C0C0','#A9A9A9','#808080','#696969']
const COLORS = [
   '#ffbaba',
   '#ff7b7b',
   '#ff5252',
   '#ff0000',
   '#a70000',
]

export const getLegendColorExpression = (min: number, max: number, linearProperty: Expression): Expression | string => {
   if (min === max) return COLORS[0];

   const tickValue = (max - min) / (COLORS.length - 1);

   const exp: Expression = ['interpolate', ['linear'], linearProperty];

   COLORS.forEach((color, i) => {
      exp.push(min + tickValue * i);
      exp.push(color);
   });
   return exp;
};