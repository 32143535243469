import { LMPMapContent } from './type';

const en: LMPMapContent = {
   mapPageTitle: 'LMP Map',
   nodeOverview: 'Node Overview',
   detailedAnalysis: 'Detailed Analysis',
   yearlyTrend: 'Yearly Trend',
};

export default en;
