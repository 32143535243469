/* eslint-disable max-len */
/* eslint-disable no-promise-executor-return */
/* eslint-disable camelcase */
import throttle from 'lodash/throttle';
import type mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';
import type { MapLayerMouseEvent, MapRef } from 'react-map-gl';

export const loadImageAsync = async (mapref: MapRef, url: string, imageName: string, options?: { pixelRatio?: number | undefined; sdf?: boolean | undefined }, override = false) => new Promise((resolve, reject) => {
   try {
      if (!mapref) return reject();

      if (mapref.hasImage(imageName)) {
         if (override) mapref.removeImage(imageName);
         else return resolve(true);
      }

      return mapref.loadImage(url, (err, img) => {
         if (err) return reject(err);

         if (img) mapref.addImage(imageName, img, options);

         return resolve(true);
      });
   } catch (err) {
      console.error('map load image async error', imageName, err);

      return reject(err);
   }
});

// #region reverse geocoding

export const registerClusterClickEvent = (mapref: MapRef, layers: string[], sourceId: string) => {
   const eventListener = (e: any) => {
      try {
         const features = mapref.queryRenderedFeatures(e.point, {
            layers,
         });
         const clusterId = features[0].properties ? features[0].properties.cluster_id : 0;

         const clusteredSource = mapref.getSource(sourceId) as mapboxgl.GeoJSONSource;

         clusteredSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            mapref.easeTo({
               center: (features[0].geometry as any).coordinates,
               zoom,
            });
         });
      } catch (err) {
         console.error('error on register cluster click event ', layers, err);
      }
   };

   // inspect a cluster on click
   mapref.on('click', layers, eventListener);

   mapref.off('click', eventListener);
};
// #endregion


// eslint-disable-next-line max-len


export const useThrottledZoomBasedFilter = (mapRef: MapRef | undefined, onZoomChange: (z: number) => any[], throttleMs = 75) => {
   const [zoomFilters, setZoomFilters] = useState<any[]>();

   useEffect(() => {
      const map = mapRef?.getMap();
      if (!map) return;

      const zoom = map.getZoom();

      setZoomFilters(onZoomChange(zoom));

      const onZoom = throttle(() => {
         onZoom?.cancel?.();

         const zoom = map.getZoom();

         setZoomFilters(onZoomChange(zoom));
      }, throttleMs);

      map.on('zoom', onZoom);

      return () => {
         map.off('zoom', onZoom);
      };
   }, [mapRef, onZoomChange, throttleMs]);

   return zoomFilters;
};

export function generateGetExpression<T = never>(value: keyof T) {
   return ['get', value];
}

export const createLayerHover = (map: MapRef, layerId: string, source: string, sourceLayer?: string) => {
   let hoveredFutureId: any = null;

   const onMouseMove = (e: MapLayerMouseEvent) => {
      if (e.features && e.features.length > 0) {
         if (hoveredFutureId !== null) {
            map.setFeatureState(
               {
                  source,
                  id: hoveredFutureId,
                  sourceLayer,
               },
               { hover: false },
            );
         }

         hoveredFutureId = e.features[0].id;

         map.setFeatureState(
            {
               source,
               id: hoveredFutureId,
               sourceLayer,
            },
            { hover: true },
         );
      }
   };

   const onMouseLeave = () => {
      if (hoveredFutureId !== null) {
         map.setFeatureState(
            {
               source,
               id: hoveredFutureId,
               sourceLayer,
            },
            { hover: false },
         );
      }

      hoveredFutureId = null;
   };

   // When the user moves their mouse over the layer, we'll update the
   // feature state for the feature under the mouse.
   map.on('mousemove', layerId, onMouseMove);

   // When the mouse leaves from layer, update the feature state of the
   // previously hovered feature.
   map.on('mouseleave', layerId, onMouseLeave);

   return () => {
      map.off('mousemove', layerId, onMouseMove);
      map.off('mouseleave', layerId, onMouseLeave);
   };
};
