/* eslint-disable max-len */

function EnvelopeSVG({ className, color = '#41D447' }: { className?: string; color?: string }) {
   return (
      <svg
         width="72" height="73" viewBox="0 0 72 73"
         fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
         <path
            fill={color}
            stroke={color}
            strokeWidth="0.5"
            d="M0.75 63.5415V63.5427C0.75 65.852 1.66737 68.0668 3.30031 69.6997C4.93324 71.3326 7.14797 72.25 9.45729 72.25H62.2308C64.5401 72.25 66.7548 71.3326 68.3877 69.6997C70.0207 68.0668 70.938 65.852 70.938 63.5427V27.853V27.2609V27.0109H70.688H70.5999L60.0282 19.3526V1.97366C60.0282 1.68305 59.9127 1.40435 59.7072 1.19886C59.5017 0.993371 59.223 0.87793 58.9324 0.87793H12.7556C12.465 0.87793 12.1863 0.993371 11.9808 1.19886C11.7753 1.40435 11.6599 1.68305 11.6599 1.97366V19.353L1.25693 26.9264H1.16915H0.919146V27.1764V27.5141V27.5147L0.75 63.5415ZM2.94146 63.6277V63.6273V29.9486L26.3107 46.8172L3.76695 66.7916C3.22463 65.8272 2.93945 64.7375 2.94146 63.6277ZM68.7466 63.5427L68.7466 63.5463C68.7629 64.6839 68.4771 65.8037 67.9208 66.7922L45.3774 46.9009L68.7466 29.95L68.7466 63.5427ZM9.4576 70.0585C7.88125 70.0566 6.36233 69.4834 5.17966 68.4504L28.0795 48.132L35.1866 53.2557C35.1873 53.2562 35.188 53.2567 35.1887 53.2572C35.378 53.3985 35.6078 53.4748 35.844 53.4748C36.0802 53.4748 36.3101 53.3985 36.4994 53.2572C36.5001 53.2567 36.5008 53.2562 36.5015 53.2557L43.6086 48.132L66.5084 68.4504C65.3257 69.4834 63.8068 70.0566 62.2304 70.0585H9.4576ZM60.0282 22.0002L67.9789 27.7684L60.0282 33.5365V22.0002ZM57.8367 3.06939V35.1676L35.844 51.0558L13.8514 35.1676V3.06939H57.8367ZM11.6599 33.5404L3.7111 27.8516L11.6599 22.0848V33.5404Z"
         />
         <path
            stroke={color}
            strokeWidth="0.4"
            fill={color}
            d="M24.4268 16.889H35.8441C36.1215 16.889 36.3874 16.7788 36.5836 16.5827C36.7797 16.3866 36.8898 16.1206 36.8898 15.8433C36.8898 15.5659 36.7797 15.3 36.5836 15.1038C36.3874 14.9077 36.1215 14.7976 35.8441 14.7976H24.4268C24.1494 14.7976 23.8835 14.9077 23.6873 15.1038C23.4912 15.3 23.3811 15.5659 23.3811 15.8433C23.3811 16.1206 23.4912 16.3866 23.6873 16.5827C23.8835 16.7788 24.1494 16.889 24.4268 16.889Z"
         />
         <path
            fill={color}
            stroke={color}
            strokeWidth="0.4"
            d="M24.4268 25.0927H47.2615C47.5388 25.0927 47.8048 24.9825 48.0009 24.7864C48.197 24.5903 48.3072 24.3243 48.3072 24.047C48.3072 23.7696 48.197 23.5036 48.0009 23.3075C47.8048 23.1114 47.5388 23.0012 47.2615 23.0012H24.4268C24.1494 23.0012 23.8835 23.1114 23.6873 23.3075C23.4912 23.5036 23.3811 23.7696 23.3811 24.047C23.3811 24.3243 23.4912 24.5903 23.6873 24.7864C23.8835 24.9825 24.1494 25.0927 24.4268 25.0927Z"
         />
         <path
            fill={color}
            stroke={color}
            strokeWidth="0.4"
            d="M24.4268 33.2964H47.2615C47.5388 33.2964 47.8048 33.1862 48.0009 32.9901C48.197 32.794 48.3072 32.528 48.3072 32.2506C48.3072 31.9733 48.197 31.7073 48.0009 31.5112C47.8048 31.3151 47.5388 31.2049 47.2615 31.2049H24.4268C24.1494 31.2049 23.8835 31.3151 23.6873 31.5112C23.4912 31.7073 23.3811 31.9733 23.3811 32.2506C23.3811 32.528 23.4912 32.794 23.6873 32.9901C23.8835 33.1862 24.1494 33.2964 24.4268 33.2964Z"
         />
      </svg>
   );
}

export default EnvelopeSVG;
