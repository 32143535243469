/**
 * this function gets coordinates of a line and returns the arc's coordinates
 */

const lineToArcByArchHeight = (fromLngLat: [number, number] | number[], toLngLat: [number, number] | number[], arcHeight: number, pointCount: number, arcDirection: 'clockwise' | 'counterclockwise'): [number, number][] => {
   const points: [number, number][] = [];
   const [fromX, fromY] = fromLngLat;
   const [toX, toY] = toLngLat;

   const dx = toX - fromX;
   const dy = toY - fromY;

   const angle = Math.atan2(dy, dx);

   for (let i = 0; i < pointCount; i++) {
      const fraction = i / (pointCount - 1);

      const linearX = fromX + fraction * dx;
      const linearY = fromY + fraction * dy;

      const directionFactor = arcDirection === 'clockwise' ? -1 : 1;
      const heightFactor = directionFactor * 4 * arcHeight * fraction * (1 - fraction);

      const arcX = linearX + heightFactor * Math.cos(angle + Math.PI / 2);
      const arcY = linearY + heightFactor * Math.sin(angle + Math.PI / 2);

      points.push([arcX, arcY]);
   }

   return points;
};

const lineToArc = (fromLngLat: [number, number] | number[], toLngLat: [number, number] | number[], arcAngle: number, pointCount: number, arcDirection: 'clockwise' | 'counterclockwise'): [number, number][] => {
   const radius = Math.sqrt((toLngLat[0] - fromLngLat[0]) ** 2 + (toLngLat[1] - fromLngLat[1]) ** 2) / 2;
   const arcHeight = radius * Math.tan((arcAngle * Math.PI) / 180 / 2);
   return lineToArcByArchHeight(fromLngLat, toLngLat, arcHeight, pointCount, arcDirection);
};

export default lineToArc;
