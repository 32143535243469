/* eslint-disable max-len */
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
   <svg
      viewBox="0 0 1024 1024" width="1em" height="1em"
      fill="currentColor">
      <path
         d="M624 573.638V636.729C624 642.755 631.049 646.18 635.783 642.447L764.208 541.177C767.347 538.763 767.93 534.243 765.494 531.11C765.117 530.631 764.688 530.186 764.208 529.826L635.783 428.556C632.627 426.074 628.048 426.621 625.561 429.772C624.549 431.056 624 432.648 624 434.274V573.621V573.638Z"
         fill="#040000"
         stroke="#040000"
         strokeWidth="0.59"
         strokeMiterlimit="10"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M333.807 222.902C370.849 188.511 429.356 171 510.014 171H745.014V239.569H510.014C461.164 239.569 424.719 250.759 401.359 273.74C377.989 296.732 366.606 331.963 366.606 378.788V501.096H696.211V570.659H366.606V860.986H278V378.788C278 309.061 296.776 257.284 333.807 222.902Z"
         fill="#040000"
      />
   </svg>
);

const IconSvg = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;
export default IconSvg;
