import { Flex } from 'antd';
import { PowerCol, PowerRow } from 'components/ui';
import { InputDebounce } from 'power/components/base';
import { IIQTableRequest } from 'power/types';
import { FC } from 'react';

type Props = {
  filter: IIQTableRequest;
  setFilter: (filter: IIQTableRequest) => void;
};

const FilterBar: FC<Props> = ({ filter, setFilter }) => (
   <PowerRow style={{ paddingTop: 16, paddingBottom: 16 }}>
      <PowerCol span={7}>
         {/* <Select
               value={filter.order ?? IQ_PAGE_ORDER.Undefined}
               onChange={(value) => setFilter({ ...filter, order: value })}
               options={[
                  { label: 'Default', value: IQ_PAGE_ORDER.Undefined },
                  { label: 'Date', value: IQ_PAGE_ORDER.Date },
                  { label: 'Date Desc', value: IQ_PAGE_ORDER.DateDesc },
                  { label: 'Owner', value: IQ_PAGE_ORDER.Owner },
                  { label: 'Interconnection Point', value: IQ_PAGE_ORDER.InterconnectionPoint },
               ]}
               style={{ width: '100%' }}
            /> */}
      </PowerCol>
      <PowerCol span={8}>
         <Flex dir="row">
            <Flex style={{ alignItems: 'center', justifyContent: 'center', width: 200, fontWeight: 500 }}>Project Name:</Flex>
            <InputDebounce
               placeholder="Search Project Name"
               value={filter.searchText}
               onChangeDebounce={(value) => {
                  setFilter({ ...filter, searchText: value.toString(), pageNumber: 1 });
                  return Promise.resolve(true);
               }}
            />
         </Flex>
         {/* Search Name */}
      </PowerCol>
   </PowerRow>
);

export default FilterBar;
