import { ApiClients } from 'ApiClients';
import { IICAPCapacityParameters, IICAPMonthly, IICAPSpot, IICAPStrip, IICAPStripRequest } from 'power/types';
import { ICAP_SEASON } from 'power/types/enum';
import { useCallback, useEffect, useState } from 'react';

export const useICAPData = () => {
   const [auctions, setAuctions] = useState<('strip' | 'monthly' | 'spot')[]>(['strip', 'spot', 'monthly']);

   const [queryStrip, setQueryStrip] = useState<IICAPStripRequest>({ period_year: 2024, period_season: ICAP_SEASON.SUMMER });
   const [month, setMonth] = useState<number | undefined>(5);

   const [loadingStrip, setLoadingStrip] = useState<boolean>(false);
   const [strip, setStrip] = useState<IICAPStrip[] | undefined>(undefined);

   const [loadingSpot, setLoadingSpot] = useState<boolean>(false);
   const [spot, setSpot] = useState<IICAPSpot[] | undefined>(undefined);

   const [loadingMonthly, setLoadingMonthly] = useState<boolean>(false);
   const [monthly, setMonthly] = useState<IICAPMonthly[] | undefined>(undefined);

   const [capacityParameters, setCapacityParameters] = useState<IICAPCapacityParameters[] | undefined>(undefined);

   useEffect(() => {
      setCapacityParameters(undefined);
      ApiClients.getInstance().icapCapacityParameters({ period_year: queryStrip.period_year }).then((res) => setCapacityParameters(res));
   }, [queryStrip.period_year]);

   const handleStrip = useCallback((query: IICAPStripRequest) => {
      setLoadingStrip(true);
      setStrip(undefined);
      ApiClients.getInstance().icapStrip(query)
         .then((res) => {
            setStrip(res);
         })
         .finally(() => {
            setLoadingStrip(false);
         });
   }, []);

   const handleSpot = useCallback(
      (query: IICAPStripRequest) => {
         if (!month) return;
         setLoadingSpot(true);
         setSpot(undefined);
         ApiClients.getInstance().icapSpot({ ...query, period_month: month })
            .then((res) => {
               setSpot(res);
            })
            .finally(() => {
               setLoadingSpot(false);
            });
      },
      [month],
   );

   const handleMonthly = useCallback(
      (query: IICAPStripRequest) => {
         if (!month) return;
         setLoadingMonthly(true);
         setMonthly(undefined);
         ApiClients.getInstance().icapMonthly({ ...query, period_month: month })
            .then((res) => {
               setMonthly(res);
            })
            .finally(() => {
               setLoadingMonthly(false);
            });
      },
      [month],
   );

   useEffect(() => {
      setStrip(undefined);
      setSpot(undefined);
      setMonthly(undefined);
      if (auctions.includes('strip')) handleStrip(queryStrip);
      if (auctions.includes('spot')) handleSpot(queryStrip);
      if (auctions.includes('monthly')) handleMonthly(queryStrip);
   }, [queryStrip, handleStrip, auctions, handleSpot, month, handleMonthly]);

   return {
      auctions,
      queryStrip,
      setAuctions,
      setQueryStrip,
      month,
      setMonth,
      loadingStrip,
      strip,
      loadingSpot,
      spot,
      loadingMonthly,
      monthly,
      capacityParameters,
   };
};
