enum IQ_PAGE_ORDER {
  Undefined = 0,
  Date = 1,
  ProjectName = 2,
  Capacity = 3,
  Owner = 4,
  InterconnectionPoint = 5,
  DateDesc = 6,
  ProjectNameDesc = 7,
  CapacityDesc = 8,
  OwnerDesc = 9,
  InterconnectionPointDesc = 10,
}

export default IQ_PAGE_ORDER;
