import { SyncOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import { SelectSubstation } from 'power/components/filter';
import { API_ROUTES } from 'power/constants';
import { IOptions, ISubstationInfoRequest } from 'power/types';
import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';

type Props = {
  loading: boolean;
  query: ISubstationInfoRequest;
  onSubmit: (filter: ISubstationInfoRequest) => void;
};

const QueryBar: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formQuery, setFormQuery] = useState<ISubstationInfoRequest>(query);

   const onChange = useCallback((partialFilter: Partial<ISubstationInfoRequest>) => {
      setFormQuery((prev) => ({ ...prev, ...partialFilter }));
   }, []);

   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   // TODO: parametrelerden etkilenir hale geldiğinde düzeltilecek.
   const { data: substationOptions } = useQuery<IOptions>([API_ROUTES.LOCATION.Hubs], () => ApiClients.getInstance().locationSubstations(undefined, undefined, query.substationId, 0));

   return (
      <Form layout="vertical" name="node_dashboards_form">
         <div className="filter-bar-container">
            <div className="filter-bar-row">
               <div className="filter-bar-col">
                  <PowerRow gutter={[16, 16]}>
                     <PowerCol xs={24} sm={24} lg={7}>
                        <Form.Item label="Substation" rules={[{ required: true }]}>
                           <SelectSubstation
                              value={formQuery.substationId.toString()} onChange={(value) => onChange({ substationId: Number(value) })} options={substationOptions ?? []}
                              disabled={loading} />
                        </Form.Item>
                     </PowerCol>

                     <PowerCol xs={24} sm={12} lg={6}>
                        <Button
                           className="btn-filter-bar-green" type="primary" size="large"
                           icon={<SyncOutlined />} onClick={onFormSubmit} loading={loading}>
                  Reload
                        </Button>
                     </PowerCol>
                  </PowerRow>
               </div>
            </div>
         </div>
      </Form>
   );
};

export default QueryBar;
