import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import InfoBox from 'power/components/base/InfoBox/InfoBox';
import IArbitrageInfo from 'power/types/IArbitrageInfo';
import { MARKET_TYPE, TXBX_COMPONENT } from 'power/types/enum';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  nodeId: number;
};

const InfoBoxes: FC<Props> = ({ nodeId }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data1, setData1] = useState<IArbitrageInfo[]>();
   const [data2, setData2] = useState<IArbitrageInfo[]>();
   const [data3, setData3] = useState<IArbitrageInfo[]>();
   const [data4, setData4] = useState<IArbitrageInfo[]>();

   useEffect(() => {
      const p1 = ApiClients.getInstance().arbitrageInfoBox(nodeId, TXBX_COMPONENT.Tb1);
      const p2 = ApiClients.getInstance().arbitrageInfoBox(nodeId, TXBX_COMPONENT.Tb2);
      const p3 = ApiClients.getInstance().arbitrageInfoBox(nodeId, TXBX_COMPONENT.Tb3);
      const p4 = ApiClients.getInstance().arbitrageInfoBox(nodeId, TXBX_COMPONENT.Tb4);

      Promise.all([p1, p2, p3, p4]).then(([res1, res2, res3, res4]) => {
         setData1(res1);
         setData2(res2);
         setData3(res3);
         setData4(res4);
         setLoading(false);
      });
      
   }, [nodeId]);

   const tb4DaValue = data4?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.value ?? '';
   const tb4RtValue = data4?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.value ?? '';
   const tb3DaValue = data3?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.value ?? '';
   const tb3RtValue = data3?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.value ?? '';
   const tb2DaValue = data2?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.value ?? '';
   const tb2RtValue = data2?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.value ?? '';
   const tb1DaValue = data1?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.value ?? '';
   const tb1RtValue = data1?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.value ?? '';

   if (loading) return null;

   return (
      <PowerRow gutter={[16, 16]} style={{ padding: '4px' }}>
         <PowerCol span={0} xxl={2}></PowerCol>
         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="4 Hour Arbitrate $kW/year" data={[
                  { key: 'DA', value: tb4DaValue },
                  { key: 'RT', value: tb4RtValue },
               ]} />
         </PowerCol>
         
         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="3 Hour Arbitrate $kW/year" data={[
                  { key: 'DA', value: tb3DaValue },
                  { key: 'RT', value: tb3RtValue },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="2 Hour Arbitrate $kW/year" data={[
                  { key: 'DA', value: tb2DaValue },
                  { key: 'RT', value: tb2RtValue },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="1 Hour Arbitrate $kW/year" data={[
                  { key: 'DA', value: tb1DaValue },
                  { key: 'RT', value: tb1RtValue },
               ]} />
         </PowerCol>

         <PowerCol span={0} xxl={2}></PowerCol>
      </PowerRow>
   );
};

export default memo(InfoBoxes);
