import { ApiClients } from 'ApiClients';
import { DATA_PROVIDER, LOCATION_TYPE } from 'power/types/enum';
import { CustomControlPosition } from 'power/types/map';
import React, { useCallback } from 'react';
import CustomOverlay from '../base/CustomOverlay';
import SearchControlContainer from './SearchControlContainer';

const USA = [-132.05570369775322, 21.40698596016368, -58.49875870847299, 52.58626192521436];

type Props = {
  position?: CustomControlPosition;
};

const SearchControl: React.FC<Props> = ({ position }) => {
   const fetcher = useCallback((query: string) => ApiClients.getInstance().locationSearch(query, LOCATION_TYPE.Undefined, USA, DATA_PROVIDER.NYISO, 200), []);

   return (
      <CustomOverlay position={position}>
         <SearchControlContainer fetcher={fetcher} />
      </CustomOverlay>
   );
};

export default SearchControl;
