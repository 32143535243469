import { Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import NodeTab from './tabs/NodeTab';
import SubstationTab from './tabs/SubstationTab';

const TAB_ITEMS: TabsProps['items'] = [
   {
      key: '#node',
      label: 'Node',
      children: <NodeTab />,
   },
   {
      key: '#substation',
      label: 'Substation',
      children: <SubstationTab />,
   },
];

const TestTabs = () => {
   const [activeKey, setActiveKey] = useState<string>(TAB_ITEMS[0].key);

   return (
      <>
         <Tabs
            style={{ paddingBottom: 32 }} activeKey={activeKey} centered
            type="card" items={TAB_ITEMS} onChange={setActiveKey} />
      </>
   );

   
};

export default TestTabs;
