// import { PowerMap } from 'power/components/maps';
import { Spin } from 'antd';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  loadingSize?: [number, number];
  headers: string[];
  rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[];
};

const Table: FC<Props> = ({ loadingSize, headers, rows }) => {
   if (loadingSize) {
      return (
         <div className={classes.table} style={{ width: loadingSize[0], height: loadingSize[1] }}>
            <div className={classes.loading}><Spin /></div>
         </div>
      );
   }

   if(!rows.length || !rows[0].keys.length || !rows[0].allValues.length || !rows[0].allValues[0].length) {
      return (
         <div className={classes.table}>
            <div className={classes.loading}>No data available</div>
         </div>
      );
   }

   return (
      <div className={classes.table}>
         <table>
            <thead>
               <tr>
                  {headers.map((header, index) => {
                     if (index === 0) {
                        return (
                           <th key={index} className={classes.firstHeader}>
                              {header}
                           </th>
                        );
                     }
                     return <th key={index}>{header}</th>;
                  })}
               </tr>
            </thead>
            <tbody>
               {rows.map((row, index) => (
                  <tr key={index}>
                     <td>{row.zone}</td>

                     <td className={classes.keys}>
                        {row.keys.map((key, index) => (
                           <div key={index}>{key}</div>
                        ))}
                     </td>

                     {row.allValues.map((values, index) => (
                        <td key={index} className={classes.values}>
                           {values.map((value, index) => (
                              <div key={index}>{value}</div>
                           ))}
                        </td>
                     ))}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default Table;
