import { InfoTable } from 'power/components/base';
import { IMAGE_PATHS } from 'power/constants';
import { usePowerMap } from 'power/map/usePowerMap';
import React, { useEffect, useMemo, useState } from 'react';
import { Popup, useMap } from 'react-map-gl';
import SymbolLayer from './SymbolLayer';
import useHeadroomStaticLayers from './useCapacityStaticLayers';

const GENERATOR_LAYER = 'Capacity_Generator';
const INTERCONNECTION_QUEUE_LAYER = 'Capacity_Interconnection Queue';
const LOAD_LAYER = 'Capacity_Load';
const RETIREMENT_LAYER = 'Capacity_Retirement';
const TRANSFORMER_LAYER = 'Capacity_Transformer';
const ALL_LAYERS = [GENERATOR_LAYER, INTERCONNECTION_QUEUE_LAYER, LOAD_LAYER, RETIREMENT_LAYER, TRANSFORMER_LAYER];

const CapacityStaticLayers: React.FC<{ mapId: string }> = ({ mapId }) => {
   const { capacityStaticLayers } = usePowerMap();
   const { [mapId]: map } = useMap();
   const { geojsons, fetch } = useHeadroomStaticLayers();

   const [info, setInfo] = useState<mapboxgl.MapboxGeoJSONFeature | undefined>(undefined);
   const [clickedCoordinate, setClickedCoordinate] = useState<number[] | undefined>(undefined);

   useEffect(() => {
      if (capacityStaticLayers && !geojsons) fetch();
   }, [capacityStaticLayers, fetch, geojsons]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         setInfo(undefined);
         setClickedCoordinate(undefined);
         // console.log('click', HEADROOM_SUBSTATION_LAYER_ID, features);
         // const ids = features.map((f) => f.properties.id);
         // onClickSubstation?.(ids, ev.lngLat.toArray());
         setTimeout(() => {
            setInfo(features[0]);
            if (features[0].geometry.type === 'Point') setClickedCoordinate(features[0].geometry.coordinates);
            else setClickedCoordinate(ev.lngLat.toArray());
         }, 300);

      // console.log('onClickListener', ids, features[0].properties);
      };

      if (map) {
         map.on('click', ALL_LAYERS, onClickListener);
      }

      return () => {
         if (map) {
            map.off('click', ALL_LAYERS, onClickListener);
         }
      };
   }, [map]);

   const infoKeyValues = useMemo(() => {
      if (!info) return undefined;
      const props = Object.entries(info.properties || {}).map(([key, value]) => ({ key, value }));
      return props;
   }, [info]);

   if (!geojsons || !capacityStaticLayers) return null;

   return (
      <>
         {capacityStaticLayers?.allActive && capacityStaticLayers.iq?.filter?.active && (
            <SymbolLayer
               mapId={mapId} layerId={INTERCONNECTION_QUEUE_LAYER} geojson={geojsons.iq}
               iconUrl={IMAGE_PATHS.MAP_ASSETS.FILE('grid/iq.png')} iconOffset={[0, -200]} />
         )}

         {geojsons.generator && capacityStaticLayers?.allActive && capacityStaticLayers.generator?.filter?.active && (
            <SymbolLayer
               mapId={mapId} layerId={GENERATOR_LAYER} geojson={geojsons.generator}
               iconUrl={IMAGE_PATHS.MAP_ASSETS.FILE('grid/generator.png')} />
         )}

         {capacityStaticLayers?.allActive && capacityStaticLayers.transformer?.filter?.active && (
            <SymbolLayer
               mapId={mapId} layerId={TRANSFORMER_LAYER} geojson={geojsons.transformer}
               iconUrl={IMAGE_PATHS.MAP_ASSETS.FILE('grid/transformer.png')} iconOffset={[200, 0]} />
         )}

         {capacityStaticLayers?.allActive && capacityStaticLayers.load?.filter?.active && (
            <SymbolLayer
               mapId={mapId} layerId={LOAD_LAYER} geojson={geojsons.load}
               iconUrl={IMAGE_PATHS.MAP_ASSETS.FILE('grid/load.png')} iconOffset={[0, 200]} />
         )}

         {capacityStaticLayers?.allActive && capacityStaticLayers.retirement?.filter?.active && (
            <SymbolLayer
               mapId={mapId} layerId={RETIREMENT_LAYER} geojson={geojsons.retirement}
               iconUrl={IMAGE_PATHS.MAP_ASSETS.FILE('grid/retirement.png')} iconOffset={[-200, 0]} />
         )}

         {info && infoKeyValues && clickedCoordinate && (
            <Popup
               longitude={clickedCoordinate[0]}
               latitude={clickedCoordinate[1]}
               closeButton
               closeOnMove={false}
               onClose={() => {
                  setInfo(undefined);
                  setClickedCoordinate(undefined);
               }}
               maxWidth="300px"
            >
               <div style={{ /* paddingTop: 20, */ width: '100%' }}>
                  <InfoTable title={info.layer.id.split('_')[1]} info={infoKeyValues.filter(x => x.key !== 'icon' && x.value.toString().trim() !== '')} width="100%" />
               </div>
            </Popup>
         )}
         {/* <LayerControl layers={ALL_LAYERS} activeLayers={activeLayers} setActiveLayers={setActiveLayers} /> */}
      </>
   );
};

export default CapacityStaticLayers;
