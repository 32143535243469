enum TL_VOLTAGE {
  Undefined = 'Undefined',
  '0 - 68' = '0 - 68',
  '69 - 114' = '69 - 114',
  '115 - 274' = '115 - 274',
  '275 - 344' = '275 - 344',
  '345 - 499' = '345 - 499',
  '500+' = '500+',
}

export default TL_VOLTAGE;
