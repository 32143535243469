import { TIME_GRANULARITY } from 'power/types/enum';

const formatDate = (d: string, selectedTimeGranularity: TIME_GRANULARITY, language = 'en') => {
   const date = new Date(d);

   switch (selectedTimeGranularity) {
   case TIME_GRANULARITY.hourly: {
      const year = d.split('-')[0]; // YYYY
      const monthDay = date.toLocaleString(language, { month: 'short', day: '2-digit' }).split(' ').join('-'); // Mm-DD
      const hour = new Date(d).toLocaleString(language, { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' }); // HH:mm

      // HH:mm
      // YYYY-Mm-DD
      return `${hour}
      ${year}-${monthDay}`;
   }

   case TIME_GRANULARITY.daily: {
      const year = d.split('-')[0]; // YYYY
      const monthDay = date.toLocaleString(language, { month: 'short', day: '2-digit' }).split(' ').join('-'); // Mm-DD
      return `${year}-${monthDay}`; // YYYY-Mm-DD
   }
   case TIME_GRANULARITY.monthly: {
      const year = d.split('-')[0]; // YYYY
      const month = date.toLocaleString(language, { month: 'short' }); // Mm
      return `${year}-${month}`; // YYYY-Mm
   }

   case TIME_GRANULARITY.yearly: {
      return d.split('-')[0]; // YYYY
   }

   default: {
      return date.toLocaleString(language, {
         year: '2-digit',
         month: 'short',
         day: '2-digit',
      });
   }
   }
};

export default formatDate;
