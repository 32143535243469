import { utils } from 'power/utils';
import { FC, useMemo } from 'react';
import classes from './HeatTable.module.scss';
// import { sampleData } from './sampleData';

const HOURS = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// const HEAT_COLORS_EX = ['#FFFFFF', '#2D8C6C', '#42B389', '#5AD8A6', '#85E6BC', '#B3F2D5', '#DBFFEC', '#F5BCB5', '#E88D87', '#DB605C', '#CF3636', '#A82328', '#82151C'];
// const HEAT_COLORS = ['#f9ecdd', '#f7d0b4', '#f59971', '#f05c42', '#d5234a', '#9f195b', '#661f54', '#31183b', '#0c0e22']; //  0, 25, 50, 75, 100, 125, 150, 175 ,200
const HEAT_COLORS = ['#f9ecdd', '#f7d0b4', '#f59971', '#f05c42', '#d5234a', '#9f195b', '#661f54', '#31183b', '#0c0e22']; //  10, 15, 20, 25, 30, 35, 40

interface IHeatTable {
  data?: { date: string; hour: string; value: number }[]; // 12*24=288 items for all year
  initialMinMaxValues?: { min: number; max: number };
}

const HeatTable: FC<IHeatTable> = ({ data = [], initialMinMaxValues }) => {
   const minMaxValues = useMemo(
      () => {
         if(initialMinMaxValues) return initialMinMaxValues;

         return data.reduce(
            (acc, { value }) => ({
               min: Math.min(acc.min, value),
               max: Math.max(acc.max, value),
            }),
            { min: Infinity, max: -Infinity },
         );
      },
      [data, initialMinMaxValues],
   );

   const groupedData: { [key: string]: { [key: string]: number } } = useMemo(() => {
      const result: { [key: string]: { [key: string]: number } } = {};

      data.forEach((item) => {
         const month = new Date(item.date).toLocaleString('en-US', { month: 'short' });
         if (!result[month]) {
            result[month] = {};
         }
         result[month][item.hour] = item.value;
      });

      return result;
   }, [data]);

   const getColor = (value: number) => utils.interpolateColor(value, minMaxValues.min, minMaxValues.max, HEAT_COLORS);

   return (
      <table className={classes.table}>
         <thead>
            <tr>
               <th>h/m</th>
               {MONTHS.map((month) => (
                  <th key={month}>{month}</th>
               ))}
            </tr>
         </thead>
         <tbody>
            {HOURS.map((hour) => (
               <tr key={hour}>
                  <td className={classes.hour}>{hour}</td>
                  {MONTHS.map((month) => {
                     const colors = getColor(groupedData[month]?.[hour] || 0);
                     return (
                        <td key={month} style={{ backgroundColor: colors.backgroundColor, color: colors.textColor, fontWeight: 400  }}>
                           {groupedData[month]?.[hour]?.toFixed(0) || ''}
                        </td>
                     );
                  })}
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default HeatTable;
