import { PowerCol, PowerRow, StaticMap } from 'components/ui';
import { ISubstationInfoResponse } from 'power/types';
import { utils } from 'power/utils';
import { FC } from 'react';
import SubstationInfoBox from './SubstationInfoBox';

type Props = {
  data: ISubstationInfoResponse;
};

const InfoBar: FC<Props> = ({ data }) => (
   <div className="info-bar">
      <PowerRow gutter={[16, 16]}>
         <PowerCol
            sm={0} md={0} lg={2}
            xxl={6}></PowerCol>
         <PowerCol
            xs={24} md={12} lg={10}
            xxl={6}>
            <div className="info-box">
               <SubstationInfoBox substation={data.substation} />
            </div>
         </PowerCol>
         <PowerCol
            xs={0}
            md={12}
            lg={10}
            xxl={6}
            style={{
               position: 'relative',
               minHeight: '80px',
               width: '100%',
               overflow: 'hidden',
            }}
         >
            {utils.isExist(data?.substation?.latitude) && (
               <div className="info-box" style={{ height: 176 }}>
                  <StaticMap latitude={data?.substation?.latitude as any} longitude={data?.substation?.longitude as any} />
               </div>
            )}
         </PowerCol>
         <PowerCol
            sm={0} md={0} lg={2}
            xxl={6}></PowerCol>
      </PowerRow>
   </div>
);

export default InfoBar;
