import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import InfoBox from 'power/components/base/InfoBox/InfoBox';
import { ITbxSumValue } from 'power/types';
import { MARKET_TYPE, PERIOD_TYPE, TXBX_COMPONENT } from 'power/types/enum';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  nodeId: number;
};

const InfoBoxes: FC<Props> = ({ nodeId }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<ITbxSumValue[]>();

   useEffect(() => {
      ApiClients.getInstance().tbxSumValues(nodeId, PERIOD_TYPE.last_5_year).then((res) => {
         setData(res);
         setLoading(false);
      });
   }, [nodeId]);

   const tb4DaValue = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead && x.component === TXBX_COMPONENT.Tb4)?.value ?? '';
   const tb4RtValue = data?.find((x) => x.marketType === MARKET_TYPE.Realtime && x.component === TXBX_COMPONENT.Tb4)?.value ?? '';
   const tb3DaValue = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead && x.component === TXBX_COMPONENT.Tb3)?.value ?? '';
   const tb3RtValue = data?.find((x) => x.marketType === MARKET_TYPE.Realtime && x.component === TXBX_COMPONENT.Tb3)?.value ?? '';
   const tb2DaValue = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead && x.component === TXBX_COMPONENT.Tb2)?.value ?? '';
   const tb2RtValue = data?.find((x) => x.marketType === MARKET_TYPE.Realtime && x.component === TXBX_COMPONENT.Tb2)?.value ?? '';
   const tb1DaValue = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead && x.component === TXBX_COMPONENT.Tb1)?.value ?? '';
   const tb1RtValue = data?.find((x) => x.marketType === MARKET_TYPE.Realtime && x.component === TXBX_COMPONENT.Tb1)?.value ?? '';

   if (loading || !data || data.length < 8) return null;

   return (
      <PowerRow gutter={[16, 16]} style={{ padding: '4px' }}>
         <PowerCol span={0} xxl={2}></PowerCol>
         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="TB4 Performance Avg(%)" data={[
                  { key: 'DA', value: tb4DaValue },
                  { key: 'RT', value: tb4RtValue },
               ]} />
         </PowerCol>
         
         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="TB3 Performance Avg(%)" data={[
                  { key: 'DA', value: tb3DaValue },
                  { key: 'RT', value: tb3RtValue },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="TB2 Performance Avg(%)" data={[
                  { key: 'DA', value: tb2DaValue },
                  { key: 'RT', value: tb2RtValue },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="TB1 Performance Avg(%)" data={[
                  { key: 'DA', value: tb1DaValue },
                  { key: 'RT', value: tb1RtValue },
               ]} />
         </PowerCol>

         <PowerCol span={0} xxl={2}></PowerCol>
      </PowerRow>
   );
};

export default memo(InfoBoxes);
