/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { blackBase } from 'constants/Colors';
import { FC, ReactNode, useState } from 'react';

const InfoTooltip: FC<{
  title: string;
  info: ReactNode;
}> = ({ title, info }) => {
   const [tooltipOpen, setTooltipOpen] = useState(false);

   return (
      <Tooltip
         placement="leftTop"
         title={(
            <div className="cp-info-tooltip">
               <div className="cp-info-tooltip-title">
                  {title}

                  <span className="cp-toolbar-item cp-info-tooltip-close" role="button" onClick={() => setTooltipOpen(false)}>
                     <CloseOutlined />
                  </span>
               </div>

               <div className="cp-info-tooltip-content">{info}</div>
            </div>
         )}
         trigger={['click', 'hover']}
         overlayStyle={{
            maxHeight: '300px',
            minWidth: '420px',
         }}
         overlayInnerStyle={{
            padding: '0px',
            // backgroundColor: 'rgba(0, 0, 0, 0.80)',
            backgroundColor: '#FFF',
            color: blackBase.main,
            border: `${blackBase.main} 2px solid`,
            borderRadius: '0px',
         }}
         autoAdjustOverflow
         open={tooltipOpen}
         onOpenChange={setTooltipOpen}
      >
         <span className="cp-toolbar-item" role="button">
            <InfoCircleOutlined />
         </span>
      </Tooltip>
   );
};

export default InfoTooltip;
