import { Content, ContentKey } from './types';

import base from './base/en';

import basisRiskDetail from './basis-risk/detail/en';
import basisRiskHeatmaps from './basis-risk/heatmaps/en';
import basisRisk from './basis-risk/index/en';
import basisRiskMap from './basis-risk/map/en';
import basisRiskOverview from './basis-risk/overview/en';

import bessCreate from './bess/create/en';
import bessEdit from './bess/edit/en';

import lmpDetail from './lmp/detail/en';
import lmpHeatmaps from './lmp/heatmaps/en';
import lmpIndex from './lmp/index/en';
import lmpMap from './lmp/map/en';
import lmpOverview from './lmp/overview/en';

import iqDashboard from './iq/dashboard/en';
import iqMap from './iq/map/en';
import iqOverview from './iq/overview/en';

import ppByISO from './pp/by-iso/en';
import ppDetail from './pp/detail/en';
import ppMap from './pp/map/en';
import ppOverview from './pp/overview/en';

const CONTENT: Content = {
   base,

   basisRisk,
   basisRiskMap,
   basisRiskOverview,
   basisRiskDetail,
   basisRiskHeatmaps,

   bessCreate,
   bessEdit,

   lmpDetail,
   lmpHeatmaps,
   lmpIndex,
   lmpMap,
   lmpOverview,

   iqDashboard,
   iqMap,
   iqOverview,

   ppByISO,
   ppDetail,
   ppMap,
   ppOverview,
};

export const useContent = () => {
   const t = (key: ContentKey): string => {
      const [m, k] = key.split('.');
      if (!k) return key;

      const module = (CONTENT as any)[m];
      if (!module) return key;

      const text = module[k];
      if (!text) return key;

      return text;
   };

   return { t };
};
