import { Line } from '@ant-design/plots';
import { Slider, Table, Typography } from 'antd';
import { ApiClients } from 'ApiClients';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import { grayBase, greenBase } from 'constants/NewColors';
import { useContent } from 'content';
import { extent } from 'd3';
import { chain } from 'lodash';
import { API_ROUTES } from 'power/constants';
import useAntdChartConfig from 'power/hooks/useAntdChartConfig';
import { PRICE_COMPONENT, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { parseDateByTimeGranularity, setChartRange } from 'utils/data';
import { normalizeNumber } from 'utils/math';

const component = PRICE_COMPONENT.STDDEV;

type Props = {
  nodeId: number;
};

const STDTimeSeries: FC<Props> = ({ nodeId }) => {
   const { t } = useContent();

   const [range, setRange] = useState<number[]>(null as any);

   const chartConfig = useAntdChartConfig('line', 'Monthly', '$/MWh');

   const { isFetching, data, error, refetch } = useQuery([API_ROUTES.LMP.TimeSeries, component, nodeId], () => ApiClients.getInstance().lmpTimeSeries({
      component,
      dayAheadMarket: true,
      realtimeMarket: true,
      nodeId,
   }).then((res) => {
      const [min, max] = extent((res.result as any).data.flatMap((d: any) => d.y));

      return {
         ...res,
         // sort by data.group includes this strings, order is LMP,MCE,MCC,MCL
         result: (res.result as any).data
            .map((d: any) => {
               let order = 0;

               if (d.group.includes('LMP')) order = 1;
               if (d.group.includes('MCE')) order = 2;
               if (d.group.includes('MCC')) order = 3;
               if (d.group.includes('MCL')) order = 4;

               return {
                  ...d,
                  order,
               };
            })
            .sort((a: any, b: any) => a.order - b.order),

         range: setChartRange(min as any, max as any),

         tableData: Object.entries(
            chain(
               res.result?.data.map((d) => ({
                  date: d.x.split('T')[0],
                  marketType: d.group?.split('-')[0],
                  group: d.group?.split('-')[1],
                  value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
               })),
            )
               .groupBy((d) => `${d.date}--${d.marketType}`)
               .value(),
         ).map(([group, data]) => ({
            date: group.split('--')[0],
            marketType: group.split('--')[1],
            ...(data.reduce(
               (acc, cur) => ({
                  ...acc,
                  [cur.group as any]: cur.value,
               }),
               {},
            ) as { date: string; marketType: string; LMP: number }[]),
         })),
      };
   }),
   );

   useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]);

   return (
      <CardPanel
         title={t('lmpOverview.stdDevTimeSeries')}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : data?.result?.length < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         downloadURL={data?.downloadUrl}
         refreshClick={refetch}
         info={t('lmpOverview.stdDevTimeSeriesChartInfo')}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data?.range?.[0]}
                              max={data?.range?.[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )}
               </div>

               {viewMode === 'chart' && (
                  <Line
                     {...chartConfig}
                     data={data?.result ?? []}
                     xField="x"
                     yField="y"
                     seriesField="group"
                     xAxis={{
                        ...chartConfig.xAxis,
                        tickCount: 12,
                        label: {
                           formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
                        },
                     }}
                     yAxis={{
                        ...chartConfig.yAxis,
                        min: range?.[0],
                        max: range?.[1],
                     }}
                     tooltip={{
                        shared: true,
                        title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.monthly),
                        formatter: (d) => ({
                           name: d.group,
                           value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                        }),
                     }}
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.date'),
                           dataIndex: 'date',
                           key: 'date',
                        },
                        {
                           title: t('base.marketType'),
                           dataIndex: 'marketType',
                           key: 'marketType',
                        },
                        {
                           title: 'STDDEV',
                           dataIndex: 'STDDEV',
                           key: 'STDDEV',
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.tableData ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default STDTimeSeries;
