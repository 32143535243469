import { PP_STATUS } from 'power/types/enum';

const PP_STATUSES = [
   /* {
    label: 'All',
    value: PP_STATUS.Undefined,
  }, */
   {
      label: 'Retired',
      value: PP_STATUS.Retired,
   },
   {
      label: 'Canceled',
      value: PP_STATUS.Canceled,
   },
   {
      label: 'Operating',
      value: PP_STATUS.Operating,
   },
   {
      label: 'Planned',
      value: PP_STATUS.Planned,
   },
   {
      label: 'Out of Service',
      value: PP_STATUS.OutOfService,
   },
];

export default PP_STATUSES;
