/* eslint-disable max-len */
import { BasisRiskOverviewContent } from './type';

const en: BasisRiskOverviewContent = {
   overviewPageTitle: 'Basis Risk Overview',
   mapPageTitle: 'Basis Risk Map',
   nodeHubTimeSeries: 'Basis Risk Time Series',
   historicalBasisRisk: 'Historical Basis Risk',
   historicalSolarWindBasisRisk: 'Historical Solar & Wind Basis Risk',
   averageBasisRisk: 'Average Basis Risk',
   solarBasisRisk: 'Solar Basis Risk',
   windBasisRisk: 'Wind Basis Risk',
   monthlyPattern: ' Basis Risk Monthly Pattern',
   hourlyPattern: ' Basis Risk Hourly Pattern',
   averageBasisRiskClosest: 'Average Basis Risk of the 10 Closest Nodes',
   basisRiskDetails: 'Basis Risk Details',
   yearlyTrend: 'Yearly Trend',
   dateFromDateTo: 'Date from/to Date',
   latLng: 'Lat/Lng',
   countyState: 'County/State',
   detailedAnalysis: 'Detailed Analysis',
   basisRiskTimeSeriesChartInfo:
    'The Basis Risk Time Series chart displays day-ahead and real-time average LMP. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   historicalBasisRiskChartInfo:
    'The Historical Basis Risk Time Series chart displays day-ahead and real-time average LMP. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   averageBasisRiskChartInfo:
    'The Average Basis Risk chart displays the nodal and hub price as time series in the real-time or day-ahead market. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   windBasisRiskChartInfo:
    'The Wind Basis Risk chart displays the nodal and hub wind-weighted price in the real-time or day-ahead market as time series. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   solarBasisRiskChartInfo:
    'The Solar Basis Risk chart displays the nodal and hub solar-weighted price in the real-time or day-ahead market as time series. The bars represent the difference between the nodal price and the hub price, i.e., the basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   historicalSolarWindBasisRiskChartInfo:
    'The Historical Solar & Wind Basis Risk chart shows the average yearly basis risk, solar basis risk, and wind basis risk. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   basisRiskHeatmap: 'Basis Risk Heat Map',
   basisRiskHeatmapChartInfo: 'The Basis Risk Heat Map displays the average or solar basis risk for each hour and month in the selected year, offering insights into real-time and day-ahead markets.',
   averageBasisRiskClosestChartInfo: 'The Average Basis Risk of the 10 Closest Nodes table shows the basis risk of the 10 closest other nodes with the selected hub. ',
   monthlyPatternChartInfo: 'The Basis Risk Monthly Pattern chart displays the average monthly basis risk over the years chosen to show seasonal trends.',
   hourlyPatternChartInfo: 'The Basis Risk Hourly Pattern chart displays the average hourly basis risk over the years chosen to show diurnal trends.',
   heatmaps: 'Heatmaps',
};

export default en;
