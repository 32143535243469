enum NODE_TYPE {
  ExcludeBus = -1,
  Undefined = 0,
  TransmissionNodeType = 50, // node
  LmpZone = 99, // zone
  IsoHub = 100, // hub
  IsoHubNode = 105, // node
  DrrAz = 106, // aggregate
  IsoInterface = 107, // interface
  IsoAggregate = 108, // aggregate
  IsoEhv = 109, // ehv
  DraftNode = 110, // node
  IsoBus = 111, // bus
  eXTNode = 113, // node
  MapBox = 114, // for adress
}

export default NODE_TYPE;
