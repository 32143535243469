import { FeatureCollection, Point } from 'geojson';
import { FUTURE_PRICE_STYLE } from 'power/constants/map/styles';
import { FuturePrice } from 'power/types';
import React, { useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { getLegendColorExpression } from './getLegendColorExpression';

const LAYER_ID = 'future-zone-price';

type Props = {
  mapId: string;
  prices: FuturePrice[];
  minmax: number[];
};

const FutureZonePriceLayer: React.FC<Props> = ({ mapId, prices, minmax }) => {
   const { [mapId]: map } = useMap();

   const geojson: FeatureCollection<Point> = useMemo(() => ({
      type: 'FeatureCollection',
      features: ZONE_LABEL_GEOJSON.features.map((feature) => {
         const zone = feature.properties?.zone;
         const price = prices.find((p) => p.zone === zone)?.price || 0;

         return {
            ...feature,
            properties: {
               ...feature.properties,
               price,
            },
         };
      })}), [prices]);

   const exp = useMemo(() => minmax && getLegendColorExpression(minmax[0], minmax[1], ['get', 'price']), [minmax]);

   if (!map) return null;

   return (
      <>
         <Source id={LAYER_ID} type="geojson" data={geojson}>
            <Layer
               id={`${LAYER_ID}-circle`} type="circle" layout={FUTURE_PRICE_STYLE.CIRCLE_LAYOUT}
               paint={FUTURE_PRICE_STYLE.CIRCLE_PAINT(exp)} />
            <Layer
               id={`${LAYER_ID}-label`} type="symbol" source={LAYER_ID}
               layout={FUTURE_PRICE_STYLE.TITLE_LAYOUT} paint={FUTURE_PRICE_STYLE.TITLE_PAINT(exp)} />
         </Source>
      </>
   );
};

export default FutureZonePriceLayer;


/*
Zone A - West - WEST					lng: -78.90845847543294, lat: 42.337055220742826
Zone B - Genesee - Genesee				lng: -77.39286253644373, lat: 43.16562717328546, lng: -78.10087815757731, lat: 42.38610127438341
Zone C - Central - Central				lng: -76.74016063571139, lat: 42.435109039802285
Zone D - North - NORTH					lng: -73.66471778141275, lat: 44.84419371122695
Zone E - Mohawk Valley - MHK VL 		lng: -75.36838036976543, lat: 43.55147268125472
Zone F - Capital - CAPITL.              lng: -73.93022363933761, lat: 43.197657789865076
Zone G - Hudson Valley - HUD VL.        lng: -74.01872559197956, lat: 41.75352978359419
Zone H - Millwood - MILLWD 				lng: -73.78640796629486, lat: 41.264757792278544
Zone I - Dunwoodie - DUNWOD 			lng: -73.79747071037545, lat: 41.014815484318376
Zone J - New York City - N.Y.C 			lng: -73.95234912749667, lat: 40.67461123278923
Zone K - Long Island - LONGIL        	lng: -72.9124511839569, lat: 40.87567284683874
*/ 
const ZONE_LABEL_GEOJSON : FeatureCollection<Point> = {
   type: 'FeatureCollection',
   features: [
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-78.90845847543294, 42.337055220742826],
         },
         properties: {
            zone: 'A',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-77.39286253644373, 43.16562717328546],
         },
         properties: {
            zone: 'B',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-78.10087815757731, 42.38610127438341],
         },
         properties: {
            zone: 'B',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-76.74016063571139, 42.435109039802285],
         },
         properties: {
            zone: 'C',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-73.66471778141275, 44.84419371122695],
         },
         properties: {
            zone: 'D',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-75.36838036976543, 43.55147268125472],
         },
         properties: {
            zone: 'E',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-73.93022363933761, 43.197657789865076],
         },
         properties: {
            zone: 'F',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-74.01872559197956, 41.75352978359419],
         },
         properties: {
            zone: 'G',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            // coordinates: [-73.78640796629486, 41.264757792278544],
            coordinates: [-73.4, 41.4],
         },
         properties: {
            zone: 'H',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            // coordinates: [-73.79747071037545, 41.014815484318376],
            coordinates: [-74.2, 41.014815484318376],
         },
         properties: {
            zone: 'I',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            // coordinates: [-73.95234912749667, 40.67461123278923],
            coordinates: [-74.5, 40.55],
         },
         properties: {
            zone: 'J',
            price: 0,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-72.9124511839569, 40.87567284683874],
         },
         properties: {
            zone: 'K',
            price: 0,
         },
      },
   ],
};