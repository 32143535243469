import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { useCallback, useState } from 'react';

export const useSelectedFeature = () => {
   const [selectedStripZone, setSelectedStripZone] = useState<ICAP_ZONE_NYISO>();
   const [selectedSpotZone, setSelectedSpotZone] = useState<ICAP_ZONE_NYISO>();
   const [selectedMonthlyZone, setSelectedMonthlyZone] = useState<ICAP_ZONE_NYISO>();

   const handleStripClick = useCallback((zone?: ICAP_ZONE_NYISO) => {
      setSelectedStripZone(zone);
   }, []);

   const handleSpotClick = useCallback((zone?: ICAP_ZONE_NYISO) => {
      setSelectedSpotZone(zone);
   }, []);

   const handleMonthlyClick = useCallback((zone?: ICAP_ZONE_NYISO) => {
      setSelectedMonthlyZone(zone);
   }, []);

   const clearSelectedZones = useCallback(() => {
      setSelectedStripZone(undefined);
      setSelectedSpotZone(undefined);
      setSelectedMonthlyZone(undefined);
   }, []);

   return {
      selectedStripZone,
      selectedSpotZone,
      selectedMonthlyZone,
      handleStripClick,
      handleSpotClick,
      handleMonthlyClick,
      clearSelectedZones,
   };
}