import { MAPBOX } from 'power/constants';
import { NODE_MAP_DATA_COMPONENT } from 'power/types/enum';
import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { ICapacityLayers } from './layers/CapacityLayers/type';
import { ICapacityStaticLayers } from './layers/CapacityStaticLayers/type';
import { INodeLayers } from './layers/NodeLayer/type';
import { IStaticLayers } from './layers/StaticLayers/type';

export type PowerMapSelection = {
  substationIds?: number[];
  branchIds?: string[];
  nodes?: { component?: NODE_MAP_DATA_COMPONENT; ids: number[] };
};

type IPowerMapContext = {
  mapStyle: 'Default' | 'Satellite';
  setMapStyle: (newStyle: 'Default' | 'Satellite') => void;

  bounds: mapboxgl.LngLatBoundsLike;
  setBounds: (newBounds: mapboxgl.LngLatBoundsLike) => void;

  capacityLayer?: ICapacityLayers;
  setCapacityLayer: React.Dispatch<React.SetStateAction<ICapacityLayers | undefined>>;

  capacityStaticLayers?: ICapacityStaticLayers;
  setCapacityStaticLayers: React.Dispatch<React.SetStateAction<ICapacityStaticLayers | undefined>>;

  nodeLayers?: INodeLayers;
  setNodeLayers: React.Dispatch<React.SetStateAction<INodeLayers | undefined>>;

  staticLayers?: IStaticLayers;
  setStaticLayers: React.Dispatch<React.SetStateAction<IStaticLayers | undefined>>;

  selection: PowerMapSelection;
  setSelection: React.Dispatch<React.SetStateAction<PowerMapSelection>>;
};

const defaultContext: IPowerMapContext = {
   mapStyle: 'Default',
   setMapStyle: (newStyle: 'Default' | 'Satellite') => {},

   bounds: MAPBOX.Bounds.USA,
   setBounds: (newBounds: mapboxgl.LngLatBoundsLike) => {},

   capacityLayer: undefined,
   setCapacityLayer: () => {},

   capacityStaticLayers: undefined,
   setCapacityStaticLayers: () => {},

   nodeLayers: undefined,
   setNodeLayers: () => {},   

   staticLayers: undefined,
   setStaticLayers: () => {},

   selection: {},
   setSelection: () => {},
};

const PowerMapContext = createContext<IPowerMapContext>(defaultContext);

const PowerMapProvider: FC<PropsWithChildren<{ initialState?: Partial<IPowerMapContext> }>> = ({ children, initialState }) => {
   const context = initialState ? { ...defaultContext, ...initialState } : defaultContext;

   const [mapStyle, setMapStyle] = useState(context.mapStyle);
   const [bounds, setBounds] = useState(context.bounds);

   const [capacityLayer, setCapacityLayer] = useState(context.capacityLayer);
   const [capacityStaticLayers, setCapacityStaticLayers] = useState(context.capacityStaticLayers);
   const [nodeLayers, setNodeLayers] = useState(context.nodeLayers);
   const [staticLayers, setStaticLayers] = useState(context.staticLayers);
   
   const [selection, setSelection] = useState<PowerMapSelection>(context.selection);

   // TODO: Implement the logic to set staticLayers and others

   return (
      <PowerMapContext.Provider
         value={{
            mapStyle,
            setMapStyle,
            bounds,
            setBounds,
            capacityLayer,
            setCapacityLayer,
            capacityStaticLayers,
            setCapacityStaticLayers,
            nodeLayers,
            setNodeLayers,
            staticLayers,
            setStaticLayers,
            selection,
            setSelection,
         }}
      >
         {children}
      </PowerMapContext.Provider>
   );
};

const usePowerMap = () => useContext(PowerMapContext);

export { /* PowerMapContext, */ PowerMapProvider, usePowerMap };
