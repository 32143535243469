enum PP_STATUS {
  Undefined = 0,
  Retired = 1,
  Canceled = 2,
  Operating = 3,
  Planned = 4,
  OutOfService = 5,
}

export default PP_STATUS;
