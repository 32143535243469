import { ApiClients } from 'ApiClients';
import CardPanel from 'components/ui/CardPanel';
import { HeatTable } from 'power/components/base';
import { FutureHeatmap, FutureHeatmapRequest } from 'power/types';
import { FC, useEffect, useState } from 'react';

type Props = {
  query: Omit<FutureHeatmapRequest, 'year'>;
  year: number;
  minMaxValues: { min: number; max: number };
};

export const LazyHeatmap: FC<Props> = ({ query, year, minMaxValues }) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<FutureHeatmap>();

   useEffect(() => {
      setLoading(true);
      setData(undefined);
      ApiClients.getInstance().futureHeatmap({ ...query, year })
         .then((data) => {
            // console.log('LazyHeatmap', query, data);
            setData(data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [query, year]);

   return (
      <CardPanel
         title={year.toString()} defaultViewMode="chart" fullScreen
         status={loading ? 'loading' : (data?.length ?? 0) < 1 ? 'noData' : 'success'} disableRefresh>
         {({ viewMode, isFullScreen }) => <HeatTable data={data} initialMinMaxValues={minMaxValues} />}
      </CardPanel>
   );
};
