import ISubstationInfoModel from 'power/types/ISubstationInfoModel';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  substation: ISubstationInfoModel;
};

const SubstationInfoBox: FC<Props> = ({ substation }) => (
   <div className={classes.infoBox}>
      <div className={classes.left}>
         <div>
            <div className={classes.title1}>Substation</div>
            <div className={classes.identifier}>[{substation.identifier}]</div>
         </div>
         <div>
            <div className={classes.title2}>Name</div>
            <div className={classes.name}>{substation.name}</div>
         </div>
         <div>
            {substation.county}, {substation.state}
         </div>
      </div>
      <div className={classes.right}>
         <div className={classes.center}>
            <div className={classes.title1}>Nominal</div>
            <div className={classes.title1}>Voltage</div>
            <div className={classes.voltage}>{substation.voltage}</div>
            <div className={classes.unit}>kV</div>
         </div>

         {/* <div className={classes.center}>
            <img src={transformer} className={classes.icon} alt="transformer" />
            <div className={classes.transformer}>Transformer</div>
         </div> */}
      </div>
   </div>
);

export default SubstationInfoBox;
