import { ENV } from 'power/constants';
import { FC, useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line max-len
const getStaticMapURL = (lat: number, lng: number, width: number, height: number) => `https://api.mapbox.com/styles/v1/powerdevd/cl4noxfe3004h14pfi3dw3822/static/geojson%28%7B%22type%22%3A%22Feature%22%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${lng}%2C${lat}%5D%7D%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23BB2552%22%7D%7D%29/${lng},${lat},9/${width}x${height}?access_token=${ENV.MAPBOX_ACCESS_TOKEN}`;

const StaticMap: FC<{ latitude: number; longitude: number }> = ({ latitude = 39.83, longitude = -101.23 }) => {
   const ref = useRef<HTMLDivElement>(null);

   const [mapUrl, setMapUrl] = useState(getStaticMapURL(latitude, longitude, 600, 800));

   useLayoutEffect(() => {
      setMapUrl(getStaticMapURL(latitude, longitude, 600, 800));
   }, [latitude, longitude]);

   return (
      <div className="static-map-cont" ref={ref} style={{ backgroundImage: `url(${mapUrl})` }}>
         {/* <img src={mapUrl} alt="Map" style={{ height: '100%' }} /> */}
      </div>
   );
};

export default StaticMap;
