import { BasisRiskHeatmapsContent } from './type';

const en: BasisRiskHeatmapsContent = {
   heatmapsPageTitle: 'Basis Risk Heatmaps',
   overview: 'Overview',
   detailedAnalysis: 'Detailed Analysis',
   dateFromDateTo: 'Date from/to Date',
   latLng: 'Lat/Lng',
   countyState: 'County/State',
};

export default en;
