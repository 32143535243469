import CustomOverlay from 'power/map/controls/base/CustomOverlay';
import { PowerMapSelection } from 'power/map/usePowerMap';
import { IHeadroomCapacityFilter, INodeMapPageQuery } from 'power/types';
import React, { useMemo } from 'react';
import SiderControlContent from './container';
import Sider from './container/content';

type Props = {
  capacityQuery?: IHeadroomCapacityFilter;
  nodeQuery: Partial<INodeMapPageQuery>;

  selection: PowerMapSelection;

  setSelectedSubstation: (substationId: number) => void;
  setSelectedNode: (nodeId: number) => void;
  onClose: () => void;
  width?: string | number;
};

const SiderControl: React.FC<Props> = ({ capacityQuery, nodeQuery, selection, setSelectedSubstation, setSelectedNode, onClose, width }) => {
   const open = useMemo(() => (selection.substationIds?.length || selection.branchIds?.length || selection.nodes?.ids.length), [selection]);

   return (
      <CustomOverlay position="top-left">
         <SiderControlContent onClose={onClose} width={width}>
            {open && (
               <Sider
                  capacityQuery={capacityQuery} nodeQuery={nodeQuery} selection={selection}
                  setSelectedSubstation={setSelectedSubstation} setSelectedNode={setSelectedNode}
                  onClose={onClose} />
            )}
         </SiderControlContent>
      </CustomOverlay>
   );
};

export default SiderControl;
