import { FUEL_TYPE } from 'power/types/enum';

const FUEL_TYPES: { label: string; value: FUEL_TYPE }[] = [
   { label: 'Wind', value: FUEL_TYPE.Wind },
   { label: 'Solar', value: FUEL_TYPE.Solar },
   { label: 'Hydro', value: FUEL_TYPE.Hydro },
   { label: 'Gas', value: FUEL_TYPE.Gas },
   { label: 'Oil', value: FUEL_TYPE.Oil },
   { label: 'BioMass', value: FUEL_TYPE.BioMass },
   { label: 'Battery', value: FUEL_TYPE.Battery },
   { label: 'Nuclear', value: FUEL_TYPE.Nuclear },
   { label: 'Coal', value: FUEL_TYPE.Coal },
   // {label: 'Hybrid', value: FUEL_TYPE.Hybrid},
   { label: 'Thermal', value: FUEL_TYPE.Thermal },
   { label: 'Other Storage', value: FUEL_TYPE.OtherStorage },
   // {label: 'ACTransmission', value: FUEL_TYPE.ACTransmission},
   // {label: 'DCTransmission', value: FUEL_TYPE.DCTransmission},
   // {label: 'Other', value: FUEL_TYPE.Other},
];

export default FUEL_TYPES;
