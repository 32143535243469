import { ApiClients } from 'ApiClients';
import { IICAPFuture, IICAPFutureRequest } from 'power/types';
import { useCallback, useEffect, useState } from 'react';

export const useICAPFutureData = () => {
   const [query, setQuery] = useState<IICAPFutureRequest>({ period_year: 2030, period_month: 1 });
   
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPFuture[] | undefined>(undefined);

   
   const handleData = useCallback((query: IICAPFutureRequest) => {
      setLoading(true);
      setData(undefined);
      ApiClients.getInstance().icapFuture(query)
         .then((res) => {
            setData(res);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);


   useEffect(() => {
      handleData(query);
   }, [handleData, query]);

   return {
      data,
      loading,
      query,
      setQuery,
   };
};
