import { Button } from 'antd';
import PATHS from 'power/PATHS';
import PowerCard from 'power/components/cards/PowerCard/PowerCard';
import { INodeMapPageQuery } from 'power/types';
import { NODE_MAP_DATA_COMPONENT } from 'power/types/enum';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './PowerTimeSeries.module.scss';
import TimeSeriesChart from './chart/TimeSeriesChart';
import { usePowerTimeSeries } from './usePowerTimeSeries';

type Props = {
  title: string;
  apiRoute: string;
  query: {
    component: NODE_MAP_DATA_COMPONENT;
    nodeId: number;
  };
  moreQuery: Partial<INodeMapPageQuery>;
  selectedSubstationId: number;
  overviewHash?: string;
  yTitle?: string;
};

const PowerTimeSeries: FC<Props> = ({ title, apiRoute, query, moreQuery, selectedSubstationId, overviewHash, yTitle = '$ MW/h' }) => {
   const { loading, fetch, data } = usePowerTimeSeries(apiRoute);

   useEffect(() => {
      const fullQuery = {
         ...query,
         hour: query.component === NODE_MAP_DATA_COMPONENT.TBX ? moreQuery.tbxType : query.component === NODE_MAP_DATA_COMPONENT.BATTER_SITING ? moreQuery.durationHour : 4,
         tbxValueType: query.component === NODE_MAP_DATA_COMPONENT.TBX && moreQuery.tbxValueType,
      };
      fetch(fullQuery);
   }, [fetch, query, moreQuery]);

   if (!loading && !data?.result?.data.length) return null;

   return (
      <PowerCard
         loading={loading}
         title={title}
         noData={!data?.result?.data.length}
         minHeight={290}
         maxHeight={290}
         showFullScreen={false}
         showMinimize={false}
         headerContent={
            overviewHash && (
               <div className={classes.average}>
                  <Link to={`${PATHS.NodeAnalysis}?substationId=${selectedSubstationId}&nodeId=${query.nodeId}${overviewHash}`} target="_blank" rel="noopener noreferrer">
                     <Button
                        size="small" type="default" shape="round"
                        style={{ color: '#42B389', borderColor: '#42B389' }}>
                Go to Detail
                     </Button>
                  </Link>
               </div>
            )
         }
      >
         <div className={classes.widget}>
            <div className={classes.yAxis}>{yTitle}</div>
            <TimeSeriesChart data={data?.result?.data ?? []} height={220} />
         </div>
      </PowerCard>
   );
};

export default PowerTimeSeries;
