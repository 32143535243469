import { FeatureCollection, LineString, MultiLineString, Point } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { MAP_LEGEND_COLORS } from 'power/constants/map/MAP_LEGEND_COLORS';
import { CapacityProperties } from 'power/map/layers/CapacityLayers/type';
import { IHeadroomCapacity } from 'power/types';
import { utils } from 'power/utils';

export const headroomCapacityToSubstationsGeojson = (data: IHeadroomCapacity): FeatureCollection<Point, CapacityProperties> => {
   const geojson: FeatureCollection<Point, CapacityProperties> = utils.map.arrayToGeoJsonPoint<CapacityProperties>(data.substations, ['capacity', 'voltage', 'cost'], { type: 'substation' });
   return geojson;
};

export const headroomCapacityToTransmissionLinesGeojson = (data: IHeadroomCapacity): FeatureCollection<MultiLineString, CapacityProperties> => {
   const geojson: FeatureCollection<MultiLineString, CapacityProperties> = utils.map.arrayToGeoJsonMultiLineString<CapacityProperties>(data.transmissionLines, ['capacity', 'voltage', 'cost'], { type: 'transmissionLine' }, 30);
   return geojson;
};

export const getMapBounds = (data: IHeadroomCapacity): mapboxgl.LngLatBoundsLike | undefined => {
   // console.log('getMapBounds', data.substations[0], data.transmissionLines[0]);
   let ssBound: mapboxgl.LngLatBounds | undefined;
   let tlBound: mapboxgl.LngLatBounds | undefined;

   if (data.substations.length !== 0) {
      const coordinates = data.substations.map((s) => s[1]);
      ssBound = coordinates.reduce((bounds, coord) => bounds.extend([coord[0], coord[1]]), new mapboxgl.LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[1][0], coordinates[1][1]]));
   }

   if (data.transmissionLines.length !== 0) {
      const coordinates = data.transmissionLines.map((t) => t[1]);
      tlBound = coordinates.reduce((bounds, coord) => coord.reduce((b, c) => b.extend([c[0], c[1]]), bounds), new mapboxgl.LngLatBounds([coordinates[0][0][0], coordinates[0][0][1]], [coordinates[0][1][0], coordinates[0][1][1]]));
   }

   if (ssBound && tlBound) {
      return ssBound.extend(tlBound.getSouthWest()).extend(tlBound.getNorthEast());
   }
   if (ssBound) {
      return ssBound;
   }
   return tlBound;
};

export const getFeatureBounds = (data: FeatureCollection): mapboxgl.LngLatBoundsLike | undefined => {
   if (data.features.length === 0) return undefined;

   if (data.features[0].geometry.type === 'Point') {
      if (data.features.length === 1) {
         const { coordinates } = (data as FeatureCollection<Point>).features[0].geometry;
         let poiBounds = new mapboxgl.LngLatBounds([coordinates[0], coordinates[1]], [coordinates[0], coordinates[1]]);
         /**
       * Set offset to single poi bounds
       */
         poiBounds = poiBounds.extend([coordinates[0] + 0.005, coordinates[1] + 0.005]);
         poiBounds = poiBounds.extend([coordinates[0] - 0.007, coordinates[1] - 0.005]);
         return poiBounds;
      }
      const coordinates = (data as FeatureCollection<Point>).features.map((s) => s.geometry.coordinates);
      return coordinates.reduce((bounds, coord) => bounds.extend([coord[0], coord[1]]), new mapboxgl.LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[1][0], coordinates[1][1]]));
   }

   if (data.features[0].geometry.type === 'LineString') {
      if (data.features.length === 1) {
         const { coordinates } = (data as FeatureCollection<LineString>).features[0].geometry;
         let lineBounds = coordinates.reduce((bounds, coord) => bounds.extend([coord[0], coord[1]]), new mapboxgl.LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[1][0], coordinates[1][1]]));
         /**
       * Set offset to single line bounds
       */
         lineBounds = lineBounds.extend([coordinates[0][0] + 0.005, coordinates[0][1] + 0.005]);
         lineBounds = lineBounds.extend([coordinates[1][0] - 0.007, coordinates[1][1] - 0.005]);
         return lineBounds;
      }

      const coordinates = (data as FeatureCollection<LineString>).features.map((t) => t.geometry.coordinates as unknown as number[][]);
      return coordinates.reduce((bounds, coord) => coord.reduce((b, c) => b.extend([c[0], c[1]]), bounds), new mapboxgl.LngLatBounds([coordinates[0][0][0], coordinates[0][0][1]], [coordinates[0][1][0], coordinates[0][1][1]]));
   }

   return undefined;
};


export const getPaintSubstationsHighlight = (): mapboxgl.CirclePaint => ({
   'circle-radius': 10,
   'circle-color': '#7c00bf',
});
export const getPaintSubstations = (min: number, max: number): mapboxgl.CirclePaint => {
   if (min === max) {
      return {
      // 'circle-color': '#00000000',
         'circle-opacity': 0,
         'circle-radius': 8,
         'circle-stroke-color': MAP_LEGEND_COLORS[0],
         'circle-stroke-width': 6,
      };
   }

   const tickValue = (max - min) / (MAP_LEGEND_COLORS.length - 1);

   // console.log(min, max, tickValue);

   // min => mapColorGradient[0]
   // min+tickValue => mapColorGradient[1]
   // min+tickValue*2 => mapColorGradient[2]
   // ...
   // max => mapColorGradient[mapColorGradient.length - 1]

   const p: mapboxgl.CirclePaint = {
      // 'circle-color': '#000000',
      'circle-stroke-width': 6,
      'circle-stroke-color': [
         'interpolate',
         ['linear'],
         ['get', 'capacity'],
      /* min,
         '#00aa00',
         med,
         '#cccc00',
         max,
         '#aa0000', */
      ],
      'circle-opacity': 0,
      'circle-radius': [
         'interpolate',
         ['linear'],
         ['get', 'capacity'],
      // min, // value1
      // 8, // px1
      // med, // value2
      // 14, // px2
      // max, // value3
      // 20, // px3
      ],
   };

   MAP_LEGEND_COLORS.forEach((color, i) => {
      // (p['circle-color'] as any[]).push(min + tickValue * i);
      (p['circle-stroke-color'] as any[]).push(min + tickValue * i);
      // (p['circle-color'] as any[]).push(color);
      (p['circle-stroke-color'] as any[]).push(color);
      (p['circle-radius'] as any).push(min + tickValue * i);
      (p['circle-radius'] as any).push(10 + i / 4);
   });

   return p;
};

export const getPaintBranchesHighlight = (): mapboxgl.LinePaint => ({
   'line-width': 10,
   'line-color': '#7c00bf',
   'line-opacity': 1,
});
export const getPaintBranches = (min: number, max: number): mapboxgl.LinePaint => {
   const tickValue = (max - min) / (MAP_LEGEND_COLORS.length - 1);

   // console.log(min, max, tickValue);

   // min => mapColorGradient[0]
   // min+tickValue => mapColorGradient[1]
   // min+tickValue*2 => mapColorGradient[2]
   // ...
   // max => mapColorGradient[mapColorGradient.length - 1]

   const p: mapboxgl.LinePaint = {
      'line-width': ['case', ['>=', ['get', 'voltage'], 345], 7, ['>=', ['get', 'voltage'], 275], 6, ['>=', ['get', 'voltage'], 115], 5, ['>=', ['get', 'voltage'], 69], 4, 3],
      'line-color': [
         'interpolate',
         ['linear'],
         ['get', 'capacity'],
      /* min,
         '#00aa00',
         med,
         '#cccc00',
         max,
         '#aa0000', */
      ],
      'line-opacity': 0.75,
      // line start and end point radius'
   };

   MAP_LEGEND_COLORS.forEach((color, i) => {
      (p['line-color'] as any[]).push(min + tickValue * i);
      (p['line-color'] as any[]).push(color);
      // p['circle-radius'].push(min + tickValue * i);
      // p['circle-radius'].push(8 + i * 2);
   });

   // console.log(p);
   return p;
};
