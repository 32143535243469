/* eslint-disable max-len */
import { LMPOverviewContent } from './type';

const en: LMPOverviewContent = {
   overviewPageTitle: 'LMP Overview',
   dartSpreadTimeSeries: 'DART Spread LMP - Time Series',
   lmpSummary: 'LMP Summary',
   timeSeries: 'Time Series',
   lmpComponentsTimeSeries: 'LMP Components - Time Series',
   hourlyPricePattern: 'LMP - Hourly Price Pattern',
   monthlyPricePattern: 'LMP - Monthly Price Pattern',
   dateFromDateTo: 'Date from/to Date',
   latLng: 'Lat/Lng',
   countyState: 'County/State',
   detailedAnalysis: 'Detailed Analysis',
   dataDownload: 'Data Download',
   heatmaps: 'Heatmaps',
   futureData: 'Future Data',
   substituteNode: 'Substitute Node',
   substituteNodeDescription: 'The substitute node is the closest node that provides data if the selected node doesn\'t have data for a required date range. The substitute node selection is based on low distance and high price profile similarity.',
   congestionMCCSummary: 'Congestion(MCC) Summary',
   lmpDayAheadHourly: 'LMP Day-Ahead Hourly',
   lmpRealTimeHourly: 'LMP Real-Time Hourly',
   weightedSeries: 'The Solar & Wind-Weighted LMP Time Series',
   historicalAvgPrices: 'Historical AVG Prices',
   stdDayAhead: 'Standard Deviation Day-Ahead',
   stdRealTime: 'Standard Deviation Real-Time',
   stdDevTimeSeries: 'Standard Deviation LMP - Time Series',
   stdDevLmpSummary: 'Standard Deviation LMP Summary',
   solarProductionHeatmap: 'Solar Production Heatmap',
   windProductionHeatmap: 'Wind Production Heatmap',
   negativeHours: 'Negative Hours',
   negativeLmpCount: 'Negative LMP Count',
   negativeLmpAverage: 'Negative LMP Average',
   dartSpreadTimeSeriesInfo:
    'The DART Spread Time Series chart displays day-ahead and real-time LMP(or its components) as well as the difference between them for the selected date range in time-series format. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   lmpComponentsTimeSeriesChartInfo:
    'The LMP Components Time Series chart displays real-time and day-ahead the Marginal Cost of Energy (MCE), Marginal Cost of Congestion (MCC), and Marginal Cost of Loss (MCL) prices in a time-series format. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   lmpSummaryChartInfo: 'LMP Summary displays the average yearly LMP for the real-time and day-ahead markets. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   congestionMCCSummaryChartInfo:
    'MCC Summary displays the average yearly Marginal Cost of Congestion (MCC) for the real-time and day-ahead markets. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   lmpDayAheadHourlyChartInfo: 'The LMP Day-Ahead Hourly Heat Map displays the average day-ahead LMP(or its components) for each hour and month in the selected year.',
   lmpRealTimeHourlyChartInfo: 'The LMP Real-Time Hourly Heat Map displays the average real-time LMP(or its components) for each hour and month in the selected year.',
   weightedSeriesChartInfo: 'The Solar & Wind-Weighted LMP Time Series chart shows the LMP, Solar-weighted LMP, and Wind-weighted LMP for the real-time and day-ahead markets in time series format.',
   historicalAvgPricesChartInfo:
    'The Historical AVG Prices chart shows the yearly average prices of the LMP, Solar-weighted LMP, and Wind-weighted LMP for the real-time and day-ahead markets in time series format. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   stdDayAheadChartInfo: 'The Standard Deviation Day-Ahead Heat Map displays the standard deviation of LMP for each hour and month in the selected year, offering insights into day-ahead markets.',
   stdRealTimeChartInfo: 'The Standard Deviation Real-Time Heat Map displays the standard deviation of LMP for each hour and month in the selected year, offering insights into day-ahead markets.',
   stdDevTimeSeriesChartInfo: 'The Standard Deviation LMP-Time Series chart displays the monthly time-series-based standard deviation of LMP (Locational Marginal Price) for the real-time and day-ahead markets.',
   stdDevLmpSummaryChartInfo: 'The Standard Deviation LMP Summary chart displays the yearly average standard deviation of LMP for the real-time and day-ahead markets in time series format.',
   solarProductionHeatmapChartInfo: 'The Solar Production Heat Map displays the production in MWh for a 1 MWdc solar plant for each hour and month in a typical meteorological year (TMY). TMY data is generated by selecting data for each month from the year considered most "typical" for that month.',
   windProductionHeatmapChartInfo: 'The Wind Production Heat Map shows the production in MWh for a 1 MWac wind plant for each hour and month in a typical meteorological year(TMY). TMY data is generated by selecting data for each month from the year considered most "typical" for that month.',
   hourlyPricePatternChartInfo: 'The Hourly Price Pattern chart shows real-time and day-ahead hourly average prices to reveal diurnal patterns and compare them against historical prices.',
   monthlyPricePatternChartInfo: 'The Monthly Price Pattern chart shows real-time and day-ahead monthly average prices for the selected years.',
   negativeHoursChartInfo: 'The Negative Hours Heat Map shows the number of 5-minute intervals where the LMP is negative (Negative LMP Count) on the left and the average price for those intervals (Negative LMP Average) on the right for each hour and month in the selected year.',
   fuelMixTimeSeries: 'Fuel Mix Time Series',
   fuelMixTimeSeriesChartInfo: 'The Fuel Mix Time Series chart illustrates capacity in megawatt-hours(MWh) for different fuel types monthly and yearly. The \'Distance\' slider enables the filtering of capacity data based on kilometers.',
};

export default en;
