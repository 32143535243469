import PageContainer from 'components/ui/PageContainer';
import { useEffect } from 'react';
import NewsTable from './NewsTable';
import useNewsList from './useNewsList';

const NewsList = () => {
   const { data, loading, fetch } = useNewsList();

   useEffect(() => {
      fetch();
   }, [fetch]);

   return (
      <PageContainer status="success" statusCode={200} filterBarRowCount={0}>
         <NewsTable loading={loading} data={data as any} />
      </PageContainer>
   );
};

export default NewsList;
