import { BESSEditContent } from './type';

const en: BESSEditContent = {
   BESS_Summary: 'BESS Summary',
   projectGroup: 'Project Group',
   node: 'Node',
   iso: 'ISO',
   dateFromTo: 'Date From - To',
   countyState: 'County / State',
   latitudeLongitude: 'Lat / Lng',
   substituteNode: 'Substitute Node',
   version: 'Version',
   createdOn: 'Created On',
   lastSimulationDate: 'Last Simulation Date',
   BESS_Tabs: 'BESS Tabs',
   modelParameters: 'Model Parameters',
   financialParameters: 'Financial Parameters',
   BESS_Model_Tabs_Item: 'BESS Model Tabs Item',
   revenueStacking: 'Revenue Stacking',
   energyRevenue: 'Energy Revenue',
   capacityRevenue: 'Capacity Revenue',
   ancillaryServicesRevenue: 'Ancillary Services Revenue',
   resourceAndGeneration: 'Resource And Generation',
   powerPlant: 'Power Plant',
   modelingAndSimulation: 'Modeling And Simulation',
   modeling: 'Modeling',
   battery: 'Battery',
   BESS_Financial_Tabs_Item: 'BESS Financial Tabs Item',
   cost: 'Cost',
   capex: 'Capital Expenditures(CapEx)',
   opex: 'Operating Expenditures(OpEx)',
   financial: 'Financial',
   financialBase: 'Financial',
   incentives: 'Incentives',
   incentivesBase: 'Incentives',
   BESS_Model_revenueStacking_energyRevenue: 'BESS Model - revenueStacking - energyRevenue',
   energyDataSource: 'Energy Data Source',
   energyDataId: 'Custom LMP Data Source',
   baseMarket: 'Energy Market Type',
   maxEnergyPriceCap: 'Max Energy Price Cap',
   energySetting_incrementMethod: 'Price Incement Method',
   energySetting_incrementRate: 'Annual Fixed Rate',
   energySetting_incrementSchedule: 'Yearly Schedule',
   BESS_Model_revenueStacking_capacityRevenue: 'BESS Model - revenueStacking - capacityRevenue',
   capacityBasis: 'Capacity Data Source',
   capacitySetting_paymentPerYear: 'Capacity Payment',
   capacitySetting_netEligibleRate: 'Net Eligible Factor',
   capacitySetting_incrementMethod: 'Payment Incement Method',
   capacitySetting_incrementRate: 'Annual Fixed Rate',
   capacitySetting_incrementSchedule: 'Yearly Schedule',
   capacityDataId: 'TOD Data Source',
};

export default en;
