import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
// import classes from './SidebarActions.module.scss'

export const SidebarActions = ({ headerCount = 0, children }: { headerCount?: number; children: React.ReactNode }) => (
   <Layout>
      <Sider
         collapsed
         style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            right: 0,
            top: headerCount * 72,
            bottom: 0,
            backgroundColor: '#eeeeee',
            borderWidth: 1,
            borderColor: '#bbbbbb',
            borderStyle: 'solid',
            /* width: 48,
            minWidth: 48,
            maxWidth: 48, */
         }} /* className={classes.sidebarActions} */
      >
         {children}
      </Sider>
   </Layout>
);
