type ThresholdKey = 'thousand' | 'billion' | 'million';
type Thresholds = Partial<Record<ThresholdKey, { value: number; text: string }>>;

const THRESHOLDS: Thresholds = {
   billion: { value: 1.0e9, text: 'B' },
   million: { value: 1.0e6, text: 'M' },
};

const FULL_THRESHOLDS: Thresholds = {
   billion: { value: 1.0e9, text: 'B' },
   million: { value: 1.0e6, text: 'M' },
   thousand: { value: 1.0e3, text: 'K' },
};

const localize = (n = 0, options?: Intl.NumberFormatOptions, locales?: string): string => Number(n).toLocaleString(locales, {
   maximumFractionDigits: options?.maximumFractionDigits ?? (options?.minimumFractionDigits ? undefined : 1),
   ...options,
});

const formatNumber = (n = 0, options?: Intl.NumberFormatOptions, full = false, language = 'en'): string => {
   let helperText = '';

   const thresholds = full ? FULL_THRESHOLDS : THRESHOLDS;
   Object.values(thresholds).forEach((threshold) => {
      if (Math.abs(n) >= threshold.value) {
         n /= threshold.value;
         helperText = threshold.text;
      }
   });

   const localized = localize(n, options, language);
   return `${localized}${helperText}`;
};

export default formatNumber;
