import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IBindingConstraintItem } from 'power/types';
import { HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON } from 'power/types/enum';
import { FC, useMemo, useState } from 'react';
import HeadroomQuery from './HeadroomQuery';

type Props = {
  loading: boolean;
  data: IBindingConstraintItem[] | undefined;
};

const ConstraintsTable: FC<Props> = ({ loading, data }) => {
   const [season, setSeason] = useState<HEADROOM_CAPACITY_SEASON>(HEADROOM_CAPACITY_SEASON.SUMMER);
   const [direction, setDirection] = useState<HEADROOM_CAPACITY_DIRECTION>(HEADROOM_CAPACITY_DIRECTION.CHARGING);

   const filteredData = useMemo(() => {
      if (data === undefined) return;
      return data.filter((item) => item.season === season && item.direction === direction);
   }, [data, season, direction]);

   return (
      <>
         <HeadroomQuery
            loading={loading} season={season} setSeason={setSeason}
            direction={direction} setDirection={setDirection} />
         <Table
            style={{ overflowX: 'scroll' }}
            loading={loading}
            dataSource={filteredData}
            columns={columns}
            expandable={{
               expandedRowRender: (record) => (
                  <table style={{ margin: '-16px 0 0' }}>
                     <tr>
                        <td>
                           <b>Area:</b> {record.areaMonitoredFacility}
                        </td>
                        <td>
                           <b>Dfax:</b> {record.distributionFactor}
                        </td>
                        <td>
                           <b>Base MVA:</b> {record.baseMva}
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <b>contingency MVA:</b> {record.contingencyMva}
                        </td>
                        <td>
                           <b>Base Flow MW:</b> {record.baseFlowMw}
                        </td>
                        <td>
                           <b>Contingency Flow MW:</b> {record.contingencyFlowMw}
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <b>Loading %:</b> {record.loadingBeforeWithdrawal}
                        </td>
                        <td>
                           <b>Line Length:</b> {record.bindingConstraintLineLength}
                        </td>
                        <td>
                           <b>Constraint Type:</b> {record.bindingConstraintType}
                        </td>
                     </tr>
                  </table>
               ),
               // rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            rowKey={(record) => record.bindingConstraint}
            pagination={{ pageSize: 5, hideOnSinglePage: true, showSizeChanger: false }}
         />
      </>
   );
};

export default ConstraintsTable;

const columns: ColumnsType<IBindingConstraintItem> = [
   {
      title: 'Constraint',
      dataIndex: 'bindingConstraint',
      key: 'bindingConstraint',
      /* width: 600, */
   },
   {
      title: 'Voltage (KV)',
      dataIndex: 'voltage',
      key: 'voltage',
      width: 100,
   },
   /* {
      title: 'Area',
      dataIndex: 'areaMonitoredFacility',
      key: 'areaMonitoredFacility',
   }, */
   {
      title: 'Power (MW)',
      dataIndex: 'capacity',
      key: 'capacity',
      width: 120,
   },
   /* {
      title: 'Dfax',
      dataIndex: 'distributionFactor',
      key: 'distributionFactor',
   },
   {
      title: 'Base MVA',
      dataIndex: 'baseMva',
      key: 'baseMva',
   },
   {
      title: 'Contingency MVA',
      dataIndex: 'contingencyMva',
      key: 'contingencyMva',
   }, */
   {
      title: 'Contingency',
      dataIndex: 'bindingContingency',
      key: 'bindingContingency',
      width: 360,
   },
   /* {
      title: 'Base Flow (MW)',
      dataIndex: 'baseFlowMw',
      key: 'baseFlowMw',
   },
   {
      title: 'Contingency Flow (MW)',
      dataIndex: 'contingencyFlowMw',
      key: 'contingencyFlowMw',
   },
   {
      title: 'Loading (%)',
      dataIndex: 'loadingBeforeWithdrawal',
      key: 'loadingBeforeWithdrawal',
   },
   {
      title: 'Line Length',
      dataIndex: 'bindingConstraintLineLength',
      key: 'bindingConstraintLineLength',
   }, */
   {
      title: 'Ref. Cost ($M)',
      dataIndex: 'totalRefurbishmentCost',
      key: 'totalRefurbishmentCost',
      width: 150,
   },
   /* {
      title: 'Constraint Type',
      dataIndex: 'bindingConstraintType',
      key: 'bindingConstraintType',
   }, */
   Table.EXPAND_COLUMN,
];
