// import { PowerMap } from 'power/components/maps';
import { Line } from '@ant-design/plots';
import { Flex, Spin } from 'antd';
import { TIME_GRANULARITY } from 'power/types/enum';
import React, { FC } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';
import classes from './index.module.scss';

const COLORS = ['#5d7092', '#42b389', '#ff7a45', '#597ef7'];

type Props = {
  title: string;
  loadingSize?: [number, number];
  data: any[];
  select?: React.ReactNode;
  yField?: string;
  yTitle?: string;
  xTitle?: 'Season' | 'Month';
};

const Chart: FC<Props> = ({ title, loadingSize, data, select, yField = 'price', yTitle = 'Price ( $/kW - Month)', xTitle = 'Month' }) => {
   if (loadingSize) {
      return (
         <div className={classes.chart} style={{ /* width: loadingSize[0],  */ height: loadingSize[1] }}>
            <div className={classes.loading}>
               <Spin />
            </div>
         </div>
      );
   }

   if (!data.length) {
      return (
         <div className={classes.chart}>
            <div className={classes.loading}>No data available</div>
         </div>
      );
   }

   return (
      <div className={classes.chart}>
         <Flex justify="space-between">
            <h3 style={{ margin: '4px 0 8px 4px' }}>{title}</h3>
            {select}
         </Flex>
         <Line
            data={data}
            xField="x"
            yField={yField}
            seriesField="zone"
            height={350}
            /* width={800} */
            color={COLORS}
            legend={{ position: 'top' }}
            yAxis={{
               title: {
                  text: yTitle,
               },
            }}
            xAxis={{
               type: 'time',
               /* title: {
                  text: xTitle,
               }, */
               tickInterval: 1,
               tickMethod: 'cat',
               label: {
                  formatter(text, item, index) {
                     return xTitle === 'Season' ? (text.split('-')[1] === '06' ? text.split('-')[0] + ' Summer' : text.split('-')[0] + '/' + (Number(text.split('-')[0]) + 1) + ' Winter') : parseDateByTimeGranularity(text, TIME_GRANULARITY.monthly);
                  },
                  autoRotate: true,
                  // rotate: 45,
                  // offsetX: 32,
               },
            }}
            tooltip={{
               title: (x) => (xTitle === 'Season' ? (x.split('-')[1] === '06' ? x.split('-')[0] + ' Summer' : x.split('-')[0] + '/' + (Number(x.split('-')[0]) + 1) + ' Winter') : parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly)),
            }}
         />
      </div>
   );
};

export default Chart;
