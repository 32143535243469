import PATHS from 'power/PATHS';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import { type RouteProps } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';

type Props = RouteProps & {
  subscriptionPackages: SUBSCRIPTION_PACKAGE[];
  all?: boolean;
};

export const ModuleRoute: React.FC<Props> = ({ subscriptionPackages, all = true, ...props }) => {
   const { isos, hasPackage } = useAuth();

   const hasRequiredPackages = useMemo(() => (isos.length ? (all ? subscriptionPackages.every((p) => hasPackage(p)) : subscriptionPackages.some((p) => hasPackage(p))) : undefined), [all, hasPackage, isos, subscriptionPackages]);

   // user packages(isos) are not loaded yet
   if (hasRequiredPackages === undefined) return null;

   // user has required packages
   if (hasRequiredPackages) return <Outlet />;

   // user does not have required packages
   return <Navigate to={PATHS.Home} replace />;
};
