// import { PowerMap } from 'power/components/maps';
import { ApiClients } from 'ApiClients';
import { IICAPSpot, IICAPStripRequest } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import Table from '../Table';

const ICAP_ZONES: ICAP_ZONE_NYISO[] = [ICAP_ZONE_NYISO.NYCA, ICAP_ZONE_NYISO.LI, ICAP_ZONE_NYISO.NYC, ICAP_ZONE_NYISO.GJ];

const MONTHS = [
   { id: 5, name: 'May' },
   { id: 6, name: 'Jun' },
   { id: 7, name: 'Jul' },
   { id: 8, name: 'Aug' },
   { id: 9, name: 'Sep' },
   { id: 10, name: 'Oct' },
   { id: 11, name: 'Nov' },
   { id: 12, name: 'Dec' },
   { id: 1, name: 'Jan' },
   { id: 2, name: 'Feb' },
   { id: 3, name: 'Mar' },
   { id: 4, name: 'Apr' },
];

type Props = {
  queryStrip: IICAPStripRequest;
  month: number;
};

const SpotTable: FC<Props> = ({ queryStrip, month }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPSpot[]>();

   useEffect(() => {
      setData(undefined);
      setLoading(true);

      const promises = ICAP_ZONES.map((zone) => ApiClients.getInstance().icapSpotDetail({ ...queryStrip, period_month: month, zone }));

      Promise.all(promises)
         .then((res) => {
            setData(res.flat());
         })
         .finally(() => setLoading(false));
   }, [month, queryStrip]);

   const keys = ['Awarded Deficiency (MW)', 'Awarded Excess (MW)', '% Excess Above Requirement', 'Price ($/kW-M)'];

   /* const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] = [
      { zone: ICAP_ZONE_NYISO.NYCA, keys, allValues: [[2440, 1672.0, 3.91, 1]] },
      { zone: ICAP_ZONE_NYISO.LI, keys, allValues: [[3440, 2672.0, 4.91, 2]] },
      { zone: ICAP_ZONE_NYISO.NYC, keys, allValues: [[3440, 2672.0, 4.91, 2]] },
      { zone: ICAP_ZONE_NYISO.GJ, keys, allValues: [[3440, 2672.0, 4.91, 2]] },
   ]; */

   const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] = data?.map((spot) => ({
      zone: spot.zone,
      keys,
      allValues: [[spot.awarded_deficiency, spot.awarded_excess, spot.excess_above_requirement, spot.price]],
   })) ?? [];

   return <Table headers={['Spot', '', MONTHS.find((x) => x.id === month)?.name ?? '']} rows={rows} loadingSize={loading ? [403, 398] : undefined} />;
};

export default SpotTable;
