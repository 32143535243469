import { Checkbox, Flex } from 'antd';
import { IMAGE_PATHS } from 'power/constants';
import { usePowerMap } from 'power/map/usePowerMap';
import React, { PropsWithChildren, useCallback } from 'react';
import BaseSection from '../base/BaseSection';
import classes from './CapacityStaticLayersSection.module.scss';

type Props = {
  mapId: string;
};

const CapacityStaticLayersSection: React.FC<PropsWithChildren<Props>> = ({ mapId }) => {
   const { capacityStaticLayers, setCapacityStaticLayers } = usePowerMap();

   const changeActiveAll = useCallback(() => {
      setCapacityStaticLayers(
         (prev) => prev && {
            ...prev,
            allActive: !prev.allActive,
         },
      );
   }, [setCapacityStaticLayers]);

   const onChangeVisible = useCallback(
      (layer: 'generator' | 'iq' | 'load' | 'retirement' | 'transformer') => {
         setCapacityStaticLayers(
            (prev) => prev && {
               ...prev,
               [layer]: { ...prev[layer], filter: { ...prev[layer]?.filter, active: !prev[layer]?.filter?.active ?? false } },
            },
         );
      },
      [setCapacityStaticLayers],
   );

   if (!capacityStaticLayers) return null;

   return (
      <BaseSection
         mapId={mapId} active={capacityStaticLayers.allActive} setActive={changeActiveAll}
         title="Headroom Capacity Layers" defaultCollapsed={capacityStaticLayers?.defaultCollapsed ?? true}>
         <div className={classes.capacityStaticLayerControl}>
            <Flex vertical gap={2} style={{ padding: 4 }}>
               <Checkbox checked={capacityStaticLayers.generator?.filter?.active} onChange={() => onChangeVisible('generator')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                        src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/generator.png')} width={16} height={16}
                        alt="generator" style={{ marginRight: 5 }} />
              Generator
                  </div>
               </Checkbox>

               <Checkbox checked={capacityStaticLayers.iq?.filter?.active} onChange={() => onChangeVisible('iq')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                        src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/iq.png')} width={16} height={16}
                        alt="iq" style={{ marginRight: 5 }} />
              Interconnection Queue
                  </div>
               </Checkbox>

               <Checkbox checked={capacityStaticLayers.load?.filter?.active} onChange={() => onChangeVisible('load')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                        src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/load.png')} width={16} height={16}
                        alt="load" style={{ marginRight: 5 }} />
              Load
                  </div>
               </Checkbox>

               <Checkbox checked={capacityStaticLayers.retirement?.filter?.active} onChange={() => onChangeVisible('retirement')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                        src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/retirement.png')} width={16} height={16}
                        alt="retirement" style={{ marginRight: 5 }} />
              Retirement
                  </div>
               </Checkbox>

               <Checkbox checked={capacityStaticLayers.transformer?.filter?.active} onChange={() => onChangeVisible('transformer')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <img
                        src={IMAGE_PATHS.MAP_ASSETS.FILE('grid/transformer.png')} width={16} height={16}
                        alt="transformer" style={{ marginRight: 5 }} />
              Transformer
                  </div>
               </Checkbox>
            </Flex>
         </div>
      </BaseSection>
   );
};

export default CapacityStaticLayersSection;
