import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import HomeMap from 'modules/HomeMap';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import { ICapacityLayers } from 'power/map/layers/CapacityLayers/type';
import { ICapacityStaticLayers } from 'power/map/layers/CapacityStaticLayers/type';
import { INodeLayers } from 'power/map/layers/NodeLayer/type';
import TlPopup from 'power/map/layers/StaticLayers/TransmissionLinesLayer/popup/TlPopup';
import { IStaticLayers } from 'power/map/layers/StaticLayers/type';
import { PowerMapProvider } from 'power/map/usePowerMap';
import { DATA_PROVIDER, FUEL_TYPE, HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON, MARKET_TYPE, NODE_MAP_DATA_COMPONENT, PERIOD_TYPE, POWER_NODE_LAYER_NAME, PRICE_COMPONENT, SUBSCRIPTION_PACKAGE, TBX_VALUE_TYPE, TL_VOLTAGE, TXBX_COMPONENT } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import ReactGA from 'react-ga4';

const INITIAL_BOUNDS: mapboxgl.LngLatBoundsLike = [-70.96, 45.8, -80.0, 39.98];
const INITIAL_MAP_STYLE: 'Default' | 'Satellite' = 'Default';

const INITIAL_CAPACITY_LAYER: ICapacityLayers = {
   active: true,
   defaultCollapsed: true,
   visible: { substations: true, branches: false },
   loading: false,
   query: {
      direction: HEADROOM_CAPACITY_DIRECTION.CHARGING,
      iso: [DATA_PROVIDER.NYISO],
      voltage: { min: 345, max: 345 }, //  [345, 275, 115, 69, 0],
      season: HEADROOM_CAPACITY_SEASON.SUMMER,
      maxRefurbishmentCost: 50,
   },
   data: { substations: { type: 'FeatureCollection', features: [] }, branches: { type: 'FeatureCollection', features: [] } },
};

const INITIAL_CAPACITY_STATIC_LAYER: ICapacityStaticLayers = {
   allActive: false,
   defaultCollapsed: true,
   
   iq: { filter: { active: true } },
   generator: { filter: { active: false } },
   load: { filter: { active: false } },
   retirement: { filter: { active: false } },
   transformer: { filter: { active: false } },
};

const INITIAL_NODE_LAYERS: INodeLayers = {
   active: false,
   defaultCollapsed: true,
   controlPosition: 'top-right',
   loading: false,
   query: {
      component: NODE_MAP_DATA_COMPONENT.LMP,
      marketType: MARKET_TYPE.DayAhead,
      periodType: PERIOD_TYPE.last_2_year,
      tbxType: TXBX_COMPONENT.Tb4,
      tbxValueType: TBX_VALUE_TYPE.Performance,
      durationHour: 4,
      sitingValueType: PRICE_COMPONENT.BATTERY_PROFIT_PER_MW,
      showAll: false,
   },
   data: { type: 'FeatureCollection', features: [] },
   layerOptions: [POWER_NODE_LAYER_NAME.LMP, POWER_NODE_LAYER_NAME.MCC, POWER_NODE_LAYER_NAME.TBX, POWER_NODE_LAYER_NAME.BASIS_RISK, POWER_NODE_LAYER_NAME.BATTERY_SITING],
   layerOption: POWER_NODE_LAYER_NAME.LMP,
};

// TODO
// Get url query params for bounds, mapStyle, and staticLayers

export const MapPage = () => {
   ReactGA.send({ hitType: 'pageview', page: '/', title: 'Map' });

   const { isos, iso, hasPackage } = useAuth();

   const INITIAL_STATIC_LAYERS: IStaticLayers = useMemo(
      () => ({
         defaultCollapsed: true,
         allActive: true,

         iq: hasPackage(SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE) ? { filter: { active: false } } : undefined,
         powerPlants: hasPackage(SUBSCRIPTION_PACKAGE.POWER_PLANT) ? { filter: { active: false, fuelTypes: [FUEL_TYPE.Undefined] } } : undefined,
         transmissionLines: hasPackage(SUBSCRIPTION_PACKAGE.TRANSMISSION_LINE)
            ? {
               filter: { active: false, voltages: [TL_VOLTAGE['345 - 499'], TL_VOLTAGE['500+']] },
               popup: TlPopup as any,
            }
            : undefined,
         zone: { filter: { active: true } },
      }),
      [hasPackage],
   );

   if (isos.length === 0 || iso === DATA_PROVIDER.Undefined) return null;

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Map" />}>
         <PowerMapProvider
            initialState={{
               bounds: INITIAL_BOUNDS,
               mapStyle: INITIAL_MAP_STYLE,
               capacityLayer: hasPackage(SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY) ? INITIAL_CAPACITY_LAYER : undefined,
               capacityStaticLayers: hasPackage(SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY) ? INITIAL_CAPACITY_STATIC_LAYER : undefined,
               nodeLayers: hasPackage(SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS) ? INITIAL_NODE_LAYERS : undefined,
               staticLayers: INITIAL_STATIC_LAYERS,
            }}
         >
            <HomeMap />
         </PowerMapProvider>
      </PrivatePageLayout>
   );
};
