// import { PowerMap } from 'power/components/maps';
import { ApiClients } from 'ApiClients';
import { IICAPMonthly, IICAPStripRequest } from 'power/types';
import { ICAP_SEASON, ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import Table from '../Table';

const ICAP_ZONES: ICAP_ZONE_NYISO[] = [ICAP_ZONE_NYISO.NYCA, ICAP_ZONE_NYISO.LI, ICAP_ZONE_NYISO.NYC, ICAP_ZONE_NYISO.GJ];

const MONTHS_SUMMER = [
   { id: 5, name: 'May' },
   { id: 6, name: 'Jun' },
   { id: 7, name: 'Jul' },
   { id: 8, name: 'Aug' },
   { id: 9, name: 'Sep' },
   { id: 10, name: 'Oct' },
];
const MONTHS_WINTER = [
   { id: 11, name: 'Nov' },
   { id: 12, name: 'Dec' },
   { id: 1, name: 'Jan' },
   { id: 2, name: 'Feb' },
   { id: 3, name: 'Mar' },
   { id: 4, name: 'Apr' },
];

type Props = {
  queryStrip: IICAPStripRequest;
  month: number;
};

const MonthlyTable: FC<Props> = ({ queryStrip, month }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPMonthly[][]>();

   useEffect(() => {
      setData(undefined);
      setLoading(true);

      const promises = ICAP_ZONES.map((zone) => ApiClients.getInstance().icapMonthlyDetail({ ...queryStrip, period_month: month, zone }));

      Promise.all(promises)
         .then((res) => {
            const newData = res.map((x) => {
               if (x.length === 6) {
                  if (queryStrip.period_season === ICAP_SEASON.SUMMER) {
                     x.sort((a, b) => a.month_index - b.month_index);
                  } else {
                     // 11, 12, 1, 2, 3, 4
                     x.sort((a, b) => {
                        if (a.month_index === 11) return -1;
                        if (b.month_index === 11) return 1;
                        if (a.month_index === 12) return -1;
                        if (b.month_index === 12) return 1;
                        return a.month_index - b.month_index;
                     });
                  }
                  return x;
               }
               return [];
            });
            setData(newData);
         })
         .finally(() => setLoading(false));
   }, [month, queryStrip]);

   const keys = ['Awarded (MW)', 'Price ($/kW - Month)'];

   const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] =
    data?.map((monthly, index) => ({
       zone: ICAP_ZONES[index],
       keys,
       allValues: monthly.map((x) => [x.awarded, x.price]),
    })) ?? [];

   return <Table headers={['Monthly', '', ...(queryStrip.period_season === ICAP_SEASON.SUMMER ? MONTHS_SUMMER.map((x) => x.name) : MONTHS_WINTER.map((x) => x.name))]} rows={rows} loadingSize={loading ? [734, 254] : undefined} />;
};

export default MonthlyTable;
