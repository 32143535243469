import { usePowerMap } from 'power/map/usePowerMap';
import { IBaseFilter, IPPFilter, ITLFilter } from 'power/types/map';
import React, { PropsWithChildren, useCallback } from 'react';
import { useMap } from 'react-map-gl';
import BaseSection from '../base/BaseSection';
import PowerPlantLayerControlContainer from './PowerPlantLayerControl/PowerPlantLayerControlContainer';
import StaticBaseLayerControlContainer from './StaticBaseLayerControl/StaticBaseLayerControlContainer';
import classes from './StaticLayersSection.module.scss';
import TransmissionLinesLayerControlContainer from './TransmissionLinesLayerControl/TransmissionLinesLayerControlContainer';

type Props = {
  mapId: string;
};

const StaticLayersSection: React.FC<PropsWithChildren<Props>> = ({ mapId }) => {
   const { [mapId]: map } = useMap();
   const { staticLayers, setStaticLayers } = usePowerMap();

   const setActive = useCallback(() => {
      setStaticLayers(
         (prev) => prev && {
            ...prev,
            allActive: !prev.allActive,
         },
      );
   }, [setStaticLayers]);

   const changeFilterTransmissionLines = useCallback(
      (filter: ITLFilter) => {
         setStaticLayers(
            (prev) => prev && {
               ...prev,
               transmissionLines: {
                  ...prev.transmissionLines,
                  filter,
               },
            },
         );
      },
      [setStaticLayers],
   );

   const changeFilterPowerPlant = useCallback(
      (filter: IPPFilter) => {
         setStaticLayers(
            (prev) => prev && {
               ...prev,
               powerPlants: {
                  ...prev.powerPlants,
                  filter,
               },
            },
         );
      },
      [setStaticLayers],
   );

   const changeFilterIQ = useCallback(
      (filter: IBaseFilter) => {
         setStaticLayers(
            (prev) => prev && {
               ...prev,
               iq: {
                  ...prev.iq,
                  filter,
               },
            },
         );
      },
      [setStaticLayers],
   );

   const changeFilterZone = useCallback(
      (filter: IBaseFilter) => {
         setStaticLayers(
            (prev) => prev && {
               ...prev,
               zone: {
                  ...prev.zone,
                  filter,
               },
            },
         );
      },
      [setStaticLayers],
   );

   if (staticLayers === undefined) return null;
   if (staticLayers?.iq === undefined && staticLayers?.powerPlants === undefined && staticLayers?.transmissionLines === undefined && staticLayers?.zone === undefined) return null;

   return (
      <BaseSection
         active={staticLayers.allActive ?? false} mapId={mapId} defaultCollapsed={staticLayers.defaultCollapsed ?? false}
         setActive={setActive} title="Map Layers">
         <div className={classes.tocContent}>
            {staticLayers.iq && staticLayers.iq?.filter && (
               <div className={classes.row}>
                  <StaticBaseLayerControlContainer
                     map={map} title="Interconnection Queue" filter={staticLayers.iq?.filter}
                     changeFilter={changeFilterIQ} />
               </div>
            )}
            {staticLayers.powerPlants && staticLayers.powerPlants?.filter && (
               <div className={classes.row}>
                  <PowerPlantLayerControlContainer map={map} filter={staticLayers.powerPlants?.filter} changeFilter={changeFilterPowerPlant} />
               </div>
            )}

            {staticLayers.transmissionLines && staticLayers.transmissionLines?.filter && (
               <div className={classes.row}>
                  <TransmissionLinesLayerControlContainer map={map} filter={staticLayers.transmissionLines?.filter} changeFilter={changeFilterTransmissionLines} />
               </div>
            )}

            {staticLayers.zone && staticLayers.zone.filter && (
               <div className={classes.row}>
                  <StaticBaseLayerControlContainer
                     map={map} title="Zone" filter={staticLayers.zone.filter}
                     changeFilter={changeFilterZone} />
               </div>
            )}
         </div>
      </BaseSection>
   );
};

export default StaticLayersSection;
