const API_ROUTES_V2 = {
   AUTH: {
      Login: 'token',
      ChangePassword: '/me/change_password',
      ForgotPassword: '/users/forgot_password',
      ResetPassword: '/users/reset_password',
      Logout: '/me/logout',
      Me: '/me',
   },
   ICAP: {
      Strip: 'icap/strip',
      StripDetail: 'icap/strip/detail',
      Spot: 'icap/spot',
      SpotDetail: 'icap/spot/detail',
      Monthly: 'icap/monthly',
      MonthlyDetail: 'icap/monthly/detail',
      CapacityParameters: '/icap/capacity_parameters',
      TimeseriesStrip: '/icap/strip/timeseries',
      TimeseriesSpot: '/icap/spot/timeseries',
      TimeseriesMonthly: '/icap/monthly/timeseries',
      Future: '/icap_forecast',
      FutureTimeseries: '/icap_forecast/timeseries',
   },
   FUTURE: {
      Price: 'future/price',
      Heatmap: 'future/heatmap',
      Timeseries: '/future/timeseries',
      Tbx: '/future/tbx',
      TBxTimeSeries: '/future/tbx/timeseries',
   },
   HEADROOM_CAPACITY: {
      Map: 'headroom_capacity/data',
      Substations: 'headroom_capacity/substations',
      SubstationsList: 'headroom_capacity/substations/list',
      SubstationsSearch: '/headroom_capacity/substation/search',
      Branch: 'headroom_capacity/branches',
      ConstraintsBySubstation: 'headroom_capacity/substation_constraints',
      ConstraintsByBranch: 'headroom_capacity/branch_constraints',
      Branches: '/headroom_capacity/branches/by_substation',
      StaticLayers: '/headroom_capacity/map/layers',
   },
   NEWS: {
      List: 'news',
   },
};

export default API_ROUTES_V2;
