// import { PowerMap } from 'power/components/maps';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  scaleValues: { position: 'top' | 'bottom'; label: string; minmax: number[] }[] | undefined;
  numberFormat?: (n: number) => number | string;
  backgroundColor?: string;
};

const Legend: FC<Props> = ({ scaleValues = [], numberFormat = (n: number) => n.toString(), backgroundColor }) => (
   <div className={classes.mapOverlay}>
      <div className={classes.inner}>
         <div className={classes.legend}>
            <div className={classes.legendItems}>
               {scaleValues
                  .filter((x) => x.position === 'top')
                  .map(({ label, minmax: [min, max] }) => (
                     <div key={`${label}`} className={classes.legendItem}>
                        <span>{numberFormat(min)}</span>
                        <span>{label}</span>
                        <span>{numberFormat(max)}</span>
                     </div>
                  ))}
            </div>
            <div className={classes.bar} style={backgroundColor ? {background: backgroundColor} : undefined} />
            <div className={classes.legendItems}>
               {scaleValues
                  .filter((x) => x.position === 'bottom')
                  .map(({ label, minmax: [min, max] }) => (
                     <div key={`${label}`} className={classes.legendItem}>
                        <span>{numberFormat(min)}</span>
                        <span>{label}</span>
                        <span>{numberFormat(max)}</span>
                     </div>
                  ))}
            </div>
         </div>
      </div>
   </div>
);

export default Legend;
