import { ICapacitySubstations, ISubstationInfoRequest, ISubstationInfoResponse } from 'power/types';
import { DATA_PROVIDER } from 'power/types/enum';
import { FC, useMemo } from 'react';
import InfoBar from './InfoBar';
import TabsContent from './Tabs';

type Props = {
  query: ISubstationInfoRequest;
  data: ICapacitySubstations;
};

const Container: FC<Props> = ({ query, data }) => {
   const substationInfoResponse: ISubstationInfoResponse = useMemo(() => ({
      substation: {
         id: data.substations[0].id,
         provider: DATA_PROVIDER.NYISO,
         identifier: data.substations[0].identifier,
         name: data.substations[0].name,
         voltage: data.substations[0].voltage,
         hubId: data.location.hubId,
         hubName: data.location.hubName,
         state: data.location.state,
         county: data.location.county,
         utility: data.location.utility,
         longitude: data.substations[0].lngLat[0],
         latitude: data.substations[0].lngLat[1],
         source: 1,
      },
   }), [data]);

   return (
      <>
         <InfoBar data={substationInfoResponse} />
         <div className="page-tab-container">
            <TabsContent query={query} data={substationInfoResponse} />
         </div>
      </>
   );
}

export default Container;
