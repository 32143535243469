// import { PowerMap } from 'power/components/maps';
import { Flex } from 'antd';
import { IICAPStripRequest } from 'power/types';
import { FC } from 'react';
import classes from './index.module.scss';
import MonthlyChart from './MonthlyTable';
import SpotChart from './SpotTable';
import StripChart from './StripChart';

type Props = {
  auctions: ('strip' | 'monthly' | 'spot')[];
  queryStrip: IICAPStripRequest;
  month?: number;
};

const ChartView: FC<Props> = ({ auctions, queryStrip, month }) => (
   <div className={classes.container}>
      <Flex justify="space-between" vertical>
         {auctions.includes('strip') && <StripChart queryStrip={queryStrip} />}
         {auctions.includes('spot') && month && <SpotChart queryStrip={queryStrip} month={month} />}
         {auctions.includes('monthly') && month && <MonthlyChart queryStrip={queryStrip} month={month} />}
      </Flex>
   </div>
);

export default ChartView;
