import { CustomControlPosition } from 'power/types/map';
import React from 'react';
import CustomOverlay from '../base/CustomOverlay';
import BaseLayerControlContainer from './BaseLayerControlContainer';

type Props = {
  position?: CustomControlPosition;
};
const BaseLayerControl: React.FC<Props> = ({ position }) => (
   <CustomOverlay position={position}>
      <BaseLayerControlContainer />
   </CustomOverlay>
);

export default BaseLayerControl;
