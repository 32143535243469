import LazyComponent from 'power/components/base/LazyComponent';
import { PowerCol, PowerRow } from 'power/components/base/grid';
import { INodeInfoRequest, INodeInfoResponse } from 'power/types';
import { FC, memo } from 'react';
import HistoricalBasisRisk from './components/HistoricalBasisRisk';
import InfoBoxes from './components/InfoBoxes';
import TimeSeries from './components/TimeSeries';

type Props = {
   query: INodeInfoRequest;
   data: INodeInfoResponse;
};

const BasisRiskTab: FC<Props> = ({ query, data }) => (
   <PowerRow gutter={[16, 16]}>
      <PowerCol
         xs={24} sm={24} md={24}
         lg={24}>
         <InfoBoxes nodeId={data.node.id} />
      </PowerCol>
      <PowerCol md={24} lg={16}>
         <LazyComponent>
            <TimeSeries nodeId={data.node.id} hubId={data.node.hubId} />
         </LazyComponent>
      </PowerCol>

      <PowerCol md={24} lg={8}>
         <LazyComponent>
            <HistoricalBasisRisk nodeId={data.node.id} hubId={data.node.hubId} />
         </LazyComponent>
      </PowerCol>

      {/* <PowerCol md={24} lg={12}>
            <LazyComponent>
               <BasisSpreadTimeSeries
                  nodeId={data.node.id} hubId={data.node.hubId} title={t('basisRiskOverview.windBasisRisk')}
                  component={PRICE_COMPONENT.BASISWIND} info={t('basisRiskOverview.windBasisRiskChartInfo')} />
            </LazyComponent>
         </PowerCol>

         <PowerCol md={24} lg={12}>
            <LazyComponent>
               <HistoricalSolarWind nodeId={data.node.id} hubId={data.node.hubId} />
            </LazyComponent>
         </PowerCol>

         <PowerCol md={24} lg={12}>
            <LazyComponent>
               <BasisSpreadTimeSeries
                  nodeId={data.node.id} hubId={data.node.hubId} title={t('basisRiskOverview.windBasisRisk')}
                  component={PRICE_COMPONENT.BASISWIND} info={t('basisRiskOverview.windBasisRiskChartInfo')} />
            </LazyComponent>
         </PowerCol>

         <PowerCol md={24} lg={12}>
            <LazyComponent>
               <BasisSpreadTimeSeries
                  nodeId={data.node.id} hubId={data.node.hubId} title={t('basisRiskOverview.windBasisRisk')}
                  component={PRICE_COMPONENT.BASISWIND} info={t('basisRiskOverview.windBasisRiskChartInfo')} />
            </LazyComponent>
         </PowerCol> */}
   </PowerRow>
);

export default memo(BasisRiskTab);
