/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AreaChartOutlined, BarChartOutlined, CloudDownloadOutlined, FullscreenExitOutlined, FullscreenOutlined, ReloadOutlined, TableOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Modal, Skeleton } from 'antd';
import clsx from 'clsx';
import { useContent } from 'content';
import JsFileDownloader from 'js-file-downloader';
import throttle from 'lodash/throttle';
import ErrorSVG from 'power/assets/svg/ErrorSVG';
import { ENV } from 'power/constants';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import InfoTooltip from './InfoTooltip';

type ViewMode = 'chart' | 'table';

export interface IBaseChartPanelProps {
  title: string;
  // blockquote?: string;
  className?: string;
  theme?: 'default' | 'green';
  size?: 'sm';
  status: 'loading' | 'noData' | 'success' | 'error';

  downloadURL?: string;

  refreshClick?: () => void;

  defaultViewMode: ViewMode;

  multipleViewMode?: boolean;

  fullScreen?: boolean;

  message?: string;

  info?: ReactNode;

  children: FC<{ viewMode: ViewMode; isFullScreen: boolean }>;

  minHeight?: number;
  disableRefresh?: boolean;
}

const CardPanel = ({
   title,
   className,
   theme = 'default',
   size,
   status,
   downloadURL,
   refreshClick,
   defaultViewMode = 'chart',
   fullScreen,
   //   blockquote,
   info,
   message,
   minHeight,
   multipleViewMode,
   children,
   disableRefresh,
}: IBaseChartPanelProps) => {
   const [scrollTop, setScrollTop] = useState(0);

   const { t } = useContent();

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [modal, contextHolder] = Modal.useModal();

   const [render, setRender] = useState(true);

   const [_viewMode, _setViewMode] = useState<ViewMode>(defaultViewMode);

   const fullScreenHandle = useFullScreenHandle();

   const enterFullScreen = useCallback(() => {
      setScrollTop(window.scrollY);
      fullScreenHandle.enter();
   }, [fullScreenHandle]);

   const exitFullScreen = useCallback(() => {
      fullScreenHandle.exit().then(() => {
         setTimeout(() => window.scrollTo({ top: scrollTop }), 100);
      });
   }, [fullScreenHandle, scrollTop]);

   const onRefresh = useMemo(
      () => throttle(() => {
         _setViewMode(defaultViewMode);

         setRender(false);

         setTimeout(() => {
            refreshClick?.();

            setRender(true);
         }, 50);
      }, 1000),
      [defaultViewMode, refreshClick],
   );

   return (
      <FullScreen handle={fullScreenHandle} className={clsx(`card-panel card-panel-${theme}`, className, size && !fullScreenHandle.active && `card-panel-${size}`)}>
         <div className="card-panel-header">
            <h2 className="card-panel-title">{title}</h2>

            <div className="card-panel-toolbar">
               {multipleViewMode && (
                  <>
                     {_viewMode === 'chart' && (
                        <span className="cp-toolbar-item" role="button" onClick={() => _setViewMode('table')}>
                           <TableOutlined />
                        </span>
                     )}

                     {_viewMode === 'table' && (
                        <span className="cp-toolbar-item" role="button" onClick={() => _setViewMode('chart')}>
                           <AreaChartOutlined />
                        </span>
                     )}
                  </>
               )}

               {!disableRefresh && (
                  <span className="cp-toolbar-item" role="button" onClick={onRefresh}>
                     <ReloadOutlined />
                  </span>
               )}

               {!!downloadURL && status !== 'noData' && (
                  <span
                     className="cp-toolbar-item"
                     role="button"
                     onClick={() => {
                        new JsFileDownloader({
                           url: ENV.BASE_API_URL + downloadURL,
                           autoStart: true,
                           filename: (ENV.BASE_API_URL + downloadURL).includes('format=') ? `${title}.${(ENV.BASE_API_URL + downloadURL).split('format=').pop()}` : undefined,
                        }).catch(() => {
                           modal.error({
                              title: 'Download Error!',
                              content: 'An unexpected error occurred while processing your request. Our team noticed this issue. We will fix it as soon as possible. Please try again later.',
                              style: {
                                 top: '35%',
                              },
                           });
                        });
                     }}
                  >
                     <CloudDownloadOutlined />
                  </span>
               )}

               {fullScreen && (
                  <span className="cp-toolbar-item" role="button" onClick={fullScreenHandle.active ? exitFullScreen : enterFullScreen}>
                     {fullScreenHandle.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  </span>
               )}

               {info && <InfoTooltip title={title} info={info} />}
            </div>
         </div>

         {contextHolder}

         <div className="card-panel-content" style={{ minHeight }}>
            {status === 'loading' && (
               <>
                  <div className="card-panel-blocker">
                     <Skeleton.Node active fullSize /* rootClassName="u-full-width" */ style={{ height: 400, width: 800 }}>
                        <BarChartOutlined style={{ fontSize: 300, color: '#bfbfbf' }} />
                     </Skeleton.Node>
                  </div>

                  {/* <div className="card-panel-blocker">
                  <div className="panel-loader" />

                  <div className="card-panel-blocker-message">{t('base.loading')}</div>
               </div> */}
               </>
            )}

            {status === 'error' && (
               <div className="card-panel-error">
                  <div className="cp-error-icon">
                     <WarningFilled />
                  </div>

                  <div className="card-panel-blocker-message">
                     {message || t('base.serviceError')}

                     {!disableRefresh && (
                        <div className="cp-refresh-button">
                           <Button role="button" onClick={onRefresh} icon={<ReloadOutlined />}>
                    Refresh
                           </Button>
                        </div>
                     )}
                  </div>
               </div>
            )}

            {status === 'noData' && (
               <div className="card-panel-no-data">
                  <div className="cp-no-data-icon">
                     <ErrorSVG />
                  </div>

                  <div className="card-panel-blocker-message">
                     {message || t('base.noResults')}

                     {!disableRefresh && (
                        <div className="cp-refresh-button">
                           <Button role="button" onClick={onRefresh} icon={<ReloadOutlined />}>
                    Refresh
                           </Button>
                        </div>
                     )}
                  </div>
               </div>
            )}

            {render && children({ viewMode: _viewMode, isFullScreen: fullScreenHandle.active })}
         </div>
      </FullScreen>
   );
};

export default CardPanel;
