/* eslint-disable max-len */
import { PPDetailContent } from './type';

const en: PPDetailContent = {
   title: 'Power Plant Detail',
   selectedAreaFuelType: 'Selected Area Fuel Type',
   selectedAreaFuelTypeInfo: 'The Selected Area Fuel Type chart shows capacity information for fuel types in the selected region. MW or MWh-based data presentation selection can be made with the slider.',
   generatorInfo: 'Generator Info',
   generatorInfoInfo: 'The Generator Info table provides details such as Generator ID, Nameplate Capacity (MW), Net Summer Capacity (MW), Net Winter Capacity (MW), Technology, Energy Source Code, Prime Mover Code, Operating Month, Operating Year, etc. for the selected power plant.',
   historicalPlantCharacteristics: 'Historical Plant Characteristics',
   historicalPlantCharacteristicsInfo: 'The Historical Plant Characteristics chart displays historical data of capacity, net production, and capacity factor for the selected power plant. Data range can be adjusted with the \'Year\' slider.',
   historicalPlantEmission: 'Historical Plant Emission',
   historicalPlantEmissionInfo: 'The Historical Plant Emission chart displays the historical SO2, CO2, and NOx emissions from the power plant on a monthly basis, measured in mass (short tons) and rate (lbs/mmBtu). The user can access the data of the relevant year by using the \'Year\' filter.',
};

export default en;
