import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Select } from 'antd';
import { PowerCol } from 'components/ui';
import { PowerRow } from 'power/components/base/grid';
import { IICAPStripRequest } from 'power/types';
import { ICAP_SEASON } from 'power/types/enum';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  auctions: ('strip' | 'monthly' | 'spot')[];
  query: IICAPStripRequest;
  month?: number;
  onSubmit: (auctions: ('strip' | 'monthly' | 'spot')[], query: IICAPStripRequest, month?: number) => void;
};

const ICAPMapFilter: FC<Props> = ({ loading, auctions, query, month, onSubmit }) => {
   const [formAuctions, setFormAuctions] = useState<('strip' | 'monthly' | 'spot')[]>(auctions);
   const [formFilter, setFormFilter] = useState<IICAPStripRequest>(query);
   const [formMonth, setFormMonth] = useState<number | undefined>(month);

   const justStrip = formAuctions.length === 1 && formAuctions[0] === 'strip';

   const onChangeAuctions = useCallback(
      (values: ('strip' | 'monthly' | 'spot')[]) => {
         setFormAuctions(values);

         const newJustStrip = values.length === 1 && values[0] === 'strip';
         if (newJustStrip) {
            setFormMonth(undefined);
         } else {
            if (formMonth === undefined) {
               if (formFilter.period_season === ICAP_SEASON.WINTER) setFormMonth(WINTER_MONTHS[0].value);
               else if (formFilter.period_season === ICAP_SEASON.SUMMER) setFormMonth(SUMMER_MONTHS[0].value);
            }
         }
      },
      [formFilter.period_season, formMonth],
   );

   const onChangeYear = useCallback((value: number) => {
      setFormFilter((prev) => ({ ...prev, period_year: value }));
   }, []);

   const onChangeSeason = useCallback(
      (value: ICAP_SEASON) => {
         setFormFilter((prev) => ({ ...prev, period_season: value }));

         if (justStrip) setFormMonth(undefined);
         else {
            if (value === ICAP_SEASON.SUMMER) {
               setFormMonth(SUMMER_MONTHS[0].value);
            }
            if (value === ICAP_SEASON.WINTER) {
               setFormMonth(WINTER_MONTHS[0].value);
            }
         }
      },
      [justStrip],
   );

   const onChangeMonth = useCallback((value: number | undefined) => {
      setFormMonth(value);
   }, []);

   const onFormSubmit = useCallback(() => {
      onSubmit(formAuctions, formFilter, formMonth);
   }, [formAuctions, formFilter, formMonth, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={4} xl={6} lg={7}
            xs={6} style={{ borderLeft: '2px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Auction Type:</span>
               <Select
                  variant="borderless" placeholder="Select auction" mode="multiple"
                  allowClear value={formAuctions} disabled={loading}
                  onChange={(values) => onChangeAuctions(values as ('strip' | 'monthly' | 'spot')[])}>
                  <Select.Option value="strip">Strip</Select.Option>
                  <Select.Option value="spot">Spot</Select.Option>
                  <Select.Option value="monthly">Monthly</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Capability Year:</span>
               <Select
                  variant="borderless" placeholder="Select year" value={formFilter.period_year}
                  disabled={loading} onChange={onChangeYear} options={Array.from({ length: 19 }, (_, i) => ({ label: `${2024 - i}/${2024 - i + 1}`, value: 2024 - i }))}></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Season:</span>
               <Select
                  variant="borderless"
                  placeholder="Select season"
                  value={formFilter.period_season}
                  disabled={loading}
                  onChange={onChangeSeason}
                  options={[
                     /* { label: 'All', value: null }, */
                     { label: 'Summer', value: ICAP_SEASON.SUMMER },
                     { label: 'Winter', value: ICAP_SEASON.WINTER },
                  ]}
               ></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={3} lg={3}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Month:</span>
               <Select
                  variant="borderless" placeholder="All" value={formMonth}
                  disabled={loading || justStrip} onChange={onChangeMonth} options={justStrip ? [] : [...(formFilter.period_season === ICAP_SEASON.SUMMER ? SUMMER_MONTHS : WINTER_MONTHS)]}></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '2px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}
               disabled={formAuctions.length === 0}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default ICAPMapFilter;

const SUMMER_MONTHS = [
   { label: 'May', value: 5 },
   { label: 'Jun', value: 6 },
   { label: 'Jul', value: 7 },
   { label: 'Aug', value: 8 },
   { label: 'Sep', value: 9 },
   { label: 'Oct', value: 10 },
];

const WINTER_MONTHS = [
   { label: 'Nov', value: 11 },
   { label: 'Dec', value: 12 },
   { label: 'Jan', value: 1 },
   { label: 'Feb', value: 2 },
   { label: 'Mar', value: 3 },
   { label: 'Apr', value: 4 },
];
