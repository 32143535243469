import { Buffer } from 'buffer';
import { IJwtPayload } from 'power/types';

global.Buffer = global.Buffer || Buffer;

const parseJwtToken = (jwtToken: string): IJwtPayload | undefined => {
   try {
      const base64Url: string = jwtToken.split('.')[1];

      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      const encodedURIComponent: string = Buffer.from(base64, 'base64').toString();

      const jsonPayload: string = decodeURIComponent(encodedURIComponent);

      return JSON.parse(jsonPayload);
   } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error('Invalid jwtToken token', error);
      return undefined;
   }
};

export default parseJwtToken;
