import { ApiClients } from 'ApiClients';
import { ILocationSimple } from 'power/types';
import { DATA_PROVIDER, MARKET_TYPE, PRICE_COMPONENT } from 'power/types/enum';
import { useCallback, useEffect, useState } from 'react';
import { TimeSeriesTest } from './Requests/basisRisk/TimeSeries';
import { DartTimeSeriesTest } from './Requests/lmp/DartTimeSeries';
import { LMPSummaryTest } from './Requests/lmp/LMPSummary';

const NodeTab = () => {
   const [nodes, setNodes] = useState<ILocationSimple[]>();
   const selectNodeFethcer = useCallback(() => ApiClients.getInstance().nodeList({ iso: DATA_PROVIDER.NYISO }), []);

   useEffect(() => {
      selectNodeFethcer().then((data) => setNodes(data));
   }, [selectNodeFethcer]);

   if (!nodes) return null;

   return (
      <div>
         <h3>Total Node Count: {nodes.length}</h3>

         <h4>LMP Data</h4>
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.LMP} marketType={MARKET_TYPE.DayAhead} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.LMP} marketType={MARKET_TYPE.Realtime} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCE} marketType={MARKET_TYPE.DayAhead} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCE} marketType={MARKET_TYPE.Realtime} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCC} marketType={MARKET_TYPE.DayAhead} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCC} marketType={MARKET_TYPE.Realtime} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCL} marketType={MARKET_TYPE.DayAhead} />
         <DartTimeSeriesTest nodes={nodes} component={PRICE_COMPONENT.MCL} marketType={MARKET_TYPE.Realtime} />
         <LMPSummaryTest nodes={nodes} />

         <h4>Basis Risk Data</h4>
         <TimeSeriesTest nodes={nodes} marketType={MARKET_TYPE.DayAhead} />
         <TimeSeriesTest nodes={nodes} marketType={MARKET_TYPE.Realtime} />
      </div>
   );
};

export default NodeTab;
