// import { PowerMap } from 'power/components/maps';
import { Select } from 'antd';
import { ApiClients } from 'ApiClients';
import { IICAPStripRequest } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import Chart from '../Chart';

const ICAP_ZONES: ICAP_ZONE_NYISO[] = [ICAP_ZONE_NYISO.NYCA, ICAP_ZONE_NYISO.LI, ICAP_ZONE_NYISO.NYC, ICAP_ZONE_NYISO.GJ];
const OPTIONS = [
   { label: 'Price ($/kW - Month)', value: 'price' },
   { label: 'Awarded (MW)', value: 'awarded' },
];

type Props = {
  queryStrip: IICAPStripRequest;
  month: number;
};

const MonthlyChart: FC<Props> = ({ queryStrip, month }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<any[]>([]);
   const [yField, setYField] = useState<string>(OPTIONS[0].value);

   useEffect(() => {
      setLoading(true);
      setData([]);

      const promises = ICAP_ZONES.map((zone) => ApiClients.getInstance().icapTimeseriesMonthly({ zone }));

      Promise.all(promises)
         .then((res) => {
            const d = res.flat();
            const d2 = d.map((item) => ({ ...item, x: `${item.period_year}-${item.period_month.toString().padStart(2, '0')}-01T00:00:00.000` }));
            setData(d2);
         })
         .finally(() => setLoading(false));
   }, [queryStrip]);

   return (
      <Chart
         select={(
            <Select
               value={yField} options={OPTIONS} onChange={(value) => setYField(value)}
               style={{ minWidth: 180, textAlign: 'right' }} variant="borderless" size="small" />
         )}
         title="Monthly"
         loadingSize={loading ? [352, 254] : undefined}
         data={data}
         yField={yField}
         yTitle={OPTIONS.find((o) => o.value === yField)?.label}
         xTitle="Month"
      />
   );
};

export default MonthlyChart;
