import { SymbolLayout, SymbolPaint } from 'mapbox-gl';
import { FUEL_TYPE } from 'power/types/enum';

/* enum PowerPlantCapacityClass {
  // if <0 = unknown
  'UNKNOWN' = 'UNKNOWN',
  '<10' = '<10',
  '20-29' = '20-29',
  '30-39' = '30-39',
  '40-49' = '40-49',
  '50-99' = '50-99',
  '100-149' = '100-149',
  '150-249' = '150-249',
  '250-499' = '250-499',
  '500-749' = '500-749',
  '750-999' = '750-999',
  '1000-2499' = '1000-2499',
  '2500-3499' = '2500-3499',
  '3500-4799' = '3500-4799',
  '>4800' = '>4800',
} */

const scale = 0.3;
const LAYOUT: SymbolLayout = {
   'icon-size': [
      'match',
      ['get', 'c_class'],
      'UNKNOWN',
      0.8 * scale,
      '<10',
      0.8 * scale,
      '20-29',
      0.85 * scale,
      '30-39',
      0.9 * scale,
      '40-49',
      0.95 * scale,
      '50-99',
      1 * scale,
      '100-149',
      1.05 * scale,
      '150-249',
      1.1 * scale,
      '250-499',
      1.15 * scale,
      '500-749',
      1.2 * scale,
      '750-999',
      1.25 * scale,
      '1000-2499',
      1.3 * scale,
      '2500-3499',
      1.35 * scale,
      '3500-4799',
      1.4 * scale,
      '>4800',
      1 * scale,
      1 * scale,
   ],
   'icon-image': [
      'case',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Wind],
      'Wind-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Solar],
      'Solar-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Coal],
      'Coal-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Gas],
      'Gas-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Hydro],
      'Hydroelectric-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Nuclear],
      'Nuclear-image-powerdev',
      // ['==', ['get', 'b_f_type'], FUEL_TYPE.Hybrid],
      // 'Hybrid-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Oil],
      'Oil-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Battery],
      'Battery-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.OtherStorage],
      'OtherStorage-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.BioMass],
      'Biomass-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Thermal],
      'Geothermal-image-powerdev',
      // ['==', ['get', 'b_f_type'], FUEL_TYPE.ACTransmission],
      // 'ACTransmission-image-powerdev',
      // ['==', ['get', 'b_f_type'], FUEL_TYPE.DCTransmission],
      // 'DCTransmission-image-powerdev',
      ['==', ['get', 'b_f_type'], FUEL_TYPE.Other],
      'Other-image-powerdev',
      'default-icon',

      // ['==', ['get', 'b_f_type'], 13],
      // 'PumpedStorage-image-powerdev',
   ],
   'icon-allow-overlap': true,
};

const PAINT: SymbolPaint = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
