/* eslint-disable max-len */
import { IMAGE_PATHS } from 'power/constants';
import addMapImages from 'power/map/helper/addMapImages';
import { IICAPCapacityParameters } from 'power/types';
import { ICAP_ZONE_NYISO, VECTOR_LAYER_ID } from 'power/types/enum';
import { utils } from 'power/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { geojsonCapacityParameters } from './geojson';

const { layerId, layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.ICAP_ZONE);
const symbolLayerId = `${layerId}-points-capacity-parameters-symbol-1`;
const symbolLayerId2 = `${layerId}-points-capacity-parameters-symbol-2`;
const symbolLayerId3 = `${layerId}-points-capacity-parameters-symbol-3`;

const images = [{ name: 'info-rectangle', url: IMAGE_PATHS.MAP_ASSETS.FILE('info-rectangle.png') }];

type Props = {
  mapId: string;
  data?: IICAPCapacityParameters[];
};

const ICAPCapacityParametersLayer: React.FC<Props> = ({ mapId, data }) => {
   const [loadingImages, setLoadingImages] = useState<boolean>(true);

   const { [mapId]: map } = useMap();

   const geojson = useMemo(() => geojsonCapacityParameters(data), [data]);

   const installedReserveMargin = data?.find((d) => d.zone === ICAP_ZONE_NYISO.NYCA && d.installed_reserve_margin)?.installed_reserve_margin;

   useEffect(() => {
      const loadImgaes = () => {
         setLoadingImages(true);
         map && addMapImages(map, images).then(() => setLoadingImages(false));
      };

      if (map) {
         map.on('style.load', loadImgaes);
      }

      return () => {
         if (map) {
            map.off('style.load', loadImgaes);
         }
      };
   }, [map]);

   if (!map || !geojson || loadingImages) return null;

   return (
      <>
         <Source id={`${layerId}-points-capacity-parameters`} type="geojson" data={geojson}>
            <Layer
               id={symbolLayerId}
               type="symbol"
               layout={{
                  'icon-image': 'info-rectangle',
                  'icon-size': 0.08,
                  'icon-offset': [0, 0],
                  'icon-allow-overlap': true,
               }}
               minzoom={layerMinzoom}
               maxzoom={layerMaxzoom}
            />
            <Layer
               id={symbolLayerId2}
               type="symbol"
               layout={{
                  'text-field': ['concat', 'TSL   ', ['concat', ['coalesce', ['get', 'transmission_security_limit'], ' N/A'], ' %']],
                  'text-offset': [0, 0],
                  'text-anchor': 'bottom',
                  'text-allow-overlap': true,
                  'text-size': 13,
               }}
               paint={{
                  'text-color': '#ffffff',
                  'text-halo-color': '#ffffff',
                  'text-halo-width': 0.1,
               }}
            />
            <Layer
               id={symbolLayerId3}
               type="symbol"
               layout={{
                  'text-field': ['concat', 'LCR   ', ['concat', ['coalesce', ['get', 'capacity_requirement'], ' N/A'], ' %']],
                  'text-offset': [0, 0],
                  'text-anchor': 'top',
                  'text-allow-overlap': true,
                  'text-size': 13,
               }}
               paint={{
                  'text-color': '#ffffff',
                  'text-halo-color': '#ffffff',
                  'text-halo-width': 0.1,
               }}
            />
         </Source>
         <div
            style={{ position: 'absolute', /* zIndex: 100, */ width: 315, textAlign: 'center', top: 12, right: 80, backgroundColor: 'white', padding: '10px 14px', borderRadius: '0.5rem', boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.16)', fontFamily: 'Inter', fontSize: 14, fontWeight: 500, color: '#665b5b' }}
         >
        Installed Reserve Margin: <span style={{ fontWeight: 700, color: 'black' }}>{installedReserveMargin}%</span>
         </div>
      </>
   );
};

export default ICAPCapacityParametersLayer;
