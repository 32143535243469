class ValueColorLineer {
   private values: number[];

   private min: number;

   private max: number;

   private center: number;

   constructor(values: number[]) {
      this.values = values;
      this.min = Math.min(...values);
      this.max = Math.max(...values);
      this.center = (this.min + this.max) / 2;
   }

   /* // green #00aa00 = rgb(0, 170, 0)
   // yellow #dddd00 = rgb(221, 221, 0)
   // red #aa0000 = rgb(170, 0, 0)
   public getColor = (value: number): string => {
      const ratio = (value - this.min) / (this.max - this.min);
      const green = Math.floor((1 - ratio) * 170);
      const red = Math.floor(ratio * 170);
      return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}00`;
   }; */

   // green #00aa00 = rgb(0, 170, 0)
   // yellow #dddd00 = rgb(221, 221, 0)
   // red #aa0000 = rgb(170, 0, 0)
   public getColor = (value: number): string => {
      const index = this.values.indexOf(value);
      const ratio = Math.floor((100 * index) / this.values.length) / 100;
      const green = Math.floor((1 - ratio) * 170);
      const red = Math.floor(ratio * 170);
      return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}00`;
   };

   public getColors(): string[] {
      return this.values.map(this.getColor);
   }
}

export default ValueColorLineer;
