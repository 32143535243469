import { FillLayout, FillPaint } from 'mapbox-gl';

const LAYOUT: FillLayout = {};

const PAINT: FillPaint = {
   'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#39C41D', '#c5e89d'],
   'fill-outline-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#7EF759', '#39C41D'],
   'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.3],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
