import { BranchInfo } from 'power/types';
import { FC } from 'react';
import sprintPng from './assets/sprint.png';
import summerPng from './assets/summer.png';
import winterPng from './assets/winter.png';

import classes from './CardTabs.module.scss';

type Props = {
  title: string;
  branchInfos?: BranchInfo[];
  selectedBranchId?: string;
  setSelectedBranchId: (id: string) => void;
};

const BranchCardTabs: FC<Props> = ({ title, branchInfos, selectedBranchId, setSelectedBranchId }) => (
   <>
      <div className={classes.title}>{title}</div>
      <div className={classes.tabs}>
         {branchInfos?.map((branchInfo) => (
            <div
               key={branchInfo.id} className={`${classes.tab} info-box`} onClick={() => setSelectedBranchId(branchInfo.id)}
               style={{ backgroundColor: selectedBranchId === branchInfo.id ? '#f3fff7' : 'white' }}>
               <div className={classes.substationInfo}>
                  <div className={classes.left}>
                     <div>
                        <div className={classes.identifier}>[{branchInfo.id}]</div>
                        <div className={classes.name}>{branchInfo.name}</div>
                     </div>
                  </div>
               </div>
               <div className={classes.capacities}>
                  <div className={classes.header}>Available Capacity</div>
                  <div className={classes.body}>
                     <div className={classes.left}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={summerPng} alt="Summer Peak" />
                           <span className={classes.iconText}>Summer Peak</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.summerPeak.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branchInfo.refurbishmentCosts.summerPeak.charging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.summerPeak.discharging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branchInfo.refurbishmentCosts.summerPeak.discharging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={classes.right}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={sprintPng} alt="Spring Light" />
                           <span className={classes.iconText}>Spring Light</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.lightLoad.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branchInfo.refurbishmentCosts.lightLoad.charging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.lightLoad.discharging}
                                 <span className={classes.unit2}> MW</span>
                                 <div className={classes.value}>
                        ${branchInfo.refurbishmentCosts.lightLoad.discharging?.toFixed(1)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={classes.right}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={winterPng} alt="Winter Peak" />
                           <span className={classes.iconText}>Winter Peak</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.winterPeak?.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branchInfo.refurbishmentCosts.winterPeak?.charging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {branchInfo.capacities.winterPeak?.discharging}
                                 <span className={classes.unit2}> MW</span>
                                 <div className={classes.value}>
                        ${branchInfo.refurbishmentCosts.winterPeak?.discharging?.toFixed(1)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ))}
      </div>
   </>
);

export default BranchCardTabs;
