import { ApiClients } from 'ApiClients';
import { FutureTbxTimeseries, FutureTbxTimeseriesRequest } from 'power/types';
import { useCallback, useState } from 'react';

const useFutureTbxTimeseries = () => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<FutureTbxTimeseries[]>([]);
  
   const fetch = useCallback((query: FutureTbxTimeseriesRequest) => {
      setLoading(true);
      setData([]);

      ApiClients.getInstance().futureTbxTimeseries(query).then((res) => {
         setData(res);
      }).finally(() => {
         setLoading(false);
      });
   }, []);

   return { loading, data, fetch };
}

export default useFutureTbxTimeseries;