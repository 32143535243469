import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import { NodeAnalysis } from 'modules/NodeAnalysis';
import PATHS from 'power/PATHS';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const NodeAnalysisPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.NodeAnalysis, title: 'Node Analysis' });
   
   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Node Analysis" />}>
         <NodeAnalysis />
      </PrivatePageLayout>
   );

}
