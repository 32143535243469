/* eslint-disable max-len */
import { IQDashboardContent } from './type';

const en: IQDashboardContent = {
   title: 'Interconnection Queue: Detailed',
   projectByFuelType: 'Project by IQ Type',
   projectByFuelTypeInfo: 'The Project by IQ Type chart shows the number and capacity of interconnection requests by fuel type. The data can be further explored by utilizing the filters at the top of the page.',
   capacityMW: 'Capacity (MW)',
   projectByFuelTypeAndIRDate: 'Project by Fuel Type & IR Date',
   projectByFuelTypeAndIRDateInfo: 'The Project by Fuel Type & IR Date chart displays the interconnection requests categorized by the fuel type and interconnection request date. The data can be explored in depth by the filters at the top of the page.',
   projectByFuelTypeAndIRDateAlert: 'In this chart, the logarithmic y-axis scale compresses larger values and expands smaller values, allowing for visualization of data across multiple orders of magnitude and emphasizing relative changes.',
   iqRequests: 'Interconnection Queue Requests',
   iqRequestsInfo:
    'This table presents crucial data for interconnection request projects, including the request date, project name, state, county, transmission owner, interconnection point, status, fuel type, capacity, etc. Projects can be easily searched, sorted, and explored based on your preferences. Additionally, the view can be refined by utilizing the filters at the top of the page. For a closer look at individual interconnection request details, click \'Details\' at the end of the row.',
};

export default en;
