import { Spin, Typography } from 'antd';
import { ApiClients } from 'ApiClients';
import { IIQDetail } from 'power/types';
import { DATA_PROVIDER, FUEL_TYPE, IQ_STATUS, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';

const InfoItem: FC<{ label: string; value: any }> = ({ label, value }) => (
   <div className="map-popup-row">
      <div className="map-popup-row-label">
         <Typography.Text>{label}</Typography.Text>
      </div>
      <div className="map-popup-row-value">{value}</div>
   </div>
);

/* const InfoItemInner: FC<{ label: string; value: any }> = ({ label, value }) => (
   <div className="map-popup-row">
      <div className="map-popup-row-label-inner">{label}</div>
      <div className="map-popup-row-value">{value}</div>
   </div>
); */

type Props = {
  id: number;
};

const IqPopup: FC<Props> = ({ id }) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<IIQDetail>();

   useEffect(() => {
      ApiClients.getInstance()
         .mapIqDetail(id)
         .then((data) => {
            setData(data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [id]);

   if (loading || data === undefined)
      return (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 300, height: 340 }}>
            <Spin size="large" />
         </div>
      );

   return (
      <div style={{ /* paddingTop: 10,  */ width: 300 }}>
         <h3 style={{ textAlign: 'center', marginTop: 0 }}>Interconnection Queue</h3>
         <div className="custom-popup">
            {/* <InfoItem label="Queue ID" value={data.queuePosition} /> */}
            <InfoItem label="Project Name" value={data.projectName} />
            <InfoItem label="POI" value={data.interconnectionPoint} />
            <InfoItem label="Utility" value={data.utility} />
            <InfoItem label="County" value={data.county} />
            <InfoItem label="State" value={data.state} />
            <InfoItem label="Fuel Type" value={FUEL_TYPE[data.fuelType1]} />
            <InfoItem label="Request Date" value={data.requestDate && parseDateByTimeGranularity(data.requestDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Expected COD" value={data.commercialOperationDate && parseDateByTimeGranularity(data.commercialOperationDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="In Service Date" value={data.inServiceDate && parseDateByTimeGranularity(data.inServiceDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Last Updated" value={data.lastUpdatedDate && parseDateByTimeGranularity(data.lastUpdatedDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Status" value={IQ_STATUS[data.status]} />
            {/* <InfoItem label="ISO Status" value={data.statusText} /> */}
            <InfoItem label="Status Desc" value={data.statusText} />
            {/* <InfoItem label="Technology Type" value={data.type1} /> */}
            <InfoItem label="Capacity (MW)" value={data.mw1} />
            {/* <InfoItem label="Capacity Type 2" value={data.mw2} /> */}

            <InfoItem label="ISO" value={DATA_PROVIDER[data.provider]} />

            {/* <PowerCol xs={24} md={12} style={{ padding: 15 }}>
               <InfoItem label="Status" value={data.status} />
               <InfoItem label="ISO Status" value={data.statusText} />
               <div style={{ marginTop: '10%' }} />
               <InfoItem label="Fuel Type" value="" />
               <InfoItemInner label="Type 1" value={data.fuelType1} />
               <InfoItem label="Technology Type" value="" />
               <InfoItemInner label="Type 1" value={data.type1} />
               <InfoItem label="Capacity Type" value="" />
               <InfoItemInner label="Type 1" value={data.mw1} />
               <InfoItemInner label="Type 2" value={data.mw2} />
            </PowerCol> */}
         </div>
      </div>
   );
};

export default IqPopup;
