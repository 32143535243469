import React from 'react';
import classes from './MainLogo.module.scss';

export const MainLogo: React.FC<{ min: boolean }> = ({ min }) => {
   const src = min ? '/img/favicon_base.png' : '/img/powerdev_96_48.png';

   return (
      <div className={classes.mainLogo}>
         <img src={src} alt="PowerDev" />
      </div>
   );
};
