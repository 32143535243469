import { FeatureCollection, Point } from 'geojson';
import { IICAPCapacityParameters, IICAPMonthly, IICAPSpot, IICAPStrip } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';

const baseGeojson: FeatureCollection<Point, any> = {
   type: 'FeatureCollection',
   features: [
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-75.5, 43.2],
         },
         properties: {
            id: 1,
            name: ICAP_ZONE_NYISO.NYCA,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-74.1, 41.75],
         },
         properties: {
            id: 2,
            name: ICAP_ZONE_NYISO.GJ,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-74.1, 40.6],
         },
         properties: {
            id: 3,
            name: ICAP_ZONE_NYISO.NYC,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-72.8, 40.9],
         },
         properties: {
            id: 4,
            name: ICAP_ZONE_NYISO.LI,
         },
      },
   ],
};
const baseGeojsonInfoBox: FeatureCollection<Point, any> = {
   type: 'FeatureCollection',
   features: [
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-76.7, 43.4],
         },
         properties: {
            id: 1,
            name: ICAP_ZONE_NYISO.NYCA,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-75.3, 41.9],
         },
         properties: {
            id: 2,
            name: ICAP_ZONE_NYISO.GJ,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-75.3, 40.8],
         },
         properties: {
            id: 3,
            name: ICAP_ZONE_NYISO.NYC,
         },
      },
      {
         type: 'Feature',
         geometry: {
            type: 'Point',
            coordinates: [-71.6, 41.1],
         },
         properties: {
            id: 4,
            name: ICAP_ZONE_NYISO.LI,
         },
      },
   ],
};

export const geojsonStrip = (data?: IICAPStrip[]) => {
   if (!data || !data.length) return undefined;

   const geojson: FeatureCollection<Point, IICAPStrip & { id: number; name: ICAP_ZONE_NYISO }> = { ...baseGeojson };
   const zoneNames = data.map((x) => x.zone);

   geojson.features = geojson.features.filter((feature) => zoneNames.includes(feature.properties.name));

   geojson.features.forEach((feature) => {
      feature.properties = { ...feature.properties, ...data.find((x) => x.zone === feature.properties.name) };
   });

   return geojson;
};

export const geojsonSpot = (data?: IICAPSpot[]) => {
   if (!data || !data.length) return undefined;

   const geojson: FeatureCollection<Point, IICAPSpot & { id: number; name: ICAP_ZONE_NYISO }> = { ...baseGeojson };
   const zoneNames = data.map((x) => x.zone);

   geojson.features = geojson.features.filter((feature) => zoneNames.includes(feature.properties.name));

   geojson.features.forEach((feature) => {
      feature.properties = { ...feature.properties, ...data.find((x) => x.zone === feature.properties.name) };
   });

   return geojson;
};

export const geojsonMonthly = (data?: IICAPMonthly[]) => {
   if (!data || !data.length) return undefined;

   const geojson: FeatureCollection<Point, IICAPMonthly & { id: number; name: ICAP_ZONE_NYISO }> = { ...baseGeojson };
   const zoneNames = data.map((x) => x.zone);

   geojson.features = geojson.features.filter((feature) => zoneNames.includes(feature.properties.name));

   geojson.features.forEach((feature) => {
      feature.properties = { ...feature.properties, ...data.find((x) => x.zone === feature.properties.name) };
   });

   return geojson;
};

export const geojsonCapacityParameters = (data?: IICAPCapacityParameters[]) => {
   if (!data || !data.length) return undefined;

   const geojson: FeatureCollection<Point, IICAPCapacityParameters & { id: number; name: ICAP_ZONE_NYISO }> = { ...baseGeojsonInfoBox };
   const zoneNames = data.map((x) => x.zone);

   geojson.features = geojson.features.filter((feature) => zoneNames.includes(feature.properties.name));

   geojson.features.forEach((feature) => {
      feature.properties = { ...feature.properties, ...data.find((x) => x.zone === feature.properties.name) };
   });

   geojson.features = geojson.features.filter((feature) => feature.properties.transmission_security_limit || feature.properties.capacity_requirement);

   return geojson;
};
