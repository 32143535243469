import { utils } from 'power/utils';

/**
 * Returns the minimum and maximum values in an array of numbers.
 * extent([1, 2, 3, 4, 5]);  // Returns [1, 5]
 *
 * @param arr - The array of numbers
 * @returns An array with the minimum value at index 0 and the maximum value at index 1.
 */
export const extent = (arr: number[]): [number, number] => [Math.min(...arr), Math.max(...arr)];

/**
 * Localizes a number using the current language from i18next and the specified options.
 *
 * @param n - The number to localize
 * @param options - Options to pass to the toLocaleString function. The maximumFractionDigits option is set to 1 by default.
 * @param language - The language to use for localization. If not specified, the current language from i18next is used.
 * @returns The localized number as a string.
 */
export const localizeNumber = (
   n = 0,
   options?: Intl.NumberFormatOptions,
   language = 'en',
   // eslint-disable-next-line arrow-body-style
): string => {
   // Localize the number using the toLocaleString function and the specified options and language
   // Set the maximumFractionDigits option to 1 by default
   return Number(n).toLocaleString(language, {
      maximumFractionDigits: options?.maximumFractionDigits ?? (options?.minimumFractionDigits ? undefined : 1),
      ...options,
   });
};

export interface IThreshold {
  value: number;
  text: string;
}

export type ThresholdType = 'thousand' | 'billion' | 'million';

export type IThresholds = Partial<Record<ThresholdType, IThreshold>>;

const _thresholds: IThresholds = {
   billion: { value: 1.0e9, text: 'B' },
   million: { value: 1.0e6, text: 'M' },
   // thousand: { value: 1.0e+3, text: 'K' },
};

const _fullThresholds: IThresholds = {
   billion: { value: 1.0e9, text: 'B' },
   million: { value: 1.0e6, text: 'M' },
   thousand: { value: 1.0e3, text: 'K' },
};

/**
 * Normalizes a number by dividing it by a threshold value and appending a corresponding text to the end.
 *
 * @param n - The number to normalize.
 * @param options - Options for formatting the number using the `Intl.NumberFormat` function.
 * @returns The normalized number as a string.
 */
export const normalizeNumber = (n = 0, options?: Intl.NumberFormatOptions, thresholds: IThresholds = _thresholds): string => {
   // The text to append to the end of the normalized number
   let helperText = '';

   // If a threshold was found, divide the number by the value and set the helper text to the corresponding text

   Object.values(thresholds).forEach((threshold) => {
      if (Math.abs(n) >= threshold.value) {
         n /= threshold.value;
         helperText = threshold.text;
      }
   });

   // if (Math.abs(n) >= thresholds.billion.value) {

   //   n /= thresholds.billion.value;
   //   helperText = thresholds.billion.text;

   // } else if (Math.abs(n) >= thresholds.million.value) {

   //   n /= thresholds.million.value;
   //   helperText = thresholds.million.text;

   // } else if (Math.abs(n) >= thresholds.thousand.value) {

   //   n /= thresholds.thousand.value;
   //   helperText = thresholds.thousand.text;

   // }

   // Localize the normalized number
   const localized = localizeNumber(n, options);

   // Return the localized number with the helper text appended
   return `${localized}${helperText}`;
};

export const normalizeNumberFullThreshold = (n = 0, options?: Intl.NumberFormatOptions, thresholds: IThresholds = _fullThresholds) => normalizeNumber(n, options, thresholds);

/**
 * Converts a string representation of a number with a threshold indicator (e.g., "10k", "99M") to a numeric value.
 *
 * @param value - The string representation of the number.
 * @returns The numeric value.
 */
export const deNormalizeNumber = (value: string): number => {
   const numericPart = parseFloat(value);
   const lastCharacter = value.slice(-1);

   const thresHoldsMapped = {
      // K: _thresholds.thousand.value,
      M: _thresholds.million?.value,
      B: _thresholds.billion?.value,
   };

   const multiplier = (thresHoldsMapped as any)[lastCharacter];

   // Default case: assume the string represents the number as is
   if (!utils.isExist(multiplier)) return numericPart;

   return numericPart * (thresHoldsMapped as any)[lastCharacter];
};

export const moneyRate = (
   n = 0,
   digit = 2,
   // eslint-disable-next-line arrow-body-style
): string => localizeNumber(n, { minimumFractionDigits: digit, maximumFractionDigits: digit });

export const toPercent = (
   n = 0,
   digit = 2,
   // eslint-disable-next-line arrow-body-style
): string => `${localizeNumber(n, { minimumFractionDigits: digit, maximumFractionDigits: digit })} %`;
