import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import { Future } from 'modules/Future';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const FuturePage = () => {
   ReactGA.send({ hitType: 'pageview', page: '/future', title: 'Future' });

   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="Future" />}>
         <Future />
      </PrivatePageLayout>
   );
};
