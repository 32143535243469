/**
 * legendTicks function get number array and scale it to 3 ticks
 * every tick has to be same count of sub array
 * @param {number[]} array
 * @returns {number[]}
 *
 */
const legendTicks = (array: number[], scaleCount: number): number[] => {
   const sortedArray = array.sort((a, b) => a - b);
   const ticks: number[] = [];
   const { length } = sortedArray;
   const scale = length / scaleCount;
   for (let i = 0; i < scaleCount; i++) {
      ticks.push(sortedArray[Math.floor(i * scale)]);
   }
   return ticks;
};

export default legendTicks;
