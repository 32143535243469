import { Mix } from '@ant-design/plots';
import type { IPlot } from '@antv/g2plot/lib/plots/mix/types';
import { Radio, Slider, Table, Typography } from 'antd';
import { ApiClients } from 'ApiClients';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import { grayBase, greenBase, yellowBase } from 'constants/NewColors';
import { useContent } from 'content';
import { extent } from 'd3';
import { chain } from 'lodash';
import { API_ROUTES } from 'power/constants';
import useAntdChartConfig from 'power/hooks/useAntdChartConfig';
import { DATA_PROVIDER, PRICE_COMPONENT, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { parseDateByTimeGranularity, setChartRange } from 'utils/data';
import { normalizeNumber } from 'utils/math';

type Props = {
  nodeId: number;
  provider: DATA_PROVIDER;
};

const DARTSpreadTimeSeries: FC<Props> = ({ nodeId, provider }) => {
   const { t } = useContent();

   const [component, setComponent] = useState(PRICE_COMPONENT.LMP);

   const [range, setRange] = useState<number[]>(null as any);

   const chartConfig = useAntdChartConfig('line', 'Monthly', '$/MWh');

   const { isFetching, data, error, refetch } = useQuery([API_ROUTES.LMP.TimeSeries, component, nodeId], () => ApiClients.getInstance().lmpTimeSeries({
      component: provider === DATA_PROVIDER.ERCOT ? PRICE_COMPONENT.LMP : component,
      dayAheadMarket: true,
      realtimeMarket: true,
      nodeId,
      getDiff: true,
   }).then((res) => {
      const [min, max] = extent((res.result as any)?.data.map((d: any) => d.y));

      return {
         ...res,

         tableData: Object.entries(
            chain(
               res.result?.data
                  .filter((d) => d.group !== 'DART')
                  .map((d) => ({
                     date: d.x.split('T')[0],
                     marketType: d.group?.split('-')[0],
                     group: d.group?.split('-')[1],
                     value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                  })),
            )
               .groupBy((d) => `${d.date}--${d.group}`)
               .value(),
         ).map(([group, data]) => ({
            date: group.split('--')[0],
            component: group.split('--')[1],
            ...(data.reduce(
               (acc, cur) => ({
                  ...acc,
                  [cur.marketType as any]: cur.value,
               }),
               {},
            ) as { date: string; DA: number; RT: number }[]),
         })),

         range: setChartRange(min as any, max as any),
      };
   }),
   );

   const plots: IPlot[] = useMemo(() => {
      if (!data?.result?.data?.length) return [];

      const diffData = data?.result?.data
         ?.filter((d) => d.group === 'DART')
         .map((d) => ({
            ...d,
            g: d.group,
         }));

      return [
         {
            type: 'column',
            options: {
               ...(chartConfig as any),
               brush: false,
               data: diffData,
               xField: 'x',
               yField: 'y',
               seriesField: 'g',
               isGroup: true,
               color: yellowBase.main,
               yAxis: {
                  ...chartConfig.yAxis,
                  min: range?.[0],
                  max: range?.[1],
               },
               tooltip: false,
               xAxis: false,
            },
         },
         {
            type: 'line',
            options: {
               ...chartConfig,
               data: data?.result?.data?.filter((d) => d.group !== 'DART'),
               xField: 'x',
               yField: 'y',
               seriesField: 'group',
               // color: (datum) => (datum?.group?.includes('DA-') ? ColorSets.main[0] : ColorSets.dark[0]),
               yAxis: {
                  ...chartConfig.yAxis,
                  min: range?.[0],
                  max: range?.[1],
               },

               tooltip: {
                  follow: true,
                  title: (_, datum) => parseDateByTimeGranularity(datum.x, TIME_GRANULARITY.monthly),
                  formatter: (datum) => ({
                     name: datum.group,
                     value: normalizeNumber(datum.y, { maximumFractionDigits: 2 }),
                  }),

                  customItems(originalItems) {
                     const data = diffData.find((dd) => dd.x === originalItems[0].data.x);

                     const spreadItems: typeof originalItems = [
                        {
                           color: yellowBase.main,
                           marker: originalItems[0].marker,
                           name: (data as any)?.g,
                           data: data as any,
                           value: normalizeNumber(data?.y, { maximumFractionDigits: 2 }),
                           mappingData: {} as any,
                        },
                     ];

                     return [...originalItems, ...spreadItems];
                  },
               },

               xAxis: {
                  ...chartConfig.xAxis,
                  label: {
                     formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.monthly),
                  },
               },
            },
         },
      ] as IPlot[];
   }, [chartConfig, data?.result?.data, range]);

   useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]);

   const isErcot = provider === DATA_PROVIDER.ERCOT;

   return (
      <CardPanel
         title={t('lmpOverview.dartSpreadTimeSeries')}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : (data?.result?.data?.length ?? 0) < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         downloadURL={data?.downloadUrl}
         refreshClick={refetch}
         info={t('lmpOverview.dartSpreadTimeSeriesInfo')}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data?.range?.[0]}
                              max={data?.range?.[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )}

                  <Radio.Group
                     /* defaultValue={isErcot ? PRICE_COMPONENT.LMP : component} */
                     value={component}
                     onChange={(e) => setComponent(e.target.value)}
                  >
                     <Radio value={PRICE_COMPONENT.LMP}>{t('base.lmp')}</Radio>
                     <Radio value={PRICE_COMPONENT.MCE}>{t('base.mce')}</Radio>
                     <Radio value={PRICE_COMPONENT.MCC}>{t('base.mcc')}</Radio>
                     <Radio disabled={isErcot} value={PRICE_COMPONENT.MCL}>
                        {t('base.mcl')}
                     </Radio>
                  </Radio.Group>
               </div>

               {viewMode === 'chart' && (
                  <Mix
                     className="antd-chart"
                     plots={plots}
                     appendPadding={8}
                     legend={
                [
                   {
                      position: 'bottom',
                      ...chartConfig.legend,
                   },
                ] as any
                     }
                     tooltip={false}
                     syncViewPadding
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.date'),
                           dataIndex: 'date',
                           key: 'date',
                        },
                        {
                           title: 'Component',
                           dataIndex: 'component',
                           key: 'component',
                        },
                        {
                           title: 'DA',
                           dataIndex: 'DA',
                           key: 'DA',
                        },
                        {
                           title: 'RT',
                           dataIndex: 'RT',
                           key: 'RT',
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.tableData ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default DARTSpreadTimeSeries;
