enum DATA_SUM_TYPE {
  Undefined = 0,
  Hourly = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  Yearly = 5,
  SeasonalMonthHour = 11,
  SolarWind = 12,
}

export default DATA_SUM_TYPE;
