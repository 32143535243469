import type { ValueType } from '@rc-component/mini-decimal';
import { InputNumber, type InputNumberProps } from 'antd';
import { useOnChangeDebounce } from 'power/hooks';
import { FC, PropsWithChildren, useCallback } from 'react';

interface IInputNumberDebounce extends InputNumberProps {
  value: ValueType;
  endAdornment?: string;
  startAdornment?: string;
  type: 'decimal' | 'int';
  step?: number;
  onChangeDebounce: (newValue: ValueType) => Promise<boolean>;
  onChangeCallback?: (newValue: ValueType) => void;
  delay?: number;
}

const InputNumberDebounce: FC<PropsWithChildren<IInputNumberDebounce>> = ({
   value,
   min,
   max,
   endAdornment,
   startAdornment,
   type = 'decimal',
   disabled,
   step,
   size = 'middle',
   // custom props
   onChangeDebounce,
   onChangeCallback,
   delay = 500,
   ...rest
}) => {
   const { inputValue, onChangeHandler } = useOnChangeDebounce(value, onChangeDebounce, onChangeCallback, delay);

   const onChange = useCallback(
      (newValue: ValueType | null) => {
      // console.log('InputNumber.onChange.newValue', newValue);
         if (newValue !== null) onChangeHandler(newValue);
      },
      [onChangeHandler],
   );

   return (
      <InputNumber
         value={inputValue}
         min={min}
         max={max}
         type="number"
         step={step || type === 'decimal' ? 0.5 : 1}
         size={size}
         addonBefore={startAdornment && <span className="input-grey-text">{startAdornment}</span>}
         addonAfter={endAdornment && <span className="input-grey-text">{endAdornment}</span>}
         disabled={disabled}
         style={{ width: '100%' }}
         {...rest}
         onChange={onChange}
      />
   );
};

export default InputNumberDebounce;
