import { Column } from '@ant-design/plots';
import { Slider, Table, Typography } from 'antd';
import { ApiClients } from 'ApiClients';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import columnChartConfig from 'configs/antd/charts';
import { grayBase, greenBase } from 'constants/NewColors';
import { useContent } from 'content';
import { extent } from 'd3';
import { API_ROUTES } from 'power/constants';
import { PRICE_COMPONENT } from 'power/types/enum';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { setChartRange } from 'utils/data';
import { normalizeNumber } from 'utils/math';

type Props = {
  nodeId: number;
  title: string;
  info: ReactNode;
  component: PRICE_COMPONENT;
};
const YearlySum: FC<Props> = ({ nodeId, component, info, title }) => {
   const { t } = useContent();

   const [range, setRange] = useState<number[]>(null as any);

   const chartConfig = useMemo(() => columnChartConfig('Yearly', '$/MWh'), []);

   const { isFetching, data, error, refetch } = useQuery([API_ROUTES.LMP.YearlySum, nodeId, component], () => ApiClients.getInstance().lmpYearlySum({
      nodeId,
      component,
   }).then((res) => {
      const [min, max] = extent((res.result as any).data.flatMap((d: any) => d.y));

      const data = res.result?.data
         .map((d) => ({
            x: d.x,
            y: d.y,
            group: d.group?.split('-')[1],
         }))
         .sort((a, b) => {
            // first DA, second RT
            if ((a.group as any) < (b.group as any)) return -1;
            if ((a.group as any) > (b.group as any)) return 1;
            return 0;
         });

      return {
         ...res,
         result: data,
         range: setChartRange(min as any, max as any),
      };
   }),
   );

   useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]);

   return (
      <CardPanel
         title={title}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : (data?.result?.length as any) < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         downloadURL={data?.downloadUrl}
         refreshClick={refetch}
         info={info}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data?.range?.[0]}
                              max={data?.range?.[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )}
               </div>

               {viewMode === 'chart' && (
                  <Column
                     {...chartConfig}
                     data={data?.result || []}
                     xField="x"
                     yField="y"
                     seriesField="group"
                     isGroup
                     yAxis={{
                        ...chartConfig.yAxis,
                        min: range?.[0],
                        max: range?.[1],
                        label: {
                           formatter: (v) => normalizeNumber(+v, { maximumFractionDigits: 2 }),
                        },
                     }}
                     /* color={(d) => {

                if (d?.group?.includes('DA')) return greenBase.main;

                return greenBase.dark;
        
              }} */
                     tooltip={{
                        shared: true,
                        formatter: (d) => ({
                           name: d.group,
                           value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                        }),
                     }}
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.year'),
                           dataIndex: 'x',
                           key: 'x',
                        },
                        {
                           title: t('base.marketType'),
                           dataIndex: 'group',
                           key: 'group',
                        },
                        {
                           title: t('base.value'),
                           dataIndex: 'y',
                           key: 'y',
                           render: (value) => normalizeNumber(value, { maximumFractionDigits: 2 }),
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.result ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default YearlySum;
