import { VECTOR_LAYERS } from 'power/constants/map';
import { VECTOR_LAYER_ID } from 'power/types/enum';
import { IVectorLayer } from 'power/types/map';

const getLayerInfo = (vectorLayerId: VECTOR_LAYER_ID) => {
   const vectorLayer: IVectorLayer = VECTOR_LAYERS[vectorLayerId];
   return {
      layerType: vectorLayer.type,
      layerId: vectorLayer.id,
      sourceUrl: `mapbox://${vectorLayer.id}`,
      sourceLayer: vectorLayer.sourceLayer,
      layerLayout: vectorLayer.layout,
      layerPaint: vectorLayer.paint,
      layerMinzoom: vectorLayer.minzoom,
      layerMaxzoom: vectorLayer.maxzoom,
   };
};

export default getLayerInfo;
