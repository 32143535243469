import { MapRef } from 'react-map-gl';

const addMapImage = (_mapRefCurrent: MapRef, imageName: string, imageUrl: string, sdf = false) => new Promise((resolve, reject) => {
   try {
      const existingImage = _mapRefCurrent.hasImage(imageName);
      if (existingImage) _mapRefCurrent.removeImage(imageName);

      _mapRefCurrent.loadImage(imageUrl, (err, image) => {
         if (err || image === undefined) {
            console.error('addMapImage.Error.1', imageName, imageUrl, err, image);
            return reject(err);
         }
         _mapRefCurrent.addImage(imageName, image, { sdf });
         return resolve(true);
      });
      
   } catch (err) {
      console.error('addMapImage.Error.2', imageName, imageUrl, err);
      return reject(err);
   }
});

const addMapImages = (_mapRefCurrent: MapRef, images: { name: string; url: string }[], sdf = false) => {
   const promises = images.map((image) => addMapImage(_mapRefCurrent, image.name, image.url, sdf));
   return Promise.all(promises);
};

export default addMapImages;
