import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Select } from 'antd';
import { PowerCol } from 'components/ui';
import { PowerRow } from 'power/components/base/grid';
import { IICAPFutureRequest } from 'power/types';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  query: IICAPFutureRequest;
  onSubmit: (query: IICAPFutureRequest) => void;
};

const ICAPFutureMapFilter: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formFilter, setFormFilter] = useState<IICAPFutureRequest>(query);

   const onChangeYear = useCallback((value: number) => {
      setFormFilter((prev) => ({ ...prev, period_year: value }));
   }, []);

   const onChangeMonth = useCallback((value: number) => {
      setFormFilter((prev) => ({ ...prev, period_month: value }));
   }, []);

   const onFormSubmit = useCallback(() => {
      onSubmit(formFilter);
   }, [formFilter, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={3} xl={4} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Capability Year:</span>
               <Select
                  variant="borderless" placeholder="Select year" value={formFilter.period_year}
                  disabled={loading} onChange={onChangeYear} options={Array.from({ length: 10 }, (_, i) => ({ label: `${2024 + i}/${2024 + i + 1}`, value: 2024 + i }))}></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={3} lg={3}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical style={{ paddingBottom: 12 }}>
               <span className={classes.label}>Month:</span>
               <Select
                  variant="borderless" placeholder="All" value={query.period_month}
                  disabled={loading} onChange={onChangeMonth} options={MONTHS}></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '2px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default ICAPFutureMapFilter;

const MONTHS = [
   { label: 'Jan', value: 1 },
   { label: 'Feb', value: 2 },
   { label: 'Mar', value: 3 },
   { label: 'Apr', value: 4 },
   { label: 'May', value: 5 },
   { label: 'Jun', value: 6 },
   { label: 'Jul', value: 7 },
   { label: 'Aug', value: 8 },
   { label: 'Sep', value: 9 },
   { label: 'Oct', value: 10 },
   { label: 'Nov', value: 11 },
   { label: 'Dec', value: 12 },
];
