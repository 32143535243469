import { FeatureCollection } from 'geojson';
import React from 'react';
import { Layer, MapRef, Source } from 'react-map-gl';

type Props = {
  layerId: string;
  highlightLayerId: string;
  map: MapRef;
  minMax: number[];
  paint: mapboxgl.CirclePaint;
  paintHighlight: mapboxgl.CirclePaint;
  data: FeatureCollection;
  selectedIds?: number[];
  beforeId?: string;
};

const CapacitySubstationsLayer: React.FC<Props> = ({ layerId, highlightLayerId, map, minMax, paint, paintHighlight, data, selectedIds, beforeId }) => (
   <Source id={layerId} type="geojson" data={data}>
      <Layer
         type="circle" id={layerId} paint={paint}
         beforeId={beforeId} />
      {selectedIds && selectedIds.length > 0 && (
         <Layer
            id={highlightLayerId} type="circle" paint={paintHighlight}
            filter={['==', ['get', 'id'], selectedIds[0]]} beforeId={beforeId} />
      )}
   </Source>
);

export default CapacitySubstationsLayer;
