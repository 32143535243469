import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import InfoBox from 'power/components/base/InfoBox/InfoBox';
import { ILmpSumValue } from 'power/types';
import { MARKET_TYPE, PERIOD_TYPE } from 'power/types/enum';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  nodeId: number;
};

const InfoBoxes: FC<Props> = ({ nodeId }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<ILmpSumValue[]>();

   useEffect(() => {
      ApiClients.getInstance().lmpSumValues(nodeId, PERIOD_TYPE.last_5_year).then((res) => {
         setData(res);
         setLoading(false);
      });
   }, [nodeId]);

   const avgDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.lmpAvg ?? '';
   const avgRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.lmpAvg ?? '';
   const stdDevDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.lmpStdDev ?? '';
   const stdDevRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.lmpStdDev ?? '';
   const solarDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.solarAvg ?? '';
   const solarRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.solarAvg ?? '';
   const windDA = data?.find((x) => x.marketType === MARKET_TYPE.DayAhead)?.windAvg ?? '';
   const windRT = data?.find((x) => x.marketType === MARKET_TYPE.Realtime)?.windAvg ?? '';

   if (loading || !data || data.length < 2) return null;

   return (
      <PowerRow gutter={[16, 16]} style={{ padding: '4px' }}>
         <PowerCol span={0} xxl={2}></PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP AVG"
               data={[
                  { key: 'DA', value: avgDA },
                  { key: 'RT', value: avgRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP STDV"
               data={[
                  { key: 'DA', value: stdDevDA },
                  { key: 'RT', value: stdDevRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP Solar Weighted"
               data={[
                  { key: 'DA', value: solarDA },
                  { key: 'RT', value: solarRT },
               ]}
            />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="LMP Wind Weighted"
               data={[
                  { key: 'DA', value: windDA },
                  { key: 'RT', value: windRT },
               ]}
            />
         </PowerCol>

         <PowerCol span={0} xxl={2}></PowerCol>
      </PowerRow>
   );
};

export default memo(InfoBoxes);
