// import { PowerMap } from 'power/components/maps';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { ApiClients } from 'ApiClients';
import { IICAPMonthly, IICAPStripRequest } from 'power/types';
import { ICAP_SEASON, ICAP_ZONE_NYISO } from 'power/types/enum';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classes from '../infobox.module.scss';

const MONTHS_SUMMER = [
   { id: 5, name: 'May' },
   { id: 6, name: 'Jun' },
   { id: 7, name: 'Jul' },
   { id: 8, name: 'Aug' },
   { id: 9, name: 'Sep' },
   { id: 10, name: 'Oct' },
];

const MONTHS_WINTER = [
   { id: 11, name: 'Nov' },
   { id: 12, name: 'Dec' },
   { id: 1, name: 'Jan' },
   { id: 2, name: 'Feb' },
   { id: 3, name: 'Mar' },
   { id: 4, name: 'Apr' },
];

type Props = {
  queryStrip: IICAPStripRequest;
  month: number;
  selectedZone: ICAP_ZONE_NYISO;
  onClose: () => void;
};

const MonthlyInfo: FC<Props> = ({ queryStrip, month, selectedZone, onClose }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPMonthly[]>();

   const renderData = useMemo(() => data?.map((x) => ({ auctionMonth: x.period_month, month: x.month, zone: x.zone, awarded: x.awarded, price: x.price })), [data]);

   useEffect(() => {
      setData(undefined);
      setLoading(true);
      ApiClients.getInstance().icapMonthlyDetail({ ...queryStrip, period_month: month, zone: selectedZone })
         .then((res) => {
            if (res.length === 6) {
               if (queryStrip.period_season === ICAP_SEASON.SUMMER) {
                  res.sort((a, b) => a.month_index - b.month_index);
               } else {
                  // 11, 12, 1, 2, 3, 4
                  res.sort((a, b) => {
                     if (a.month_index === 11) return -1;
                     if (b.month_index === 11) return 1;
                     if (a.month_index === 12) return -1;
                     if (b.month_index === 12) return 1;
                     return a.month_index - b.month_index;
                  });
               }
               setData(res);
            }
         })
         .finally(() => setLoading(false));
   }, [month, queryStrip, selectedZone]);

   const monthsInfo = useCallback(
      (auctionMonth: number, monthParam: number): { auctionMonthName: string; monthName: string; position: -1 | 0 | 1 } => {
         let MONTHS = MONTHS_SUMMER;
         if (queryStrip.period_season === ICAP_SEASON.WINTER) MONTHS = MONTHS_WINTER;

         const auctionMonthInfo = MONTHS?.find((x) => x.id === auctionMonth);
         const monthInfo = MONTHS?.find((x) => x.id === monthParam);

         let position: -1 | 0 | 1 = auctionMonth === month && monthParam === month ? 0 : auctionMonth === month ? 1 : -1;

         return {
            auctionMonthName: auctionMonthInfo?.name ?? '',
            monthName: monthInfo?.name ?? '',
            position,
         };
      },
      [month, queryStrip.period_season],
   );

   return (
      <div className={classes.info} style={{ minWidth: 640 }}>
         <div className={classes.header}>
            <div className={classes.title}>Monthly</div>
            <div className={classes.subtitle}>{selectedZone}</div>
            <div className={classes.close}>
               <Button
                  icon={<CloseOutlined />} onClick={onClose} style={{ border: 'none' }}
                  size="small" shape="circle" />
            </div>
         </div>
         <div className={classes.content}>
            {loading && <Spin style={{ margin: '0 auto', padding: 18 }} />}
            {!loading && renderData && (
               <>
                  <div className={classes.rowTitles}>
                     <span>Auction Month</span>
                     <span>Month</span>
                     <span>Awarded (MW)</span>
                     <span>Price ($/kW - Month)</span>
                  </div>

                  {renderData.map((x) => {
                     const monthsInfos = monthsInfo(x.auctionMonth, x.month);

                     return (
                        <div key={`${monthsInfos.auctionMonthName}-${monthsInfos.monthName}`} className={monthsInfos.position === 0 ? classes.rowValuesCurrent : monthsInfos.position === -1 ? classes.rowValuesPast : classes.rowValues}>
                           <span>{monthsInfos.auctionMonthName}</span>
                           <span className={classes.pastGreen}>{monthsInfos.monthName}</span>
                           <span>{x.awarded}</span>
                           <span>{x.price}</span>
                        </div>
                     );
                  })}
               </>
            )}
         </div>
      </div>
   );
};

export default MonthlyInfo;
