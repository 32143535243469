import { Button } from 'antd';
import { ApiClients } from 'ApiClients';
import { ILmpOverviewTimeSeriesRequest } from 'modules/NodeAnalysis/Container/Tabs/lmp/types';
import { API_ROUTES } from 'power/constants';
import { IBaseChartData, IBaseDataResponse, IChartData, ILocationSimple } from 'power/types';
import { PRICE_COMPONENT } from 'power/types/enum';
import { FC, useState } from 'react';

const testLmpSummart = async (nodes: ILocationSimple[]) => {
   const result: (IBaseDataResponse<IBaseChartData<IChartData<any, number>>, ILmpOverviewTimeSeriesRequest> | undefined)[] = [];
   const promises: Promise<IBaseDataResponse<IBaseChartData<IChartData<any, number>>, ILmpOverviewTimeSeriesRequest> | undefined>[] = [];

   const fetcher = (nodeId: number) => ApiClients.getInstance().ApiRequestChart(API_ROUTES.LMP.YearlySum, {
      nodeId,
      component: PRICE_COMPONENT.LMP,
      cacheDate: '',
   });

   nodes.forEach((node) => {
      promises.push(fetcher(node.id));
   });

   await Promise.all(promises).then((values) => {
      values.forEach((value) => {
         console.log('Value:', value?.result?.data);
         result.push(value);
      });
   });

   return result;
};

type Props = {
  nodes: ILocationSimple[];
};

export const LMPSummaryTest: FC<Props> = ({ nodes }) => {
   const [result, setResult] = useState<string>();

   const runTestLmpSummary = async () => {
      if (!nodes) return;
      const testResult = await testLmpSummart(nodes);
      const emptyResults = testResult.filter((response) => response === undefined || response.result === undefined || response.result.data === undefined || response.result.data.length === 0);
      setResult(emptyResults.length === 0 ? '' : 'Failed');
      console.log('Empty Results:', emptyResults);
   };
   return (
      <>
         <div>
            <Button onClick={runTestLmpSummary}>Test LMP Summary API</Button>
         </div>
         {result !== undefined && <div>{result === '' ? 'Success' : <div>{result}</div>}</div>}
      </>
   );
};
