import Sider from 'antd/es/layout/Sider';
import { MainLogo } from './MainLogo/MainLogo';
import { NavigatorMenu } from './NavigatorMenu';

type Props = {
  collapsedMenu: boolean;
  setCollapsedMenu: (newCollapsed: boolean) => void;
};
export const SidebarNavigator: React.FC<Props> = ({ collapsedMenu, setCollapsedMenu }) => (
   <Sider
      className="layout-menu-sider"
      defaultCollapsed
      collapsedWidth={48}
      width={280}
      theme="light"
      collapsed={collapsedMenu}
      /* breakpoint="lg" */
      /* collapsedWidth="0" */
      onBreakpoint={(broken) => {
      // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
         setCollapsedMenu(collapsed);
      // console.log(collapsed, type);
      }}
      collapsible
      /* collapsed={collapsedMenu} */
   >
      <MainLogo min={collapsedMenu} />
      <div className="sider-menu-container tiny-scrollbar">
         <NavigatorMenu setCollapsedMenu={setCollapsedMenu} />
      </div>
   </Sider>
);
