import { Select /* type SelectProps */ } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { IOptions } from 'power/types';
import { FC, PropsWithChildren } from 'react';
// import styles from './SelectHub.module.scss';

interface ISelectSubstation {
  value?: number | string;
  onChange: (value: number | string, selectedOption: IOptions) => void;
  options: IOptions;
  disabled?: boolean;
}

const SelectSubstation: FC<PropsWithChildren<ISelectSubstation>> = ({ value, onChange, options, disabled }) => (
   <Select
      value={value}
      size="small"
      variant="borderless"
      showSearch
      options={options}
      optionFilterProp="label"
      onChange={(value: number | string, option: DefaultOptionType | DefaultOptionType[]) => {
         onChange(value, (option as any).payload as IOptions);
      }}
      disabled={disabled}
   ></Select>
);

export default SelectSubstation;
