/* eslint-disable prefer-destructuring */
export const toQueryString = <S extends Record<string, any>>(object: S): string => {
   const formatedObject = Object.entries(object).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
         if (['startDate', 'endDate'].includes(key)) {
            acc[key] = value.split('T')[0];
         } else if (Array.isArray(value)) {
            acc[key] = JSON.stringify(value);
         } else acc[key] = value;
      }
      return acc;
   }, {} as any);
   const data = new URLSearchParams(formatedObject).toString();
   // console.log('data', data);
   return data;
};

export const fromQueryString = <S>(params: URLSearchParams): Partial<S> => {
   const obj: Partial<S> = {};
   params.forEach((value: any, key: string) => {
      if (value === '') {
         (obj as any)[key] = '';
      } else if (value === 'null') {
         (obj as any)[key] = null;
      } else if (value === 'undefined') {
         (obj as any)[key] = undefined;
      } else if (value === 'true') {
         (obj as any)[key] = true;
      } else if (value === 'false') {
         (obj as any)[key] = false;
      } else if (value.toString().startsWith('[') && value.toString().endsWith(']')) {
         try {
            (obj as any)[key] = JSON.parse(value);
         } catch {
            // Ignored wrong JSON format of array
         }
      } else if (!Number.isNaN(Number(value))) {
         (obj as any)[key] = Number(value);
      } else {
         (obj as any)[key] = value;
      }
   });
   return obj;
};
