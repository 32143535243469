import { Column } from '@ant-design/plots';
import { Table } from 'antd';
import { AxiosError } from 'axios';
import CardPanel from 'components/ui/CardPanel';
import { greenBase } from 'constants/NewColors';
import { useContent } from 'content';
import { useHistoricalBasisRiskData } from 'modules/NodeAnalysis/Container/Tabs/basis-risk/hooks';
import useAntdChartConfig from 'power/hooks/useAntdChartConfig';
import { PRICE_COMPONENT } from 'power/types/enum';
import { FC, useState } from 'react';
import { normalizeNumber } from 'utils/math';

type Props = {
  nodeId: number;
  hubId: number;
};
const HistoricalBasisRisk: FC<Props> = ({ nodeId, hubId }) => {
   const { t } = useContent();

   const [component, /* setComponent */] = useState(PRICE_COMPONENT.BASISVAL);

   // const [range, setRange] = useState<number[]>(null as any);

   const chartConfig = useAntdChartConfig('column', 'Yearly', '$/MWh');

   const { isFetching, data, error, refetch } = useHistoricalBasisRiskData({
      dayAheadMarket: true,
      realtimeMarket: true,
      component,
      hubId,
      nodeId,
   });

   /* useEffect(() => {
      if (data) setRange(setChartRange(data.range[0], data.range[1]));
   }, [data]); */

   return (
      <CardPanel
         title={t('basisRiskOverview.historicalBasisRisk')}
         fullScreen
         defaultViewMode="chart"
         multipleViewMode
         status={isFetching ? 'loading' : error ? 'error' : (data?.res?.result?.data?.length ?? 0) < 1 ? 'noData' : 'success'}
         message={((error as AxiosError)?.response?.data as any)?.message ?? (error as AxiosError)?.message}
         refreshClick={refetch}
         downloadURL={data?.res?.downloadUrl}
         info={t('basisRiskOverview.historicalBasisRiskChartInfo')}
      >
         {({ viewMode, isFullScreen }) => (
            <>
               <div className="card-panel-filter-bar">
                  {/* {data && viewMode === 'chart' && (
                     <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                        <Typography.Text>{t('base.range')}</Typography.Text>

                        <span className="custom-slider" style={{ width: 200, padding: '0 20px 0 8px' }}>
                           <Slider
                              min={data.range[0]}
                              max={data.range[1]}
                              value={range}
                              range={{ draggableTrack: true }}
                              onChange={setRange}
                              styles={{
                                 track: { backgroundColor: greenBase.main },
                                 rail: { backgroundColor: grayBase.main },
                                 handle: {
                                    backgroundColor: greenBase.main,
                                    color: greenBase.main,
                                    borderColor: greenBase.main,
                                 },
                              }}
                           />
                        </span>
                     </span>
                  )} */}

                  {/* <Radio.Group value={component} onChange={(e) => setComponent(e.target.value)}>
                     <Radio value={PRICE_COMPONENT.BASISVAL}>{t('base.average')}</Radio>

                     <Radio value={PRICE_COMPONENT.BASISSOLAR}>{t('base.solar')}</Radio>

                     <Radio value={PRICE_COMPONENT.BASISWIND}>{t('base.wind')}</Radio>
                  </Radio.Group> */}
               </div>

               {viewMode === 'chart' && (
                  <Column
                     {...chartConfig}
                     data={data?.res?.result?.data || []}
                     xField="x"
                     yField="y"
                     seriesField="group"
                     isGroup
                     yAxis={{
                        ...chartConfig.yAxis,
                        // min: range?.[0],
                        // max: range?.[1],
                        /* label: {
                           formatter: (x) => parseDateByTimeGranularity(x, TIME_GRANULARITY.yearly),
                        }, */
                        tickInterval: 1,
                     }}
                     color={(d) => {
                        if (d.group.includes('DA')) return greenBase.main;

                        return greenBase.dark;
                     }}
                     tooltip={{
                        shared: true,
                        formatter: (d) => ({
                           name: d.group,
                           value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                        }),
                     }}
                  />
               )}

               {viewMode === 'table' && (
                  <Table
                     className="chart custom-table"
                     size="small"
                     columns={[
                        {
                           title: t('base.year'),
                           dataIndex: 'year',
                           key: 'year',
                        },
                        {
                           title: t('base.marketType'),
                           dataIndex: 'marketType',
                           key: 'marketType',
                        },
                        {
                           title: 'BASIS',
                           dataIndex: 'BASIS',
                           key: 'BASIS',
                        },
                     ]}
                     sticky
                     scroll={{
                        scrollToFirstRowOnChange: true,
                        y: isFullScreen ? '100%' : '400px',
                     }}
                     pagination={false}
                     dataSource={data?.tableData ?? []}
                  />
               )}
            </>
         )}
      </CardPanel>
   );
};

export default HistoricalBasisRisk;
