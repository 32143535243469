export type DATA_PROVIDER_STRING = 'Undefined' | 'SPP' | 'ERCOT' | 'MISO' | 'CAISO' | 'PJM' | 'ISONE' | 'NYISO' | 'NW' | 'SW' | 'SE' | 'AESO' | 'IESO' | 'NONISO' | 'EIA' | 'PNM' | 'NREL';

enum DATA_PROVIDER {
  Undefined = 0,
  SPP = 1,
  ERCOT = 2,
  MISO = 3,
  CAISO = 4,
  PJM = 5,
  ISONE = 6,
  NYISO = 7,
  NW = 8, // Northwest
  SW = 9, // Soutwest
  SE = 10, // Southeast
  AESO = 11,
  IESO = 12,
  NONISO = 99,
  EIA = 100,
  PNM = 150,
  NREL = 200,
}

export default DATA_PROVIDER;
