import { ApiClients } from 'ApiClients';
import { FeatureCollection, Point } from 'geojson';
import { useAuth } from 'providers/AuthProvider';
import { useEffect, useState } from 'react';

const useIqLayer = (active: boolean) => {
   const { iso } = useAuth();
   const [iqLayer, setIqLayer] = useState<FeatureCollection<Point, any> | undefined>(undefined);

   useEffect(() => {
      if (!active) return;
      if (iqLayer) return;

      let isMounted = true;

      const fetch = async () => ApiClients.getInstance()
         .mapIq(iso)
         .then((data) => {
            if (data && isMounted) setIqLayer(data);
         })
         .catch((error) => {
            console.error(error);
            if (isMounted) setIqLayer(undefined);
         });

      fetch();

      return () => {
         isMounted = false;
      };
   }, [active, iqLayer, iso]);

   return {
      iqLayer,
   };
};

export default useIqLayer;
