import { BarChartOutlined, FullscreenExitOutlined, FullscreenOutlined, InfoCircleOutlined, MinusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import LazyComponent from 'power/components/base/LazyComponent';
import React, { ReactNode, useState } from 'react';
import classes from './PowerCard.module.scss';

type Props = {
  loading: boolean;
  noData: boolean;
  title: string;
  children?: ReactNode;
  onInfoClick?: () => void;
  maxHeight?: string | number;
  minHeight?: string | number;
  showMinimize?: boolean;
  showFullScreen?: boolean;
  headerContent?: ReactNode;
};
const PowerCard: React.FC<Props> = ({ loading, noData, title, children, onInfoClick, maxHeight = 600, minHeight, showMinimize = true, showFullScreen = true, headerContent }) => {
   const [fullScreen, setFullScreen] = useState(false);
   const [minimize, setMinimize] = useState(false);

   return (
      <div className={classes.powerCardSpace} style={{ maxHeight, minHeight: minimize ? '48px' : minHeight ?? undefined }}>
         <LazyComponent classname={classes.lazyContainer}>
            <div className={`${classes.powerCard} ${fullScreen ? classes.fullScreen : ''}`}>
               <div className={classes.container}>
                  <div className={classes.header}>
                     <div className={classes.titleContainer}>
                        <div className={classes.infoContainer}>
                           {onInfoClick && (
                              <div className={classes.info}>
                                 <InfoCircleOutlined />
                              </div>
                           )}
                        </div>
                        <span className={classes.title}>{title}</span>
                     </div>

                     <div className={classes.toolboxContainer}>
                        {headerContent}
                        {showMinimize && !fullScreen && <Button type="text" icon={minimize ? <PlusCircleOutlined /> : <MinusOutlined />} onClick={() => setMinimize((prev) => !prev)} />}
                        {showFullScreen && (
                           <Button
                              type="text"
                              icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                              onClick={() => {
                                 setFullScreen((prev) => !prev);
                                 setMinimize(false);
                              }}
                           />
                        )}
                     </div>
                  </div>

                  {!minimize && (
                     <div className={classes.content}>
                        {loading ? (
                           <Skeleton.Node
                              active fullSize rootClassName="u-full-width"
                              style={{ height: '100%', width: '100%' }}>
                              <BarChartOutlined />
                           </Skeleton.Node>
                        ) : noData ? (
                           <Empty description="No results found" />
                        ) : (
                           children
                        )}
                     </div>
                  )}
               </div>
            </div>
         </LazyComponent>
      </div>
   );
};

export default React.memo(PowerCard);
