import { CheckCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import { ApiClients } from 'ApiClients';
import { useAuth } from 'providers/AuthProvider';
import { useCallback, useState } from 'react';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import styles from './ChangePasswordPage.module.scss';

const ChangePasswordPage = () => {
   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);
   const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

   const { user } = useAuth();

   const onChangePassword = useCallback(
      (currentPassword: string, newPassword: string) => {
         setLoading(true);
         setErrorMessage(undefined);

         return ApiClients.getInstance()
            .changePassword(user.userId, currentPassword, newPassword)
            .then((res) => {
               if (res) setSuccess(true);
               else {
                  setErrorMessage('An error occurred');
                  setLoading(false);
               }
            })
            .catch((err) => {
               const msg = err?.response?.data?.detail;
               if (msg) setErrorMessage(msg);
               else setErrorMessage('An error occurred');
               setLoading(false);
            });
      },
      [user.userId],
   );

   return (
      <div className={styles.content}>
         <div className={styles.form}>
            {success ? (
               <Alert
                  message={(
                     <>
                        <h3 className={styles.title}>Change Password</h3>
                        <div className={styles.subtitle}>
                           <div style={{ height: 128 }}>
                              <CheckCircleFilled style={{ fontSize: '98px', color: '#5ad8a6' }} />
                           </div>
                           <div>Password changed successfully.</div>
                        </div>
                     </>
                  )}
                  type="success"
                  style={{ marginBottom: '2rem', padding: '1rem' }}
               />
            ) : (
               <h3 className={styles.title}>Change Your Password</h3>
            )}
            <ChangePasswordForm
               loading={loading} onChangePassword={onChangePassword} errorMessage={errorMessage}
               success={success} />
         </div>
      </div>
   );
};

export default ChangePasswordPage;
