import { Spin } from 'antd';
import { ApiClients } from 'ApiClients';
import { IPPDetail } from 'power/types';
import { FUEL_TYPE } from 'power/types/enum';
import { FC, useEffect, useState } from 'react';

const InfoItem: FC<{ label: string; value: any }> = ({ label, value }) => (
   <div className="map-popup-row">
      <div className="map-popup-row-label">
         <span>{label}</span>
      </div>
      <div className="map-popup-row-value">{value}</div>
   </div>
);

/* const InfoItemInner: FC<{ label: string; value: any }> = ({ label, value }) => (
   <div className="map-popup-row">
      <div className="map-popup-row-label-inner">{label}</div>
      <div className="map-popup-row-value">{value}</div>
   </div>
); */

type Props = {
  id: number;
};

const PowerPlantPopup: FC<Props> = ({ id }) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<IPPDetail>();

   useEffect(() => {
      ApiClients.getInstance().mapPowerPlantDetail(id)
         .then((data) => {
            setData(data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [id]);

   if (loading || data === undefined)
      return (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 300 }}>
            <Spin size="large" />
         </div>
      );

   return (
      <div style={{ /* paddingTop: 10,  */ width: 300 }}>
         <h3 style={{ textAlign: 'center', marginTop: 0 }}>Power Plant</h3>
         <div className="custom-popup">
            <InfoItem label="Owner" value={data.owner} />
            <InfoItem label="Operator Name" value={data.operatorName} />
            <InfoItem label="Name" value={data.plantName} />
            <InfoItem label="City" value={data.city} />
            <InfoItem label="County" value={data.county} />
            <InfoItem label="State" value={data.state} />
            <InfoItem label="Fuel Types" value={data.fuelTypes.map(x => FUEL_TYPE[x]).join(', ')} />
            <InfoItem label="Voltage" value={data.gridVoltage} />
            <InfoItem label="Capacity" value={data.capacity} />
            
            {/* <InfoItem label="Project Name" value={data.projectName} />
            <InfoItem label="POI" value={data.interconnectionPoint} />
            <InfoItem label="Utility" value={data.utility} />
            
            
            <InfoItem label="Fuel Type" value={FUEL_TYPE[data.fuelType1]} />
            <InfoItem label="Request Date" value={data.requestDate && parseDateByTimeGranularity(data.requestDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Expected COD" value={data.commercialOperationDate && parseDateByTimeGranularity(data.commercialOperationDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="In Service Date" value={data.inServiceDate && parseDateByTimeGranularity(data.inServiceDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Last Updated" value={data.lastUpdatedDate && parseDateByTimeGranularity(data.lastUpdatedDate, TIME_GRANULARITY.daily)} />
            <InfoItem label="Status" value={IQ_STATUS[data.status]} />
            <InfoItem label="Status Desc" value={data.statusText} />
            <InfoItem label="Capacity (MW)" value={data.mw1} />
            <InfoItem label="ISO" value={DATA_PROVIDER[data.provider]} /> */}
         </div>
      </div>
   );
};

export default PowerPlantPopup;
