/* eslint-disable max-len */
import { Checkbox } from 'antd';
import { IMAGE_PATHS } from 'power/constants';
import { IBaseFilter } from 'power/types/map';
import React, { useCallback } from 'react';
import { MapRef } from 'react-map-gl';
import classes from './StaticBaseLayerControlContainer.module.scss';

type Props = {
  title: string;
  icon?: 'generator' | 'iq' | 'load' | 'retirement' | 'transformer';
  filter: IBaseFilter;
  changeFilter: (filter: IBaseFilter) => void;
  map?: MapRef;
};

const StaticBaseLayerControlContainer: React.FC<Props> = ({ title, icon, filter, changeFilter, map }) => {
   const toggleActive = useCallback(() => changeFilter({ ...filter, active: !filter.active }), [changeFilter, filter]);

   if (map === undefined) return null;

   return (
      <div className={classes.staticBaseLayerControlContainer}>
         <Checkbox checked={filter.active} onChange={toggleActive}>
            <div className={classes.iconText}>
               {icon && <img height={16} src={IMAGE_PATHS.MAP_ASSETS.GRID(icon)} alt={title} />}
               <b>{title}</b>
            </div>
         </Checkbox>
      </div>
   );
};

export default StaticBaseLayerControlContainer;
