import mapboxgl from 'mapbox-gl';
import { SelectLocation } from 'power/components/filter';
import { ILocationLookupMapBox } from 'power/types';
import React, { useCallback } from 'react';
import styles from './SearchControlContainer.module.scss';

type Props = {
  fetcher: (query: string) => Promise<ILocationLookupMapBox[]>;
  map?: mapboxgl.Map;
};
const SearchControlContainer: React.FC<Props> = ({ fetcher, map }) => {
   /* const toggleStyle = useCallback(() => {
      if (map) {
         const nextStyleOption = styleOption === 'Default' ? 'Satellite' : 'Default';
         map.setStyle(MAPBOX.Styles[nextStyleOption]);
         setStyleOption(nextStyleOption);
      }
   }, [map, styleOption]);
 */
   const onSelectLocation = useCallback(
      (location: ILocationLookupMapBox) => {
         if (map) {
            map.flyTo({ center: [location.lng, location.lat], zoom: 18 });
         }
      },
      [map],
   );

   if (map === undefined) return null;

   return (
      <div className={styles.searchControlContainer}>
         <SelectLocation fetcher={fetcher} onSelectLocation={onSelectLocation} minWidth={416} />
      </div>
   );
};

export default SearchControlContainer;
