import { ApiClients } from 'ApiClients';
import { FuturePrice, FuturePriceRequest } from 'power/types';
import { useEffect, useMemo, useState } from 'react';

export const useFutureData = (initialQuery: FuturePriceRequest) => {
   const [query, setQuery] = useState<FuturePriceRequest>(initialQuery);
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<FuturePrice[]>();

   useEffect(() => {
      setLoading(true);
      setData(undefined);
      ApiClients.getInstance().futurePrice(query)
         .then((data) => {
            // console.log('LazyHeatmap', query, data);
            setData(data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [query]);

   const minmax = useMemo(
      () => data?.reduce(
         (acc, curr) => {
            if (curr.price < acc[0]) acc[0] = curr.price;
            if (curr.price > acc[1]) acc[1] = curr.price;
            return acc;
         },
         [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
      ) ?? [0, 0],
      [data],
   );

   // const minmax = useMemo(() => [20, 80], []);

   return {
      query,
      onSubmit: setQuery,
      loading,
      data,
      minmax,
   };
}