const DEFAULT_HEATMAP_COLORS = [
   /* '#033028',
   '#093b32',
   '#10463c',
   '#185247',
   '#1f5d52', */
   '#26695d',
   '#2d7668',
   '#358274',
   '#3c8f80',
   '#449c8c',
   '#4ca292',
   '#54a999',
   '#5bafa0',
   '#63b6a6',
   '#6abcad',
   '#72c3b4',
   '#79c9ba',
   '#81d0c1',
   '#88d6c8',
   '#90ddcf',
   '#91d9c4',
   '#94d4b9',
   '#97d0ae',
   '#9bcba3',
   '#9fc699',
   '#a4c08f',
   '#a8bb87',
   '#adb57f',
   '#b1af78',
   '#b5a973',
   '#b4a56c',
   '#b2a166',
   '#b19d60',
   '#b09959',
   '#af9553',
   '#ae904d',
   '#ad8c47',
   '#ac8841',
   '#ab833b',
   '#aa7f35',
   '#ae7c33',
   '#b17931',
   '#b57630',
   '#b9732f',
   '#bd702f',
   '#c16c2f',
   '#c46930',
   '#c86532',
   '#cc6034',
   '#cf5c36',
   '#ce5639',
   '#cd513b',
   '#cc4b3e',
   '#ca4641',
   '#c84043',
   '#c63b46',
   '#c43549',
   '#c1304c',
   '#be2a4f',
   '#bb2552',
   '#b4224a',
   '#ac2042',
   '#a51d3b',
   '#9d1b33',
   '#96192c',
   /* '#8e1826',
   '#86161f',
   '#7e1519',
   '#771313',
   '#6f120d', */
          
   /* '#FFFFFF',
   '#85E6BC',
   '#5AD8A6',
   '#2D8C6C',
   '#DB605C',
   '#CF3636',
   '#82151C' */

   /* '#FFFFFF',
   '#2D8C6C',
   '#42B389',
   '#5AD8A6',
   '#85E6BC',
   '#B3F2D5',
   '#DBFFEC',
   '#F5BCB5',
   '#E88D87',
   '#DB605C',
   '#CF3636',
   '#A82328',
   '#82151C' */
]
const hexToRgb = (hex: string): [number, number, number] => {
   // Fix "Cannot read properties of undefined (reading 'slice')" error
   if(!hex) return [0, 0, 0];
   
   const bigint = parseInt(hex.slice(1), 16);
   const r = (bigint >> 16) & 255;
   const g = (bigint >> 8) & 255;
   const b = bigint & 255;
   return [r, g, b];
}

const rgbToHex = (r: number, g: number, b: number): string => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`

const getTextColor = (backgroundColor: string): string => {
   const [r, g, b] = hexToRgb(backgroundColor);
   const brightness = (r * 299 + g * 587 + b * 114) / 1000;
   return brightness > 128 ? '#000000' : '#FFFFFF';
}

const interpolateColor = (value: number, min: number, max: number, colors: string[] = DEFAULT_HEATMAP_COLORS): {backgroundColor: string, textColor: string } => {
   if(value < min) value = min;
   if(value > max) value = max;

   const normalizedValue = (value - min) / (max - min);
   const steps = colors.length - 1;
   const scaledValue = normalizedValue * steps;
   const lowerIndex = Math.floor(scaledValue);
   const upperIndex = Math.ceil(scaledValue);
   const weight = scaledValue - lowerIndex;

   const lowerColor = hexToRgb(colors[lowerIndex]);
   const upperColor = hexToRgb(colors[upperIndex]);

   const r = Math.round(lowerColor[0] + weight * (upperColor[0] - lowerColor[0]));
   const g = Math.round(lowerColor[1] + weight * (upperColor[1] - lowerColor[1]));
   const b = Math.round(lowerColor[2] + weight * (upperColor[2] - lowerColor[2]));

   const backgroundColor = rgbToHex(r, g, b);
   const textColor = getTextColor(backgroundColor);
   return { backgroundColor, textColor };
}


export default interpolateColor;