import { FeatureCollection, Point } from 'geojson';
import { IPriceLayerProperties } from 'power/types/map';

const datasToPriceGeojsons = (datas: any[]): FeatureCollection<Point, IPriceLayerProperties> => {
   const featureCollection: FeatureCollection<Point, IPriceLayerProperties> = {
      type: 'FeatureCollection',
      features: datas
         .filter((d) => Number(d[0]) > 1)
         .map((d) => ({
            type: 'Feature',
            geometry: {
               type: 'Point',
               coordinates: [Number(d[1]), Number(d[0])],
            },
            properties: {
               price: Number(d[2]),
               locationType: d[3] === 'IsoHub' ? 'hub' : d[3] === 'DraftNode' ? 'node' : 'bus',
               id: Number(d[4]),
            },
         })),
   };

   return featureCollection;
};

const nodeMap = { datasToPriceGeojsons };

export default nodeMap;
