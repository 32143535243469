enum PRICE_COMPONENT {
  NO = 0,
  LMP = 1,
  MCC = 2,
  MCL = 3,
  MCE = 4,
  SOLAR = 5,
  WIND = 6,
  STDDEV = 10,
  MAXVAL = 11,
  MINVAL = 12,
  NEGATIVE_AVG = 15,
  NEGATIVE_HOURS = 20,
  STDDEV_AVG = 101,
  BASISVAL = 200,
  BASISSOLAR = 210,
  BASISWIND = 211,
  DARTVAL = 201,
  BATTER_CHARGE = 301, // AVG Rate or Price of charging
  BATTERY_DISCHARGE = 302, // AVG Rate or Price of discharging
  BATTERY_REVENUE = 303, // BU ASLINDA KAR, Yani Profit
  BATTER_SUM_PRICES = 304, // ?
  BATTERY_AVG_PRICES = 305, // ?
  BATTERY_REVENUE_KW_YEAR = 310, // BU ASLINDA KAR, Yani Profit
  BATTER_CHARGE_COUNT = 311,
  BATTERY_DISCHARGE_COUNT = 312,
  BATTER_CHARGE_SUM = 321, // Charging cost
  BATTERY_DISCHARGE_SUM = 322, // DisCharging revenue
  BATTERY_PROFIT_PER_MW = 333, // Total Profit / total discharge energy mw
  BATTERY_CHARGING_MWH = 341,
  BATTERY_DISCHARGING_MWH = 342,
  BATTERY_SOLAR_WSTORAGE = 351,
  BATTERY_SOLAR_WOSTORAGE = 352,

  BATTERY_ARBITRAGE = 400,
  BATTERY_WEIGHTED_ARBITRAGE_RATE = 401,
  BATTERY_HISTORICAL_PRICES = 450,
  BATTERY_PRODUCTION_DISTRIBUTION = 455,
  BATTERY_CYCLE_RATE = 456,
  BATTERY_CAPACITY = 460,
  BATTERY_CHARGING_RATE = 470,
  BATTERY_DISCHARGING_RATE = 471,
  BATTERY_EFFECTIVE_RATE_WS = 480,
  BATTERY_EFFECTIVE_RATE_WOS = 481,
  BATTERY_LMP = 490,
}

export default PRICE_COMPONENT;
