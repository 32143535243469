import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  lazyId?: string;
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
  classname?: string;
};

const LazyComponent: FC<PropsWithChildren<Props>> = ({ children, lazyId = Math.random(), root = null, rootMargin = '0px', threshold = 0.1, classname }) => {
   const containerRef = useRef<HTMLDivElement>(null);
   const [isVisible, setIsVisible] = useState(false);

   // console.log('LazyComponent', lazyId, isVisible);
   const options: IntersectionObserverInit = useMemo(
      () => ({
         root,
         rootMargin,
         threshold,
      }),
      [root, rootMargin, threshold],
   );

   useEffect(() => {
      const currentContainer = containerRef.current;
      // console.log('LazyComponent useEffect', lazyId, currentContainer, options);
      const intersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
         entries.forEach((entry) => {
            if (entry.isIntersecting) {
               setIsVisible((prev) => {
                  if (prev === false) console.debug('isVisible changed to true', lazyId);
                  return true;
               });
            }
         });
      };
      const observer = new IntersectionObserver(intersectionObserverCallback, options);

      if (currentContainer) observer.observe(currentContainer);

      return () => {
         if (currentContainer) {
            observer.unobserve(currentContainer);
            observer.disconnect();
         }
      };
   }, [containerRef, lazyId, options]);

   return (
      <div className={classname} style={classname === undefined && !isVisible ? { minHeight: 400 } : undefined} ref={containerRef}>
         {isVisible ? children : 'Boş'}
      </div>
   );
};

export default LazyComponent;
