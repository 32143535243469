import { ApiClients } from 'ApiClients';
import PATHS from 'power/PATHS';
import { useAuth } from 'providers/AuthProvider';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

export const LogoutPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.Auth_Logout, title: 'Auth - Logout' });

   const { authenticated, loggedOut } = useAuth();
   const navigate = useNavigate();

   useEffect(() => {
      if (!authenticated) navigate('/');
   }, [authenticated, navigate]);

   useEffect(() => {
      ApiClients.getInstance().logout();
      loggedOut();
   }, [loggedOut]);

   return null;
};
