import { FileSearchOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { ISubstationInfoRequest, ISubstationInfoResponse } from 'power/types';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import CapacityTab from './capacity';

type Props = {
  query: ISubstationInfoRequest;
  data: ISubstationInfoResponse;
};

const TabsContent: FC<Props> = ({ query, data }) => {
   const { hash } = useLocation();

   const setActiveKey = (key: string) => {
      window.location.hash = key;
   };

   const items: TabsProps['items'] = useMemo(() => {
      const tabProps: TabsProps['items'] = [
         /* {
            key: '#overview',
            label: 'Overview',
            children: <OverviewTab query={query} data={data} />,
         }, */
         {
            key: '#capacity',
            label: 'Capacity',
            children: <CapacityTab query={query} data={data} />,
            icon: <FileSearchOutlined />,
         },
      ];
      return tabProps;
   }, [query, data]);

   return (
      <Tabs
         style={{ paddingBottom: 32 }} activeKey={!!hash ? hash : items[0].key} centered
         type="card" items={items} onChange={setActiveKey} />
   );
};

export default TabsContent;
