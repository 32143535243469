/* eslint-disable max-len */
import { PPByISOContent } from './type';

const en: PPByISOContent = {
   title: 'Power Plant By ISO',
   projectMWByFuelTypeAndOperatingDate: 'Project (MW) by Fuel Type & Operating Date',
   projectMWByFuelTypeAndOperatingDateInfo:
    'The Project (MW) by Fuel Type and Operation Date chart shows the capacity additions of power plants categorized by fuel type and operation year, cumulatively added up from year to year. The data can be examined in depth using the ISO, fuel type, status, and year of operation filters at the top of the page.',
   fuelTypeOperatingDate: 'Fuel Type / Operating Date',
   capacityAndCountByFuelType: 'Capacity & Count by Fuel Type',
   capacityAndCountByFuelTypeInfo: 'The Capacity & Count by Fuel Type table displays the number of power plants and their total capacity categorized by fuel type. The data can be explored in depth by utilizing the ISO, fuel type, status, and operating year filters located at the top of the page.',
   capacityAsPercentage: 'Capacity as (%)',
   capacityAsPercentageInfo: 'The Capacity as (%) pie chart shows the proportional capacity distribution among different fuel types.The data can be explored in depth by utilizing the ISO, fuel type, status, and operating year filters located at the top of the page.',
   powerPlants: 'Power Plants',
   powerPlantsInfo:
    'This table displays essential data for various power plants, including their owner, plant name, market area, status, energy sources, age, capacity, emissions, etc. You can easily search, sort, and explore the power plants based on your preferences. You can also use the filters at the top of the page to refine the view. To delve deeper into individual power plant details, click the \'Details\' link at the end of the row.',
   plantName: 'Plant Name',
   plantAge: 'Plant Age',
   capacity: 'Capacity',
   co2: 'CO2',
   so2: 'SO2',
   nox: 'NOX',
   ageYear: 'Age (Year)',
   retired: 'Retired',
   canceled: 'Canceled',
   operating: 'Operating',
};

export default en;
