import { Menu } from 'antd';
import { useAuth } from 'providers/AuthProvider';
import React, { useMemo } from 'react';
import { useLocation /* , useNavigate */ } from 'react-router-dom';
import menuItems from './menuItems';

type Props = {
  setCollapsedMenu: (newCollapsed: boolean) => void;
};

export const NavigatorMenu: React.FC<Props> = ({ setCollapsedMenu }) => {
   // const navigate = useNavigate();
   const location = useLocation();
   const { hasPackage } = useAuth();

   const selectedKeys = useMemo(
      () => [location.pathname],
      /* const keys = router.pathname.split('/').filter(Boolean);
        keys.push(keys.join('/'));
        return keys; */
      [location.pathname],
   );
   // console.log("selectedKeys",selectedKeys)

   const onMenuItemClick = (key: string) => {
      /**
     * TODO if need
     */

      // console.log("onMenuItemClick", key)
      // navigate(`${key}`);
      setCollapsedMenu(true);
   };

   const userMenuItems = useMemo(() => menuItems?.filter((item) => !(item as any).subscriptionpackage || hasPackage((item as any).subscriptionpackage)), [hasPackage]);

   return (
      <Menu
         selectedKeys={selectedKeys}
         onClick={(e) => {
            onMenuItemClick(e.key);
         }}
         mode="inline"
         theme="light"
         items={userMenuItems}
         inlineIndent={12}
         style={{ borderInlineEnd: 'none' }}
      />
   );
};
