/* eslint-disable max-len */
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
   <svg
      viewBox="0 0 1024 1024" width="1em" height="1em"
      fill="currentColor">
      <g>
         <path d="m733.09,108.8h-46.55V14.02c-.03-7.46-6.07-13.5-13.52-13.52h-319.85c-7.46.03-13.5,6.07-13.52,13.52v94.77h-58.51c-30.72-.27-56.41,23.28-58.8,53.9v806.89c2.39,30.63,28.08,54.18,58.8,53.9h459.31c29.77,0,53.9-24.13,53.9-53.9h0V162.95c0-29.77-24.13-53.9-53.9-53.9h0m-26.74,833.48h-410.74V189.47h118.27v-107.83h186.8v107.83h105.67v753.05Z" />
         <path d="m379.73,594.57h70.66l-36.21,144.85c-.46,1.73.57,3.5,2.3,3.95,1.1.29,2.27-.02,3.09-.82l234.29-223.55c1.27-1.25,1.29-3.3.03-4.57-.59-.6-1.4-.95-2.24-.96h-89.24l80.27-101.39c1.11-1.4.87-3.43-.53-4.54-.58-.46-1.29-.7-2.02-.7h-155.34c-1.15,0-2.22.62-2.79,1.62l-104.97,181.31c-.92,1.53-.43,3.52,1.11,4.44.51.31,1.09.47,1.69.46" />
      </g>
   </svg>
);

const IconSvg = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;
export default IconSvg;
