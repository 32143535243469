import { CustomControlPosition } from 'power/types/map';
import * as React from 'react';
import { cloneElement, useState } from 'react';
import { createPortal } from 'react-dom';
import type { ControlPosition, IControl, MapInstance } from 'react-map-gl';
import { useControl } from 'react-map-gl';

// Based on template in https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
class OverlayControl implements IControl {
   _map: MapInstance | null = null;

   _container: HTMLElement;

   _redraw: () => void;

   constructor(redraw: () => void) {
      this._container = document.createElement('div');
      this._redraw = redraw;
   }

   onAdd(map: MapInstance) {
      this._map = map;
      map.on('move', this._redraw);
      /* global-document */
      this._container = document.createElement('div');
      this._container.className = 'mapboxgl-ctrl';
      this._redraw();
      return this._container;
   }

   onRemove() {
      this._container.remove();
      if (this._map) this._map.off('move', this._redraw);
      this._map = null;
   }

   getMap() {
      return this._map;
   }

   getElement() {
      return this._container;
   }
}

/**
 * A custom control that rerenders arbitrary React content whenever the camera changes
 */
function CustomOverlay(props: { position?: CustomControlPosition; children: React.ReactElement }) {
   const [, setVersion] = useState(0);

   const correctPosition: ControlPosition = (props.position === 'top-center' || props.position === 'bottom-center' ? 'bottom-left' : props.position) as ControlPosition;
   const ctrl = useControl<OverlayControl>(
      () => {
         const forceUpdate = () => setVersion((v) => v + 1);
         return new OverlayControl(forceUpdate);
      },
      { position: correctPosition },
   );

   if (ctrl._container.parentNode) {
      if (props.position === 'top-center') {
         (ctrl._container.parentNode as any).className = 'mapboxgl-ctrl-top-center';
      } else if (props.position === 'bottom-center') {
         (ctrl._container.parentNode as any).className = 'mapboxgl-ctrl-bottom-center';
      }
   }

   const map = ctrl.getMap();

   return map && createPortal(cloneElement(props.children, { map }), ctrl.getElement());
}

export default React.memo(CustomOverlay);
