import { Checkbox, /* Flex, Radio, */ Tree } from 'antd';
import { IMAGE_PATHS } from 'power/constants';
// import { TL_STATUS } from 'power/types/enum';
import { TL_DEFAULT_FILTER } from 'power/map/layers/StaticLayers/TransmissionLinesLayer';
import TL_VOLTAGE from 'power/types/enum/TL_VOLTAGE';
import ITLFilter from 'power/types/map/ITLFilter';
import React, { useCallback } from 'react';
import { MapRef } from 'react-map-gl';
import classes from './TransmissionLinesLayerControlContainer.module.scss';

const VoltageTreeNode = ({ fuelType, title }: { fuelType: TL_VOLTAGE; title: string }) => (
   <div key={fuelType} className={classes.iconText}>
      <img height={16} src={IMAGE_PATHS.MAP_ASSETS.VOLTAGE(fuelType)} alt={title} />
      {title}
   </div>
);

type Props = {
  filter: ITLFilter;
  changeFilter: (filter: ITLFilter) => void;
  map?: MapRef;
};
const TransmissionLinesLayerControlContainer: React.FC<Props> = ({ filter, changeFilter, map }) => {
   const toggleActive = useCallback(() => changeFilter({ ...filter, active: !filter.active }), [changeFilter, filter]);

   if (map === undefined) return null;

   return (
      <div className={classes.transmissionLinesLayerControlContainer}>
         <Checkbox /* indeterminate={indeterminate} */ checked={filter.active} onChange={toggleActive}>
            <b>Transmission Line</b>
         </Checkbox>

         {filter.active && (
            <>
               {/* <Flex vertical={false} justify="center" style={{ paddingTop: 8 }}>
                  <Radio.Group
                     value={filter.status} onChange={(e) => changeFilter({ ...filter, status: e.target.value })} buttonStyle="outline"
                     size="small" optionType="button">
                     <Radio value={TL_STATUS.InService}>In Service</Radio>
                     <Radio value={TL_STATUS.InActive}>In Active</Radio>
                     <Radio value={TL_STATUS.NotAvailable}>Not Available</Radio>
                     <Radio value={TL_STATUS.UnderConstruction}>Under Construction</Radio>
                  </Radio.Group>
               </Flex> */}

               <div className={classes.body}>
                  <Tree
                     checkable
                     defaultExpandAll
                     selectable={false}
                     checkedKeys={filter.voltages.map(String)}
                     onCheck={(
                        checkedKeys:
                  | React.Key[]
                  | {
                      checked: React.Key[];
                      halfChecked: React.Key[];
                    },
                     ) => {
                        if (Array.isArray(checkedKeys)) {
                           if (checkedKeys.includes(TL_VOLTAGE.Undefined)) {
                              changeFilter({ ...filter, voltages: TL_DEFAULT_FILTER.voltages });
                           } else {
                              changeFilter({ ...filter, voltages: checkedKeys.map((x) => x as TL_VOLTAGE) });
                           }
                        } else {
                           if (checkedKeys.checked.includes(String(TL_VOLTAGE.Undefined))) {
                              changeFilter({ ...filter, voltages: TL_DEFAULT_FILTER.voltages });
                           } else {
                              changeFilter({ ...filter, voltages: checkedKeys.checked.map((x) => x as TL_VOLTAGE) });
                           }
                        }
                     }}
                     treeData={[
                        {
                           title: 'All',
                           key: TL_VOLTAGE.Undefined,
                           children: [
                              // all fuel types
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['0 - 68']} title="0 - 68" />, key: String(TL_VOLTAGE['0 - 68']) },
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['69 - 114']} title="69 - 114" />, key: String(TL_VOLTAGE['69 - 114']) },
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['115 - 274']} title="115 - 274" />, key: String(TL_VOLTAGE['115 - 274']) },
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['275 - 344']} title="275 - 344" />, key: String(TL_VOLTAGE['275 - 344']) },
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['345 - 499']} title="345 - 499" />, key: String(TL_VOLTAGE['345 - 499']) },
                              { title: <VoltageTreeNode fuelType={TL_VOLTAGE['500+']} title="500+" />, key: String(TL_VOLTAGE['500+']) },
                           ],
                        },
                     ]}
                  />
               </div>
            </>
         )}
      </div>
   );
};

export default TransmissionLinesLayerControlContainer;
