import { Spin, Typography } from 'antd';
import { ApiClients } from 'ApiClients';
import { ITransmissionLinesDetail } from 'power/types';
import { TL_TYPE } from 'power/types/enum';
import { ITransmissionLinesLayerProperties } from 'power/types/map';
import { FC, useEffect, useState } from 'react';

const InfoItem: FC<{ label: string; value: any }> = ({ label, value }) => (
   <div className="map-popup-row">
      <div className="map-popup-row-label">
         <Typography.Text>{label}</Typography.Text>
      </div>
      <div className="map-popup-row-value">{value}</div>
   </div>
);

type Props = {
   feature: ITransmissionLinesLayerProperties;
};

const TlPopup: FC<Props> = ({ feature }) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<ITransmissionLinesDetail>();

   useEffect(() => {
      ApiClients.getInstance().mapTransmissionLineDetail(Number(feature.id))
         .then((data) => {
            setData(data);
            // setData(data.filter((item) => !['currenttype', 'inferred', 'source', 'status', 'voltageclass', 'valdate', 'valmethod', 'sourcedate'].includes(item.key)));
         })
         .finally(() => {
            setLoading(false);
         });
   }, [feature.id]);

   
   if (loading || data === undefined)
      return (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 200 }}>
            <Spin size="large" />
         </div>
      );

   return (
      <div style={{ /* paddingTop: 10,  */ width: 300 }}>
         <h3 style={{ textAlign: 'center', marginTop: 0 }}>Transmission Line</h3>
         <div className="custom-popup">
            <InfoItem label="Owner" value={data.owner || 'N/A'} />
            <InfoItem label="Voltage" value={data.voltage} />
            <InfoItem label="Sub1" value={data.sub1 || 'N/A'} />
            <InfoItem label="Sub2" value={data.sub2 || 'N/A'} />
            
            {/* <InfoItem label="Inferred" value={data.inferred} /> */}
            <InfoItem label="Naics Code" value={data.naicsCode || 'N/A'} />
            <InfoItem label="Naics Description" value={data.naicsDescription || 'N/A'} />

            {/* <InfoItem label="Status" value={TL_STATUS[data.status]} /> */}
            <InfoItem label="Type" value={TL_TYPE[data.type]} />
            {/* <InfoItem label="Current Type" value={TL_CURRENT_TYPE[data.currentType]} /> */}

            {/* <InfoItem label="Source" value={data.source} />
            <InfoItem label="Source Date" value={data.sourceDate} /> */}
            
            {/* <InfoItem label="Val Method" value={TL_VAL_METHOD[data.valMethod]} />
            <InfoItem label="Val Date" value={data.valDate} /> */}
            
            
         </div>
      </div>
   );
};

export default TlPopup;
