import { Select } from 'antd';
import { ICapacityBranch, ICapacitySubstations } from 'power/types';
import React, { memo, useMemo } from 'react';
import branchPng from './assets/branch.png';
import sprintPng from './assets/sprint.png';
import summerPng from './assets/summer.png';
import winterPng from './assets/winter.png';
import classes from './index.module.scss';

type Props = {
  data: { substations?: ICapacitySubstations; branch?: ICapacityBranch };
  selectedSubstationId: number;
  selectedBranchId: string;
  setSelectedSubstation: (substationId: number) => void;
  // setSelectedBranch: (branchId: string) => void;
  // setSelectedNode: (nodeId: number) => void;
};

const CapacityInfo: React.FC<Props> = ({ data, selectedSubstationId, setSelectedSubstation }) => {
   const fixedSelectedSubstationId = useMemo(() => (selectedSubstationId > 0 ? selectedSubstationId : data.substations?.substations[0].id), [selectedSubstationId, data.substations]);
   const substation = useMemo(() => data.substations?.substations.find((item) => item.id === fixedSelectedSubstationId), [data.substations, fixedSelectedSubstationId]);
   const branch = useMemo(() => data.branch, [data.branch]);

   return (
      <div className={classes.container}>
         {substation && (
            <>
               <div className={classes.substationSelect}>
                  {/* <h3>Substation - [Model Identifier]</h3> */}
                  <Select
                     value={fixedSelectedSubstationId} onChange={setSelectedSubstation} options={data.substations?.substations.map((item) => ({ label: `${item.name} - [${item.identifier}]`, value: item.id }))}
                     style={{ width: '100%', borderRadius: 12 }} />
               </div>

               <div className={classes.substationInfo}>
                  <div className={classes.left}>
                     <div>
                        <div className={classes.identifier}>[{substation.identifier}]</div>
                        <div className={classes.name}>{substation.name}</div>
                     </div>

                     <div>
                        <div className={classes.substationArea}>Substation Area</div>
                        <div className={classes.substationAreaValue}>{data.substations?.location.substationArea}</div>
                     </div>

                     <div>
                        <div className={classes.substationArea}>County</div>
                        <div className={classes.substationAreaValue}>{data.substations?.location.county}</div>
                     </div>
                  </div>
                  <div className={classes.right}>
                     <div className={`${classes.section} ${classes.capacity}`}>
                        <div className={classes.header1}>Headroom</div>
                        <div className={classes.header1}>Capacity</div>
                        <div className={classes.bigValue}>{substation.capacity}</div>
                        <div className={classes.unit}>MW</div>
                     </div>

                     <div className={classes.hr}> </div>

                     <div className={`${classes.section} ${classes.voltage}`}>
                        <div className={classes.header1}>Nominal</div>
                        <div className={classes.header1}>Voltage</div>
                        <div className={classes.bigValue}>{substation.voltage}</div>
                        <div className={classes.unit}>kV</div>
                     </div>
                  </div>
               </div>
               <div className={classes.capacities}>
                  <div className={classes.header}>Available Capacity</div>
                  <div className={classes.body}>
                     <div className={classes.row}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={summerPng} alt="Summer Peak" />
                           <span className={classes.iconText}>Summer Peak</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {substation.capacities.summerPeak.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${substation.refurbishmentCosts.summerPeak.charging.toFixed(2)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {substation.capacities.summerPeak.discharging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${substation.refurbishmentCosts.summerPeak.discharging.toFixed(2)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={classes.row}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={sprintPng} alt="Spring Light" />
                           <span className={classes.iconText}>Spring Light</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {substation.capacities.lightLoad.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${substation.refurbishmentCosts.lightLoad.charging.toFixed(2)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {substation.capacities.lightLoad.discharging}
                                 <span className={classes.unit2}> MW</span>
                                 <div className={classes.value}>
                        ${substation.refurbishmentCosts.lightLoad.discharging.toFixed(2)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     {substation.capacities.winterPeak && substation.refurbishmentCosts.winterPeak && (
                        <div className={classes.row}>
                           <div className={classes.iconHeader}>
                              <img className={classes.icon} src={winterPng} alt="Winter Peak" />
                              <span className={classes.iconText}>Winter Peak</span>
                           </div>
                           <div className={classes.content}>
                              <div className={classes.group}>
                                 <div className={classes.header2}>Injection</div>
                                 <div className={classes.value}>
                                    {substation.capacities.winterPeak.charging}
                                    <span className={classes.unit2}> MW</span>
                                 </div>
                                 <div className={classes.value}>
                      ${substation.refurbishmentCosts.winterPeak.charging.toFixed(2)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                              <div className={classes.group}>
                                 <div className={classes.header2}>Withdrawal</div>
                                 <div className={classes.value}>
                                    {substation.capacities.winterPeak.discharging}
                                    <span className={classes.unit2}> MW</span>
                                    <div className={classes.value}>
                        ${substation.refurbishmentCosts.winterPeak.discharging.toFixed(2)}
                                       <span className={classes.unit2}>M</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </>
         )}

         {branch && (
            <>
               <div className={classes.branchInfo}>
                  <div className={classes.branch}>
                     <span>Branch</span>
                     <img className={classes.iconBranch} src={branchPng} alt="Branch" />
                  </div>
                  <div className={classes.from}>from {branch.branch.fromStation}</div>
                  <div className={classes.id}>{branch.branch.id}</div>

                  <div className={classes.sections}>
                     <div className={`${classes.section} ${classes.capacity}`} style={{ alignItems: 'flex-start' }}>
                        <div className={classes.header1}>Headroom Capacity</div>
                        <div className={classes.bigValue}>
                           {branch.branch.capacity}
                           <span className={classes.unit}> MW</span>
                        </div>
                     </div>

                     <div className={`${classes.section} ${classes.voltage}`} style={{ alignItems: 'flex-start', paddingLeft: 30 }}>
                        <div className={classes.header1}>Nominal Voltage</div>
                        <div className={classes.bigValue}>
                           {branch.branch.voltage}
                           <span className={classes.unit}> kV</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className={classes.capacities}>
                  <div className={classes.header}>Available Capacity</div>
                  <div className={classes.body}>
                     <div className={classes.row}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={summerPng} alt="Summer Peak" />
                           <span className={classes.iconText}>Summer Peak</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {branch.branch.capacities.summerPeak.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.summerPeak.charging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {branch.branch.capacities.summerPeak.discharging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.summerPeak.discharging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={classes.row}>
                        <div className={classes.iconHeader}>
                           <img className={classes.icon} src={sprintPng} alt="Spring Light" />
                           <span className={classes.iconText}>Spring Light</span>
                        </div>
                        <div className={classes.content}>
                           <div className={classes.group}>
                              <div className={classes.header2}>Injection</div>
                              <div className={classes.value}>
                                 {branch.branch.capacities.lightLoad.charging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.lightLoad.charging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                           <div className={classes.group}>
                              <div className={classes.header2}>Withdrawal</div>
                              <div className={classes.value}>
                                 {branch.branch.capacities.lightLoad.discharging}
                                 <span className={classes.unit2}> MW</span>
                              </div>
                              <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.lightLoad.discharging?.toFixed(1)}
                                 <span className={classes.unit2}>M</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     {branch.branch.capacities.winterPeak && branch.branch.refurbishmentCosts.winterPeak && (
                        <div className={classes.row}>
                           <div className={classes.iconHeader}>
                              <img className={classes.icon} src={winterPng} alt="Winter Peak" />
                              <span className={classes.iconText}>Winter Peak</span>
                           </div>
                           <div className={classes.content}>
                              <div className={classes.group}>
                                 <div className={classes.header2}>Injection</div>
                                 <div className={classes.value}>
                                    {branch.branch.capacities.winterPeak.charging}
                                    <span className={classes.unit2}> MW</span>
                                 </div>
                                 <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.winterPeak.charging?.toFixed(1)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                              <div className={classes.group}>
                                 <div className={classes.header2}>Withdrawal</div>
                                 <div className={classes.value}>
                                    {branch.branch.capacities.winterPeak.discharging}
                                    <span className={classes.unit2}> MW</span>
                                 </div>
                                 <div className={classes.value}>
                      ${branch.branch.refurbishmentCosts.winterPeak.discharging?.toFixed(1)}
                                    <span className={classes.unit2}>M</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

export default memo(CapacityInfo);
