import { PrivatePageLayout } from 'layout/PrivatePageLayout';
import NewsList from 'modules/News/List';
import PATHS from 'power/PATHS';
import HeaderTitle from 'power/components/base/HeaderTitle/HeaderTitle';
import ReactGA from 'react-ga4';

export const NewsListPage = () => {
   ReactGA.send({ hitType: 'pageview', page: PATHS.News, title: 'PowerNews AI' });
   
   return (
      <PrivatePageLayout headerPrimary={<HeaderTitle title="PowerNews AI" />}>
         <NewsList />
      </PrivatePageLayout>
   );
}