// import { PowerMap } from 'power/components/maps';
import { ApiClients } from 'ApiClients';
import { IICAPStrip, IICAPStripRequest } from 'power/types';
import { ICAP_ZONE_NYISO } from 'power/types/enum';
import { utils } from 'power/utils';
import { FC, useEffect, useState } from 'react';
import Table from '../Table';

const ICAP_ZONES: ICAP_ZONE_NYISO[] = [ICAP_ZONE_NYISO.NYCA, ICAP_ZONE_NYISO.LI, ICAP_ZONE_NYISO.NYC, ICAP_ZONE_NYISO.GJ];

type Props = {
  queryStrip: IICAPStripRequest;
};

const StripTable: FC<Props> = ({ queryStrip }) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [data, setData] = useState<IICAPStrip[]>();

   useEffect(() => {
      setLoading(true);
      setData(undefined);

      const promises = ICAP_ZONES.map((zone) => ApiClients.getInstance().icapStripDetail({ ...queryStrip, zone }));

      Promise.all(promises)
         .then((res) => {
            setData(res.flat());
         })
         .finally(() => setLoading(false));
   }, [queryStrip]);

   const keys = ['Awarded (MW)', 'Price ($/kW - Month)'];

   /* const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] = [
      { zone: ICAP_ZONE_NYISO.NYCA, keys, allValues: [[482.0, 3.91]] },
      { zone: ICAP_ZONE_NYISO.LI, keys, allValues: [[482.0, 3.91]] },
      { zone: ICAP_ZONE_NYISO.NYC, keys, allValues: [[482.0, 3.91]] },
      { zone: ICAP_ZONE_NYISO.GJ, keys, allValues: [[482.0, 3.91]] },
   ]; */

   const rows: { zone: ICAP_ZONE_NYISO; keys: string[]; allValues: number[][] }[] =
    data?.map((strip) => ({
       zone: strip.zone,
       keys,
       allValues: [[strip.awarded, strip.price]],
    })) ?? [];

   return <Table headers={['Strip', '', utils.capitalizeFirstLetter(queryStrip.period_season)]} rows={rows} loadingSize={loading ? [352, 254] : undefined} />;
};

export default StripTable;
