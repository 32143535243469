import { AreaConfig, BarConfig, ColumnConfig, HeatmapConfig, LineConfig, MixConfig } from '@ant-design/plots';
import { grayBase, mainColorSet } from 'constants/NewColors';
import { useMemo } from 'react';
import { normalizeNumber } from 'utils/math';

type ChartTypeConfigMap = {
  column: Partial<ColumnConfig>;
  bar: Partial<BarConfig>;
  line: Partial<LineConfig>;
  area: Partial<AreaConfig>;
  heatmap: Partial<HeatmapConfig>;
  mix: Partial<MixConfig>;
};

const configs: Record<keyof ChartTypeConfigMap, (x: string, y: string) => ChartTypeConfigMap[keyof ChartTypeConfigMap]> = {
   column: (xAxisTitle?: string, yAxisTitle?: string): Partial<ColumnConfig> => ({
      data: [],
      // TODO: control all charts
      // maxColumnWidth: 30,
      xAxis: {
         title: {
            text: xAxisTitle,
            position: 'end',
         },
      },
      yAxis: {
         title: {
            text: yAxisTitle,
            position: 'end',
            autoRotate: false,
            style: {
               y: 5,
               x: 0,
               textAlign: 'left',
            },
         },
         label: {
            formatter: (text) => (!Number.isNaN(+text) ? normalizeNumber(+text) : text),
         },
      },
      interactions: [
         {
            type: 'element-highlight-by-color',
         },
      ],
      locale: 'en',
      // label: {
      //   position: 'middle',
      //   layout: [
      //     {
      //       type: 'interval-adjust-position',
      //     },
      //     {
      //       type: 'interval-hide-overlap',
      //     },
      //     {
      //       type: 'adjust-color',
      //     },
      //   ],
      // },
      brush: {
         enabled: true,
         type: 'x-rect',
      },
      legend: {
         position: 'bottom',
         itemSpacing: 10,
         padding: [30, 0, 0, 0],
         flipPage: false,
      },
      // TODO: its not working right for all chart types i.e. heatmap
      appendPadding: [30, 0, 0, 0],
      color: mainColorSet,
      className: 'antd-chart',
   }),

   bar: (xAxisTitle?: string, yAxisTitle?: string): Partial<BarConfig> => ({
      data: [],
      xAxis: {
         title: {
            text: xAxisTitle,
            position: 'end',
         },
         label: {
            formatter: (text) => (!Number.isNaN(+text) ? normalizeNumber(+text) : text),
         },
      },
      yAxis: {
         title: {
            text: yAxisTitle,
            position: 'end',
            autoRotate: false,
            style: {
               y: 5,
               x: 0,
               textAlign: 'left',
            },
         },
      },
      interactions: [
         {
            type: 'element-highlight-by-color',
         },
      ],
      locale: 'en',
      brush: {
         enabled: true,
         type: 'x-rect',
      },
      legend: {
         position: 'bottom',
         itemSpacing: 10,
         padding: [30, 0, 0, 0],
         flipPage: false,
      },
      // TODO: its not working right for all chart types i.e. heatmap
      appendPadding: [30, 0, 0, 0],
      color: mainColorSet,
      className: 'antd-chart',
   }),

   line: (xAxisTitle?: string, yAxisTitle?: string): Partial<LineConfig> => ({
      data: [],
      xAxis: {
         title: {
            text: xAxisTitle,
            position: 'end',
         },
      },
      yAxis: {
         title: {
            text: yAxisTitle,
            position: 'end',
            autoRotate: false,
            style: {
               y: 5,
               x: 0,
               textAlign: 'left',
            },
         },
         label: {
            formatter: (text) => (!Number.isNaN(+text) ? normalizeNumber(+text) : text),
         },
      },
      interactions: [
         {
            type: 'element-highlight-by-color',
         },
      ],
      locale: 'en',
      point: {
         size: 3,
         shape: 'circle',
      },
      legend: {
         position: 'bottom',
         itemSpacing: 10,
         padding: [30, 0, 0, 0],
         flipPage: false,
      },
      appendPadding: [30, 0, 0, 0],
      color: mainColorSet,
      className: 'antd-chart',
   }),

   area: (xAxisTitle?: string, yAxisTitle?: string): Partial<AreaConfig> => ({
      data: [],
      xAxis: {
         title: {
            text: xAxisTitle,
            position: 'end',
         },
      },
      yAxis: {
         title: {
            text: yAxisTitle,
            position: 'end',
            autoRotate: false,
            style: {
               y: 5,
               x: 0,
               textAlign: 'left',
            },
         },
         label: {
            formatter: (text) => (!Number.isNaN(+text) ? normalizeNumber(+text) : text),
         },
      },
      interactions: [
         {
            type: 'element-highlight-by-color',
         },
      ],
      locale: 'en',
      legend: {
         position: 'bottom',
         itemSpacing: 10,
         padding: [30, 0, 0, 0],
         flipPage: false,
      },
      appendPadding: [30, 0, 0, 0],
      color: mainColorSet,
      className: 'antd-chart',
   }),

   heatmap: (xAxisTitle?: string, yAxisTitle?: string): Partial<HeatmapConfig> => ({
      data: [],
      autoFit: true,
      // interactions: [
      //   {
      //     type: 'element-highlight-by-color',
      //   }
      // ],
      label: {
         style: {
            fontSize: 9,
            fill: '#f2f2f2',
            shadowColor: '#000',
            shadowBlur: 1,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
         },
         formatter: (d) => normalizeNumber(d.value),
      },
      xAxis: {
         position: 'top',
      },
      yAxis: {
         title: {
            text: yAxisTitle,
            position: 'end',
            autoRotate: false,
            style: {
               y: 5,
               x: 0,
               textAlign: 'left',
            },
         },
         label: {
            style: (_, index) => ({
               fill: index % 2 === 0 ? grayBase.main : undefined,
               fillOpacity: index % 2 === 0 ? 0.55 : 1,
               fontSize: 10,
            }),
            // formatter: (d) => normalizeNumber(d)
         },
      },
      locale: 'en',
      legend: {
         position: 'bottom',
         itemSpacing: 10,
         padding: [30, 0, 0, 0],
         flipPage: false,
      },
      appendPadding: [20, 0, 0, 0],
      color: mainColorSet,
      className: 'antd-chart',
   }),

   mix: (): Partial<MixConfig> => ({
      className: 'antd-chart',
      plots: [],
      // appendPadding: 8,
      legend: [
         {
            position: 'bottom',
            itemSpacing: 10,
            padding: [30, 0, 0, 0],
            flipPage: false,
         },
      ] as any,
      tooltip: false,
      syncViewPadding: true,
   }),
};

const useAntdChartConfig = <T extends keyof ChartTypeConfigMap>(chart: T, xAxisTitle = '', yAxisTitle = ''): ChartTypeConfigMap[T] => {
   const chartConfig = useMemo(() => configs[chart](xAxisTitle, yAxisTitle), [chart, xAxisTitle, yAxisTitle]);

   return chartConfig as ChartTypeConfigMap[T];
};

export default useAntdChartConfig;
