import { CircleLayout, CirclePaint, Expression, SymbolLayout, SymbolPaint } from 'mapbox-gl';

const LAYOUT_CIRCLE_STRIP: CircleLayout = {};
const PAINT_CIRCLE_STRIP = (circleColor?: Expression | string): CirclePaint => ({
   // 'circle-color': '#00ff00', // clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
   'circle-color': circleColor,
   'circle-radius': 26,
});

const LAYOUT_STRIP_TITLE: SymbolLayout = {
   'text-field': ['literal', 'ST'],
   'text-size': 12,
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, -1],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const LAYOUT_STRIP: SymbolLayout = {
   'text-field': ['concat', '$', ['get', 'price']], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 13, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0.6],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const PAINT_STRIP: SymbolPaint = {
   'text-color': '#ffffff',
};

// SPOT
const LAYOUT_CIRCLE_SPOT: CircleLayout = {};
const PAINT_CIRCLE_SPOT = (circleColor?: Expression | string): CirclePaint => ({
   // 'circle-color': '#00ff00', // clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
   'circle-color': circleColor,
   'circle-radius': 26,
   'circle-translate': [-26, -46],
});

const LAYOUT_SPOT_TITLE: SymbolLayout = {
   'text-field': ['literal', 'SP'],
   'text-size': 12,
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [-2.2, -5],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const LAYOUT_SPOT: SymbolLayout = {
   'text-field': ['concat', '$', ['get', 'price']], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 13, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [-2, -3],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const PAINT_SPOT: SymbolPaint = {
   'text-color': '#ffffff',
};

// MONTLY
const LAYOUT_CIRCLE_MONTLY: CircleLayout = { };
const PAINT_CIRCLE_MONTLY = (circleColor?: Expression | string): CirclePaint => ({
   // 'circle-color': '#00ff00', // clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
   'circle-color': circleColor,
   'circle-radius': 26,
   'circle-translate': [+26, -46],
});

const LAYOUT_MONTLY_TITLE: SymbolLayout = {
   'text-field': ['literal', 'MO'],
   'text-size': 12,
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [2.25, -5],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const LAYOUT_MONTLY: SymbolLayout = {
   'text-field': ['concat', '$', ['get', 'price']], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 13, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [+2, -3],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const PAINT_MONTLY: SymbolPaint = {
   'text-color': '#ffffff',
};

// FUTURE
const LAYOUT_CIRCLE_FUTURE: CircleLayout = {};
const PAINT_CIRCLE_FUTURE = (circleColor?: Expression | string): CirclePaint => ({
   // 'circle-color': '#00ff00', // clusterColor, // ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
   'circle-color': circleColor,
   'circle-radius': 26,
});

const LAYOUT_FUTURE_TITLE: SymbolLayout = {
   'text-field': ['literal', 'FU'],
   'text-size': 12,
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, -1],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const LAYOUT_FUTURE: SymbolLayout = {
   'text-field': ['concat', '$', ['get', 'price']], // GeoJSON'daki 'name' özelliğini kullan
   'text-size': 13, // Yazı boyutu
   'text-anchor': 'center', // Yazıyı merkezde konumlandır
   'text-offset': [0, 0.6],
   'symbol-placement': 'point',
   'text-allow-overlap': false,
   'icon-allow-overlap': false,
};

const PAINT_FUTURE: SymbolPaint = {
   'text-color': '#ffffff',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   LAYOUT_CIRCLE_STRIP,
   PAINT_CIRCLE_STRIP,
   LAYOUT_STRIP,
   LAYOUT_STRIP_TITLE,
   PAINT_STRIP,
   LAYOUT_CIRCLE_SPOT,
   PAINT_CIRCLE_SPOT,
   LAYOUT_SPOT,
   LAYOUT_SPOT_TITLE,
   PAINT_SPOT,
   LAYOUT_CIRCLE_MONTLY,
   PAINT_CIRCLE_MONTLY,
   LAYOUT_MONTLY,
   LAYOUT_MONTLY_TITLE,
   PAINT_MONTLY,
   LAYOUT_CIRCLE_FUTURE,
   PAINT_CIRCLE_FUTURE,
   LAYOUT_FUTURE,
   LAYOUT_FUTURE_TITLE,
   PAINT_FUTURE,
};
