import { ENV } from 'power/constants';
import { NYISO_ZONE } from 'power/types/enum';

type HubItem = {
  id: number;
  // provider: string;
  // lat?: number;
  // lng?: number;
  // nodeCount: number;
  label: string;
  zone: NYISO_ZONE;
};

const NYISO_HUBS_DEMO: HubItem[] = [
   {
      id: 41180,
      label: 'LONGIL',
      zone: NYISO_ZONE.K,
   },
];
const NYISO_HUBS_FULL: HubItem[] = [
   {
      id: 41172,
      label: 'WEST',
      zone: NYISO_ZONE.A,
   },
   {
      id: 41175,
      label: 'GENESE',
      zone: NYISO_ZONE.B,
   },
   {
      id: 41177,
      label: 'CENTRL',
      zone: NYISO_ZONE.C,
   },
   {
      id: 41174,
      label: 'NORTH',
      zone: NYISO_ZONE.D,
   },
   {
      id: 41171,
      label: 'MHK VL',
      zone: NYISO_ZONE.E,
   },
   {
      id: 41178,
      label: 'CAPITL',
      zone: NYISO_ZONE.F,
   },
   {
      id: 41184,
      label: 'HUD VL',
      zone: NYISO_ZONE.G,
   },
   {
      id: 41176,
      label: 'MILLWD',
      zone: NYISO_ZONE.H,
   },
   {
      id: 41179,
      label: 'DUNWOD',
      zone: NYISO_ZONE.I,
   },
   {
      id: 41173,
      label: 'N Y C',
      zone: NYISO_ZONE.J,
   },
   {
      id: 41180,
      label: 'LONGIL',
      zone: NYISO_ZONE.K,
   },
];

export const NYISO_HUBS = ENV.DOMAIN === 'trial' ? NYISO_HUBS_DEMO : NYISO_HUBS_FULL;
