import { FeatureCollection, MultiLineString, Point } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { usePowerMap } from 'power/map/usePowerMap';
import React, { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import CapacityBranchesLayer from './CapacityBranchesLayer';
import CapacitySubstationsLayer from './CapacitySubstationsLayer';
import { CapacityProperties } from './type';
import useHeadroomCapacity from './useHeadroomCapacity';
import { getPaintBranches, getPaintBranchesHighlight, getPaintSubstations, getPaintSubstationsHighlight } from './utils';

const CAPACITY_BRANCHES_LAYER_ID = 'capacity-branhces-layer';
const CAPACITY_BRANCHES_HIGHLIGHT_LAYER_ID = 'capacity-branhces-highlight-layer';
const CAPACITY_SUBSTATIONS_LAYER_ID = 'capacity-substations-layer';
const CAPACITY_SUBSTATIONS_HIGHLIGHT_LAYER_ID = 'capacity-substations-highlight-layer';

type Props = {
  mapId: string;
};

const CapacityLayers: React.FC<Props> = ({ mapId }) => {
   const { capacityLayer, setCapacityLayer, selection, setSelection } = usePowerMap();
   const { [mapId]: map } = useMap();
   const { loading, fetch, capacitySubstations, capacityBranches } = useHeadroomCapacity();

   useEffect(() => {
      setCapacityLayer((prev) => (prev ? { ...prev, loading } : undefined));
   }, [loading, setCapacityLayer]);

   useEffect(() => {
      if (capacityLayer?.query) fetch(capacityLayer?.query);
   }, [fetch, capacityLayer?.query]);

   useEffect(() => {
      setCapacityLayer((prev) => (prev ? { ...prev, data: { substations: capacitySubstations, branches: capacityBranches } } : undefined));
   }, [capacitySubstations, capacityBranches, setCapacityLayer]);

   const [paintSubstations, paintSubstationsHighlight, paintBranches, paintBranchesHighlight, minMaxSubstations, minMaxBranches] = useMemo(() => {
      const valuesSubstations = capacityLayer?.data?.substations?.features.map((s) => s.properties.capacity) ?? [0];
      const minMaxSubstations = [Math.min(...valuesSubstations), Math.max(...valuesSubstations)];
      const paintSubstations = getPaintSubstations(minMaxSubstations[0], minMaxSubstations[1]) ?? [0];
      const paintSubstationsHighlight = getPaintSubstationsHighlight();
      const countSubstations = capacityLayer?.data?.substations?.features.length ?? 0;

      const valuesBranches = capacityLayer?.data?.branches?.features.map((t) => t.properties.capacity) ?? [0];
      const minMaxBranches = [Math.min(...valuesBranches), Math.max(...valuesBranches)];
      const paintBranches = getPaintBranches(minMaxBranches[0], minMaxBranches[1]);
      const paintBranchesHighlight = getPaintBranchesHighlight();
      const countBranches = capacityLayer?.data?.branches?.features.length ?? 0;

      return [paintSubstations, paintSubstationsHighlight, paintBranches, paintBranchesHighlight, minMaxSubstations, minMaxBranches, countSubstations, countBranches];
   }, [capacityLayer?.data?.substations?.features, capacityLayer?.data?.branches?.features]);

   // onClick features listeners
   useEffect(() => {
      const onClickListener = (ev: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] } & mapboxgl.EventData) => {
         const { features } = ev;
         if (!features || features.length === 0) return;

         // console.log('click', features);
         const substationIds = features.filter((f) => f.layer.id === CAPACITY_SUBSTATIONS_LAYER_ID)?.map((x) => x.properties?.id);
         const branchIds = features.filter((f) => f.layer.id === CAPACITY_BRANCHES_LAYER_ID)?.map((x) => x.properties?.id);
         // console.log('click', substationIds, branchIds);
         // setSelection({ substationIds, branchIds });
         if (substationIds.length > 0) setSelection({ substationIds });
         else if (branchIds.length > 0) setSelection({ branchIds });
      };

      if (map) {
         map.on('click', [CAPACITY_SUBSTATIONS_LAYER_ID, CAPACITY_BRANCHES_LAYER_ID], onClickListener as any);
      }

      return () => {
         if (map) {
            map.off('click', [CAPACITY_SUBSTATIONS_LAYER_ID, CAPACITY_BRANCHES_LAYER_ID], onClickListener as any);
         }
      };
   }, [map, setSelection]);

   useEffect(() => {
      if (map) {
         if (capacityLayer?.data?.substations && capacityLayer?.data.branches) {
            // map.flyTo({ center: [headroomCapacity.substations[0][1], headroomCapacity.substations[0][2]] });
            const bounds = getMapBounds(capacityLayer?.data.substations, capacityLayer?.data.branches);
            if (bounds)
               map.fitBounds(bounds, {
                  /* bearing: 180, pitch: 40 */
               });
         }
      }
   }, [capacityLayer?.data?.branches, capacityLayer?.data?.substations, map]);

   if (!capacityLayer?.active || !capacityLayer?.data || (!capacityLayer?.data.substations && !capacityLayer?.data.branches) || !map) return null;

   return (
      <>
         {capacityLayer?.visible?.branches && capacityLayer?.data.branches && (
            <CapacityBranchesLayer
               layerId={CAPACITY_BRANCHES_LAYER_ID} highlightLayerId={CAPACITY_BRANCHES_HIGHLIGHT_LAYER_ID} map={map}
               minMax={minMaxBranches} paint={paintBranches} paintHighlight={paintBranchesHighlight}
               data={capacityLayer?.data.branches} selectedIds={selection?.branchIds} />
         )}
         {capacityLayer?.visible?.substations && capacityLayer?.data.substations && (
            <CapacitySubstationsLayer
               layerId={CAPACITY_SUBSTATIONS_LAYER_ID}
               highlightLayerId={CAPACITY_SUBSTATIONS_HIGHLIGHT_LAYER_ID}
               map={map}
               minMax={minMaxSubstations}
               paint={paintSubstations}
               paintHighlight={paintSubstationsHighlight}
               data={capacityLayer?.data.substations}
               selectedIds={selection?.substationIds}
            />
         )}
      </>
   );
};

export const getMapBounds = (substations: FeatureCollection<Point, CapacityProperties>, branches: FeatureCollection<MultiLineString, CapacityProperties>): mapboxgl.LngLatBoundsLike | undefined => {
   // console.log('getMapBounds', data.substations[0], data.transmissionLines[0]);
   let ssBound: mapboxgl.LngLatBounds | undefined;
   let tlBound: mapboxgl.LngLatBounds | undefined;

   if (substations.features.length !== 0) {
      const coordinates = substations.features.map((f) => f.geometry.coordinates);
      ssBound = coordinates.reduce((bounds, coord) => bounds.extend([coord[0], coord[1]]), new mapboxgl.LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[1][0], coordinates[1][1]]));
   }

   if (branches.features.length !== 0) {
      const coordinatesArr = branches.features.map((t) => t.geometry.coordinates);
      const coordinates = coordinatesArr.reduce((acc, c) => acc.concat(c), []);
      tlBound = coordinates.reduce((bounds, coord) => coord.reduce((b, c) => b.extend([c[0], c[1]]), bounds), new mapboxgl.LngLatBounds([coordinates[0][0][0], coordinates[0][0][1]], [coordinates[0][1][0], coordinates[0][1][1]]));
   }

   if (ssBound && tlBound) {
      return ssBound.extend(tlBound.getSouthWest()).extend(tlBound.getNorthEast());
   }
   if (ssBound) {
      return ssBound;
   }
   return tlBound;
};

export default CapacityLayers;
