/**
 * symbol layer component with react-map-gl 
 */

import { FeatureCollection, Point } from 'geojson';
import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';

type Props = {
  layerId: string;
  iconImage: string;
  geojson: FeatureCollection<Point>;
  iconOffset?: number[];
};

const SymbolLayer: FC<Props> = ({ layerId, geojson, iconImage, iconOffset = [0, 0] }) => (
   <Source id={layerId} type="geojson" data={geojson}>
      <Layer id={layerId} type="symbol" layout={{ 'icon-image': iconImage, 'icon-size': 0.15, 'icon-allow-overlap': true, 'icon-offset': iconOffset }} />
   </Source>
);

export default memo(SymbolLayer);
