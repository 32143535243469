import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Radio, Select } from 'antd';
import { PowerCol } from 'components/ui';
import dayjs from 'dayjs';
import { FUTURE_YEARS } from 'modules/Future/FUTURE_YEARS';
import { PowerRow } from 'power/components/base/grid';
import { SelectDateRange } from 'power/components/filter';
import { ENV } from 'power/constants';
import { FutureTimeseriesRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE, NYISO_ZONE, TIME_GRANULARITY } from 'power/types/enum';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  query: FutureTimeseriesRequest;
  onSubmit: (newQuery: FutureTimeseriesRequest) => void;
};

const FutureTimeseriesFilter: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formQuery, setFormQuery] = useState<FutureTimeseriesRequest>(query);
  
   const onChangeScenario = useCallback((value: FUTURE_SCENARIO) => {
      setFormQuery((prev) => ({ ...prev, scenario: value }));
   }, []);
   const onChangeMarketType = useCallback((value: MARKET_TYPE) => {
      setFormQuery((prev) => ({ ...prev, market_type: value }));
   }, []);
   const onChangeZone = useCallback((value: NYISO_ZONE) => {
      setFormQuery((prev) => ({ ...prev, zone: value }));
   }, []);
   
   const onChangeTimeGranularity = useCallback((value: TIME_GRANULARITY) => {
      setFormQuery((prev) => ({ ...prev, time_granularity: value }));
   }, []);

   const onChangeDateRange = useCallback((start_date: string, end_date: string) => {
      setFormQuery((prev) => ({ ...prev, start_date, end_date }));
   }, []);


   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={3} style={{ borderLeft: '2px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Scenario:</span>
               <Select
                  variant="borderless" placeholder="Select scenario" value={formQuery.scenario}
                  disabled={loading} onChange={onChangeScenario}>
                  <Select.Option value={FUTURE_SCENARIO.LOW} disabled={ENV.DOMAIN === 'trial'}>Low</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.REF}>Ref</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.HIGH} disabled={ENV.DOMAIN === 'trial'}>High</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={5} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Market Type:</span>
               <Radio.Group
                  value={formQuery.market_type} disabled={loading} onChange={(e) => onChangeMarketType(e.target.value)}
                  style={{ padding: '8px 0px 6px' }}>
                  <Radio value={MARKET_TYPE.DayAhead}>Day Ahead</Radio>
                  <Radio value={MARKET_TYPE.Realtime}>Real Time</Radio>
               </Radio.Group>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Zone:</span>
               <Select
                  variant="borderless"
                  placeholder="Select zone"
                  value={formQuery.zone}
                  disabled={loading}
                  onChange={onChangeZone}
                  options={[
                     { label: 'A', value: NYISO_ZONE.A },
                     { label: 'B', value: NYISO_ZONE.B },
                     { label: 'C', value: NYISO_ZONE.C },
                     { label: 'D', value: NYISO_ZONE.D },
                     { label: 'E', value: NYISO_ZONE.E },
                     { label: 'F', value: NYISO_ZONE.F },
                     { label: 'G', value: NYISO_ZONE.G },
                     { label: 'H', value: NYISO_ZONE.H },
                     { label: 'I', value: NYISO_ZONE.I },
                     { label: 'J', value: NYISO_ZONE.J },
                     { label: 'K', value: NYISO_ZONE.K },
                  ]}
               ></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={4} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Time Granularity:</span>
               <Radio.Group
                  value={formQuery.time_granularity} disabled={loading} onChange={(e) => onChangeTimeGranularity(e.target.value)}
                  style={{ padding: '8px 0px 6px' }}>
                  <Radio value={TIME_GRANULARITY.daily}>Daily</Radio>
                  <Radio value={TIME_GRANULARITY.hourly}>Hourly</Radio>
               </Radio.Group>
            </Flex>
         </PowerCol>

         <PowerCol
            span={5} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Start Date:</span>
               <SelectDateRange
                  startDate={formQuery.start_date} endDate={formQuery.end_date} onChange={onChangeDateRange} 
                  minDate={dayjs(FUTURE_YEARS[0].toString())}
                  maxDate={dayjs(`${FUTURE_YEARS[FUTURE_YEARS.length - 1]}-12-31`)}
               />
            </Flex>
         </PowerCol>

         
         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '2px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default FutureTimeseriesFilter;

