import { /* BellOutlined, */ CustomerServiceOutlined, KeyOutlined, LogoutOutlined /* , SettingOutlined */ } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ENV } from 'power/constants';
// import { Checkbox } from 'antd';
import PATHS from 'power/PATHS';
import { SUBSCRIPTION_PACKAGE } from 'power/types/enum';
import { useAuth } from 'providers/AuthProvider';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
};

const SidebarRight: React.FC<Props> = ({ title }) => {
   const { user, iso, hasPackage, loggedIn } = useAuth();
   const userName = useMemo(() => `${user.firstName} ${user.lastName}`, [user.firstName, user.lastName]);
   const userLatters = useMemo(() => `${user.firstName?.charAt(0) ?? '?'}${user.lastName?.charAt(0) ?? ''}`, [user.firstName, user.lastName]);

   const toggleModules = (subscriptionPackage: SUBSCRIPTION_PACKAGE) => {
      if (hasPackage(subscriptionPackage)) {
         loggedIn({ ...user, package_contents: user.package_contents.filter((x) => !(x[0] === iso && x[1] === subscriptionPackage)) });
      } else {
         loggedIn({ ...user, package_contents: [...user.package_contents, [iso, subscriptionPackage]] });
      }
   };

   return (
      <div className="layout-sidebar-right">
         <div className="layout-sidebar-usermenu">
            <div className="user-avatar">
               <span role="button" className="user-avatar-icon">
                  {userLatters}
               </span>
            </div>
            <div className="user-dropdown-menu">
               <div className="user-dd-name">{userName}</div>
               {ENV.DEVELOPMENT && (
                  <>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.HEADROOM_CAPACITY}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.NODAL_ANALYSIS}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.INTERCONNECTION_QUEUE}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.POWER_PLANT)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.POWER_PLANT)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.POWER_PLANT}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.TRANSMISSION_LINE)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.TRANSMISSION_LINE)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.TRANSMISSION_LINE}</span>
                        </Checkbox>
                     </div>
                     
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.ICAP)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.ICAP)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.ICAP}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.ICAP_FORECAST)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.ICAP_FORECAST)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.ICAP_FORECAST}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.FUTURE_PRICE)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.FUTURE_PRICE)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.FUTURE_PRICE}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.GENERATION_POCKET)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.GENERATION_POCKET)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.GENERATION_POCKET}</span>
                        </Checkbox>
                     </div>
                     <div className="user-dd-logout">
                        <Checkbox checked={hasPackage(SUBSCRIPTION_PACKAGE.NEWS)} onChange={() => toggleModules(SUBSCRIPTION_PACKAGE.NEWS)}>
                           <span style={{ color: 'white' }}>{SUBSCRIPTION_PACKAGE.NEWS}</span>
                        </Checkbox>
                     </div>
                  </>
               )}
               <Link to={PATHS.Auth_ChangePassword} className="user-dd-logout">
                  <KeyOutlined /> Change Password
               </Link>
               <Link to={PATHS.Auth_Logout} className="user-dd-logout">
                  <LogoutOutlined /> Logout
               </Link>
            </div>
         </div>
         <div className="layout-sidebar-menu-item">
            <Link to={PATHS.Support} title="Support">
               <CustomerServiceOutlined />
            </Link>
         </div>
         {/* <div className="layout-sidebar-menu-item">
            <span role="button" title="Alerts">
               <BellOutlined />
               <span className="badge">2</span>
            </span>
         </div>
         <div className="layout-sidebar-menu-item">
            <span role="button" title="Settings">
               <SettingOutlined />
            </span>
         </div> */}
      </div>
   );
};
export default SidebarRight;
