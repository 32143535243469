import { ApiClients } from 'ApiClients';
import { IBaseChartData, IBaseDataResponse, IChartData } from 'power/types';
import { useCallback, useState } from 'react';

export const usePowerTimeSeries = <Q>(apiRoute: string) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<IBaseDataResponse<IBaseChartData<IChartData>>>();

   const fetch = useCallback(
      async (q: Q, reload = false) => {
         setLoading(true);
         setData(undefined);
         ApiClients.getInstance().ApiRequestChart<Q>(apiRoute, q)
            .then((data) => setData(data))
            .catch((error) => {
               console.error(error);
               setData(undefined);
            })
            .finally(() => {
               setLoading(false);
            });
      },
      [apiRoute],
   );

   return {
      loading,
      fetch,
      data,
   };
};
