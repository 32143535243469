import { utils } from 'power/utils';

const CHECK_CLEAR_CACHE_INTERVAL_SECONDS = 5000;

const keysToString = (keys: any[]) => JSON.stringify(keys);

interface ICachedDataItem {
  keys: any[];
  cachedTime: number; // utc second
  expireSecond: number;
  data: any;
}

class CacheClass {
   private static _instance: CacheClass;

   private _cacheData: Map<string, ICachedDataItem>;

   private _timeout: any; // NodeJS.Timeout;

   private _clearExpiredCaches = () => {
      const nowTime = utils.times.nowSeconds();
      /* this._cacheData.forEach(((cacheDataItem, key) => {
      console.log('cacheDataItem', key, cacheDataItem.data); 
    })); */
      this._cacheData.forEach((cachedItem: ICachedDataItem, key: string, map: Map<string, ICachedDataItem>) => {
         if (cachedItem.cachedTime + cachedItem.expireSecond < nowTime) {
            map.delete(key);
         }
      });
   };

   private constructor() {
      this._cacheData = new Map();
      this._timeout = setInterval(this._clearExpiredCaches, CHECK_CLEAR_CACHE_INTERVAL_SECONDS);
   }

   public static get Instance() {
      if (!this._instance) this._instance = new this();
      return this._instance;
   }

   public set = <T>(keys: any[], data: T, expireSecond = 5 * 60): void => {
      const keysString = keysToString(keys);
      const nowTime = utils.times.nowSeconds();
      this._cacheData.set(keysString, {
         keys,
         cachedTime: nowTime,
         expireSecond,
         data,
      });
   };

   public get = <T>(keys: any[]): T | undefined => {
      const keysString = keysToString(keys);
      const nowTime = utils.times.nowSeconds();
      const cachedDataItem = this._cacheData.get(keysString);
      if (cachedDataItem) {
         if (nowTime < cachedDataItem.cachedTime + cachedDataItem.expireSecond) {
            return cachedDataItem.data as T;
         }
         this._cacheData.delete(keysString);
      }
      return undefined;
   };

   public delete = (keys: any[]): void => {
      const keysString = keysToString(keys);
      this._cacheData.delete(keysString);
   };

   public deleteAll = (keyPrefix: string): void => {
      const keyPrefixJson = `["${keyPrefix}",`;
      console.log('keyPrefixJson', keyPrefixJson);
      const deleteKeys: string[] = [];
      this._cacheData.forEach((_, key: string) => {
         console.log('key', key);
         if (key.startsWith(keyPrefixJson)) deleteKeys.push(key);
      });

      console.log('deleteKeys', deleteKeys);
      deleteKeys.forEach((key) => {
         this._cacheData.delete(key);
      });
   };
}

export const CACHE = CacheClass.Instance;
