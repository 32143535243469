import dayjs from 'dayjs';
import INodeInfoModel from 'power/types/INodeIfoModel';
import { FC } from 'react';
import classes from './index.module.scss';

type Props = {
  node: INodeInfoModel;
};

const NodeInfoBox: FC<Props> = ({ node }) => (
   <div className={classes.infoBox}>
      <div className={classes.left}>
         <div>
            <div className={classes.title1}>Node</div>
            <div className={classes.identifier}>{node.name}</div>
         </div>
         <div>
            <div className={classes.title2}>Substitute Node</div>
            <div className={classes.name}>{node.substituteName}</div>
         </div>
         <div>
            {node.county}, {node.state}
         </div>
      </div>
      <div className={classes.right}>
         <div>
            <div className={classes.title1}>Data Availability</div>
            <div className={classes.unit}>{dayjs(node.endDateDa).format('YYYY-MM-DD')}</div>
            <div className={classes.unit}>{dayjs(node.startDateDa).format('YYYY-MM-DD')}</div>
         </div>

         <div>
            <div className={classes.title1}>Hub</div>
            <div className={classes.hubName}>{node.hubName}</div>
         </div>

         {node.zoneName && (
            <div>
               <div className={classes.title1}>Zone</div>
               <div className={classes.hubName}>{node.zoneName}</div>
            </div>
         )}
      </div>
      {/* <dl className="info-card-horizontal">
         <dt>Identifier</dt>
         <dd>{substation.identifier}</dd>
         <dt>Name</dt>
         <dd>{substation.name}</dd>
         <dt>Voltage</dt>
         <dd>{substation.voltage}</dd>
         <dt>County</dt>
         <dd>{substation.county}</dd>
         <dt>Type</dt>
         <dd>-</dd>
      </dl> */}
   </div>
);

export default NodeInfoBox;
