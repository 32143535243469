// import { PowerMap } from 'power/components/maps';
import { Flex } from 'antd';
import { IICAPFutureRequest } from 'power/types';
import { FC } from 'react';
import FutureChart from './FutureChart';
import classes from './index.module.scss';

type Props = {
  query: IICAPFutureRequest;
};

const ChartView: FC<Props> = ({ query }) => (
   <div className={classes.container}>
      <Flex justify="space-between" vertical>
         <FutureChart query={query} />
      </Flex>
   </div>
);

export default ChartView;
