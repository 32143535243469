import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex, Radio, Select } from 'antd';
import { PowerCol } from 'components/ui';
import { FUTURE_YEARS } from 'modules/Future/FUTURE_YEARS';
import { PowerRow } from 'power/components/base/grid';
import { ENV } from 'power/constants';
import { FuturePriceRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE } from 'power/types/enum';
import { FC, useCallback, useState } from 'react';
import classes from './index.module.scss';

type Props = {
  loading: boolean;
  query: FuturePriceRequest;
  onSubmit: (query: FuturePriceRequest) => void;
};

const FutureMapFilter: FC<Props> = ({ loading, query, onSubmit }) => {
   const [formQuery, setFormQuery] = useState<FuturePriceRequest>(query);

   const onChangeScenario = useCallback((value: FUTURE_SCENARIO) => {
      setFormQuery((prev) => ({ ...prev, scenario: value }));
   }, []);

   const onChangeMarketType = useCallback((value: MARKET_TYPE) => {
      setFormQuery((prev) => ({ ...prev, market_type: value }));
   }, []);

   const onChangeYear = useCallback((value: number) => {
      setFormQuery((prev) => ({ ...prev, year: value }));
   }, []);

   const onChangeMonth = useCallback((value: number) => {
      setFormQuery((prev) => ({ ...prev, month: value }));
   }, []);

   const onFormSubmit = useCallback(() => {
      onSubmit(formQuery);
   }, [formQuery, onSubmit]);

   return (
      <PowerRow style={{ height: '100%', alignItems: 'center' }}>
         <PowerCol
            span={0} xl={0} lg={0}
            md={0} sm={0} xs={0} />

         <PowerCol
            span={3} xl={5} lg={5}
            xs={6} style={{ borderLeft: '2px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Scenario:</span>
               <Select
                  variant="borderless" placeholder="Select scenario" value={formQuery.scenario}
                  disabled={loading} onChange={onChangeScenario}>
                  <Select.Option value={FUTURE_SCENARIO.LOW} disabled={ENV.DOMAIN === 'trial'}>Low</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.REF}>Ref</Select.Option>
                  <Select.Option value={FUTURE_SCENARIO.HIGH} disabled={ENV.DOMAIN === 'trial'}>High</Select.Option>
               </Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={5} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Market Type:</span>
               <Radio.Group
                  value={formQuery.market_type} disabled={loading} onChange={(e) => onChangeMarketType(e.target.value)}
                  style={{ padding: '8px 0px 6px' }}>
                  <Radio value={MARKET_TYPE.DayAhead}>Day Ahead</Radio>
                  <Radio value={MARKET_TYPE.Realtime}>Real Time</Radio>
               </Radio.Group>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={5} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Year:</span>
               <Select
                  variant="borderless" placeholder="Select year" value={formQuery.year}
                  disabled={loading} onChange={onChangeYear} options={FUTURE_YEARS.map((year) => ({ label: year.toString(), value: year }))}></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={3} xl={5} lg={5}
            xs={6} style={{ borderLeft: '1px solid #f0f0f0', borderRight: '1px solid #f0f0f0' }}>
            <Flex vertical >
               <span className={classes.label}>Month:</span>
               <Select
                  variant="borderless"
                  placeholder="Select month"
                  value={formQuery.month}
                  disabled={loading}
                  onChange={onChangeMonth}
                  options={[
                     { label: 'All', value: 0 },
                     { label: 'Jan', value: 1 },
                     { label: 'Feb', value: 2 },
                     { label: 'Mar', value: 3 },
                     { label: 'Apr', value: 4 },
                     { label: 'May', value: 5 },
                     { label: 'Jun', value: 6 },
                     { label: 'Jul', value: 7 },
                     { label: 'Aug', value: 8 },
                     { label: 'Sep', value: 9 },
                     { label: 'Oct', value: 10 },
                     { label: 'Nov', value: 11 },
                     { label: 'Dec', value: 12 },
                  ]}
               ></Select>
            </Flex>
         </PowerCol>

         <PowerCol
            span={4} xl={4} lg={4}
            xs={6} style={{ display: 'flex', alignItems: 'center', height: '100%', borderLeft: '1px solid #f0f0f0', borderRight: '2px solid #f0f0f0' }}>
            <Button
               type="primary" size="large" icon={<ReloadOutlined />}
               style={{ width: '100%', alignItems: 'center' }} onClick={onFormSubmit} loading={loading}>
          Reload
            </Button>
         </PowerCol>
      </PowerRow>
   );
};

export default FutureMapFilter;
