import { ApiClients } from 'ApiClients';
import { extent } from 'd3';
import { chain } from 'lodash';
import { API_ROUTES } from 'power/constants';
import { IBaseChartData, IBaseDataResponse, IChartData } from 'power/types';
import { utils } from 'power/utils';
import { UseQueryOptions, useQuery } from 'react-query';
import { setChartRange } from 'utils/data';
import { normalizeNumber } from 'utils/math';
import { IBasisRiskCommonReqModel, IBasisRiskOverviewTimeSeriesRequest } from './types';

interface IHistoricalBasisRiskData {
  res: IBaseDataResponse<IBaseChartData<IChartData>>;
  tableData: {
    year: string;
    marketType: string;
    BASISSOLAR: number;
    BASISVAL: number;
    BASISWIND: number;
  }[];
}

export const useHistoricalBasisRiskData = (req: IBasisRiskCommonReqModel, options?: Omit<UseQueryOptions<IHistoricalBasisRiskData, unknown, IHistoricalBasisRiskData, (string | number | boolean)[]>, 'queryKey' | 'queryFn'>) => useQuery(
   [API_ROUTES.BASIS_RISK.YearlySum, req.dayAheadMarket, req.realtimeMarket, req.component, req.hubId, req.nodeId],
   () => ApiClients.getInstance().basisRiskYearlySum(req).then((res) => {
      const [min, max] = extent(res.result?.data.map((d) => d.y) as any);

      return {
         res,

         range: setChartRange(min as any, max as any),

         tableData: Object.entries(
            chain(
               (res.result as any).data.map((d: { x: any; group: string; y: number | undefined }) => ({
                  year: d.x,
                  marketType: d.group.split('-')[0],
                  group: d.group.split('-')[1],
                  value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
               })),
            )
               .groupBy((d) => `${d.year}--${d.marketType}`)
               .value(),
         ).map(([group, data]) => ({
            year: group.split('--')[0],
            marketType: group.split('--')[1],
            ...((data as any).reduce(
               (acc: any, cur: any) => ({
                  ...acc,
                  [cur.group]: cur.value,
                  BASIS: cur.value,
               }),
               {},
            ) as { date: string; marketType: string; BASIS: number }[]),
         })),
      };
   }),
    options as any,
);


export const useBasisRiskOverviewTimeSeriesData = (data: IBasisRiskOverviewTimeSeriesRequest, enabled?: boolean) => useQuery(
   [API_ROUTES.BASIS_RISK.TimeSeries, data.nodes, data.dayAheadMarket, data.realtimeMarket, data.hubId, data.component],
   () => ApiClients.getInstance().basisRiskTimeSeries(data).then((res) => {
      const [min, max] = extent(res.result?.data.map((d) => d.y) ?? []);

      return {
         res,

         range: setChartRange(min ?? 0, max ?? 0),

         allDataGroups: res.result?.data
            .map((d) => d.group)
            .map((d) => d?.replace('-DA', '').replace('-RT', ''))
            .filter(utils.onlyUnique),

         tableData: Object.entries(
            chain(
               res.result?.data.map((d) => ({
                  date: d.x.split('T')[0],
                  group: d.group?.split('-')[0],
                  marketType: d.group?.split('-')[1],
                  component: d.group?.split('-')[2],
                  value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
               })),
            )
               .groupBy((d) => `${d.date}--${d.component}--${d.marketType}`)
               .value(),
         ).map(([group, data]) => ({
            date: group.split('--')[0],
            component: group.split('--')[1],
            marketType: group.split('--')[2],

            ...((data as any).reduce(
               (acc: any, cur: any) => ({
                  ...acc,
                  [cur.group]: cur.value,
               }),
               {},
            ) as { date: string; marketType: string; SOLAR: number; LMP: number; WIND: number }[]),
         })),
      };
   }),
   { enabled },
);

