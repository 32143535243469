import { PERIOD_TYPE } from 'power/types/enum';

const PERIOD_TYPE_LIST = [
   PERIOD_TYPE.custom,
   PERIOD_TYPE.yesterday,
   PERIOD_TYPE.last_3_days,
   PERIOD_TYPE.last_7_days,
   PERIOD_TYPE.last_15_days,
   PERIOD_TYPE.this_month,
   PERIOD_TYPE.last_month,
   PERIOD_TYPE.this_quarter,
   PERIOD_TYPE.this_year,
   PERIOD_TYPE.last_year,
   PERIOD_TYPE.last_2_year,
   PERIOD_TYPE.last_3_year,
   PERIOD_TYPE.last_4_year,
   PERIOD_TYPE.last_5_year,
   PERIOD_TYPE.live,
];

export default PERIOD_TYPE_LIST;
