import { FC } from 'react';
import classes from './InfoTable.module.scss';

interface IInfoTable {
  info: { key: string; value: string | number }[];
  width?: string | number;
  title?: string;

  highlightKeys?: string[];
}

const InfoTable: FC<IInfoTable> = ({ info, width, title, highlightKeys = [] }) => (
   <table className={classes.table} style={{ width }}>
      {title && (
         <thead>
            <tr>
               <th colSpan={2} className={classes.title}>
                  {title}
               </th>
            </tr>
         </thead>
      )}
      <tbody>
         {info
            .filter((x) => highlightKeys.includes(x.key))
            .map(({ key, value }) => (
               <tr key={key} className={classes.highlight}>
                  <td className={classes.key}>{key}</td>
                  <td className={classes.val}>{value}</td>
               </tr>
            ))}
         {info
            .filter((x) => !highlightKeys.includes(x.key))
            .map(({ key, value }) => (
               <tr key={key}>
                  <td className={classes.key}>{key}</td>
                  <td className={classes.val}>{value}</td>
               </tr>
            ))}
      </tbody>
   </table>
);

export default InfoTable;
