import { FeatureCollection, GeoJsonProperties, LineString, MultiLineString, Point, Polygon } from 'geojson';
import lineToArc from './lineToArc';

export const arrayToGeoJsonPoint = <P extends { id: number | string } & GeoJsonProperties>(data: any[], propertyNames: string[], extraProperty: { [key: string]: any } = {}): FeatureCollection<Point, P> => {
   const geojson: FeatureCollection<Point, P> = {
      type: 'FeatureCollection',
      features: data.map((d) => {
         const propertiesValues = d.filter((_: any, i: any) => i !== 1);
         const properties = ['id', ...propertyNames].reduce((acc, key, i) => ({ ...acc, [key]: propertiesValues[i] }), { /* id: d[0], */ ...extraProperty }) as P;
         return {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: d[1] },
            properties,
         };
      }),
   };
   return geojson;
};

export const arrayToGeoJsonLineString = <P extends { id: number | string } & GeoJsonProperties>(data: any[], propertyNames: string[], extraProperty: { [key: string]: any } = {}): FeatureCollection<LineString, P> => {
   const geojson: FeatureCollection<LineString, P> = {
      type: 'FeatureCollection',
      features: data.map((d) => {
         const propertiesValues = d.filter((_: any, i: any) => i !== 1);
         const properties = ['id', ...propertyNames].reduce((acc, key, i) => ({ ...acc, [key]: propertiesValues[i] }), { /* id: d[0], */ ...extraProperty }) as P;
         return {
            type: 'Feature',
            geometry: { type: 'LineString', coordinates: d[1] },
            properties,
         };
      }),
   };
   return geojson;
};

export const arrayToGeoJsonMultiLineString = <P extends { id: number | string } & GeoJsonProperties>(data: any[], propertyNames: string[], extraProperty: { [key: string]: any } = {}, arcPointCount: number = 0): FeatureCollection<MultiLineString, P> => {
   const getCoordinates = (coords: any) => {
      if (arcPointCount === 0) {
         return [coords];
      }

      const arcAngle = Math.floor(Math.random() * 120) - 60;
      const points = lineToArc(coords[0], coords[1], arcAngle, arcPointCount, 'clockwise');
      const coordinates = [];
      for (let i = 0; i < points.length - 1; i++) {
         coordinates.push([points[i], points[i + 1]]);
      }
      return coordinates;
   };

   const geojson: FeatureCollection<MultiLineString, P> = {
      type: 'FeatureCollection',
      features: data.map((d) => {
         const propertiesValues = d.filter((_: any, i: any) => i !== 1);
         const properties = ['id', ...propertyNames].reduce((acc, key, i) => ({ ...acc, [key]: propertiesValues[i] }), { /* id: d[0], */ ...extraProperty }) as P;
         return {
            type: 'Feature',
            geometry: { type: 'MultiLineString', coordinates: getCoordinates(d[1]) },
            properties,
         };
      }),
   };
   return geojson;
};

export const arrayToGeoJsonPolygon = <P extends { id: number | string } & GeoJsonProperties>(data: any[], propertyNames: string[], extraProperty: { [key: string]: any } = {}): FeatureCollection<Polygon, P> => {
   const geojson: FeatureCollection<Polygon, P> = {
      type: 'FeatureCollection',
      features: data.map((d) => {
         const propertiesValues = d.filter((_: any, i: any) => i !== 1);
         const properties = ['id', ...propertyNames].reduce((acc, key, i) => ({ ...acc, [key]: propertiesValues[i] }), { /* id: d[0], */ ...extraProperty }) as P;
         return {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: d[1] },
            properties,
         };
      }),
   };
   return geojson;
};
