/* eslint-disable max-len */
import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const Svg = () => (
   <svg
      viewBox="0 0 896 896" width="1em" height="1em"
      fill="currentColor">
      <path d="m864,500h-108v-113.6c0-17.7-14.3-32-32-32h-315l-117.3-112.2c-1.49-1.4-3.46-2.18-5.5-2.2H32c-17.7,0-32,14.3-32,32v592c0,17.7,14.3,32,32,32h698c13,0,24.8-7.9,29.7-20l134-332c1.5-3.8,2.3-7.9,2.3-12,0-17.7-14.3-32-32-32ZM72,312h188.5l119.6,114.4h303.9v73.6H174c-13,0-24.8,7.9-29.7,20l-72.3,179.2v-387.2Zm635.3,512H95l103.3-256h612.4l-103.4,256Z" />
      <rect
         x="525.24" y="56.88" width="71"
         height="222.51" rx="7.89" ry="7.89" />
      <rect
         x="675.12" y="135.75" width="71"
         height="143.63" rx="7.89" ry="7.89" />
      <rect
         x="825" y="0" width="71"
         height="279.38" rx="7.89" ry="7.89" />
   </svg>
);

const IconSvg = (props: Partial<CustomIconComponentProps>) => <Icon component={Svg} {...props} />;
export default IconSvg;
