export const MAPBOX = {
   Username: 'powerdevd',
   Styles: {
      Default: 'mapbox://styles/powerdevd/clftl3w79001a01nwzn6h3lmy',
      Satellite: 'mapbox://styles/powerdevd/clftl50dr001201mvomsa5wf4',
   },
   Bounds: {
      USA: [-132.05570369775322, 21.40698596016368, -58.49875870847299, 52.58626192521436] as [number, number, number, number],
   },
   TileSetIds: {
      /* powerPlant: 'power_plants_tileset', */
      environmental: {
         criticalHabitat: {
            line: 'critical_habitat_line_tileset',
            polygon: 'critical_habitat_polygon_tileset',
         },
      },
      transmissionLine: {
         /* id: '7brjs8nr', */
         fields: [
            { field: 'id', type: 'string' },
            { field: 'voltage', type: 'number' },
            { field: 'voltage_class', type: 'string' },
         ],
      },
      boundaries: {
         balancingAuthority: 'balancing_auths_tileset',
         iso: 'iso_tileset',
         stateBoundaries: 'state_boundaries_tileset',
         countyBoundaries: 'county_boundaries_tileset',
         electricHoldingCompanyAreas: 'electric_h_c_areas_tileset',
         electricPlanningAreas: 'electric_p_areas_tileset',
         fercRegions: 'ferc_regions_tileset',
         nercRegions: 'nerc_regions_tileset',
         controlAreas: 'control_areas_tileset',
      },
   },
};

export default MAPBOX;
