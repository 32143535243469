import { MailOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { FC } from 'react';

type Props = {
  loading: boolean;
  onForgot: (email: string) => Promise<void>;
  errorMessage?: string;
  onSignIn: () => void;
  success: boolean;
};

const ForgotPasswordForm: FC<Props> = ({ loading, onForgot, errorMessage, onSignIn, success }) => {
   const [form] = Form.useForm();

   return (
      <>
         {errorMessage && <Alert message={errorMessage} type="error" style={{ marginBottom: '1rem' }} />}
         <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => {
               onForgot(values.email);
            }}
         >
            {!success && (
               <>
                  <Form.Item
                     label="Email address"
                     name="email"
                     rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'The input is not valid E-mail!' },
                     ]}
                  >
                     <Input
                        size="large" placeholder="Enter email address" prefix={<MailOutlined />}
                        disabled={loading} />
                  </Form.Item>
                  <Form.Item>
                     <Button
                        style={{ marginTop: '1rem' }} block type="primary"
                        size="large" htmlType="submit" loading={loading}>
                Request Password Link
                     </Button>
                  </Form.Item>
               </>
            )}

            <Form.Item>
               <Button
                  type="link"
                  block
                  onClick={onSignIn}
                  /* disabled={loading} */
               >
            Sign In
               </Button>
            </Form.Item>
         </Form>
      </>
   );
};

export default ForgotPasswordForm;
