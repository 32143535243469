enum FUEL_TYPE {
  Undefined = 0,
  Wind = 1,
  Solar = 2,
  Coal = 3,
  Gas = 4, // NaturalGas
  Hydro = 5,
  Nuclear = 6,
  Hybrid = 7,
  Oil = 8,
  Battery = 9,
  OtherStorage = 10,
  BioMass = 11,
  Thermal = 12,
  ACTransmission = 71,
  DCTransmission = 72,
  Other = 99,
}

export default FUEL_TYPE;
