import { usePowerMap } from 'power/map/usePowerMap';
import React from 'react';
import BaseControl from '../base/BaseControl';
import CapacityLayersSection from './CapacityLayersSection/CapacityLayersSection';
import CapacityStaticLayersSection from './CapacityStaticLayersSection/CapacityStaticLayersSection';
import NodeLayersSection from './NodeLayersSection/NodeLayersSection';
import SiderRightControlContainer from './SiderRightControlContainer';
import StaticLayersSection from './StaticLayersSection/StaticLayersSection';

type Props = {
  mapId: string;
  defaultOpen?: boolean;
  width?: string | number;
};

const SiderRightControl: React.FC<Props> = ({ mapId, defaultOpen, width }) => {
   const { capacityLayer, capacityStaticLayers, nodeLayers, staticLayers } = usePowerMap();

   if (capacityLayer === undefined && capacityStaticLayers === undefined && nodeLayers === undefined && staticLayers === undefined) return null;

   return (
      <BaseControl position="top-right">
         <SiderRightControlContainer defaultOpen={defaultOpen} width={width}>
            <CapacityLayersSection mapId={mapId} />
            <CapacityStaticLayersSection mapId={mapId} />
            <NodeLayersSection mapId={mapId} />
            <StaticLayersSection mapId={mapId} />
         </SiderRightControlContainer>
      </BaseControl>
   );
};

export default SiderRightControl;
