import { LineLayout, LinePaint } from 'mapbox-gl';

const LAYOUT: LineLayout = {
   'line-join': 'round',
   'line-cap': 'round',
};

const PAINT: LinePaint = {
   // 'line-color': ['case', ['==', ['get', 'STATUS'], 0], '#BB2552', ['==', ['get', 'STATUS'], 1], '#469D74', ['==', ['get', 'STATUS'], 2], '#934EBC', ['==', ['get', 'STATUS'], 3], '#9B7431', ['==', ['get', 'STATUS'], 4], '#597EF7', '#040000'],
   'line-color': [
      'case',
      ['==', ['get', 'voltage_class_range'], '500+'], // ok
      '#FFC53D',
      ['==', ['get', 'voltage_class_range'], '345 - 499'], // ok
      '#43C2F0',
      ['==', ['get', 'voltage_class_range'], '275 - 344'], // ok
      '#FA9333',
      ['==', ['get', 'voltage_class_range'], '115 - 274'], // ok
      '#A4D850',
      ['==', ['get', 'voltage_class_range'], '69 - 114'], // ok
      '#F782A9',
      ['==', ['get', 'voltage_class_range'], '0 - 68'], // ok
      '#597EF7',
      '#597EF7',
   ],

   'line-width': [
      'case',
      ['==', ['get', 'voltage_class_range'], '500+'], // ok
      6,
      ['==', ['get', 'voltage_class_range'], '345 - 499'], // ok
      5,
      ['==', ['get', 'voltage_class_range'], '275 - 344'], // ok
      4,
      ['==', ['get', 'voltage_class_range'], '115 - 274'], // ok
      3.25,
      ['==', ['get', 'voltage_class_range'], '69 - 114'], // ok
      2,
      ['==', ['get', 'voltage_class_range'], '0 - 68'], // ok
      1,
      1,
   ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { LAYOUT, PAINT };
