import type { ColumnConfig } from '@ant-design/plots';
import { ColorSets } from 'constants/Colors';
import { normalizeNumber } from 'utils/math';

// TODO: use chart config hook with custom charts

const columnChartConfig = (xAxisTitle?: string, yAxisTitle?: string): Partial<ColumnConfig> => ({
   data: [],
   xAxis: {
      title: {
         text: xAxisTitle,
         position: 'end',
      },
   },
   yAxis: {
      title: {
         text: yAxisTitle,
         position: 'end',
         autoRotate: false,
         style: {
            y: 5,
            x: 0,
            textAlign: 'left',
         },
      },
      label: {
         formatter: (text) => (!Number.isNaN(+text) ? normalizeNumber(+text) : text),
      },
   },
   interactions: [
      {
         type: 'element-highlight-by-color',
      },
   ],
   locale: 'en',
   // label: {
   //   position: 'middle',
   //   layout: [
   //     {
   //       type: 'interval-adjust-position',
   //     },
   //     {
   //       type: 'interval-hide-overlap',
   //     },
   //     {
   //       type: 'adjust-color',
   //     },
   //   ],
   // },
   brush: {
      enabled: true,
      type: 'x-rect',
   },
   legend: {
      position: 'bottom',
      itemSpacing: 10,
      padding: [30, 0, 0, 0],
      flipPage: false,
   },
   // TODO: its not working right for all chart types i.e. heatmap
   appendPadding: [30, 0, 0, 0],
   color: ColorSets.main,
   className: 'antd-chart',
});

export default columnChartConfig;
