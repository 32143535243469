import { Flex, Spin } from 'antd';
import { ENV } from 'power/constants';
import queryClientProvider from 'providers/QueryClientProvider';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import useAppLoading from './useAppLoading';

const App: React.FC = () => {
   if(ENV.GA_TRACKING_ID) ReactGA.initialize(ENV.GA_TRACKING_ID);

   const { loading } = useAppLoading();

   if (loading)
      return (
         <Flex align="center" justify="center" style={{ height: '100vh' }}>
            <Spin size="large" />
         </Flex>
      );

   return (
      <BrowserRouter>
         <QueryClientProvider client={queryClientProvider}>
            <AppRoutes />
         </QueryClientProvider>
      </BrowserRouter>
   );
};
export default App;
