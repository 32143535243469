import { ICAP_ZONE_LABEL_STYLE } from 'power/constants/map/styles';
import Legend from 'power/map/Legend';
import { IICAPMonthly, IICAPStripRequest } from 'power/types';
import { ICAP_ZONE_NYISO, VECTOR_LAYER_ID } from 'power/types/enum';
import { utils } from 'power/utils';
import React, { useEffect, useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { geojsonMonthly } from './geojson';
import { getLegendColorExpression } from './getLegendColorExpression';

const { layerId, layerMinzoom, layerMaxzoom } = utils.map.getLayerInfo(VECTOR_LAYER_ID.ICAP_ZONE);
const circleLayerId = `${layerId}-points-monthly-circle`;

type Props = {
  mapId: string;
  query: IICAPStripRequest;
  month: number;
  monthly?: IICAPMonthly[];
  selectedFeatureZone: (zone: ICAP_ZONE_NYISO) => void;
};

const ICAPMonthlyLayer: React.FC<Props> = ({ mapId, query, month, monthly, selectedFeatureZone }) => {
   const { [mapId]: map } = useMap();

   const geojson = useMemo(() => geojsonMonthly(monthly), [monthly]);

   const minmax = useMemo(
      () => monthly?.reduce(
         (acc, curr) => {
            if (curr.price < acc[0]) acc[0] = curr.price;
            if (curr.price > acc[1]) acc[1] = curr.price;
            return acc;
         },
         [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
      ),
      [monthly],
   );

   const exp = useMemo(() => minmax && getLegendColorExpression(minmax[0], minmax[1], ['get', 'price']), [minmax]);

   useEffect(() => {
      // add click event listener for circleLayerId
      if (!map) return;

      map.on('click', circleLayerId, (e) => {
         const features = map.queryRenderedFeatures(e.point, { layers: [circleLayerId] });
         if (!features.length) return;

         const feature = features[0];
         if(!feature.properties) return;

         // console.log('Monthly feature clicked', feature.properties.zone as ICAP_ZONE_NYISO);
         selectedFeatureZone(feature.properties.zone as ICAP_ZONE_NYISO);
      });
   }, [map, selectedFeatureZone]);

   if (!map || !geojson || !exp) return null;

   return (
      <>
         <Source id={`${layerId}-points-monthly`} type="geojson" data={geojson}>
            <Layer
               id={circleLayerId} type="circle" layout={ICAP_ZONE_LABEL_STYLE.LAYOUT_CIRCLE_MONTLY}
               paint={ICAP_ZONE_LABEL_STYLE.PAINT_CIRCLE_MONTLY(exp)} minzoom={layerMinzoom} maxzoom={layerMaxzoom} />
            <Layer
               id={`${layerId}-points-monthly-title`} type="symbol" layout={ICAP_ZONE_LABEL_STYLE.LAYOUT_MONTLY_TITLE}
               paint={ICAP_ZONE_LABEL_STYLE.PAINT_MONTLY} minzoom={layerMinzoom} maxzoom={layerMaxzoom} />
            <Layer
               id={`${layerId}-points-monthly-value`} type="symbol" layout={ICAP_ZONE_LABEL_STYLE.LAYOUT_MONTLY}
               paint={ICAP_ZONE_LABEL_STYLE.PAINT_MONTLY} minzoom={layerMinzoom} maxzoom={layerMaxzoom} />
         </Source>
         {minmax && /* minmax[0] !== minmax[1] && */ (
            <div style={{ position: 'absolute', width: 280, bottom: 116, left: 12, borderRadius: 8, background: 'white', boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.16)', padding: '12px 12px 4px 8px' }}>
               <Legend scaleValues={[{ label: 'Monthly Price', minmax, position: 'bottom' }]} />
            </div>
         )}
      </>
   );
};

export default ICAPMonthlyLayer;
