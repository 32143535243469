import { ApiClients } from 'ApiClients';
import { IBaseChartData, IBaseDataResponse, IChartData } from 'power/types';
import { useCallback, useState } from 'react';

const INITIAL_CHART_DATA: IBaseDataResponse<IBaseChartData<IChartData>> = {
   cacheKey: '',
   cacheValidationStatus: false,
   // downloadUrl?: string;
   // messageKey?: string;

   // if cacheValidationStatus is true, this field will be undefined
   // result?: TResult;
   // requestModel?: RequestModel;
};

const useChartData = <Q>(apiRoute: string) => {
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState<string | undefined>(undefined);
   const [data, setData] = useState<IBaseDataResponse<IBaseChartData<IChartData>>>(INITIAL_CHART_DATA);

   const fetch = useCallback(
      async (query: Q, reload: boolean = false) => {
         setLoading(true);
         setError(undefined);
         ApiClients.getInstance().ApiRequestChart<Q>(apiRoute, query, reload)
            .then((data) => {
               setData(data);
            })
            .catch((error) => {
               console.error(error);
               setError(error.message);
               setData(INITIAL_CHART_DATA);
            })
            .finally(() => {
               setLoading(false);
            });
      },
      [apiRoute],
   );

   return {
      loading,
      error,
      data,
      fetch,
   };
};

export default useChartData;
