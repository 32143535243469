export const MAP_LEGEND_COLORS = [
   '#033028',
   '#093b32',
   '#10463c',
   '#185247',
   '#1f5d52',
   '#26695d',
   '#2d7668',
   '#358274',
   '#3c8f80',
   '#449c8c',
   '#4ca292',
   '#54a999',
   '#5bafa0',
   '#63b6a6',
   '#6abcad',
   '#72c3b4',
   '#79c9ba',
   '#81d0c1',
   '#88d6c8',
   '#90ddcf',
   '#91d9c4',
   '#94d4b9',
   '#97d0ae',
   '#9bcba3',
   '#9fc699',
   '#a4c08f',
   '#a8bb87',
   '#adb57f',
   '#b1af78',
   '#b5a973',
   '#b4a56c',
   '#b2a166',
   '#b19d60',
   '#b09959',
   '#af9553',
   '#ae904d',
   '#ad8c47',
   '#ac8841',
   '#ab833b',
   '#aa7f35',
   '#ae7c33',
   '#b17931',
   '#b57630',
   '#b9732f',
   '#bd702f',
   '#c16c2f',
   '#c46930',
   '#c86532',
   '#cc6034',
   '#cf5c36',
   '#ce5639',
   '#cd513b',
   '#cc4b3e',
   '#ca4641',
   '#c84043',
   '#c63b46',
   '#c43549',
   '#c1304c',
   '#be2a4f',
   '#bb2552',
   '#b4224a',
   '#ac2042',
   '#a51d3b',
   '#9d1b33',
   '#96192c',
   '#8e1826',
   '#86161f',
   '#7e1519',
   '#771313',
   '#6f120d',
];
