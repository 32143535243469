import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React, { PropsWithChildren } from 'react';
import { useMap } from 'react-map-gl';
import classes from './BaseSection.module.scss';

type Props = {
  mapId: string;
  active: boolean;
  setActive: (open: boolean) => void;
  title: string;
  defaultCollapsed: boolean;
  loading?: boolean;
  width?: string | number;
};

const BaseSection: React.FC<PropsWithChildren<Props>> = ({ mapId, active, setActive, title, defaultCollapsed, loading, width = 400, children }) => {
   const { [mapId]: map } = useMap();
   const [collapse, setCollapse] = React.useState(defaultCollapsed);

   if (map === undefined) return null;

   return (
      <>
         <div className={classes.header}>
            <div
               className={classes.left}
               onClick={() => {
                  setActive(!active);
                  setCollapse(!active);
               }}
            >
               <div className={classes.openButton}>
                  {active ? <EyeOutlined style={{ fontSize: 16 }} /> : <EyeInvisibleOutlined style={{ fontSize: 16 }} />}
                  {/* <Button
                     className={classes.eye} shape="circle"
                     type="text" icon={active ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={() => {setActive(!active); setCollapse(!active)}} /> */}
               </div>
               <div className={classes.title}>{title}</div>
            </div>
            <div className={classes.right}>
               {loading ? (
                  <div className={classes.collapse}>
                     <LoadingOutlined style={{ fontSize: 14 }} />
                  </div>
               ) : (
                  active && (
                     <div className={classes.collapse} onClick={() => setCollapse(!collapse)}>
                        {collapse ? <MinusOutlined style={{ fontSize: 14 }} /> : <PlusOutlined style={{ fontSize: 14 }} />}
                     </div>
                  )
               )}
            </div>
         </div>

         <div className={`${classes.container} ${active && collapse ? classes.isOpen : classes.isClose}`}>{children}</div>
      </>
   );
};

export default BaseSection;
