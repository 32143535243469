import { interpolateRgb } from 'd3';
import { ScaleLinear, ScaleQuantile, scaleLinear, scaleQuantile } from 'd3-scale';

class ValueColorInterpolate {
   private quantileScale: ScaleQuantile<string, string>;

   private colorInterpolators: ScaleLinear<string, string>[];

   constructor(values: number[], colors: string[]) {
      this.quantileScale = scaleQuantile<string>().domain(values).range(colors);

      this.colorInterpolators = colors.slice(0, -1).map((color, i) => scaleLinear<string>()
         .domain([this.quantileScale.quantiles()[i], this.quantileScale.quantiles()[i + 1] ?? Math.max(...values)])
         .range([color, colors[i + 1]])
         .interpolate(interpolateRgb),
      );
   }

   getColor(value: number): string {
      const rangeIndex = this.quantileScale.range().indexOf(this.quantileScale(value));
      if (this.colorInterpolators[rangeIndex]) {
         return this.colorInterpolators[rangeIndex](value);
      }
      return this.quantileScale.range()[this.quantileScale.range().length - 1];
   }
}
export default ValueColorInterpolate;
