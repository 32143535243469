import { ApiClients } from 'ApiClients';
import { PowerCol, PowerRow } from 'components/ui';
import InfoBox from 'power/components/base/InfoBox/InfoBox';
import { ICongestionInfoBox } from 'power/types';
import { MARKET_TYPE, PERIOD_TYPE } from 'power/types/enum';
import { FC, memo, useEffect, useState } from 'react';

type Props = {
  nodeId: number;
};

const InfoBoxes: FC<Props> = ({ nodeId }) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [data, setData] = useState<ICongestionInfoBox[]>();

   useEffect(() => {
      ApiClients.getInstance().headroomCongestionInfoBox(nodeId, PERIOD_TYPE.last_5_year).then((res) => {
         setData(res);
         setLoading(false);
      });
   }, [nodeId]);

   const mccAvgDA = data?.find(x => x.marketType === MARKET_TYPE.DayAhead)?.mccAvg ?? '';
   const mccAvgRT = data?.find(x => x.marketType === MARKET_TYPE.Realtime)?.mccAvg ?? '';
   const mccStdDevDA = data?.find(x => x.marketType === MARKET_TYPE.DayAhead)?.mccStdDev ?? '';
   const mccStdDevRT = data?.find(x => x.marketType === MARKET_TYPE.Realtime)?.mccStdDev ?? '';

   const topFiveConstAvgDA = data?.find(x => x.marketType === MARKET_TYPE.DayAhead)?.topFiveConstAvg ?? '';
   const topFiveConstAvgRT = data?.find(x => x.marketType === MARKET_TYPE.Realtime)?.topFiveConstAvg ?? '';
   const topFiveConstCountDA = data?.find(x => x.marketType === MARKET_TYPE.DayAhead)?.topFiveConstCount ?? '';
   const topFiveConstCountRT = data?.find(x => x.marketType === MARKET_TYPE.Realtime)?.topFiveConstCount ?? '';


   if (loading || !data || data.length < 2) return null;

   return (
      <PowerRow gutter={[16, 16]} style={{ padding: '4px' }}>
         <PowerCol span={0} xxl={2}></PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="MCC Avg Last 5 Years ($)" data={[
                  { key: 'DA', value: mccAvgDA },
                  { key: 'RT', value: mccAvgRT },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="MCC STDV Last 5 Years ($)" data={[
                  { key: 'DA', value: mccStdDevDA },
                  { key: 'RT', value: mccStdDevRT },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="Top 5 Const Avg ($)" data={[
                  { key: 'DA', value: topFiveConstAvgDA },
                  { key: 'RT', value: topFiveConstAvgRT },
               ]} />
         </PowerCol>

         <PowerCol
            xs={12} sm={12} md={12}
            lg={12} xl={6} xxl={5}>
            <InfoBox
               title="Top 5 Const Count" data={[
                  { key: 'DA', value: topFiveConstCountDA },
                  { key: 'RT', value: topFiveConstCountRT },
               ]} />
         </PowerCol>

         <PowerCol span={0} xxl={2}></PowerCol>
      </PowerRow>
   );
};

export default memo(InfoBoxes);
