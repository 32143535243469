import { ApiClients } from 'ApiClients';
import { INewsListItem } from 'power/types';
import { useCallback, useState } from 'react';


const useNewsList = () => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<INewsListItem[] | undefined>(undefined);

   const fetch = useCallback(async () => {
      setLoading(true);
      return ApiClients.getInstance().newsList()
         .then((data) => {
            setData(data);
         })
         .catch((error) => {
            console.error(error);
            setData(undefined);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   return {
      loading,
      data,
      fetch,
   };
};

export default useNewsList;
