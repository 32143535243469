/* eslint-disable max-len */
import { Checkbox, Tree } from 'antd';
import { FUEL_TYPES, IMAGE_PATHS } from 'power/constants';
import usePowerPlantLayer from 'power/map/layers/StaticLayers/PowerPlantLayer/usePowerPlantLayer';
import { FUEL_TYPE } from 'power/types/enum';
import IPPFilter from 'power/types/map/IPPFilter';
import React, { useCallback, useMemo } from 'react';
import { MapRef } from 'react-map-gl';
import classes from './PowerPlantLayerControlContainer.module.scss';

export const PP_DEFAULT_FILTER: IPPFilter = {
   active: false,
   fuelTypes: FUEL_TYPES.map((x) => x.value),
};

const FuelTypeTreeNode = ({ fuelType, title, count }: { fuelType: FUEL_TYPE; title: string, count?: number }) => (
   <div key={fuelType} className={classes.iconText}>
      <img height={16} src={IMAGE_PATHS.MAP_ASSETS.FUEL_TYPE(fuelType)} alt={title} />
      {title} ({count})
   </div>
);

type Props = {
  filter: IPPFilter;
  changeFilter: (filter: IPPFilter) => void;
  map?: MapRef;
};
const PowerPlantLayerControlContainer: React.FC<Props> = ({ filter, changeFilter, map }) => {
   const toggleActive = useCallback(() => changeFilter({ ...filter, active: !filter.active }), [changeFilter, filter]);
   const { powerPlantLayer } = usePowerPlantLayer(true);


   const counts = useMemo(() => {
      // all fuel types counts
      const counts: { [key: number]: number } = FUEL_TYPES.reduce((acc, fuelType) => {
         acc[fuelType.value] = 0;
         return acc;
      }, {} as { [key: number]: number });
   
      /* const counts: { [key: number]: number } = {
         [FUEL_TYPE.Wind]: 0,
         [FUEL_TYPE.Solar]: 0,
         [FUEL_TYPE.Gas]: 0,
         [FUEL_TYPE.Hydro]: 0,
         [FUEL_TYPE.Nuclear]: 0,
         [FUEL_TYPE.Hybrid]: 0,
         [FUEL_TYPE.Oil]: 0,
         [FUEL_TYPE.Battery]: 0,
         [FUEL_TYPE.OtherStorage]: 0,
         [FUEL_TYPE.BioMass]: 0,
         [FUEL_TYPE.Thermal]: 0,
         [FUEL_TYPE.Other]: 0,
      }; */

      powerPlantLayer?.features.forEach((feature) => {
         const fuelType = feature.properties?.b_f_type;
         if (fuelType !== undefined) {
            counts[fuelType] = (counts[fuelType] || 0) + 1;
         }
      });

      return counts;
   }, [powerPlantLayer?.features]);


   if (map === undefined) return null;

   return (
      <div className={classes.powerPlantLayerControlContainer}>
         <Checkbox /* indeterminate={indeterminate} */ checked={filter.active} onChange={toggleActive}>
            <b>Power Plant</b>
         </Checkbox>

         {filter.active && (
            <>
               {/* <Flex vertical={false} style={{ paddingLeft: 8, paddingTop: 6 }}>
                  <Radio.Group
                     value={filter.status} onChange={(e) => changeFilter({ ...filter, status: e.target.value })} buttonStyle="outline"
                     size="small" optionType="button">
                     <Radio value={PP_STATUS.Operating}>Operating</Radio>
                     <Radio value={PP_STATUS.Retired}>Retired</Radio>
                  </Radio.Group>
               </Flex> */}

               <div className={classes.body}>
                  <Tree
                     checkable
                     defaultExpandAll
                     selectable={false}
                     checkedKeys={filter.fuelTypes.map(Number)}
                     onCheck={(
                        checkedKeys:
                  | React.Key[]
                  | {
                      checked: React.Key[];
                      halfChecked: React.Key[];
                    },
                     ) => {
                        if (Array.isArray(checkedKeys)) {
                           if (checkedKeys.includes(FUEL_TYPE.Undefined)) {
                              changeFilter({ ...filter, fuelTypes: PP_DEFAULT_FILTER.fuelTypes });
                           } else {
                              changeFilter({ ...filter, fuelTypes: checkedKeys.map(Number) });
                           }
                        } else {
                           if (checkedKeys.checked.includes(FUEL_TYPE.Undefined)) {
                              changeFilter({ ...filter, fuelTypes: PP_DEFAULT_FILTER.fuelTypes });
                           } else {
                              changeFilter({ ...filter, fuelTypes: checkedKeys.checked.map(Number) });
                           }
                        }
                     }}
                     treeData={[
                        {
                           title: `All (${powerPlantLayer?.features.length ?? 0})`,
                           key: FUEL_TYPE.Undefined,
                           children: FUEL_TYPES.map((fuelType) => ({
                              title: <FuelTypeTreeNode fuelType={fuelType.value} title={fuelType.label} count={counts[fuelType.value]} />,
                              key: fuelType.value,
                           })),
                           /* children: [
                              // all fuel types
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Wind} title="Wind" count={counts[FUEL_TYPE.Wind]} />, key: FUEL_TYPE.Wind },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Solar} title="Solar" count={counts[FUEL_TYPE.Solar]} />, key: FUEL_TYPE.Solar },
                              // { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Coal} title="Coal" />, key: FUEL_TYPE.Coal },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Gas} title="Gas" count={counts[FUEL_TYPE.Gas]} />, key: FUEL_TYPE.Gas },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Hydro} title="Hydro" count={counts[FUEL_TYPE.Hydro]} />, key: FUEL_TYPE.Hydro },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Nuclear} title="Nuclear" count={counts[FUEL_TYPE.Nuclear]} />, key: FUEL_TYPE.Nuclear },
                              // { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Hybrid} title="Hybrid" />, key: FUEL_TYPE.Hybrid },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Oil} title="Oil" count={counts[FUEL_TYPE.Oil]} />, key: FUEL_TYPE.Oil },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Battery} title="Battery" count={counts[FUEL_TYPE.Battery]} />, key: FUEL_TYPE.Battery },
                              // { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.OtherStorage} title="Other Storage" />, key: FUEL_TYPE.OtherStorage },
                              { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.BioMass} title="BioMass" count={counts[FUEL_TYPE.BioMass]} />, key: FUEL_TYPE.BioMass },
                              // { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Thermal} title="Thermal" />, key: FUEL_TYPE.Thermal },
                              // { title: <FuelTypeTreeNode fuelType={FUEL_TYPE.Other} title="Other" />, key: FUEL_TYPE.Other },
                           ], */
                        },
                     ]}
                  />
               </div>
            </>
         )}
      </div>
   );
};

export default PowerPlantLayerControlContainer;
