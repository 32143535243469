import { Line } from '@ant-design/plots';
import { ApiClients } from 'ApiClients';
import CardPanel from 'components/ui/CardPanel';
import useAntdChartConfig from 'power/hooks/useAntdChartConfig';
import { FutureTimeseries, FutureTimeseriesRequest } from 'power/types';
import { TIME_GRANULARITY } from 'power/types/enum';
import { utils } from 'power/utils';
import { FC, useEffect, useState } from 'react';
import { parseDateByTimeGranularity } from 'utils/data';

type Props = {
  query: FutureTimeseriesRequest;
};

export const FutureTimeseriesChart: FC<Props> = ({ query }) => {
   const [loading, setLoading] = useState(true);
   const [data, setData] = useState<FutureTimeseries[]>();

   useEffect(() => {
      setLoading(true);
      setData(undefined);
      ApiClients.getInstance().futureTimeseries(query)
         .then((data) => {
            // console.log('FutureTimeseriesChart', query, data);
            setData(data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [query]);

   const chartConfig = useAntdChartConfig('line', query.time_granularity === TIME_GRANULARITY.daily ? 'Day' : 'Hour', 'Price ($)');
   

   return (
      <CardPanel
         title={`${utils.capitalizeFirstLetter(query.scenario)} - Zone ${query.zone} ${utils.capitalizeFirstLetter(TIME_GRANULARITY[query.time_granularity])} Time Series`} defaultViewMode="chart" fullScreen
         status={loading ? 'loading' : (data?.length ?? 0) < 1 ? 'noData' : 'success'} disableRefresh>
         {({ viewMode, isFullScreen }) => (
            
            <Line
               {...chartConfig}
               data={data ?? []}
               xField="date"
               yField="price"
               xAxis={{
                  ...chartConfig.xAxis,
                  /* tickCount: 12, */
                  label: {
                     formatter: (x) => parseDateByTimeGranularity(x, query.time_granularity),
                     autoRotate: query.time_granularity === TIME_GRANULARITY.daily,
                  },
               }}
               tooltip={{
                  title: (_, datum) => parseDateByTimeGranularity(datum.date, query.time_granularity),
                  /* formatter: (d) => ({
                     name: d.group,
                     value: normalizeNumber(d.y, { maximumFractionDigits: 2 }),
                  }), */
               }}
            />
               
         )}
      </CardPanel>
   );
};
