import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

const PowerMapDebug = ({ mapId }: { mapId: string }) => {
   const { [mapId]: map } = useMap();

   useEffect(() => {
      if (map) {
      // console.log('Map.PriceLayer.onLoad: ', mapId);
         (window as any).pmaps = (window as any).pmaps || {};
         (window as any).pmaps[mapId] = map;
      }

      return () => {
         if (map) {
            // console.log('Map.PriceLayer.onUnload: ', mapId);
            delete (window as any).pmaps[mapId];
         }
      };
   }, [map, mapId]);
   return null;
};

export default PowerMapDebug;
