import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
// import { REGEXES } from 'power/constants';
import { FC } from 'react';

type Props = {
  loading: boolean;
  onSignIn: (email: string, password: string) => Promise<void>;
  errorMessage?: string;
  onForgot: () => void;
};

const SignInForm: FC<Props> = ({ loading, onSignIn, errorMessage, onForgot }) => {
   const [form] = Form.useForm();

   return (
      <>
         {errorMessage && <Alert message={errorMessage} type="error" style={{ marginBottom: '1rem' }} />}
         <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => {
               onSignIn(values.email, values.password);
            }}
         >
            <Form.Item
               label="Email address"
               name="email"
               rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'The input is not valid E-mail!' },
               ]}
            >
               <Input
                  size="large" placeholder="Enter email address" prefix={<MailOutlined />}
                  disabled={loading} />
            </Form.Item>
            <Form.Item
               style={{ marginTop: '2rem' }}
               label="Password"
               name="password"
               rules={[
                  { required: true, message: 'Please input your password!' },
                  /* { min: 6, message: 'Password must be at least 6 characters long' },
                  { max: 24, message: 'Password must be at most 24 characters long' },
                  { pattern: REGEXES.PASSWORD, message: 'Password must contain at least one letter and one number' }, */
               ]}
            >
               <Input.Password
                  size="large" placeholder="Enter password" type="password"
                  prefix={<KeyOutlined />} disabled={loading} />
            </Form.Item>
            <Form.Item>
               <Button
                  style={{ marginTop: '1rem' }} block type="primary"
                  size="large" htmlType="submit" loading={loading}>
            Sign In
               </Button>
            </Form.Item>
            <Form.Item>
               <Button
                  type="link" block onClick={onForgot}
                  disabled={loading}>
            Forgot Your Password?
               </Button>
            </Form.Item>
         </Form>
      </>
   );
};

export default SignInForm;
