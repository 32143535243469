enum CONGESTION_COMPONENT {
  Undefined = 0,
  Avg = 1,
  Count = 2,
  NegativeAvg = 3,
  NegativeCount = 4,
  StdDev = 5,
}

export default CONGESTION_COMPONENT;
