import { Row, RowProps } from 'antd';
import { FC } from 'react';

const filterStyle = { borderBottom: '2px solid #C9C1C1', padding: '0 8px' };

type Props = RowProps & {
  filter?: boolean;
};
const PowerRow: FC<Props> = ({ filter = false, style, ...props }) => <Row gutter={16} {...props} style={filter ? { ...style, ...filterStyle } : style} />;

export default PowerRow;
