import { Radio } from 'antd';
import { HEADROOM_CAPACITY_DIRECTION, HEADROOM_CAPACITY_SEASON } from 'power/types/enum';
import { FC } from 'react';

type Props = {
  loading: boolean;
  season: HEADROOM_CAPACITY_SEASON;
  setSeason: (season: HEADROOM_CAPACITY_SEASON) => void;
  direction: HEADROOM_CAPACITY_DIRECTION;
  setDirection: (direction: HEADROOM_CAPACITY_DIRECTION) => void;
};

const HeadroomQuery: FC<Props> = ({ loading, season, setSeason, direction, setDirection }) => (
   <div style={{ paddingBottom: 14 }}>
      <Radio.Group
         disabled={loading} value={direction} onChange={(e) => setDirection(e.target.value)}
         style={{ marginRight: 100 }}>
         <Radio value={HEADROOM_CAPACITY_DIRECTION.CHARGING}>Injection</Radio>
         <Radio value={HEADROOM_CAPACITY_DIRECTION.DISCHARGING}>Withdrawal</Radio>
      </Radio.Group>
      
      <Radio.Group disabled={loading} value={season} onChange={(e) => setSeason(e.target.value)}>
         <Radio value={HEADROOM_CAPACITY_SEASON.SUMMER}>Summer Peak</Radio>
         <Radio value={HEADROOM_CAPACITY_SEASON.LIGHTLOAD}>Light Load</Radio>
         <Radio value={HEADROOM_CAPACITY_SEASON.WINTER}>Winter Peak</Radio>
      </Radio.Group>
   </div>
);

export default HeadroomQuery;
