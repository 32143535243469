/* eslint-disable max-len */
import { LMPDetailContent } from './type';

const en: LMPDetailContent = {
   detailPageTitle: 'LMP Detailed Analysis',
   lmpOverview: 'LMP Overview',
   dateFromDateTo: 'Date from/to Date',
   latLng: 'Lat/Lng',
   countyState: 'County/State',
   detailedAnalysis: 'Detailed Analysis',
   dataDownload: 'Data Download',
   heatmaps: 'Heatmaps',
   futureData: 'Future Data',
   substituteNode: 'Substitute Node',
   substituteNodeDescription: 'The substitute node is the closest node that provides data if the selected node doesn\'t have data for a required date range. The substitute node selection is based on low distance and high price profile similarity.',
   dartSpreadTimeSeries: 'DART Spread LMP - Time Series',
   lmpComponentsTimeSeries: 'LMP Components - Time Series',
   weightedSeries: 'Solar & Wind-Weighted Time Series',
   stdDevTimeSeries: 'Standard Deviation LMP - Time Series',
   dartSpreadTimeSeriesInfo:
    'The DART Spread Time Series chart displays day-ahead and real-time LMP(or its components) as well as the difference between them for the selected date range in time-series format. To view this in more detail, you can adjust the granularity of the time series. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   lmpComponentsTimeSeriesChartInfo:
    'The LMP Components Time Series chart displays the Marginal Cost of Energy (MCE), Marginal Cost of Congestion (MCC), and Marginal Cost of Loss (MCL) for the selected date range in a time-series format. To view these components in more detail, you can adjust the granularity of the time series. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   weightedSeriesChartInfo:
    'The Solar & Wind-Weighted LMP Time Series chart shows the LMP, Solar-weighted LMP, and Wind-weighted LMP for the real-time and day-ahead markets in time series format. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   stdDevTimeSeriesChartInfo:
    'The Standard Deviation LMP-Time Series chart displays the monthly time-series-based standard deviation of LMP (Locational Marginal Price) for the real-time and day-ahead markets. The “Range” slider allows you to adjust the y-axis maximum value to filter out outliers, enabling a more precise visualization of underlying patterns.',
   fuelMixTimeSeries: 'Fuel Mix Time Series',
   fuelMixTimeSeriesChartInfo: 'The Fuel Mix Time Series chart illustrates capacity in megawatt-hours(MWh) for different fuel types, with the filter selection of \'daily\' or \'hourly\' at the top of the page.',
};

export default en;
