import { Expression } from 'mapbox-gl';
import { MAP_LEGEND_COLORS } from 'power/constants/map/MAP_LEGEND_COLORS';

export const getLegendColorExpression = (min: number, max: number, linearProperty: Expression): Expression | string => {
   if (min === max) return MAP_LEGEND_COLORS[0];

   const tickValue = (max - min) / (MAP_LEGEND_COLORS.length - 1);

   const exp: Expression = ['interpolate', ['linear'], linearProperty];

   MAP_LEGEND_COLORS.forEach((color, i) => {
      exp.push(min + tickValue * i);
      exp.push(color);
   });
   return exp;
};