import { CloseOutlined, LineChartOutlined } from '@ant-design/icons';
import PowerMap from 'power/map';
import Legend from 'power/map/Legend';
import { FuturePriceRequest } from 'power/types';
import { FUTURE_SCENARIO, MARKET_TYPE } from 'power/types/enum';
import { useState } from 'react';
import FutureMapFilter from './FilterBar';
import classes from './index.module.scss';
import FutureZoneLayer from './layers/FutureZoneLayer';
import FutureZonePriceLayer from './layers/FutureZonePriceLayer';
import FutureTbx from './Tbx';
import { useFutureData } from './useFutureData';

const MAP_ID = 'future-map-v2';
const INITIAL_QUERY: FuturePriceRequest = { scenario: FUTURE_SCENARIO.REF, market_type: MARKET_TYPE.DayAhead, year: 2028, month: 0 };

const FutureMapTab = () => {
   const { query, loading, data, minmax, onSubmit } = useFutureData(INITIAL_QUERY);
   const [futureTbxViewOpen, setFutureTbxViewOpen] = useState(false);

   return (
      <div className={classes.fullContainer}>
         <div className={classes.filterBar}>
            <FutureMapFilter loading={loading} query={query} onSubmit={onSubmit} />
         </div>
         <div className={classes.container}>
            <PowerMap
               id={MAP_ID}
               /* customMapProps={{ maxBounds: [-82.02867082714488, 40.3754382777056, -69.65441258121662, 45.12977354909813] as [number, number, number, number], minZoom: 6.7, maxZoom: 6.7 }} */
               customMapProps={{ maxBounds: [-81.64, 40.22, -68.61, 45.225] as [number, number, number, number], minZoom: 5.99, maxZoom: 5.99, maxPitch: 0, bearing: 0 }}
            >
               {!loading && data && <FutureZoneLayer mapId={MAP_ID} prices={data} minmax={minmax} />}
               {!loading && data && <FutureZonePriceLayer mapId={MAP_ID} prices={data} minmax={minmax} />}
            </PowerMap>
            {minmax[0] !== minmax[1] && (
               <div style={{ position: 'absolute', width: 280, bottom: 16, left: 16, borderRadius: 8, background: 'white', boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.16)', padding: '12px 12px 4px 8px' }}>
                  <Legend  scaleValues={[{ label: 'Price', minmax, position: 'bottom' }]} backgroundColor="linear-gradient(90deg, #ffbaba 0, #ff7b7b 25%, #ff5252 50%, red 75%, #a70000)" />
               </div>
            )}
            {futureTbxViewOpen && <FutureTbx query={query} />}
            {futureTbxViewOpen ? (
               <div
                  style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }}
                  onClick={() => setFutureTbxViewOpen(false)}
               >
                  <CloseOutlined style={{ fontSize: 32 }} />
               </div>
            ) : (
               <>
                  <div style={{ position: 'absolute', right: 12, top: 12, backgroundColor: '#42B389', borderRadius: 8, color: 'white', zIndex: 100, width: 52, height: 52, padding: 10, cursor: 'pointer' }} onClick={() => setFutureTbxViewOpen(true)}>
                     <LineChartOutlined style={{ fontSize: 32 }} />
                  </div>
               </>
            )}
         </div>
      </div>
   );
};

export default FutureMapTab;
