import { Layout } from 'antd';
import SidebarRight from 'layout/PrivatePageLayout/SidebarRight';
import { /* useEffect, */ useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarNavigator } from '../PrivatePageLayout/SidebarNavigator';
import classes from './index.module.scss';

export const PrivateRootLayout = ({ children }: { children?: React.ReactNode }) => {
   const [collapsedMenu, setCollapsedMenu] = useState(true);

   /* useEffect(()=>{
        setCollapsedMenu(true);
    },[setCollapsedMenu])  */

   return (
      <Layout className={classes.RootLayout}>
         {children}
         <SidebarNavigator collapsedMenu={collapsedMenu} setCollapsedMenu={(v) => setCollapsedMenu(v)} />
         <Layout style={{ marginLeft: 48, marginRight: 48 }} /* style={{ marginLeft: collapsedMenu ? 80 : 200 }} */>
            <Outlet />
         </Layout>
         <SidebarRight title="" />
      </Layout>
   );
};
